import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectorOnKeyWithoutFormik from "views/Components/College/SelectorOnKeyWithoutFormik";
import _ from "lodash";
import AsyncMultiSelector from "views/Components/College/AsyncMultiSelector.jsx";
import api from "data/utils/api";

const UniversitySelectorWithoutFormik = ({
  elements,
  handleEditData,
  CollegeIndex,
  university_type_id,
  institution_type_id,
  affiliated_to
}) => {
  const [institute_type, setInstituteType] = useState();

  useEffect(() => {
    if (!institution_type_id) {
      handleEditData({
        value: null,
        index: CollegeIndex,
        key: "university_type_id"
      });
      handleEditData({
        value: null,
        index: CollegeIndex,
        key: "affiliated_to"
      });
      setInstituteType(null);
      return;
    }
    if (elements.data.length && institution_type_id) {
      const instituteTypeString = elements.data
        .filter(item => item.key === "institution_types")[0]
        .data.filter(item => item.id === institution_type_id)[0]
        .full_name.trim()
        .toLowerCase();
      setInstituteType(instituteTypeString);
      if (instituteTypeString === "college") {
        handleEditData({
          value: null,
          index: CollegeIndex,
          key: "university_type_id"
        });
      } else if (instituteTypeString === "university") {
        handleEditData({
          value: null,
          index: CollegeIndex,
          key: "affiliated_to"
        });
      }
    }
  }, [institution_type_id]);

  const getFilteredElementsData = () => {
    let universityData = null;
    if (institute_type === "university") {
      universityData = (
        <SelectorOnKeyWithoutFormik
          name="university_type_id"
          valuesData={university_type_id}
          keyFilter="university_types"
          isMulti={false}
          nameKey="university_type_id"
          setSelectorValue={value =>
            handleEditData({
              value,
              index: CollegeIndex,
              key: "university_type_id"
            })
          }
        />
      );
    } else if (institute_type === "college") {
      universityData = (
        <React.Fragment>
          <p style={{ fontWeight: "bold" }}>Affiliated To</p>
          <AsyncMultiSelector
            selectedValues={affiliated_to}
            filterKey="affiliated_to"
            isMulti={false}
            name="affiliated_to"
            apiEndpoint={api.endpoints.college.filters}
            onChange={value =>
              handleEditData({
                value,
                index: CollegeIndex,
                key: "affiliated_to"
              })
            }
          />
        </React.Fragment>
      );
    }
    return universityData;
  };

  return (
    <React.Fragment>
      {elements.data.length ? (
        <div>{getFilteredElementsData(elements.data)}</div>
      ) : null}
    </React.Fragment>
  );
};

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(UniversitySelectorWithoutFormik);
