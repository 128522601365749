import React, { Component } from "react";
import { Prompt } from "react-router-dom";

export default class CheckFormikDirty extends Component {
  componentDidMount() {
    window.onbeforeunload = function(e) {
      e.preventDefault();
      return "Do you really want to leave our brilliant application?";
      //if we return nothing here (just calling return;) then there will be no pop-up question at all
      //return;
    };
  }
  render() {
    const { isDirty } = this.props;
    return (
      <React.Fragment>
        <Prompt
          when={isDirty}
          message={location =>
            `Leave Editor ?
             Changes that you made may not be saved.`
          }
        />
      </React.Fragment>
    );
  }
}
