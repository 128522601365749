import api from "data/utils/api";
import {
  ELEMENTS_APPSTATE_ALL_LOADING,
  ELEMENTS_APPSTATE_ALL_SUCCESS,
  ELEMENTS_APPSTATE_ALL_ERROR,
  ELEMENTS_DATA_ALL_GET,
  ELEMENTS_APPSTATE_ONE_LOADING,
  ELEMENTS_APPSTATE_ONE_SUCCESS,
  ELEMENTS_APPSTATE_ONE_ERROR,
  ELEMENTS_DATA_ONE_ADD,
  ELEMENTS_DATA_ONE_UPDATE,
  ELEMENTS_DATA_ONE_DELETE,
  ELEMENTS_DATA_REFRESH,
  ELEMENTS_UI_HIDE_DATA_MODAL,
  ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL,
  ELEMENTS_UI_HIDE_ALERT_BOX,
  ELEMENTS_UI_HIDE_STREAM_DATA_MODAL,
  ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_TAG_DATA_MODAL
} from "data/elements/types";

import showNotification from "data/utils/notificationObject";

// =================================================================================================
// GET ALL ELEMENTS ================================================================================
// =================================================================================================
export const getAllElements = () => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ALL_LOADING });
  try {
    const res = await api.get(api.endpoints.elements);
    dispatch({ type: ELEMENTS_DATA_ALL_GET, data: res.data });
    dispatch({ type: ELEMENTS_APPSTATE_ALL_SUCCESS });
  } catch (error) {
    dispatch({
      type: ELEMENTS_APPSTATE_ALL_ERROR,
      error
    });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE ELEMENT =================================================================================
// =================================================================================================
export const addElement = (path, elementIndex, data) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(`${api.endpoints.elements}${path}`, data);
    dispatch({
      type: ELEMENTS_DATA_ONE_ADD,
      elementIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_DATA_MODAL });
    dispatch(showNotification({ message: "Record Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE TAG ELEMENT =============================================================================
// =================================================================================================
export const addTagElement = (path, elementIndex, data) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(`${api.endpoints.elements}${path}`, data);
    dispatch({
      type: ELEMENTS_DATA_ONE_ADD,
      elementIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_TAG_DATA_MODAL });
    dispatch(showNotification({ message: "Record Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE BRANCH ELEMENT =============================================================================
// =================================================================================================
export const addIndependentBranchElement = (
  path,
  elementIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(`${api.endpoints.elements}${path}`, data);
    dispatch({
      type: ELEMENTS_DATA_ONE_ADD,
      elementIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL });
    dispatch(showNotification({ message: "Record Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE FEATURE ELEMENT =========================================================================
// =================================================================================================
export const addFeatureElement = (
  path,
  featurePath,
  featureId,
  elementIndex,
  featureIndex,
  featureElementIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(
      `${api.endpoints.elements}${featurePath}/${featureId}${path}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_ADD,
      elementIndex,
      featureIndex,
      featureElementIndex,
      isFeature: true,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL });
    dispatch(showNotification({ message: "Feature Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE STREAM ELEMENT ==========================================================================
// =================================================================================================
export const addStreamElement = (
  path,
  streamPath,
  streamId,
  elementIndex,
  streamIndex,
  streamElementIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(
      `${api.endpoints.elements}${streamPath}/${streamId}${path}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_ADD,
      elementIndex,
      streamIndex,
      streamElementIndex,
      isStream: true,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_STREAM_DATA_MODAL });
    dispatch(showNotification({ message: "Course Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// ADD ONE BRANCH ELEMENT ==========================================================================
// =================================================================================================
export const addBranchElement = (
  streamPath,
  streamId,
  coursePath,
  courseId,
  branchPath,
  elementIndex,
  streamIndex,
  streamElementIndex,
  courseIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.post(
      `${
        api.endpoints.elements
      }${streamPath}/${streamId}${coursePath}/${courseId}${branchPath}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_REFRESH,
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      isBranch: true,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL });
    dispatch(showNotification({ message: "Branch Added Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE ELEMENT ==============================================================================
// =================================================================================================
export const updateElement = (
  path,
  elementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${api.endpoints.elements}${path}/${data.id}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      rowIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_DATA_MODAL });
    dispatch(showNotification({ message: "Record Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE TAG ELEMENT ==========================================================================
// =================================================================================================
export const updateTagElement = (
  path,
  elementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${api.endpoints.elements}${path}/${data.id}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      rowIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_TAG_DATA_MODAL });
    dispatch(showNotification({ message: "Record Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE TAG ELEMENT ==========================================================================
// =================================================================================================
export const updateIndependentBranchElement = (
  path,
  elementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${api.endpoints.elements}${path}/${data.id}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      rowIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL });
    dispatch(showNotification({ message: "Record Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE FEATURE ELEMENT ======================================================================
// =================================================================================================
export const updateFeatureElement = (
  path,
  featurePath,
  featureId,
  elementIndex,
  featureIndex,
  featureElementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${api.endpoints.elements}${featurePath}/${featureId}${path}/${data.id}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      featureIndex,
      featureElementIndex,
      isFeature: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL });
    dispatch(showNotification({ message: "Feature Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE STREAM ELEMENT =======================================================================
// =================================================================================================
export const updateStreamElement = (
  path,
  streamPath,
  streamId,
  elementIndex,
  streamIndex,
  streamElementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${api.endpoints.elements}${streamPath}/${streamId}${path}/${data.id}`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      streamIndex,
      streamElementIndex,
      isStream: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_STREAM_DATA_MODAL });
    dispatch(showNotification({ message: "Course Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// UPDATE ONE BRANCH  ELEMENT =======================================================================
// =================================================================================================
export const updateBranchElement = (
  streamPath,
  streamId,
  coursePath,
  courseId,
  branchPath,
  elementIndex,
  streamIndex,
  streamElementIndex,
  courseIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.patch(
      `${
        api.endpoints.elements
      }${streamPath}/${streamId}${coursePath}/${courseId}${branchPath}/${
        data.id
      }`,
      data
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_UPDATE,
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      isBranch: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL });
    dispatch(showNotification({ message: "Branch Updated Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// DELETE ONE ELEMENT ==============================================================================
// =================================================================================================
export const deleteElement = (
  path,
  elementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.delete(`${api.endpoints.elements}${path}/${data.id}`);
    dispatch({
      type: ELEMENTS_DATA_ONE_DELETE,
      elementIndex,
      rowIndex,
      data: res.data
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification({ message: "Record Deleted Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// DELETE ONE FEATURE ELEMENT ======================================================================
// =================================================================================================
export const deleteFeatureElement = (
  path,
  featurePath,
  featureId,
  elementIndex,
  featureIndex,
  featureElementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.delete(
      `${api.endpoints.elements}${featurePath}/${featureId}${path}/${data.id}`
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_DELETE,
      elementIndex,
      featureIndex,
      featureElementIndex,
      isFeature: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification({ message: "Feature Deleted Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// DELETE ONE STREAM ELEMENT ======================================================================
// =================================================================================================
export const deleteStreamElement = (
  path,
  streamPath,
  streamId,
  elementIndex,
  streamIndex,
  streamElementIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.delete(
      `${api.endpoints.elements}${streamPath}/${streamId}${path}/${data.id}`
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_DELETE,
      elementIndex,
      streamIndex,
      streamElementIndex,
      isStream: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification({ message: "Course Deleted Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// DELETE ONE BRANCH ELEMENT =======================================================================
// =================================================================================================
export const deleteBranchElement = (
  streamPath,
  streamId,
  coursePath,
  courseId,
  branchPath,
  elementIndex,
  streamIndex,
  streamElementIndex,
  courseIndex,
  rowIndex,
  data
) => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_ONE_LOADING });
  try {
    const res = await api.delete(
      `${
        api.endpoints.elements
      }${streamPath}/${streamId}${coursePath}/${courseId}${branchPath}/${
        data.id
      }`
    );
    dispatch({
      type: ELEMENTS_DATA_ONE_DELETE,
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      isBranch: true,
      data: res.data,
      rowIndex
    });
    dispatch({ type: ELEMENTS_APPSTATE_ONE_SUCCESS });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification({ message: "Branch Deleted Successfully" }));
  } catch (error) {
    dispatch({ type: ELEMENTS_APPSTATE_ONE_ERROR, error });
    dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
    dispatch(showNotification(error, false));
  }
};
