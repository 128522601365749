import { Switch, Route } from "react-router-dom";
import React, { Component } from "react";
import CollegeListing from "views/College/CollegeListing/CollegeListing.jsx";
import CollegeDetail from "views/College/Detail/CollegeDetail.jsx";
import CollegeBasicTabs from "views/College/Basic/CollegeBasicTabs.jsx";
import CollegeBasic from "views/College/Basic/CollegeBasic/CollegeBasic.jsx";
import GenericDetailSection from "../views/College/Detail/GenericDetailSection";
import CollegeDetailListing from "../views/College/CollegeListing/CollegeDetailListing/CollegeDetailListing";

class CollegeSection extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/college-listing/basic" component={CollegeBasic} />
          <Route
            path="/college-listing/basic/:id"
            component={CollegeBasicTabs}
          />
          <Route
            path="/college-listing/detail/:id/:tabId"
            component={GenericDetailSection}
          />
          <Route path="/college-listing/detail/:id" component={CollegeDetail} />
          <Route path="/college-listing" component={CollegeListing} />
          <Route
            path="/college-detail-listing"
            component={CollegeDetailListing}
          />
        </Switch>
      </div>
    );
  }
}

export default CollegeSection;
