import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import api from "data/utils/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";

const yearOptions = [
  { value: 2020, label: 2020 },
  { value: 2019, label: 2019 },
  { value: 2018, label: 2018 },
  { value: 2017, label: 2017 },
  { value: 2016, label: 2016 },
  { value: 2015, label: 2015 },
  { value: 2014, label: 2014 },
  { value: 2013, label: 2013 },
  { value: 2012, label: 2012 },
  { value: 2011, label: 2011 },
  { value: 2010, label: 2010 }
];

const reservationCategoryOptions = [
  { id: 1, value: "General", label: "General" },
  {
    id: 2,
    value: "Other Backward Caste (OBC)",
    label: "Other Backward Caste (OBC)"
  },
  { id: 4, value: "Scheduled Caste (SC)", label: "Scheduled Caste (SC)" },
  { id: 5, value: "Scheduled Tribe (ST)", label: "Scheduled Tribe (ST)" },
  {
    id: 8,
    value: "Persons with Disability (PwD)",
    label: "Persons with Disability (PwD)"
  },
  { id: 9, value: "Women", label: "Women" }
];

const FormRankPredictionSection = ({ history, id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const columns = [
    {
      title: "Marks Start",
      field: "marks_start",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Marks End",
      field: "marks_end",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Rank Start",
      field: "rank_start",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Rank End",
      field: "rank_end",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Year",
      field: "year",
      cellStyle: { width: "15%" },
      editComponent: props => (
        <Select
          defaultValue={yearOptions[0] ? yearOptions[0] : null}
          options={yearOptions}
          onChange={e => props.onChange(e.value)}
          components={makeAnimated()}
        />
      )
    },
    {
      title: "Category",
      field: "category_id",
      cellStyle: { width: "25%" },
      editComponent: props => (
        <Select
          defaultValue={
            reservationCategoryOptions[0] ? reservationCategoryOptions[0] : null
          }
          options={reservationCategoryOptions}
          components={makeAnimated()}
          onChange={e => props.onChange(e.value)}
        />
      )
    }
  ];

  const handleReservationCategoryValue = value => {
    return reservationCategoryOptions.filter(item => item.value === value);
  };

  const handleYearValue = value => {
    return yearOptions.filter(item => item.value === value);
  };

  const { enqueueSnackbar } = useSnackbar();

  const getCategoryValue = category_id =>
    (
      reservationCategoryOptions.filter(item => item.id === category_id)[0] ||
      {}
    ).value;

  const getCategoryId = category_value =>
    (
      reservationCategoryOptions.filter(
        item => item.value === category_value
      )[0] || {}
    ).id;

  const handleData = data =>
    (data || []).map(item => ({
      ...item,
      category_id: getCategoryValue(item.category_id)
    }));

  useEffect(() => {
    async function getRankPredictionData() {
      //make api call here.
      try {
        setLoading(true);
        const res = await api.get(
          `${api.endpoints.forms}/${id}/rank-prediction`
        );
        const data = res.data;
        setLoading(false);
        const modifiedData = handleData(data);
        setData(modifiedData);
      } catch (error) {
        const { data: { message = "Something Unusual Happened" } = {} } =
          error || {};
        setLoading(false);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
    getRankPredictionData();
  }, [id, enqueueSnackbar]);

  const handleDataAddition = async newData => {
    try {
      const modifiedData = {
        ...newData,
        category_id: getCategoryId(newData.category_id)
      };
      const res = await api.post(
        `${api.endpoints.forms}/${id}/rank-prediction`,
        modifiedData
      );
      const newDataWithId = { ...newData, id: res.id };
      const finalData = [...data, newDataWithId];
      setData(finalData);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleDataDeletion = async newData => {
    try {
      const rank_id = newData.id;
      await api.delete(
        `${api.endpoints.forms}/${id}/rank-prediction/${rank_id}`
      );
      let oldData = [...data];
      const index = oldData.indexOf(newData);
      oldData.splice(index, 1);
      setData(oldData);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleDataUpdation = async (newData, oldData) => {
    try {
      const rank_id = newData.id;
      const modifiedData = {
        ...newData,
        category_id: getCategoryId(newData.category_id)
      };
      await api.put(
        `${api.endpoints.forms}/${id}/rank-prediction/${rank_id}`,
        modifiedData
      );
      let overallData = [...data];
      const index = overallData.indexOf(oldData);
      overallData[index] = newData;
      setData(overallData);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  return (
    <MaterialTable
      title="Rank Prediction"
      columns={columns}
      data={data}
      options={{
        actionsColumnIndex: 6
      }}
      editable={{
        onRowAdd: newData => handleDataAddition(newData),
        onRowUpdate: (newData, oldData) => handleDataUpdation(newData, oldData),
        onRowDelete: newData => handleDataDeletion(newData)
      }}
    />
  );
};

export default withStyles(formStyle)(FormRankPredictionSection);
