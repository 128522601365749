import {
  COLLEGES_APPSTATE_ALL_LOADING,
  COLLEGES_APPSTATE_ALL_SUCCESS,
  COLLEGES_APPSTATE_ALL_ERROR,
  COLLEGES_DATA_CONTENT_COUNT,
  COLLEGES_DATA_TOTAL_COUNT,
  COLLEGES_DATA_PAGE_START,
  COLLEGES_DATA_CURRENT_PAGE
} from "data/colleges/types";

export default function reducer(
  state = {
    loading: true,
    error: null,
    totalCount: 0,
    contentCount: 0,
    pageStart: 0,
    pageLimit: 20,
    currentPage: 1
  },
  action
) {
  switch (action.type) {
    case COLLEGES_APPSTATE_ALL_LOADING:
      return { ...state, loading: true };
    case COLLEGES_APPSTATE_ALL_SUCCESS:
      return { ...state, error: null, loading: false };
    case COLLEGES_APPSTATE_ALL_ERROR:
      return { ...state, loading: false, error: action.error };
    case COLLEGES_DATA_TOTAL_COUNT:
      return { ...state, totalCount: action.totalCount };
    case COLLEGES_DATA_CONTENT_COUNT:
      return { ...state, contentCount: action.contentCount };
    case COLLEGES_DATA_PAGE_START:
      return { ...state, pageStart: action.pageStart };
    // case COLLEGES_DATA_PAGE_LIMIT:
    //   return { ...state, pageLimit: action.pageLimit };
    case COLLEGES_DATA_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };

    default:
      return state;
  }
}
