import React, { Component } from "react";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import _ from "lodash";
import PictureUpload from "components/CustomUpload/PictureUpload";
import slug from "slug";
class ImageUploadDetails extends Component {
  handleClickValue = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleTitleChange = e => {
    const slug_name =
      slug(e.target.value, { lower: true }) + "." + this.state.extension;
    let stateObject = { title: e.target.value };
    if (this.state.shouldRenameURL) {
      stateObject.url = slug_name;
    }
    this.setState(stateObject);
  };

  constructor(props) {
    super(props);
    const { selectedImage } = props;

    let url = _.get(selectedImage, "url", "");
    let extension = "";
    if (!_.isEmpty(url)) {
      url = _.last(selectedImage.url.split("/"), "");
      extension = _.last(url.split("."), "");
    }

    this.state = {
      id: selectedImage && selectedImage.id ? selectedImage.id : undefined,
      url,
      extension,
      title: _.get(selectedImage, "title", ""),
      alt_text: _.get(selectedImage, "alt_text", ""),
      caption: _.get(selectedImage, "caption", ""),
      description: _.get(selectedImage, "description", ""),
      shouldRenameURL: false,
      display_url: _.get(selectedImage, "display_url", "")
    };
  }

  handlePictureUpload = file => {
    const title =
      file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
    const extension = _.last(file.name.split("."));
    const url = slug(title, { lower: true }) + "." + extension;
    this.setState({ file, title, url, extension, shouldRenameURL: true });
  };

  render() {
    const { url, title, alt_text, caption, description } = this.state;
    const { classes, displayURL } = this.props;
    return (
      <React.Fragment>
        <GridContainer justify="flex-start" alignItems="center">
          <GridItem xs={12} sm={12} md={5}>
            <PictureUpload
              pictureData={this.handlePictureUpload}
              imageUrl={displayURL}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={7}>
            <GridContainer justify="flex-start">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: "10px" }}>
                <GridContainer justify="flex-start">
                  <GridItem>
                    <label className={classes.inputHeader}>Logo URL</label>
                  </GridItem>
                  <BareTextFieldInputOnChange
                    name="url"
                    fullWidth
                    disabled
                    placeholder="URL"
                    onBlur={e => {
                      this.handleClickValue(e);
                    }}
                    value={url}
                  />
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{ marginBottom: "10px" }}>
                <GridContainer justify="flex-start">
                  <GridItem>
                    <label className={classes.inputHeader}>Title</label>
                  </GridItem>
                  <BareTextFieldInputOnChange
                    name="title"
                    fullWidth
                    placeholder="Title"
                    onBlur={e => {
                      this.handleTitleChange(e);
                    }}
                    value={title}
                  />
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <GridContainer justify="flex-start">
                  <GridItem>
                    <label className={classes.inputHeader}>Caption</label>
                  </GridItem>
                  <BareTextFieldInputOnChange
                    name="caption"
                    fullWidth
                    placeholder="Caption"
                    onBlur={e => {
                      this.handleClickValue(e);
                    }}
                    value={caption}
                  />
                </GridContainer>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: "10px" }}>
                <GridContainer justify="flex-start">
                  <GridItem>
                    <label className={classes.inputHeader}>Alt Text</label>
                  </GridItem>
                  <BareTextFieldInputOnChange
                    name="alt_text"
                    fullWidth
                    placeholder="Alt Text"
                    onBlur={e => {
                      this.handleClickValue(e);
                    }}
                    value={alt_text}
                  />
                </GridContainer>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: "10px" }}>
                <GridContainer justify="flex-start">
                  <GridItem>
                    <label className={classes.inputHeader}>Description</label>
                  </GridItem>
                  <BareTextFieldInputOnChange
                    name="description"
                    multiline
                    styleHeight
                    rows="3"
                    rowsMax="5"
                    fullWidth
                    placeholder="Description"
                    onBlur={e => {
                      this.handleClickValue(e);
                    }}
                    value={description}
                  />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              simple
              onClick={this.props.onClose}
              style={{ color: "grey" }}>
              Cancel
            </Button>
            <Button
              onClick={() => this.props.onSubmit(this.state)}
              color="rose">
              Apply
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(ImageUploadDetails);
