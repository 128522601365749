import { Switch, Route } from "react-router-dom";
import React, { Component } from "react";
import FormListing from "views/Forms/EazyForms/FormListing/FormListing.jsx";
import FormBasic from "views/Forms/EazyForms/FormBasic/FormBasic.jsx";
import GenericDetailSection from "../views/College/Detail/GenericDetailSection";
import FormBasicTabs from "../views/Forms/EazyForms/FormBasic/FormBasicTabs";

class FormSection extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/form-listing/basic" component={FormBasic} />
          <Route path="/form-listing/basic/:id" component={FormBasicTabs} />
          <Route
            path="/form-listing/detail/:id/:tabId"
            component={GenericDetailSection}
          />
          <Route path="/form-listing" component={FormListing} />
        </Switch>
      </div>
    );
  }
}

export default FormSection;
