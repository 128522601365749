import React, { Component } from "react";

import ElementCell from "views/Forms/Elements/ElementCell.jsx";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

import { connect } from "react-redux";
import DataModal from "./DataModal";
import * as actions from "data/elements/actions";
import DeleteAlertBox from "./DeleteAlertBox";
// import FormNotifications from "../../MainNotification";
import FeatureList from "./FeatureList";
import StreamList from "./StreamList";
import Accordion from "../../../components/Accordion/Accordion";
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import CollegeTag from "./CollegeTag";
import TagDataModal from "./TagDataModal";
import BranchIndependentDataModal from "./Streams/BranchIndependentDataModal";
import BranchIndependentCell from "./Streams/BranchIndependentCell";

class ElementList extends Component {
  componentDidMount() {
    this.props.getAllElements();
  }

  // =================================================================================================
  // ELEMENT LAYOUT CREATION =========================================================================
  // =================================================================================================
  handleElementsLayout = () => {
    if (this.props.elements.appState.loading) {
      return <CustomLinearProgress color="primary" />;
    }

    if (this.props.elements.appState.error) {
      return <pre>Something Went Wrong</pre>;
    }

    if (
      this.props.elements.appState.loading === false &&
      this.props.elements.data
    ) {
      const elementsData = [
        {
          name: "basic",
          data: this.props.elements.data.filter((data, index) => {
            if (data.section === "basic") {
              let modifiedData = data;
              modifiedData["elementIndex"] = index;
              return modifiedData;
            }
          })
        },
        {
          name: "form",
          data: this.props.elements.data.filter((data, index) => {
            if (data.section === "form") {
              let modifiedData = data;
              modifiedData["elementIndex"] = index;
              return modifiedData;
            }
          })
        },
        {
          name: "college",
          data: this.props.elements.data.filter((data, index) => {
            if (data.section === "college") {
              let modifiedData = data;
              modifiedData["elementIndex"] = index;
              return modifiedData;
            }
          })
        }
      ];

      return (
        <Tabs
          title="Elements:"
          headerColor="rose"
          tabs={elementsData.map(element => {
            return {
              tabName: element.name,
              tabContent: (
                <Accordion
                  active={0}
                  collapses={element.data.map((item, index) => {
                    return {
                      title: item.name,
                      content: (function getCell(itemType) {
                        switch (itemType) {
                          case "short_full":
                            return (
                              <ElementCell
                                key={index}
                                elementIndex={item.elementIndex}
                              />
                            );
                          case "feature":
                            return (
                              <FeatureList
                                key={index}
                                elementIndex={item.elementIndex}
                              />
                            );
                          case "stream":
                            return (
                              <StreamList
                                key={index}
                                elementIndex={item.elementIndex}
                              />
                            );
                          case "tag":
                            return (
                              <CollegeTag
                                key={index}
                                elementIndex={item.elementIndex}
                              />
                            );
                          case "branch":
                            return (
                              <BranchIndependentCell
                                key={index}
                                elementIndex={item.elementIndex}
                              />
                            );
                          default:
                            return null;
                        }
                      })(item.type)
                    };
                  })}
                />
              )
            };
          })}
        />
        /*{ <Accordion
          active={0}
          collapses={this.props.elements.data.map((item, index) => {
            return {
              title: item.name,
              content: (function getCell(itemType) {
                switch (itemType) {
                  case "short_full":
                    return <ElementCell key={index} elementIndex={index} />;
                  case "feature":
                    return <FeatureList key={index} elementIndex={index} />;
                  case "stream":
                    return <StreamList key={index} elementIndex={index} />;
                  default:
                    return null;
                }
              })(item.type)
            };
          })}
        /> } */
      );
    }
  };

  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleModalLayout = () => {
    return this.props.elements.ui.showModal ? <DataModal /> : null;
  };

  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleTagModalLayout = () => {
    return this.props.elements.ui.showTagModal ? <TagDataModal /> : null;
  };

  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleIndependentBranchModalLayout = () => {
    return this.props.elements.ui.showIndependentBranchModal ? (
      <BranchIndependentDataModal />
    ) : null;
  };

  // =================================================================================================
  // ELEMENT DELETION HANDLING =======================================================================
  // =================================================================================================
  handleDelete = () => {
    const { elements } = this.props;
    const { data, appState } = this.props.elements;

    if (appState.isFeature) {
      const {
        elementIndex,
        featureIndex,
        featureElementIndex,
        rowIndex
      } = appState;

      const features = elements.data[elementIndex];
      const feature = features.data[featureIndex];
      const featureElement = feature.data[featureElementIndex];

      const featurePath = features.path;
      const featureId = feature.id;
      const path = featureElement.path;
      const rowData = featureElement.data[rowIndex];

      this.props.deleteFeatureElement(
        path,
        featurePath,
        featureId,
        elementIndex,
        featureIndex,
        featureElementIndex,
        rowIndex,
        rowData
      );
    } else if (appState.isStream) {
      const {
        elementIndex,
        streamIndex,
        streamElementIndex,
        rowIndex
      } = appState;

      const streams = elements.data[elementIndex];
      const stream = streams.data[streamIndex];
      const streamElement = stream.data[streamElementIndex];

      const streamPath = streams.path;
      const streamId = stream.id;
      const path = streamElement.path;
      const rowData = streamElement.data[rowIndex];

      this.props.deleteStreamElement(
        path,
        streamPath,
        streamId,
        elementIndex,
        streamIndex,
        streamElementIndex,
        rowIndex,
        rowData
      );
    } else if (appState.isBranch) {
      const {
        elementIndex,
        streamIndex,
        streamElementIndex,
        courseIndex,
        rowIndex
      } = appState;

      const streams = elements.data[elementIndex];
      const stream = streams.data[streamIndex];
      const streamElement = stream.data[streamElementIndex];

      const streamCell = streamElement.data;
      const streamPath = streams.path;
      const streamId = stream.id;
      const coursePath = streamElement.path;
      const courseId = streamElement.data[courseIndex].id;

      const courseElement = streamCell[courseIndex];
      const branchElement = courseElement.data;
      const branchPath = branchElement.path;
      const rowData = branchElement.data[rowIndex];

      this.props.deleteBranchElement(
        streamPath,
        streamId,
        coursePath,
        courseId,
        branchPath,
        elementIndex,
        streamIndex,
        streamElementIndex,
        courseIndex,
        rowIndex,
        rowData
      );
    } else {
      const { elementIndex, rowIndex } = appState;
      const path = data[elementIndex].path;
      const deletingData = data[elementIndex].data[rowIndex];
      this.props.deleteElement(path, elementIndex, rowIndex, deletingData);
    }
  };

  // =================================================================================================
  // CLOSE ALERT BOX =================================================================================
  // =================================================================================================
  hideAlert = () => {
    this.props.hideDeleteBox();
  };

  // =================================================================================================
  // HANDLING DELETE ALERT BOX =======================================================================
  // =================================================================================================
  handleDeleteLayout = () => {
    return this.props.elements.ui.showDeleteAlert === true ? (
      <DeleteAlertBox
        handleSuccess={this.handleDelete}
        handleClose={this.hideAlert}
      />
    ) : null;
  };

  render() {
    return (
      <div>
        <React.Fragment>{this.handleElementsLayout()}</React.Fragment>
        {this.handleModalLayout()}
        {this.handleTagModalLayout()}
        {this.handleIndependentBranchModalLayout()}
        {this.handleDeleteLayout()}
      </div>
    );
  }
}

ElementList.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps({ elements }) {
  return { elements };
}
export default withStyles(sweetAlertStyle)(
  connect(mapStateToProps, actions)(ElementList)
);
