import { combineReducers } from "redux";
import appStateReducers from "./appStateReducers";
import dataReducers from "./dataReducers";
import uiReducers from "./uiReducers";

export default combineReducers({
  appState: appStateReducers,
  data: dataReducers,
  ui: uiReducers
});
