import { FORM_BASIC_DATA_GET, FORM_BASIC_DATA_RESET } from "data/forms/types";

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case FORM_BASIC_DATA_GET:
      return action.data;
    case FORM_BASIC_DATA_RESET:
      return {};
    default:
      return state;
  }
}
