import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class FormikCheckbox extends Component {
  render() {
    const { classes, onBlur, value, label } = this.props;
    return (
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            checked={value}
            onClick={() => onBlur(value)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked
            }}
          />
        }
        label={label ? label : "Yes/No?"}
        labelPlacement="start"
        style={{ margin: "0px" }}
      />
    );
  }
}

export default withStyles(extendedFormsStyle)(FormikCheckbox);
