import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import { Formik, Form, FastField } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";
import _ from "lodash";
import StreamSelectors from "views/College/Basic/Stream/StreamSelectors";
import StreamsAccordion from "views/College/Basic/Stream/StreamsAccordion";
import api from "data/utils/api";
import { showNotification } from "data/main/actions";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

class Stream extends Component {
  state = {
    showDeleteAlert: false,
    isLoading: true,
    initialData: {
      streams: []
    }
  };

  getStreamById = async id => {
    try {
      const res = await api.get(
        `${api.endpoints.colleges}/${id}/stream-details`
      );
      this.setState({ isLoading: false, initialData: res.data });
    } catch (error) {
      // console.log(error);
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong,Stream"),
        false
      );
    }
  };

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.getStreamById(id);
    }
  }

  submitCollegeBasicForm = async data => {
    const { id } = this.props;
    this.setState({ isLoading: true });
    try {
      await api.post(`${api.endpoints.colleges}/${id}/stream-details`, {
        data
      });
      this.props.showNotification("Stream Updated Successfully");
      this.setState({ isLoading: false, initialData: data });
      // history.push("/college-listing");
    } catch (error) {
      this.setState({ isLoading: false, initialData: data });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong,Stream"),
        false
      );
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Streams</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Formik
              enableReinitialize={true}
              initialValues={this.state.initialData}
              isValidating={false}
              onSubmit={(values, { setSubmitting }) => {
                this.submitCollegeBasicForm(values);
              }}
            >
              {props => {
                const {
                  values,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit
                } = props;
                return (
                  <Form onSubmit={handleSubmit}>
                    <FastField
                      component={StreamSelectors}
                      setFieldValue={setFieldValue}
                      values={values}
                    />

                    <FastField
                      component={StreamsAccordion}
                      setFieldValue={setFieldValue}
                      streams={values.streams}
                    />
                    <GridContainer justify="flex-end">
                      <GridItem>
                        <Tooltip
                          title="Save"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="success"
                            variant="fab"
                            size="lg"
                            type="submit"
                            justIcon
                            round
                            // className={classes.fab}
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-save" />
                          </Button>
                        </Tooltip>
                      </GridItem>
                    </GridContainer>
                    {process.env.NODE_ENV !== "production" ? (
                      <pre>{JSON.stringify(props, null, 2)}</pre>
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return {
    elements
  };
}

export default withStyles(formStyle)(
  connect(null, { showNotification })(Stream)
);
