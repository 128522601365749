import FormSection from "layouts/FormSection.jsx";
import CollegeSection from "layouts/CollegeSection.jsx";
import CollegeReviewSection from "layouts/CollegeReviewSection.jsx";
import ElementList from "views/Forms/Elements/ElementList.jsx";
import MultiCollegeSection from "views/College/MultiCollegeEdit/MultiCollegeSection.jsx";
import CalendarSection from "layouts/CalendarSection.jsx";
import NewsletterSection from "layouts/NewsletterSection";
import CommentSection from "../views/CommentSection/CommentSection";
import UniversityListing from "../views/College/UniversityListing/UniversityListing";

const getRoutes = user_role => {
  let dashRoutes = [
    {
      path: "/form-listing",
      name: "Form Listing",
      icon: "assignment",
      component: FormSection
    }
  ];

  if (user_role === "super_admin" || user_role === "admin") {
    dashRoutes.push({
      path: "/elements",
      name: "Elements",
      icon: "apps",
      component: ElementList
    });
  }

  dashRoutes.push({
    collapse: true,
    name: "College",
    icon: "school",
    state: "pageCollapse",
    views: [
      {
        path: "/college-listing",
        name: "College Listing",
        mini: "CL",
        component: CollegeSection
      },
      {
        path: "/university-listing",
        name: "University Listing",
        mini: "UL",
        component: UniversityListing
      },
      {
        path: "/multi-college-edit",
        name: "Multi College Edit",
        mini: "MCE",
        component: MultiCollegeSection
      },
      ...(user_role === "super_admin" || user_role === "admin"
        ? [
            {
              path: "/college-reviews",
              name: "College Reviews",
              mini: "CR",
              component: CollegeReviewSection
            }
          ]
        : [])
    ]
  });

  if (user_role === "super_admin") {
    dashRoutes.push({
      collapse: true,
      name: "Students",
      icon: "person",
      state: "studentCollapse",
      views: [
        {
          path: "/newsletter",
          name: "Newsletter Subscribers",
          mini: "NS",
          component: NewsletterSection
        }
      ]
    });
  }
  dashRoutes.push({
    path: "/calendar",
    name: "Calendar",
    icon: "date_range",
    component: CalendarSection
  });
  dashRoutes.push({
    path: "/comments",
    name: "Comments",
    icon: "comment",
    component: CommentSection
  });
  dashRoutes.push({
    redirect: true,
    path: "/",
    pathTo: "/form-listing",
    name: "Form Listing"
  });

  return dashRoutes;
};

export default getRoutes;
