import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";

import * as actions from "data/elements/actions";
import { LinearProgress } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class TagDataModal extends React.Component {
  state = {
    modal: true,
    full_name: "",
    is_part_of_gallery: false,
    id: null,
    error: ""
  };

  componentDidMount() {
    const { isEditing } = this.props.elements.ui;
    if (isEditing) {
      const { rowIndex } = this.props.elements.appState;
      const rowData = this.props.elementData.data[rowIndex];
      const { is_part_of_gallery, full_name, id } = rowData;
      this.setState({ is_part_of_gallery, full_name, id });
    }
  }
  handleTextInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSwitch = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClose = () => {
    this.props.hideTagDataModal();
  };

  handleProgressBar = () => {
    const rowLoading = this.props.elements.appState.rowLoading;
    if (rowLoading) {
      return <LinearProgress />;
    }
    return null;
  };

  handleCreateElement = () => {
    const { appState, data } = this.props.elements;
    const { elementIndex, rowIndex } = appState;
    const path = data[elementIndex].path;
    const { is_part_of_gallery, full_name, id } = this.state;
    if (full_name.length) {
      //directly call the api from here.
      if (this.props.elements.ui.isEditing) {
        this.props.updateTagElement(path, elementIndex, rowIndex, {
          id,
          is_part_of_gallery,
          full_name
        });
      } else {
        this.props.addTagElement(path, elementIndex, {
          is_part_of_gallery,
          full_name
        });
      }
    } else {
      //handle the error case.
      this.setState({ error: "Full Name is Mandatory" });
    }
  };

  handleError = () => {
    if (this.props.error) {
      return <p>{this.props.error}</p>;
    }
    return null;
  };
  render() {
    const { classes, elements, elementData } = this.props;
    const { is_part_of_gallery, full_name, error } = this.state;
    const { showTagModal } = elements.ui;
    const name = elementData.name;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showTagModal}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => this.handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={() => this.handleClose()}>
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}>
          {error ? <pre>{error}</pre> : null}
          <div>
            <GridContainer>
              <GridItem xs={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <h6>Full Name</h6>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      placeholder="Full Name"
                      variant="outlined"
                      name="full_name"
                      onChange={this.handleTextInput}
                      value={full_name}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <h6>Is it a Part Of Gallery</h6>
                  </GridItem>
                  <GridItem xs={12}>
                    <Switch
                      name="is_part_of_gallery"
                      checked={is_part_of_gallery}
                      onChange={this.handleSwitch}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <br />
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateElement} color="danger" simple>
            {this.props.elements.ui.isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {this.handleProgressBar()}
        {this.handleError()}
      </Dialog>
    );
  }
}
function mapStateToProps({ elements }) {
  const elementIndex = elements.appState.elementIndex;
  const elementData = elements.data[elementIndex];
  return { elements, elementData };
}
export default withStyles(modalStyle)(
  connect(
    mapStateToProps,
    actions
  )(TagDataModal)
);
