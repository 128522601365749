// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import * as actions from "data/elements/actions";

const tableHead = ["#", "Full Name", "Actions"];

class FeatureCell extends Component {
  /**
   * @prop(index) Index of the row
   * This would create the actions layout based on the row type.
   */
  handleActionsLayout = index => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableCell className={classes.tableCell}>
          <div>
            <Button
              onClick={() => this.editRow(index)}
              className={classes.actionButton}
              color="info"
              size="lg"
              title="Edit">
              <Edit className={classes.icon} />
            </Button>
            <Button
              onClick={() => this.deleteRow(index)}
              className={classes.actionButton}
              color="danger"
              size="lg"
              title="Delete">
              <Delete className={classes.icon} />
            </Button>
          </div>
        </TableCell>
      </React.Fragment>
    );
  };

  editRow = index => {
    const { elementIndex, featureElementIndex, featureIndex } = this.props;
    this.props.showFeatureDataModal(
      elementIndex,
      featureIndex,
      featureElementIndex,
      index,
      true
    );
  };
  deleteRow = index => {
    const { elementIndex, featureElementIndex, featureIndex } = this.props;
    this.props.showFeatureDeleteBox(
      elementIndex,
      featureIndex,
      featureElementIndex,
      index
    );
  };

  /**
   * Create a row with empty values
   */
  addRowButtonAction = () => {
    const { elementIndex, featureElementIndex, featureIndex } = this.props;
    this.props.showFeatureDataModal(
      elementIndex,
      featureIndex,
      featureElementIndex,
      null,
      false
    );
  };

  render() {
    const { classes, featureCell, name } = this.props;
    return (
      <div>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>{name}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Table>
              <TableHead className={classes["primary"]}>
                <TableRow className={classes.tableRow}>
                  {tableHead.map((prop, key) => {
                    {
                      const tableCellClasses =
                        classes.tableHeadCell + " " + classes.tableCell;
                      return (
                        <TableCell
                          key={key}
                          className={tableCellClasses}
                          style={{ fontWeight: "bold" }}>
                          {prop}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {featureCell ? (
                  featureCell.map((item, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <h4>{item.full_name}</h4>
                        </TableCell>
                        {this.handleActionsLayout(index)}
                      </TableRow>
                    );
                  })
                ) : (
                  <React.Fragment />
                )}
              </TableBody>
            </Table>
            <GridContainer justify="flex-end">
              <GridItem>
                <Button
                  color="success"
                  title="Add"
                  type="button"
                  onClick={this.addRowButtonAction}>
                  Add Row
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

FeatureCell.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  elementIndex: PropTypes.number
};

function mapStateToProps({ elements }, ownProps) {
  const elementIndex = ownProps.elementIndex;
  const featureIndex = ownProps.featureIndex;
  const featureElementIndex = ownProps.featureElementIndex;

  const features = elements.data[elementIndex];
  const feature = features.data[featureIndex];
  const featureElement = feature.data[featureElementIndex];
  const featureCell = featureElement.data;
  const name = featureElement.name;
  const path = featureElement.path;

  return {
    features,
    feature,
    featureCell,
    featureElement,
    name,
    path
  };
}

export default withStyles(tableFormStyles)(
  connect(
    mapStateToProps,
    actions
  )(FeatureCell)
);
