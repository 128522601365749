import React, { useState, useEffect } from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import TextField from "@material-ui/core/TextField";

import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import api from "data/utils/api";

const FAQModal = ({
  frequentlyAskedQuestion,
  show = false,
  url,
  classes,
  handleModalClose
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(show);
  const [faq, setFaq] = useState(frequentlyAskedQuestion || {});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    () => {
      setFaq(frequentlyAskedQuestion || {});
      setError("");
      setShowModal(show);
    },
    [show]
  );

  const handleInput = e => {
    if (faq.que && faq.ans && error) {
      setError(undefined);
    }
    setFaq({ ...faq, [e.target.name]: e.target.value });
  };

  const errorValidation = () => {
    if (faq.que && faq.ans) {
      setError("");
      return;
    }
    throw {
      data: { message: "Both Question and Answer is Required" }
    };
  };

  const checkSameContent = () => {
    if (
      frequentlyAskedQuestion.que === faq.que &&
      frequentlyAskedQuestion.ans === faq.ans
    ) {
      handleModalClose();
      enqueueSnackbar("!! No Change in the FAQ !!", { variant: "warning" });
      return false;
    }
    return true;
  };

  const cleanInputBeforeSending = () => {
    const trimmedQue = faq.que.trim();
    const trimmedAns = faq.ans.trim();
    return { que: trimmedQue, ans: trimmedAns };
  };

  const handleCreateElement = async () => {
    try {
      errorValidation();
      if (checkSameContent()) {
        setLoading(true);
        //handle api call here.
        let response;
        if (faq.id) {
          response = await api.put(`${url}/${faq.id}`, {
            ...cleanInputBeforeSending()
          });
        } else {
          response = await api.post(url, { ...cleanInputBeforeSending() });
        }
        setLoading(false);
        handleModalClose(response.data);
        enqueueSnackbar(`FAQ ${faq.id ? "Updated" : "Created"} Successfully`, {
          variant: "success"
        });
      }
    } catch (error) {
      const { data: { message = "" } = {} } = error;
      setLoading(false);
      setError(message);
    }
  };

  const handleClose = () => {
    if (!loading) {
      handleModalClose();
    }
  };

  const handleProgressBar = () => {
    if (!loading) {
      return;
    }
    return <LinearProgress color="secondary" />;
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={showModal}
      fullWidth
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description">
      <DialogTitle id="confirmation-dialog-title">
        Frequently Asked Question
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {error ? (
          <em style={{ color: "red", marginBottom: "8px" }}>{error}</em>
        ) : (
          ""
        )}
        <GridContainer>
          <GridItem xs={12}>
            <p
              style={{
                fontWeight: "bold",
                margin: "0px",
                marginBottom: "8px"
              }}>
              Question <span style={{ color: "red" }}>*</span>
            </p>
          </GridItem>
          <GridItem xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              placeholder="Add question here."
              variant="outlined"
              name="que"
              multiline={true}
              rows={1}
              rowsMax={10}
              onChange={handleInput}
              value={faq.que || ""}
            />
          </GridItem>
          <GridItem>
            <br />
          </GridItem>
          <GridItem xs={12}>
            <p
              style={{
                fontWeight: "bold",
                margin: "0px",
                marginBottom: "8px"
              }}>
              Answer <span style={{ color: "red" }}>*</span>
            </p>
          </GridItem>
          <GridItem xs={12}>
            <TextField
              fullWidth
              required
              placeholder="Add answer here."
              variant="outlined"
              name="ans"
              multiline={true}
              rows={2}
              rowsMax={10}
              onChange={handleInput}
              value={faq.ans || ""}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <br />
      <DialogActions>
        <Button color="transparent" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleCreateElement} color="info">
          Save
        </Button>
      </DialogActions>
      {handleProgressBar()}
    </Dialog>
  );
};

export default withStyles(modalStyle)(FAQModal);
