import api from "data/utils/api";
import {
  COLLEGES_APPSTATE_ALL_LOADING,
  COLLEGE_BASIC_APPSTATE_LOADING,
  COLLEGE_DETAIL_APPSTATE_LOADING,
  COLLEGES_APPSTATE_ALL_SUCCESS,
  COLLEGE_BASIC_APPSTATE_SUCCESS,
  COLLEGE_DETAIL_APPSTATE_SUCCESS,
  COLLEGES_APPSTATE_ALL_ERROR,
  COLLEGE_BASIC_APPSTATE_ERROR,
  COLLEGE_DETAIL_APPSTATE_ERROR,
  COLLEGES_DATA_ALL_GET,
  COLLEGE_BASIC_DATA_GET,
  COLLEGE_DETAIL_DATA_GET,
  COLLEGES_DATA_CONTENT_COUNT,
  COLLEGES_DATA_TOTAL_COUNT,
  COLLEGES_DATA_PAGE_START,
  COLLEGE_DETAIL_DATA_RESET,
  COLLEGE_BASIC_DATA_RESET,
  COLLEGE_BASIC_APPSTATE_RESET
  //COLLEGES_DATA_PAGE_LIMIT
} from "data/colleges/types";

import showNotification from "data/utils/notificationObject";

// =================================================================================================
// GET ALL ELEMENTS ================================================================================
// =================================================================================================
export const getAllColleges = (
  offset,
  limit,
  currentPage,
  filterValue
) => async dispatch => {
  dispatch({ type: COLLEGES_APPSTATE_ALL_LOADING });
  try {
    const res = await api.get(api.endpoints.colleges, {
      offset,
      limit,
      name: filterValue
    });

    dispatch({
      type: COLLEGES_DATA_ALL_GET,
      data: res.data,
      currentPage,
      search: filterValue ? true : false
    });
    dispatch({ type: COLLEGES_DATA_TOTAL_COUNT, totalCount: res.totalCount });
    dispatch({ type: COLLEGES_DATA_CONTENT_COUNT, contentCount: res.count });
    //dispatch({ type: COLLEGES_DATA_PAGE_LIMIT, pageLimit: res.limit });
    dispatch({ type: COLLEGES_DATA_PAGE_START, pageStart: res.start });
    dispatch({ type: COLLEGES_APPSTATE_ALL_SUCCESS });
  } catch (error) {
    dispatch({
      type: COLLEGES_APPSTATE_ALL_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET COLLEGE-BASIC ELEMENT ==========================================================================
// =================================================================================================
export const getCollegeBasicById = id => async dispatch => {
  dispatch({ type: COLLEGE_BASIC_APPSTATE_LOADING });
  try {
    const res = await api.get(`${api.endpoints.colleges}/${id}/basic`);
    dispatch({ type: COLLEGE_BASIC_DATA_GET, data: res.data });
    dispatch({ type: COLLEGE_BASIC_APPSTATE_SUCCESS });
  } catch (error) {
    dispatch({ type: COLLEGE_BASIC_DATA_RESET });
    dispatch({
      type: COLLEGE_BASIC_APPSTATE_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET COLLEGE-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const getCollegeDetailById = id => async dispatch => {
  dispatch({ type: COLLEGE_DETAIL_APPSTATE_LOADING });
  try {
    const res = await api.get(`${api.endpoints.colleges}/${id}/detail`);
    dispatch({ type: COLLEGE_DETAIL_DATA_GET, data: res.data });
    dispatch({ type: COLLEGE_DETAIL_APPSTATE_SUCCESS });
  } catch (error) {
    dispatch({ type: COLLEGE_DETAIL_DATA_RESET });
    dispatch({
      type: COLLEGE_DETAIL_APPSTATE_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET COLLEGE-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const createCollegeDetailById = (id, data) => async dispatch => {
  dispatch({ type: COLLEGE_DETAIL_APPSTATE_LOADING });
  try {
    const res = await api.post(`${api.endpoints.colleges}/${id}/detail`, {
      data
    });
    dispatch({ type: COLLEGE_DETAIL_DATA_RESET });
    dispatch({ type: COLLEGE_DETAIL_APPSTATE_SUCCESS, navigateBack: true });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({
      type: COLLEGE_DETAIL_APPSTATE_ERROR,
      error
    });
    dispatch(showNotification(error, false));
    dispatch({ type: COLLEGE_DETAIL_DATA_GET, data: { data } });
  }
};

// =================================================================================================
// GET COLLEGE-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const upsertCollegeBasicById = (id, data) => async dispatch => {
  dispatch({ type: COLLEGE_BASIC_APPSTATE_LOADING });
  try {
    var res;
    if (id) {
      res = await api.patch(`${api.endpoints.colleges}/${id}/basic`, { data });
    } else {
      res = await api.post(`${api.endpoints.colleges}`, { data });
    }
    dispatch({ type: COLLEGE_BASIC_APPSTATE_SUCCESS, navigateBack: true });
    dispatch({ type: COLLEGE_BASIC_DATA_RESET });
    dispatch({ type: COLLEGE_BASIC_APPSTATE_RESET });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({ type: COLLEGE_BASIC_DATA_GET, data: data });
    dispatch({
      type: COLLEGE_BASIC_APPSTATE_ERROR,
      error
    });
    dispatch(showNotification(error, false));
  }
};
