import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import * as actions from "data/auth/actions";
import * as routesPath from "routes/routesPath";
import { connect } from "react-redux";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      emailState: "",
      password: "",
      passwordState: "",
      tr: false,
      notificationMessage: "",
      notificationType: "info"
    };
    this.loginClick = this.loginClick.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 500 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      500
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error"
          });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error"
          });
        }
        break;
      default:
        break;
    }

    switch (type) {
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  loginClick(e) {
    e.preventDefault();
    if (this.state.emailState === "") {
      this.setState({ emailState: "error" });
    }
    if (this.state.passwordState === "") {
      this.setState({ passwordState: "error" });
    }
    if (
      this.state.emailState === "success" &&
      this.state.passwordState === "success"
    ) {
      this.props.loginUser(
        this.state.email,
        this.state.password,
        this.props.history
      );
    }
  }

  handleLoader() {
    if (this.props.appStateLogin.loading) {
      return <CustomLinearProgress color="rose" />;
    }
  }

  render() {
    const { classes, appStateLogin } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={4}>
              <form onSubmit={this.loginClick}>
                <Card
                  login
                  background={appStateLogin.loading ? true : false}
                  className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose">
                    <h4 className={classes.cardTitle}>Login</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      autoFocus
                      success={this.state.emailState === "success"}
                      error={this.state.emailState === "error"}
                      labelText="Email"
                      id="loginemail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: event => this.change(event, "email", "email"),
                        type: "email"
                      }}
                    />
                    <CustomInput
                      success={this.state.passwordState === "success"}
                      error={this.state.passwordState === "error"}
                      labelText="Password"
                      id="loginpassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        onChange: event =>
                          this.change(event, "password", "password"),
                        type: "password"
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button color="rose" type="submit">
                      Login
                    </Button>
                  </CardFooter>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Link to={routesPath.SIGNUP_PAGE}>
                      <Button color="rose" simple block>
                        SignUp
                      </Button>
                    </Link>
                    <Link to={routesPath.FORGOT_PASSWORD_PAGE}>
                      <Button color="rose" simple block>
                        Forgot Password
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </form>
              {this.handleLoader()}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps({ auth }) {
  return { appStateLogin: auth.appStateLogin };
}

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    actions
  )(LoginScreen)
);
