//All the endpoints goes here.
export default {
  dashboard: "/api/v1/dashboard",
  listings: "/api/v1/listings",
  auth: {
    login: "/api/v1/auth/login",
    signup: "/api/v1/auth/signup",
    forgot_password: "/api/v1/auth/forgot-password",
    reset: "/api/v1/auth/signup/password-create"
  },
  user: {
    auth: "/api/v1/users/auth",
    logout: "/api/v1/users/logout"
  },
  elements: "/api/v1/elements",
  form: {
    short_name: "/api/v1/forms/short-name-unique"
  },
  college: {
    filters: "/api/v1/colleges/filters"
  },
  colleges: "/api/v1/colleges",
  universities: "/api/v1/universities",
  reviews: "/api/v1/colleges/reviews",
  newsletter_subscribers: "/api/v1/newsletter-subscribers",
  college_temp: {
    root: "/api/v1/colleges-temp",
    filters: "/api/v1/colleges-temp/filters"
  },
  forms: "/api/v1/forms",
  calendar: "api/v1/forms/calendar",
  upload: "/api/v1/upload",
  images: "/api/v1/images",
  students: {
    base: "/api/v1/students",
    transactions: "/api/v1/students/transactions"
  },
  comments: "/api/v1/comments"
};
