//all the types of the elements should be added here.
export const ELEMENTS_APPSTATE_ALL_LOADING = "ELEMENTS_APPSTATE_ALL_LOADING";
export const ELEMENTS_APPSTATE_ALL_SUCCESS = "ELEMENTS_APPSTATE_ALL_SUCCESS";
export const ELEMENTS_APPSTATE_ALL_ERROR = "ELEMENTS_APPSTATE_ALL_ERROR";

export const ELEMENTS_APPSTATE_ONE_LOADING = "ELEMENTS_APPSTATE_ONE_LOADING";
export const ELEMENTS_APPSTATE_ONE_SUCCESS = "ELEMENTS_APPSTATE_ONE_SUCCESS";
export const ELEMENTS_APPSTATE_ONE_ERROR = "ELEMENTS_APPSTATE_ONE_ERROR";

export const ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX =
  "ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX";
export const ELEMENTS_APPSTATE_CURRENT_ROW_INDEX =
  "ELEMENTS_APPSTATE_CURRENT_ROW_INDEX";
export const ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX =
  "ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX";
export const ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX =
  "ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX";
export const ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX =
  "ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX";

export const ELEMENTS_DATA_ALL_ADD = "ELEMENTS_DATA_ALL_ADD";
export const ELEMENTS_DATA_ALL_GET = "ELEMENTS_DATA_ALL_GET";

export const ELEMENTS_DATA_ONE_ADD = "ELEMENTS_DATA_ONE_ADD";
export const ELEMENTS_DATA_ONE_UPDATE = "ELEMENTS_DATA_ONE_UPDATE";
export const ELEMENTS_DATA_ONE_DELETE = "ELEMENTS_DATA_ONE_DELETE";
export const ELEMENTS_DATA_ONE_READ = "ELEMENTS_DATA_ONE_READ";
export const ELEMENTS_DATA_REFRESH = "ELEMENTS_DATA_REFRESH";

export const ELEMENTS_UI_SHOW_DATA_MODAL = "ELEMENTS_UI_SHOW_DATA_MODAL";
export const ELEMENTS_UI_HIDE_DATA_MODAL = "ELEMENTS_UI_HIDE_DATA_MODAL";

export const ELEMENTS_UI_SHOW_STREAM_DATA_MODAL =
  "ELEMENTS_UI_SHOW_STREAM_DATA_MODAL";
export const ELEMENTS_UI_HIDE_STREAM_DATA_MODAL =
  "ELEMENTS_UI_HIDE_STREAM_DATA_MODAL";

export const ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL =
  "ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL";
export const ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL =
  "ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL";

export const ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL =
  "ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL";
export const ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL =
  "ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL";

export const ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL =
  "ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL";
export const ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL =
  "ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL";

export const ELEMENTS_UI_SHOW_TAG_DATA_MODAL =
  "ELEMENTS_UI_SHOW_TAG_DATA_MODAL";
export const ELEMENTS_UI_HIDE_TAG_DATA_MODAL =
  "ELEMENTS_UI_HIDE_TAG_DATA_MODAL";

export const ELEMENTS_UI_SHOW_ALERT_BOX = "ELEMENTS_UI_SHOW_ALERT_BOX";
export const ELEMENTS_UI_HIDE_ALERT_BOX = "ELEMENTS_UI_HIDE_ALERT_BOX";
export const ELEMENTS_UI_SHOW_NOTIFICATION = "ELEMENTS_UI_SHOW_NOTIFICATION";
export const ELEMENTS_UI_HIDE_NOTIFICATION = "ELEMENTS_UI_HIDE_NOTIFICATION";
