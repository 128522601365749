import { FORM_DETAIL_DATA_GET, FORM_DETAIL_DATA_RESET } from "data/forms/types";

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case FORM_DETAIL_DATA_GET:
      return {
        ...action.data
      };
    case FORM_DETAIL_DATA_RESET:
      return { ...state, data: [] };
    default:
      return state;
  }
}
