import React, { Component } from "react";
import { connect } from "react-redux";
import CourseTreeSelectorHandler from "views/Forms/EazyForms/FormBasic/CourseTreeSelectorHandler.jsx";
import _ from "lodash";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

class CourseTreeSelector extends Component {
  getStreamFormSelectors = elements => {
    const streamArray = elements.filter(item => item.key === "streams")[0];
    let updatedStreamCourse = [];
    if (!_.isEmpty(streamArray.data)) {
      updatedStreamCourse = streamArray.data.map(item => {
        return {
          label: item.full_name,
          options: this.arrayModified(item.data[0])
        };
      });
      return this.handleStreamSelectorValue(updatedStreamCourse);
    }
  };

  arrayModified = valuesItem => {
    if (valuesItem.data === null) {
      return [];
    }
    const newValue = valuesItem.data.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`
      };
    });
    return newValue;
  };

  handleStreamSelectorValue = selectorArray => {
    const { setFieldValue, values } = this.props;
    if (_.isEmpty(selectorArray)) {
      return null;
    } else {
      return (
        <GridItem xs={12} md={6}>
          <CourseTreeSelectorHandler
            valuesItem={selectorArray}
            setFieldValue={setFieldValue}
            values={values}
          />
        </GridItem>
      );
    }
  };

  render() {
    const { elements, values, classes } = this.props;
    return (
      <React.Fragment>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>{`Stream & Courses`}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {this.getStreamFormSelectors(elements.data)}
            </GridContainer>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default withStyles(tableFormStyles)(
  connect(mapStateToProps)(CourseTreeSelector)
);
