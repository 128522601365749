import React, { Component } from "react";
import { connect } from "react-redux";
import SelectorWithoutFormik from "views/Components/College/SelectorWithoutFormik.jsx";
import { getAllElements } from "data/elements/actions/dataActions";
import _ from "lodash";

class SelectorOnKeyWithoutFormik extends Component {
  getFilteredElementsData = elements => {
    const { keyFilter } = this.props;
    const questionArray = elements.filter(item => item.key === keyFilter)[0];
    if (questionArray) {
      return this.handleSelectorValue(questionArray);
    }
  };

  handleSelectorValue = selectorArray => {
    const {
      setSelectorValue,
      valuesData,
      nameKey,
      isMulti,
      isRequired
    } = this.props;
    if (_.isEmpty(selectorArray.data)) {
      return null;
    } else {
      return (
        <SelectorWithoutFormik
          elementValues={selectorArray}
          setSelectorValue={setSelectorValue}
          valuesData={valuesData}
          nameKey={nameKey}
          isMulti={isMulti}
          isRequired={isRequired}
        />
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(
  mapStateToProps,
  { getAllElements }
)(SelectorOnKeyWithoutFormik);
