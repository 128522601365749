import React, { Component } from "react";
import CourseDisplay from "views/College/Basic/Stream/CourseDisplay";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import { FastField } from "formik";
import CourseSelectors from "views/College/Basic/Stream/CourseSelectors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "views/Components/ExpansionPanelCustomized.jsx";

class StreamDisplay extends Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  /*
  tabsData = () => {
    const { streamData, setFieldValue, streamIndex } = this.props;
    return streamData.courses.map((item, index) => {
      return {
        tabName: item.full_name,
        tabContent: (
          <CourseDisplay
            courseData={
              streamData.courses[index] ? streamData.courses[index] : []
            }
            courseIndex={index}
            streamIndex={streamIndex}
            streamData={streamData}
            setFieldValue={setFieldValue}
          />
        )
      };
    });
  };
  */

  createLayout = () => {
    const { streamData, setFieldValue, streamIndex } = this.props;
    const { expanded } = this.state;
    return streamData.courses.map((item, index) => {
      return (
        <ExpansionPanel
          square
          expanded={expanded === index.toString()}
          onChange={this.handleChange(index.toString())}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {item.full_name}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <CourseDisplay
              courseData={item || []}
              courseIndex={index}
              streamIndex={streamIndex}
              streamData={streamData}
              setFieldValue={setFieldValue}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  render() {
    const { streamData, setFieldValue, streamIndex } = this.props;
    return (
      <React.Fragment>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <FastField
              component={CourseSelectors}
              streamData={streamData}
              setFieldValue={setFieldValue}
              streamIndex={streamIndex}
            />
          </GridItem>
          {streamData.courses ? (
            streamData.courses.length > 0 ? (
              <GridItem xs={12}>{this.createLayout()}</GridItem>
            ) : null
          ) : null}
          {/* <Tabs
            title="Courses:"
            headerColor="rose"
            tabs={!_.isEmpty(streamData.courses) ? this.tabsData() : []}
          /> */}
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(formStyle)(StreamDisplay);
