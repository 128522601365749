import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import { Formik, Form, FastField } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import AutoPopulateAddress from "views/College/Basic/CollegeBasic/AutoPopulateAddress.jsx";
import CollegeIntro from "views/College/Basic/CollegeBasic/CollegeIntro.jsx";
import AllCollegeSelectors from "views/College/Basic/CollegeBasic/AllCollegeSelectors.jsx";
import { getAllElements } from "data/elements/actions/dataActions";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import FormikErrorMessage from "views/College/Basic/CollegeBasic/FormikErrorMessage.jsx";

import _ from "lodash";
import {
  getCollegeBasicById,
  upsertCollegeBasicById
} from "data/colleges/actions/dataActions";
import * as Yup from "yup";
import SocialMediaFields from "./SocialMediaFields";

const initialValues = (data, id) => {
  if (!_.isEmpty(data) && id) {
    return data;
  } else
    return {
      short_name: "",
      full_name: "",
      is_active: false,
      website_url: "",
      address: {
        line_1: "",
        line_2: "",
        city: "",
        pincode: null,
        district: "",
        state: "",
        country: "",
        lat: null,
        lng: null,
        selected_address: ""
      },
      social_media_links: {
        facebook: "",
        twitter: "",
        youtube: "",
        wikipedia: "",
        linkedin: ""
      }
    };
};

const collegeFieldValidation = Yup.object().shape({
  short_name: Yup.string().required("College Short Name Should be Unique"),
  full_name: Yup.string().required("College Full Name should be Unique"),
  address: Yup.object().shape({
    line_1: Yup.string().required("Address Line 1 is Required"),
    city: Yup.string().required("City is Required"),
    state: Yup.string().required("State is Required"),
    country: Yup.string().required("Country is Required")
  }),
  institution_type_id: Yup.number()
    .typeError("Institution Types is Required")
    .required("Institution Types is Required "),
  ownership_type_id: Yup.number()
    .typeError("Ownership Types is Required")
    .required("Ownership Types is Required ")
});

class CollegeBasic extends Component {
  state = {
    showDeleteAlert: false
  };
  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.props.getAllElements();
      this.props.getCollegeBasicById(id);
    } else this.props.getAllElements();
  }

  submitCollegeBasicForm = data => {
    const { id } = this.props;
    this.props.upsertCollegeBasicById(id, data);
  };

  handleAlertSuccess = () => {
    this.setState({ showDeleteAlert: false });
    this.props.history.push("/college-listing");
  };

  showDeleteAlertHandler = isDirty => {
    if (this.state.showDeleteAlert && isDirty) {
      return (
        <DeleteAlertBox
          handleSuccess={this.handleAlertSuccess}
          handleClose={() => this.setState({ showDeleteAlert: false })}
          title="Leave Editor ?"
        >
          Changes that you made may not be saved.
        </DeleteAlertBox>
      );
    } else if (this.state.showDeleteAlert) {
      this.props.history.push("/college-listing");
      return null;
    }
  };

  scrollToTopHandler = () => {
    // alert("data");
    // console.log(window);
    // window.scrollTo(0, 0);
    // document.body.scrollTo(0, 0);
  };

  handleAddressErrors = address => {
    const data = Object.keys(address).map((item, index) => (
      <FormikErrorMessage nameValue={`address.${item}`} key={index} />
    ));
    return data;
  };

  showAllErrorMessage = errors => (
    <div>
      <FormikErrorMessage nameValue="short_name" />
      <FormikErrorMessage nameValue="full_name" />
      {_.get(errors, "address")
        ? this.handleAddressErrors(errors.address)
        : null}
    </div>
  );

  render() {
    const { classes, elements, colleges, id, history } = this.props;
    if (
      colleges.appStateBasic.loading ||
      elements.appState.loading ||
      elements.data.length === 0
    ) {
      return <Spinner />;
    }
    if (colleges.appStateBasic.navigateBack) {
      history.push("/college-listing");
    }
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(colleges.dataBasic, id)}
          validationSchema={collegeFieldValidation}
          isValidating={false}
          onSubmit={(values, { setSubmitting }) => {
            this.submitCollegeBasicForm(values);
            // return forms.appStateBasic.navigateBack;
          }}
        >
          {props => {
            const {
              values,
              setFieldValue,
              isSubmitting,
              handleSubmit,
              dirty,
              errors
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                {!id ? (
                  <GridContainer>
                    <GridItem xs={12}>
                      <Button
                        size="sm"
                        title="Go Back"
                        onClick={() => {
                          this.setState({ showDeleteAlert: true });
                        }}
                        className={classes.customBackButton}
                      >
                        <KeyboardArrowLeft />
                        BACK
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : null}
                {this.showDeleteAlertHandler(dirty)}
                {Object.keys(errors).length > 0
                  ? this.showAllErrorMessage(errors)
                  : null}
                <FastField
                  component={CollegeIntro}
                  setFieldValue={setFieldValue}
                  values={values}
                  id={id}
                />
                <FastField
                  component={AllCollegeSelectors}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <br />
                <FastField
                  component={AutoPopulateAddress}
                  setFieldValue={setFieldValue}
                  values={values.address}
                />
                <br />
                <FastField
                  component={SocialMediaFields}
                  setFieldValue={setFieldValue}
                  social_media_links={values.social_media_links}
                />
                <br />
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Tooltip
                      title="Save"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        variant="fab"
                        size="lg"
                        type="submit"
                        justIcon
                        round
                        onClick={() => this.scrollToTopHandler()}
                        className={classes.fab}
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-save" />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
                {process.env.NODE_ENV !== "production" ? (
                  <pre>{JSON.stringify(props, null, 2)}</pre>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements, colleges }) {
  return {
    elements,
    colleges
  };
}

export default withStyles(formStyle)(
  connect(mapStateToProps, {
    getAllElements,
    getCollegeBasicById,
    upsertCollegeBasicById
  })(CollegeBasic)
);
