// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";

import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "data/elements/actions";

const tableHead = [
  "#",
  "Short Name",
  "Full Name",
  "Specialization Of",
  "Actions"
];

class BranchIndependentCell extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    searchString: ""
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: +event.target.value });
  };

  emptyRows = () => {
    const { rowsPerPage, page } = this.state;
    const { elements } = this.props;
    return (
      rowsPerPage - Math.min(rowsPerPage, elements.length - page * rowsPerPage)
    );
  };

  /**
   * @prop(index) Index of the row
   * This would create the actions layout based on the row type.
   */
  handleActionsLayout = index => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableCell className={classes.tableCell}>
          <div>
            <Button
              onClick={() => this.editRow(index)}
              className={classes.actionButton}
              color="info"
              size="lg"
              title="Edit"
            >
              <Edit className={classes.icon} />
            </Button>
            <Button
              onClick={() => this.deleteRow(index)}
              className={classes.actionButton}
              color="danger"
              size="lg"
              title="Delete"
            >
              <Delete className={classes.icon} />
            </Button>
          </div>
        </TableCell>
      </React.Fragment>
    );
  };

  getParentBranch = parent_branch_id => {
    const { branches } = this.props;
    const branch = _.get(branches, "data");
    if (branch) {
      const data = branch.filter(item => item.id === parent_branch_id)[0];
      return data ? data.short_name : "-";
    } else {
      return "Not Found";
    }
  };

  editRow = index => {
    this.props.showIndependentBranchDataModal(
      this.props.elementIndex,
      index,
      true
    );
  };
  deleteRow = index => {
    this.props.showDeleteBox(this.props.elementIndex, index);
  };

  /**
   * Create a row with empty values
   */
  addRowButtonAction = () => {
    this.props.showIndependentBranchDataModal(
      this.props.elementIndex,
      null,
      false
    );
  };

  getFilteredBranches = () => {
    let value = this.state.searchString;
    if (!value) {
      return this.props.elements || [];
    }
    const lowerCaseValue = value.toLowerCase();
    let filteredBranches = this.props.elements.filter(element => {
      return (
        element.short_name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "")
          .includes(lowerCaseValue.replace(/[^a-zA-Z0-9]/g, "")) ||
        element.full_name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "")
          .includes(lowerCaseValue.replace(/[^a-zA-Z0-9]/g, ""))
      );
    });
    return filteredBranches || [];
  };

  searchBranches = event => {
    this.setState({ searchString: event.target.value, page: 0 });
  };

  getIndexOf = element => {
    const index = this.props.elements.findIndex(item => item.id === element.id);
    return index;
  };

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const searchedElements = this.getFilteredBranches();
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12}>
          <TextField
            onChange={this.searchBranches}
            variant="outlined"
            type="text"
            fullWidth={true}
            label="Search in Branches"
          />
          <Table>
            <TableHead className={classes["primary"]}>
              <TableRow className={classes.tableRow}>
                {tableHead.map((prop, key) => {
                  {
                    const tableCellClasses =
                      classes.tableHeadCell + " " + classes.tableCell;
                    return (
                      <TableCell
                        key={key}
                        className={tableCellClasses}
                        style={{ fontWeight: "bold" }}
                      >
                        {prop}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedElements
                .slice(startIndex, endIndex)
                .map((item, index) => {
                  const itemIndex = this.getIndexOf(item);
                  return (
                    <TableRow key={itemIndex} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        {itemIndex + 1}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <h4>{item.short_name}</h4>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <h4>{item.full_name}</h4>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <h4>{this.getParentBranch(item.parent_branch_id)}</h4>
                      </TableCell>
                      {this.handleActionsLayout(itemIndex)}
                    </TableRow>
                  );
                })}
              {this.emptyRows() > 0 && (
                <TableRow style={{ height: 49 * this.emptyRows() }}>
                  <TableCell colSpan={12}>
                    <h4 />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchedElements.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <GridContainer justify="flex-end">
            <GridItem>
              <Button
                color="success"
                title="Add"
                type="button"
                onClick={this.addRowButtonAction}
              >
                Add Row
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

BranchIndependentCell.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  elementIndex: PropTypes.number
};

function mapStateToProps({ elements }, ownProps) {
  const elementIndex = ownProps.elementIndex;
  const branches = elements.data.filter(item => item.key === "branches")[0];
  return {
    elements: elements.data[elementIndex].data,
    name: elements.data[elementIndex].name,
    path: elements.data[elementIndex].path,
    branches
  };
}

export default withStyles(tableFormStyles)(
  connect(mapStateToProps, actions)(BranchIndependentCell)
);
