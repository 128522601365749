import React, { Component } from "react";
import AttachmentItem from "./AttachmentItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AttachmentUploadHandler from "./AttachmentUploadHandler";
import AttachmentDeleteAlert from "./AttachmentDeleteAlert";
import api from "data/utils/api";
import { connect } from "react-redux";
import { showNotification } from "data/main/actions";
import _ from "lodash";

class AttachmentList extends Component {
  constructor(props) {
    super(props);
    const { attachmentData, selectedTag, collegeData } = props;
    const { short_name, id } = collegeData;
    this.state = {
      attachmentData,
      selectedTag,
      openImageModal: false,
      selectedImage: null,
      collegeShortName: short_name,
      collegeId: id,
      showDeleteAlert: false
    };
  }

  onEdit = (id, image) => {
    this.setState({
      openImageModal: true,
      selectedImage: image,
      attachmentId: id
    });
  };

  onDelete = (id, data) => {
    this.setState({
      showDeleteAlert: true,
      attachmentId: id,
      toBeDeletedImage: data
    });
  };

  handleDeleteOk = async () => {
    try {
      const response = await api.delete(
        `${api.endpoints.colleges}/${this.state.collegeId}/attachments/${
          this.state.attachmentId
        }`,
        {
          tag_id: this.state.selectedTag.id,
          image: this.state.toBeDeletedImage
        }
      );
      const newData = this.state.attachmentData.filter(
        item => item.id !== this.state.attachmentId
      );
      this.setState({
        showDeleteAlert: false,
        attachmentId: null,
        attachmentData: newData,
        toBeDeletedImage: null
      });
      this.props.showNotification("Attachment Deleted Successfully");
    } catch (error) {
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleAttachmentData = () => {
    return this.state.attachmentData.map(item => (
      <GridItem xs={12} md={4}>
        <AttachmentItem
          item={item}
          onEdit={this.onEdit}
          key={item.id}
          onDelete={this.onDelete}
        />
      </GridItem>
    ));
  };

  handleUpdate = ({ attachmentId, image }) => {
    const newData = this.state.attachmentData.map(item => {
      if (item.id === attachmentId) {
        return { id: attachmentId, image };
      }
      return item;
    });
    this.handleDataReflection(newData, true);
  };

  handleSubmit = ({ attachmentId, image }) => {
    const newData = this.state.attachmentData.map(item => item);
    newData.push({ id: attachmentId, image });
    this.handleDataReflection(newData, false);
  };

  getDisplayURL = item => {
    let itemURL = _.get(item, "url", "");
    if (itemURL) {
      itemURL = item.display_url;
    }
    return itemURL;
  };

  handleDataReflection = (data, isUpdated = false) => {
    this.setState({
      openImageModal: false,
      selectedImage: null,
      attachmentId: null,
      attachmentData: data
    });
  };

  render() {
    const {
      selectedTag,
      selectedImage,
      collegeShortName,
      attachmentId,
      collegeId
    } = this.state;
    return (
      <React.Fragment>
        {this.state.showDeleteAlert ? (
          <AttachmentDeleteAlert
            handleSuccess={this.handleDeleteOk}
            handleClose={() => this.setState({ showDeleteAlert: false })}
          />
        ) : null}
        {this.state.openImageModal ? (
          <AttachmentUploadHandler
            selectedImage={selectedImage}
            selectedTag={selectedTag}
            attachmentId={attachmentId}
            shortName={collegeShortName}
            folderPath="colleges"
            itemId={collegeId}
            onClose={() =>
              this.setState({
                openImageModal: false,
                selectedImage: null,
                attachmentId: null
              })
            }
            displayURL={this.getDisplayURL(selectedImage)}
            onSubmit={this.handleSubmit}
            onUpdate={this.handleUpdate}
          />
        ) : null}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>{selectedTag.value}</h3>
          <Button
            color="success"
            default
            onClick={() => this.setState({ openImageModal: true })}>
            Add File
          </Button>
        </div>
        <br />
        <GridContainer>{this.handleAttachmentData()}</GridContainer>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { showNotification }
)(AttachmentList);
