import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import { FastField } from "formik";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

class SocialMediaFields extends Component {
  handleSocialLinks = () => {
    const { setFieldValue, social_media_links } = this.props;
    const data = Object.keys(social_media_links).map((item, index) => {
      return (
        <GridItem xs={12} sm={6} md={4} keys={index}>
          <br />
          <p
            style={{
              fontWeight: "bold",
              margin: "0px",
              textTransform: "capitalize"
            }}>{`${item} Link`}</p>
          <FastField
            name={item}
            fullWidth
            type="text"
            component={BareTextFieldInput}
            placeholder={`Enter ${item} link`}
            onBlur={e => {
              setFieldValue(`social_media_links.${item}`, e.target.value);
            }}
            defaultValue={social_media_links[item]}
          />
          <br />
        </GridItem>
      );
    });
    return data;
  };

  render() {
    const { classes, social_media_links } = this.props;
    return (
      <React.Fragment>
        {social_media_links ? (
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>Social Media Links</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>{this.handleSocialLinks()}</GridContainer>
            </CardBody>
          </Card>
        ) : null}
      </React.Fragment>
    );
  }
}
export default withStyles(tableFormStyles)(SocialMediaFields);
