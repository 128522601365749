// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import * as actions from "data/elements/actions";

const tableHead = ["#", "Short Name", "Full Name", "Specialization Branch Of"];

class BranchCell extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
    searchString: ""
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: +event.target.value });
  };

  /**
   * @prop(index) Index of the row
   * This would create the actions layout based on the row type.
   */
  handleActionsLayout = index => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableCell className={classes.tableCell}>
          <div>
            <Button
              onClick={() => this.deleteRow(index)}
              className={classes.actionButton}
              color="danger"
              size="lg"
              title="Delete">
              <Delete className={classes.icon} />
            </Button>
          </div>
        </TableCell>
      </React.Fragment>
    );
  };

  getParentBranch = parent_branch_id => {
    const { elementBranches } = this.props;
    const branch = _.get(elementBranches, "data");
    if (branch) {
      const data = branch.filter(item => item.id === parent_branch_id)[0];
      return data ? data.short_name : "-";
    } else {
      return "Not Found";
    }
  };

  deleteRow = index => {
    const {
      elementIndex,
      streamElementIndex,
      streamIndex,
      courseIndex
    } = this.props;
    this.props.showBranchDeleteBox(
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      index
    );
  };

  /**
   * Create a row with empty values
   */
  addRowButtonAction = () => {
    const {
      elementIndex,
      streamElementIndex,
      streamIndex,
      courseIndex
    } = this.props;
    this.props.showBranchDataModal(
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      null,
      false
    );
  };

  getFilteredBranches = () => {
    let value = this.state.searchString;
    if (!value) {
      return this.props.branches || [];
    }
    const lowerCaseValue = value.toLowerCase();
    let filteredBranches = this.props.branches.filter(element => {
      return (
        element.short_name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "")
          .includes(lowerCaseValue.replace(/[^a-zA-Z0-9]/g, "")) ||
        element.full_name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "")
          .includes(lowerCaseValue.replace(/[^a-zA-Z0-9]/g, ""))
      );
    });
    return filteredBranches || [];
  };

  searchBranches = event => {
    this.setState({ searchString: event.target.value, page: 0 });
  };

  getIndexOf = element => {
    const index = this.props.branches.findIndex(item => item.id === element.id);
    return index;
  };

  render() {
    const { classes, name } = this.props;
    const { rowsPerPage, page } = this.state;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const searchedElements = this.getFilteredBranches();
    return (
      <React.Fragment>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{name}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <TextField
                  onChange={this.searchBranches}
                  variant="outlined"
                  type="text"
                  fullWidth={true}
                  label="Search in Branches"
                />
                <Table>
                  <TableHead className={classes["primary"]}>
                    <TableRow className={classes.tableRow}>
                      {tableHead.map((prop, key) => {
                        {
                          const tableCellClasses =
                            classes.tableHeadCell + " " + classes.tableCell;
                          return (
                            <TableCell
                              key={`branch_cell_${key}`}
                              className={tableCellClasses}
                              style={{ fontWeight: "bold" }}>
                              {prop}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedElements ? (
                      searchedElements
                        .slice(startIndex, endIndex)
                        .map((item, index) => {
                          const itemIndex = this.getIndexOf(item);
                          return (
                            <TableRow
                              key={`branch_${index}_${page}`}
                              className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>
                                {itemIndex + 1}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <h4>{item.short_name}</h4>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <h4>{item.full_name}</h4>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <h4>
                                  {this.getParentBranch(item.parent_branch_id)}
                                </h4>
                              </TableCell>
                              {this.handleActionsLayout(itemIndex)}
                            </TableRow>
                          );
                        })
                    ) : (
                      <React.Fragment />
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={searchedElements.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      title="Add"
                      type="button"
                      onClick={this.addRowButtonAction}>
                      Add a Branch
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

BranchCell.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  elementIndex: PropTypes.number
};

function mapStateToProps({ elements }, ownProps) {
  const elementIndex = ownProps.elementIndex;
  const streamIndex = ownProps.streamIndex;
  const courseIndex = ownProps.courseIndex;
  const streamElementIndex = ownProps.streamElementIndex;
  const elementBranches = elements.data.filter(
    item => item.key === "branches"
  )[0];
  const streams = elements.data[elementIndex];
  const stream = streams.data[streamIndex];
  const streamElement = stream.data[streamElementIndex];
  const streamCell = streamElement.data;
  const courseElement = streamCell[courseIndex];
  const branchElement = courseElement.data;
  const name = branchElement.name;
  const path = branchElement.path;
  const branches = branchElement.data;
  return {
    streams,
    stream,
    streamCell,
    streamElement,
    courseIndex,
    branches,
    name,
    path,
    elementBranches
  };
}

export default withStyles(tableFormStyles)(
  connect(
    mapStateToProps,
    actions
  )(BranchCell)
);
