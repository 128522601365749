import React, { Component } from "react";
import DropDownSelectorOnChange from "views/Components/DropDownSelectorOnChange.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
class HandlingSelectorsValues extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.data.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`
      };
    });
    return newValue;
  };
  selectorHandler = (data, key, isMulti) => {
    const { setFieldValue, values } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <p style={{ fontWeight: "bold" }}>{data.name}</p>
          <FastField
            component={DropDownSelectorOnChange}
            name={`form_basic.${key}`}
            data={this.arrayModified(data)}
            isMulti={isMulti}
            onChange={value => {
              setFieldValue(`form_basic.${key}`, value);
            }}
            selectedValues={values.form_basic[`${key}`]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  HandlingSelector = () => {
    const { valuesItem } = this.props;
    switch (valuesItem.key) {
      case "education_levels":
        return this.selectorHandler(valuesItem, valuesItem.key, true);
      case "streams":
        return this.selectorHandler(valuesItem, "stream_id", false);
      case "courses":
        return this.selectorHandler(valuesItem, valuesItem.key, true);
      case "exam_conducting_body_id":
        return this.selectorHandler(valuesItem, valuesItem.key, false);
      default:
        return this.selectorHandler(valuesItem, valuesItem.key, true);
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.HandlingSelector()}
        <br />
      </React.Fragment>
    );
  }
}

export default HandlingSelectorsValues;
