import _ from "lodash";
import {
  ELEMENTS_DATA_ALL_GET,
  ELEMENTS_DATA_ONE_ADD,
  ELEMENTS_DATA_ONE_UPDATE,
  ELEMENTS_DATA_ONE_DELETE,
  ELEMENTS_DATA_REFRESH
} from "data/elements/types";

// =================================================================================================
// UPDATE ARRAY ====================================================================================
// =================================================================================================
const updateItemInArray = (array, elementIndex, updateItemCallback) => {
  const updatedItems = array.map((item, index) => {
    if (index !== elementIndex) {
      return item;
    }
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });
  return updatedItems;
};

// =================================================================================================
// ADD ELEMENT =====================================================================================
// =================================================================================================
const addObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    newData = newData ? newData.concat(action.data) : [action.data];
    return { ...item, data: newData };
  });
};

// =================================================================================================
// ADD FEATURE ELEMENT =============================================================================
// =================================================================================================
const addFeatureObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    var newDataItemArray =
      newData[action.featureIndex].data[action.featureElementIndex].data;
    newDataItemArray = newDataItemArray
      ? newDataItemArray.concat(action.data)
      : [action.data];
    newData[action.featureIndex].data[
      action.featureElementIndex
    ].data = newDataItemArray;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// ADD STREAM ELEMENT =============================================================================
// =================================================================================================
const addStreamObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    var newDataItemArray =
      newData[action.streamIndex].data[action.streamElementIndex].data;
    newDataItemArray = newDataItemArray
      ? newDataItemArray.concat(action.data)
      : [action.data];
    newData[action.streamIndex].data[
      action.streamElementIndex
    ].data = newDataItemArray;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// ADD BRANCH ELEMENT ==============================================================================
// =================================================================================================
const addBranchObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    var newDataItemArray =
      newData[action.streamIndex].data[action.streamElementIndex].data[
        action.courseIndex
      ].data.data;
    newDataItemArray = newDataItemArray
      ? newDataItemArray.concat(action.data)
      : [action.data];
    newData[action.streamIndex].data[action.streamElementIndex].data[
      action.courseIndex
    ].data.data = newDataItemArray;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// UPDATE ELEMENT ==================================================================================
// =================================================================================================
const updateObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    const data = item.data.map((innerItem, innerIndex) => {
      if (innerIndex === action.rowIndex) {
        return action.data;
      }
      return innerItem;
    });
    return { ...item, data };
  });
};

// =================================================================================================
// UPDATE FEATURE ELEMENT ==========================================================================
// =================================================================================================
const updateFeatureObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    newData[action.featureIndex].data[action.featureElementIndex].data[
      action.rowIndex
    ] = action.data;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// UPDATE STREAM ELEMENT ==========================================================================
// =================================================================================================
const updateStreamObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    newData[action.streamIndex].data[action.streamElementIndex].data[
      action.rowIndex
    ] = action.data;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// UPDATE BRANCH ELEMENT ===========================================================================
// =================================================================================================
const updateBranchObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    newData[action.streamIndex].data[action.streamElementIndex].data[
      action.courseIndex
    ].data.data[action.rowIndex] = action.data;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// DELETE ELEMENT ==================================================================================
// =================================================================================================
const deleteObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    const data = item.data.filter(
      (innerItem, innerIndex) => innerIndex !== action.rowIndex
    );
    return { ...item, data };
  });
};

// =================================================================================================
// DELETE FEATURE ELEMENT ==========================================================================
// =================================================================================================
const deleteFeatureObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    const newData = _.cloneDeep(item.data);
    newData[action.featureIndex].data[action.featureElementIndex].data.splice(
      action.rowIndex,
      1
    );
    return { ...item, data: newData };
  });
};

// =================================================================================================
// DELETE STREAM ELEMENT ===========================================================================
// =================================================================================================
const deleteStreamObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    const newData = _.cloneDeep(item.data);
    newData[action.streamIndex].data[action.streamElementIndex].data.splice(
      action.rowIndex,
      1
    );
    return { ...item, data: newData };
  });
};

// =================================================================================================
// DELETE BRANCH ELEMENT ===========================================================================
// =================================================================================================
const deleteBranchObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    const newData = _.cloneDeep(item.data);
    newData[action.streamIndex].data[action.streamElementIndex].data[
      action.courseIndex
    ].data.data.splice(action.rowIndex, 1);
    return { ...item, data: newData };
  });
};

// =================================================================================================
// REFRESH BRANCH ELEMENT ==========================================================================
// =================================================================================================
const refreshBranchObject = (state, action) => {
  return updateItemInArray(state, action.elementIndex, item => {
    var newData = _.cloneDeep(item.data);
    var newDataItemArray =
      newData[action.streamIndex].data[action.streamElementIndex].data[
        action.courseIndex
      ].data.data;
    newDataItemArray = action.data;
    newData[action.streamIndex].data[action.streamElementIndex].data[
      action.courseIndex
    ].data.data = newDataItemArray;
    return { ...item, data: newData };
  });
};

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = [], action) {
  switch (action.type) {
    case ELEMENTS_DATA_ALL_GET:
      return action.data;
    case ELEMENTS_DATA_ONE_ADD:
      return action.isFeature
        ? addFeatureObject(state, action)
        : action.isStream
          ? addStreamObject(state, action)
          : action.isBranch
            ? addBranchObject(state, action)
            : addObject(state, action);
    case ELEMENTS_DATA_ONE_UPDATE:
      return action.isFeature
        ? updateFeatureObject(state, action)
        : action.isStream
          ? updateStreamObject(state, action)
          : action.isBranch
            ? updateBranchObject(state, action)
            : updateObject(state, action);
    case ELEMENTS_DATA_ONE_DELETE:
      return action.isFeature
        ? deleteFeatureObject(state, action)
        : action.isStream
          ? deleteStreamObject(state, action)
          : action.isBranch
            ? deleteBranchObject(state, action)
            : deleteObject(state, action);
    case ELEMENTS_DATA_REFRESH:
      return refreshBranchObject(state, action);

    default:
      return state;
  }
}
