import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";

const customStyles = {
  valueContainer: styles => ({
    ...styles,
    maxHeight: "150px",
    overflow: "auto"
  })
};

class BranchMultiSelector extends Component {
  // handleChange = (value, type) => {
  //   const { isMulti, onChange, selectedValues } = this.props;
  //   let newSelectedValue = selectedValues || [];
  //   let data = null;
  //   if (type.action === "select-option" && isMulti) {
  //     const aindex = newSelectedValue.reduce((acc, obj) => {
  //       acc[obj.id] = obj;
  //       return acc;
  //     }, {});
  //     const modifiedData = value.filter(obj => !aindex[obj.id]);
  //     data = modifiedData.map(item => {
  //       const { id, short_name, full_name } = item;
  //       return {
  //         id,
  //         short_name,
  //         full_name
  //       };
  //     });
  //     const updatedArray = newSelectedValue.concat(data);
  //     onChange(updatedArray);
  //     console.log(updatedArray);
  //   } else if (type.action === "remove-value") {
  //     const updatedArray = newSelectedValue.filter(
  //       item => item.id !== type.removedValue.id
  //     );
  //     onChange(updatedArray);
  //   } else if (type.action === "clear") {
  //     onChange([]);
  //   }
  // };

  handleChange = (value, type) => {
    const { onChange, selectedValues } = this.props;
    let newSelectedValue = selectedValues || [];

    //********************** */For adding a new Element to array******************************//

    if (type.action === "select-option") {
      const { id, short_name, full_name } = type.option;
      const data = [
        { id, short_name, full_name, seats: undefined, speciality: undefined }
      ];
      const updatedArray = newSelectedValue.concat(data);
      onChange(updatedArray);
    }

    //********************** */for removing 1 element from an array*******************************//
    else if (type.action === "remove-value") {
      const updatedArray = newSelectedValue.filter(
        item => item.id !== type.removedValue.id
      );
      onChange(updatedArray);
    }
    //*************************** */for removing all elements from an array*************************
    else if (type.action === "clear") {
      onChange([]);
    }
  };

  handleValue = () => {
    const { selectedValues, data } = this.props;
    var selectorValue = null;

    if (selectedValues) {
      selectorValue = selectedValues.map(item => {
        const { id, short_name, full_name } = item;
        const updated_short_name = short_name ? `(${short_name})` : "";
        return {
          id,
          value: full_name,
          label: `${full_name}${updated_short_name}`
        };
      });
      return selectorValue;
    }
  };
  render() {
    const { selectedValues, data } = this.props;

    return (
      <React.Fragment>
        <Select
          isClearable
          isMulti={true}
          options={data}
          value={this.handleValue()}
          components={makeAnimated()}
          onChange={this.handleChange}
          styles={customStyles}
          backspaceRemovesValue={false}
        />
      </React.Fragment>
    );
  }
}

export default BranchMultiSelector;
