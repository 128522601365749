import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class DropDownSelectorIdAndName extends Component {
  handleChange = value => {
    if (value) this.props.onChange(value.id, value.value);
    else this.props.onChange(value, value);
  };

  handleValue = () => {
    const { selectedValues, data, isMulti } = this.props;
    if (selectedValues) {
      return data.filter(item =>
        isMulti ? selectedValues.includes(item.id) : selectedValues === item.id
      );
    }
    return null;
  };

  render() {
    const { data, isMulti } = this.props;
    return (
      <Select
        isMulti={isMulti}
        options={data}
        isClearable
        value={this.handleValue()}
        components={makeAnimated()}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleChange}
        backspaceRemovesValue={false}
      />
    );
  }
}

export default DropDownSelectorIdAndName;
