import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { shortNameLength } from "utils/values.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";

import * as actions from "data/elements/actions";
import { LinearProgress } from "@material-ui/core";
import BasicMultiSelectors from "views/Components/BasicMultiSelectors";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class BranchIndependentDataModal extends React.Component {
  state = {
    modal: true,
    full_name: "",
    short_name: "",
    id: null,
    error: "",
    branches: [],
    parent_branch_id: null
  };

  getBranches = (branches, id) => {
    if (branches) {
      return branches.data.filter(item => item.id !== id).map(item => ({
        id: item.id,
        value: item.full_name,
        label: `${item.full_name}(${item.short_name})`
      }));
    } else {
      return [];
    }
  };

  componentDidMount() {
    const { isEditing } = this.props.elements.ui;
    const { branches } = this.props;
    if (isEditing) {
      const { rowIndex } = this.props.elements.appState;
      const rowData = this.props.elementData.data[rowIndex];
      const { parent_branch_id, short_name, full_name, id } = rowData;
      const branches_full = this.getBranches(branches, id);
      this.setState({
        parent_branch_id,
        short_name,
        full_name,
        id,
        branches: branches_full
      });
    } else {
      const branches_full = this.getBranches(branches);
      this.setState({ branches: branches_full });
    }
  }
  handleTextInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.hideIndependentBranchDataModal();
  };

  handleProgressBar = () => {
    const rowLoading = this.props.elements.appState.rowLoading;
    if (rowLoading) {
      return <LinearProgress />;
    }
    return null;
  };

  handleCreateElement = () => {
    const { appState, data } = this.props.elements;
    const { elementIndex, rowIndex } = appState;
    const path = data[elementIndex].path;
    const { short_name, full_name, id, parent_branch_id } = this.state;

    if (full_name.length) {
      //directly call the api from here.
      const data = {
        short_name,
        full_name,
        parent_branch_id
      };
      if (this.props.elements.ui.isEditing) {
        data.id = id;

        this.props.updateIndependentBranchElement(
          path,
          elementIndex,
          rowIndex,
          data
        );
      } else {
        this.props.addIndependentBranchElement(path, elementIndex, data);
      }
    } else {
      //handle the error case.
      this.setState({ error: "Full Name is Mandatory" });
    }
  };

  handleError = () => {
    if (this.props.error) {
      return <p>{this.props.error}</p>;
    }
    return null;
  };

  render() {
    const { classes, elements, elementData } = this.props;
    const name = elementData.name;
    const {
      full_name,
      short_name,
      error,
      branches,
      parent_branch_id
    } = this.state;
    const { showIndependentBranchModal } = elements.ui;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showIndependentBranchModal}
        transition={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => this.handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={() => this.handleClose()}>
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}>
          {error ? <pre>{error}</pre> : null}
          <div>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Short Name</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  placeholder="Short Name"
                  variant="outlined"
                  name="short_name"
                  onChange={this.handleTextInput}
                  inputProps={{ maxLength: shortNameLength }}
                  value={short_name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Full Name</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  fullWidth
                  required
                  placeholder="Full Name"
                  variant="outlined"
                  name="full_name"
                  onChange={this.handleTextInput}
                  value={full_name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Specialization Branch of</h6>
              </GridItem>
              <GridItem xs={12}>
                <BasicMultiSelectors
                  isMulti={false}
                  data={branches}
                  selectedValues={parent_branch_id}
                  onChange={id => this.setState({ parent_branch_id: id })}
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <br />
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateElement} color="danger" simple>
            {this.props.elements.ui.isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {this.handleProgressBar()}
        {this.handleError()}
      </Dialog>
    );
  }
}
function mapStateToProps({ elements }) {
  const elementIndex = elements.appState.elementIndex;
  const elementData = elements.data[elementIndex];
  const branches = elements.data.filter(item => item.key === "branches")[0];
  return {
    elements,
    elementData,
    branches
  };
}
export default withStyles(modalStyle)(
  connect(
    mapStateToProps,
    actions
  )(BranchIndependentDataModal)
);
