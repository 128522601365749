import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import api from "data/utils/api";
import { useSnackbar } from "notistack";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const localizer = momentLocalizer(moment);

const CalendarSection = () => {
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getCalendarData = async () => {
    //make api call here.
    try {
      setLoading(true);
      const res = await api.get(api.endpoints.calendar);
      const events = res.data;
      setLoading(false);
      setEvents(events || []);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  const selectedEvent = event => {
    window.alert(event.title);
  };

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardBody calendar>
              <BigCalendar
                selectable
                localizer={localizer}
                events={events}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={event => selectedEvent(event)}
                eventPropGetter={eventColors}
                popup
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CalendarSection;
