import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const customStyles = {
  valueContainer: styles => ({
    ...styles,
    maxHeight: "150px",
    overflow: "auto"
  }),
  multiValue: base => {
    return { ...base, backgroundColor: "#00B8D9" };
  },
  multiValueLabel: base => {
    return { ...base, fontWeight: "normal", color: "white", paddingRight: 8 };
  },
  multiValueRemove: base => {
    return { ...base, display: "none" };
  }
};

class TagsMultiSelector extends Component {
  handleChange = value => {
    const { onChange, selectedValues } = this.props;
    if (value.length > selectedValues.length) {
      onChange(value);
    }
  };

  render() {
    const { data, isMulti, selectedValues } = this.props;
    return (
      <React.Fragment>
        <Select
          isClearable={false}
          isMulti={isMulti}
          options={data}
          value={selectedValues}
          components={makeAnimated()}
          onChange={this.handleChange}
          styles={customStyles}
          backspaceRemovesValue={false}
        />
      </React.Fragment>
    );
  }
}

export default TagsMultiSelector;
