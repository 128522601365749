import React, { Component } from "react";
// import BasicDropDownSelector from "views/Forms/EazyForms/FormBasic/BasicDropDownSelector.jsx";
import TreeMultiSelector from "views/Components/TreeMultiSelector.jsx";

// import DropDownSelectorOnChange from "views/Forms/EazyForms/FormBasic/DropDownSelectorOnChange.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
class CourseTreeSelectorHandler extends Component {
  render() {
    const { setFieldValue, values, valuesItem } = this.props;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <FastField
              component={TreeMultiSelector}
              name={`form_basic.courses`}
              data={valuesItem}
              isMulti={true}
              onChange={value => {
                setFieldValue(`form_basic.courses`, value);
              }}
              selectedValues={values.form_basic.courses}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default CourseTreeSelectorHandler;
