import { combineReducers } from "redux";
// import dataReducers from "./dataReducers";
// import uiReducers from "./uiReducers";

import appStateLoginReducers from "./appStateLoginReducers";
import appStateForgotReducers from "./appStateForgotReducers";
import appStateResetReducers from "./appStateResetReducers";
import appStateSignupReducers from "./appStateSignupReducers";

export default combineReducers({
  appStateLogin: appStateLoginReducers,
  appStateForgot: appStateForgotReducers,
  appStateReset: appStateResetReducers,
  appStateSignup: appStateSignupReducers
  //   data: dataReducers,
  //   ui: uiReducers
});
