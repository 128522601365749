import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class BasicMultiSelectors extends Component {
  handleChange = value => {
    //for Multi Select id as an array
    if (this.props.isMulti) {
      let data_id = value.map(val => val.id);
      this.props.onChange(data_id);
    }
    // for single select
    else this.props.onChange(value.id);
  };

  handleValue = () => {
    const { selectedValues, data, isDisabled, isMulti } = this.props;

    if (isDisabled || !selectedValues) {
      return null;
    }
    return data.filter(item =>
      isMulti ? selectedValues.includes(item.id) : selectedValues === item.id
    );
  };

  render() {
    const { data, isMulti, isDisabled } = this.props;

    return (
      <Select
        isMulti={isMulti}
        options={data}
        isDisabled={isDisabled ? isDisabled : false}
        value={this.handleValue()}
        components={makeAnimated()}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleChange}
        backspaceRemovesValue={false}
      />
    );
  }
}

export default BasicMultiSelectors;
