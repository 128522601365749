import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import { FastField } from "formik";

class CollegeInputFields extends Component {
  render() {
    const {
      setFieldValue,
      websiteURL,
      establishmentYear,
      campusSize
    } = this.props;
    return (
      <Fragment>
        <GridItem xs={12} sm={6} md={4}>
          <br />
          <p style={{ fontWeight: "bold", margin: "0px" }}>Website URL</p>
          <FastField
            name="website_url"
            fullWidth
            // styleHeight
            type="text"
            component={BareTextFieldInput}
            placeholder="Website URL"
            onBlur={e => {
              setFieldValue("website_url", e.target.value);
            }}
            defaultValue={websiteURL}
          />
          <br />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <br />
          <p style={{ fontWeight: "bold", margin: "0px" }}>
            Establishment Year
          </p>
          <FastField
            name="established_year"
            fullWidth
            // styleHeight
            type="number"
            min="1000"
            max="9999"
            component={BareTextFieldInput}
            placeholder="Establishment Year"
            onBlur={e => {
              setFieldValue("establishment_year", e.target.value);
            }}
            defaultValue={establishmentYear}
          />
          <br />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <br />
          <p style={{ fontWeight: "bold", margin: "0px" }}>
            Campus Size (in Acres)
          </p>
          <FastField
            name="established_year"
            fullWidth
            // styleHeight
            type="number"
            min="1"
            max="99999"
            step={0.1}
            component={BareTextFieldInput}
            placeholder="Campus Size"
            onBlur={e => {
              setFieldValue("campus_size", e.target.value);
            }}
            defaultValue={campusSize}
          />
          <br />
        </GridItem>
      </Fragment>
    );
  }
}

export default CollegeInputFields;
