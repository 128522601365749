import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import { connect } from "react-redux";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import FeatureCell from "./Features/FeatureCell";
import FeatureDataModal from "./Features/FeatureDataModal";

class FeatureList extends Component {
  handleTabLayout = () => {
    return this.props.features.data.map((item, index) => {
      return {
        tabButton: item.full_name,
        tabContent: this.handleFeatureLayout(item, index)
      };
    });
  };

  handleFeatureLayout = (item, featureIndex) => {
    return (
      <GridContainer style={{ marginLeft: "0px", width: "100%" }}>
        {this.handleFeatureTableLayout(item, featureIndex)}
      </GridContainer>
    );
  };

  handleFeatureTableLayout = (item, featureIndex) => {
    return item.data.map((item, index) => {
      return (
        <GridItem xs={12} md={6} key={index}>
          <FeatureCell
            key={index}
            elementIndex={this.props.elementIndex}
            featureIndex={featureIndex}
            featureElementIndex={index}
          />
        </GridItem>
      );
    });
  };
  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleModalLayout = () => {
    return this.props.elements.ui.showFeatureModal ? (
      <FeatureDataModal />
    ) : null;
  };

  render() {
    return (
      <div>
        {this.handleModalLayout()}
        <NavPills color="warning" tabs={this.handleTabLayout()} />
      </div>
    );
  }
}

function mapStateToProps({ elements }, prevProps) {
  const elementIndex = prevProps.elementIndex;
  const features = elements.data[elementIndex];
  return { features, elements };
}

export default withStyles(tableFormStyles)(
  connect(mapStateToProps)(FeatureList)
);
