import React, { Fragment, Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import _ from "lodash";
import * as actions from "data/main/actions";
import { connect } from "react-redux";
import CollegeImageHandler from "./CollegeImageHandler";

class CollegeImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openImageModal: false,
      isLogo: false
    };
  }

  handleImageButtonAction = (isLogo = true) => {
    this.setState({ openImageModal: true, isLogo });
    // if (!this.props.collegeShortName && !this.props.id) {
    //   //show the notification that the user cannot get into image section without the short name.
    //   this.props.showNotification(
    //     "Upload image will only work after filling Unique Short Name",
    //     false
    //   );
    // } else {
    //   this.setState({ openImageModal: true, isLogo });
    // }
  };

  getDisplayURL = item => {
    let itemURL = _.get(item, "url", "");
    if (itemURL) {
      itemURL = item.display_url;
    }
    return itemURL;
  };

  handleImageValue = item => {
    // const value = _.get(item, "item.url", null);
    if (item && item.url) {
      return this.getDisplayURL(item);
    } else {
      return defaultImage;
    }
  };

  imageView = (name, value, isLogo = true) => {
    return (
      <GridContainer justify="center">
        <GridItem>
          <legend>{name}</legend>
          <div className={"thumbnail"}>
            <img
              src={this.handleImageValue(value)}
              alt="..."
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </div>
          <br />
          <Button
            color="tumblr"
            type="button"
            fullWidth
            onClick={() => this.handleImageButtonAction(isLogo)}>
            {`Manage ${name}`}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  imageHandlerView = (setFieldValue, id, logo, featured_image, folderPath) => {
    return (
      <CollegeImageHandler
        selectedImage={this.state.isLogo ? logo : featured_image}
        onClose={() => this.setState({ openImageModal: false })}
        onSubmit={value => {
          this.setState({ openImageModal: false });
          let key = folderPath === "forms" ? "form_basic." : "";
          key = `${key}${this.state.isLogo ? "logo" : "featured_image"}`;
          setFieldValue(key, value);
        }}
        itemId={id}
        isLogo={this.state.isLogo}
        folderPath={folderPath}
        displayURL={this.getDisplayURL(
          this.state.isLogo ? logo : featured_image
        )}
      />
    );
  };

  render() {
    const { setFieldValue, logo, featured_image, id, folderPath } = this.props;

    return (
      <Fragment>
        <GridItem xs={12} sm={6} md={2}>
          {this.imageView("Logo", logo, true)}
        </GridItem>
        <GridItem xs={12} sm={6} md={2}>
          {this.imageView("Featured Image", featured_image, false)}
        </GridItem>
        {this.state.openImageModal
          ? this.imageHandlerView(
              setFieldValue,
              id,
              logo,
              featured_image,
              folderPath
            )
          : null}
      </Fragment>
    );
  }
}
export default withStyles(tableFormStyles)(
  connect(
    null,
    actions
  )(CollegeImages)
);
