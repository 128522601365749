import React, { Component } from "react";
import { FieldArray, FastField } from "formik";
import FormikCheckbox from "views/Components/FormikCheckbox.jsx";
import KeyBasedTypeSelector from "views/Forms/EazyForms/FormBasic/KeyBasedTypeSelector";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Delete from "@material-ui/icons/Delete";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import Button from "components/CustomButtons/Button.jsx";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import Tooltip from "@material-ui/core/Tooltip";

const tableHead = ["Subject", "Questions", "Per Marks", "Total", "Actions"];

class ExamPaperDetails extends Component {
  state = {
    showDeleteAlert: false,
    index: null,
    remove: null
  };

  handlePaperDelete = (index, remove) => {
    this.setState({ index, showDeleteAlert: true, remove });
  };

  handleTotalMarks = (index, index1, sumValue) => {
    const { values, setFieldValue } = this.props;
    var prevValue = 0,
      prevValueTotal = 0;
    var prevValueToDec = values[`${index}`].pattern[`${index1}`].total;
    var preValueToAdd = values[`${index}`].total;
    if (prevValueToDec) {
      prevValue = parseFloat(prevValueToDec);
    }
    if (preValueToAdd) {
      prevValueTotal = parseFloat(preValueToAdd);
    }
    var sum = prevValueTotal - prevValue + sumValue;
    setFieldValue(
      `form_basic.exam_paper_details[${index}].total`,
      Math.round(sum * 100) / 100
    );
  };

  handleTotalQuestions = (index, index1, sumValue) => {
    const { values, setFieldValue } = this.props;
    var prevValue = 0,
      prevValueTotal = 0,
      newSumValue = 0;
    var prevValueToDec = values[`${index}`].pattern[`${index1}`].questions;
    var preValueToAdd = values[`${index}`].total_questions;
    if (prevValueToDec) {
      prevValue = parseInt(prevValueToDec, 10);
    }
    if (preValueToAdd) {
      prevValueTotal = parseInt(preValueToAdd, 10);
    }
    if (sumValue) {
      newSumValue = parseInt(sumValue, 10);
    }
    var sum = prevValueTotal - prevValue + newSumValue;
    setFieldValue(
      `form_basic.exam_paper_details[${index}].total_questions`,
      sum
    );
  };

  handleTotalMarksPerSubject = (
    index,
    index1,
    questionsValue,
    permarksValue
  ) => {
    const { setFieldValue, values } = this.props;
    var newQuestionValue = 0,
      newPerMarksValue = 0;
    if (!questionsValue) {
      newQuestionValue = parseFloat(
        values[`${index}`].pattern[`${index1}`].questions
      );
    }
    if (!permarksValue) {
      newPerMarksValue = parseFloat(
        values[`${index}`].pattern[`${index1}`].questions_per_marks
      );
    }
    var subjectTotalMarks =
      newQuestionValue * permarksValue + questionsValue * newPerMarksValue;
    setFieldValue(
      `form_basic.exam_paper_details[${index}].pattern[${index1}].total`,
      Math.round(subjectTotalMarks * 100) / 100
    );
    if (subjectTotalMarks > 0)
      this.handleTotalMarks(index, index1, subjectTotalMarks);
    else this.handleTotalMarks(index, index1, 0);
  };

  handleAlertSuccess = () => {
    this.setState({ showDeleteAlert: false });
    this.state.remove(this.state.index);
  };
  render() {
    const { values, setFieldValue, classes } = this.props;
    const tableCellClasses = classes.tableHeadCell + " " + classes.tableCell;
    const tableCellClassesCenter =
      classes.tableHeadCell + " " + classes.tableCell + " " + classes.center;
    return (
      <React.Fragment>
        {this.state.showDeleteAlert ? (
          <DeleteAlertBox
            handleSuccess={this.handleAlertSuccess}
            handleClose={() => this.setState({ showDeleteAlert: false })}
            title="Delete Paper ?">
            <span style={{ color: "red" }}>*Note: </span> Changes will be
            reflected in form after clicking on save button.
          </DeleteAlertBox>
        ) : null}
        <Card
          style={{
            backgroundColor: "#f9f9f9"
          }}>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Exam Pattern</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <FieldArray name={`form_basic.exam_paper_details`}>
              {({ push, remove }) => (
                <React.Fragment>
                  {values.map((item, index) => (
                    <React.Fragment
                      key={`form_basic.exam_paper_details[${index}]`}>
                      <Card>
                        <CardHeader color="rose" text>
                          <CardText color="rose">
                            <h4
                              className={
                                classes.cardTitle
                              }>{`Paper ${" "}${index + 1}`}</h4>
                          </CardText>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="flex-end">
                            <GridItem>
                              <Tooltip
                                title={`Delete Paper ${index + 1}`}
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}>
                                <Button
                                  onClick={() =>
                                    this.handlePaperDelete(index, remove)
                                  }
                                  className={classes.actionButton}
                                  color="danger"
                                  size="lg">
                                  <Delete className={classes.icon} />
                                </Button>
                              </Tooltip>
                            </GridItem>
                          </GridContainer>
                          <GridContainer alignItems="center">
                            <GridItem xs={12}>
                              <label className={classes.inputHeader}>
                                Negative Markings
                              </label>
                            </GridItem>
                            <GridItem>
                              <FastField
                                label="Does this paper has negative markings?"
                                name={`form_basic.exam_paper_details[${index}].negative_marking.is_active`}
                                component={FormikCheckbox}
                                onBlur={checkedValue => {
                                  setFieldValue(
                                    `form_basic.exam_paper_details[${index}].negative_marking.is_active`,
                                    !checkedValue
                                  );
                                }}
                                value={
                                  values[`${index}`].negative_marking.is_active
                                }
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <FastField
                                name={`form_basic.exam_paper_details[${index}].negative_marking.instruction`}
                                component={BareTextFieldInput}
                                fullWidth
                                placeholder="Negative Markings Instructions"
                                onBlur={e => {
                                  setFieldValue(
                                    `form_basic.exam_paper_details[${index}].negative_marking.instruction`,
                                    e.target.value
                                  );
                                }}
                                defaultValue={
                                  values[`${index}`].negative_marking
                                    .instruction
                                }
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12}>
                              <label className={classes.inputHeader}>
                                Paper {index + 1} Duration
                              </label>
                            </GridItem>
                            <GridItem>
                              <FastField
                                name={`form_basic.exam_paper_details[${index}].duration`}
                                component={BareTextFieldInput}
                                type="number"
                                step={0.01}
                                min={0}
                                max={100}
                                placeholder="Duration"
                                onBlur={e => {
                                  setFieldValue(
                                    `form_basic.exam_paper_details[${index}].duration`,
                                    parseFloat(e.target.value)
                                  );
                                }}
                                defaultValue={
                                  isNaN(values[`${index}`].duration)
                                    ? ""
                                    : values[`${index}`].duration
                                }
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} md={6}>
                              <br />
                              <FastField
                                component={KeyBasedTypeSelector}
                                nameKey={`form_basic.exam_paper_details[${index}].question_paper_types`}
                                setFieldValue={setFieldValue}
                                values={values[`${index}`].question_paper_types}
                                keyFilter="question_paper_types"
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12}>
                              <Table>
                                <React.Fragment>
                                  <TableHead className={classes["primary"]}>
                                    <TableRow className={classes.tableRow}>
                                      {tableHead.map((prop, key) => {
                                        return (
                                          <TableCell
                                            key={key}
                                            className={
                                              key === 0
                                                ? tableCellClasses
                                                : tableCellClassesCenter
                                            }>
                                            {prop}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  </TableHead>
                                  <FieldArray
                                    name={`form_basic.exam_paper_details[${index}].pattern`}>
                                    {({ push, remove }) => (
                                      <React.Fragment>
                                        {values[`${index}`].pattern.map(
                                          (item, index1) => (
                                            <TableBody
                                              key={`form_basic.exam_paper_details[${index}].pattern.[${index1}]`}>
                                              <TableRow
                                                className={classes.tableRow}>
                                                <TableCell
                                                  className={classes.tableCell}
                                                  style={{
                                                    width: "40%"
                                                  }}>
                                                  <br />
                                                  <FastField
                                                    component={
                                                      KeyBasedTypeSelector
                                                    }
                                                    nameKey={`form_basic.exam_paper_details[${index}].pattern[${index1}]`}
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    values={
                                                      values[`${index}`]
                                                        .pattern[`${index1}`].id
                                                    }
                                                    keyFilter="subjects"
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tableCell +
                                                    " " +
                                                    classes.center
                                                  }>
                                                  <FastField
                                                    name={`form_basic.exam_paper_details[${index}].pattern[${index1}].questions`}
                                                    component={
                                                      BareTextFieldInputOnChange
                                                    }
                                                    type="number"
                                                    min={0}
                                                    placeholder="Total Questions"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `form_basic.exam_paper_details[${index}].pattern[${index1}].questions`,
                                                        parseInt(
                                                          e.target.value,
                                                          10
                                                        )
                                                      );
                                                      this.handleTotalQuestions(
                                                        index,
                                                        index1,
                                                        e.target.value
                                                      );
                                                      this.handleTotalMarksPerSubject(
                                                        index,
                                                        index1,
                                                        parseInt(
                                                          e.target.value,
                                                          10
                                                        ),
                                                        null
                                                      );
                                                    }}
                                                    value={
                                                      isNaN(
                                                        values[`${index}`]
                                                          .pattern[`${index1}`]
                                                          .questions
                                                      )
                                                        ? ""
                                                        : values[`${index}`]
                                                            .pattern[
                                                            `${index1}`
                                                          ].questions
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tableCell +
                                                    " " +
                                                    classes.center
                                                  }>
                                                  <FastField
                                                    name={`form_basic.exam_paper_details[${index}].pattern[${index1}].questions_per_marks`}
                                                    component={
                                                      BareTextFieldInputOnChange
                                                    }
                                                    type="number"
                                                    step={0.01}
                                                    min={0}
                                                    placeholder="Marks Per Questions"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `form_basic.exam_paper_details[${index}].pattern[${index1}].questions_per_marks`,
                                                        parseFloat(
                                                          e.target.value
                                                        )
                                                      );
                                                      this.handleTotalMarksPerSubject(
                                                        index,
                                                        index1,
                                                        null,
                                                        e.target.value
                                                      );
                                                    }}
                                                    value={
                                                      isNaN(
                                                        values[`${index}`]
                                                          .pattern[`${index1}`]
                                                          .questions_per_marks
                                                      )
                                                        ? ""
                                                        : values[`${index}`]
                                                            .pattern[
                                                            `${index1}`
                                                          ].questions_per_marks
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tableCell +
                                                    " " +
                                                    classes.center
                                                  }>
                                                  <FastField
                                                    name={`form_basic.exam_paper_details[${index}].pattern[${index1}].total`}
                                                    component={
                                                      BareTextFieldInput
                                                    }
                                                    disabled
                                                    type="number"
                                                    inputColor="#1089F5"
                                                    placeholder="Total Marks"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `form_basic.exam_paper_details[${index}].pattern[${index1}].total`,
                                                        parseInt(
                                                          e.target.value,
                                                          10
                                                        )
                                                      );
                                                    }}
                                                    value={
                                                      values[`${index}`]
                                                        .pattern[`${index1}`]
                                                        .total
                                                        ? values[`${index}`]
                                                            .pattern[
                                                            `${index1}`
                                                          ].total
                                                        : 0
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  className={
                                                    classes.tableCell +
                                                    " " +
                                                    classes.center
                                                  }>
                                                  <Tooltip
                                                    title={
                                                      `Delete ` +
                                                      values[`${index}`]
                                                        .pattern[`${index1}`]
                                                        .full_name
                                                    }
                                                    placement="top"
                                                    classes={{
                                                      tooltip: classes.tooltip
                                                    }}>
                                                    <Button
                                                      onClick={() => {
                                                        remove(index1);
                                                        this.handleTotalQuestions(
                                                          index,
                                                          index1,
                                                          0
                                                        );
                                                        this.handleTotalMarks(
                                                          index,
                                                          index1,
                                                          0
                                                        );
                                                      }}
                                                      className={
                                                        classes.actionButton
                                                      }
                                                      color="danger"
                                                      size="lg">
                                                      <Delete
                                                        className={classes.icon}
                                                      />
                                                    </Button>
                                                  </Tooltip>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          )
                                        )}
                                        <TableBody>
                                          <TableRow
                                            className={classes.tableRow}>
                                            <TableCell
                                              className={classes.tableCell}>
                                              <GridContainer
                                                justify="flex-end"
                                                alignItems="center">
                                                <GridItem>
                                                  <label
                                                    className={
                                                      classes.inputHeader
                                                    }>
                                                    Total Questions
                                                  </label>
                                                </GridItem>
                                              </GridContainer>
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableCell}>
                                              <FastField
                                                name={`form_basic.exam_paper_details[${index}].total_questions`}
                                                component={BareTextFieldInput}
                                                type="number"
                                                disabled
                                                inputColor="#1089F5"
                                                placeholder="Total Questions"
                                                onBlur={e => {
                                                  setFieldValue(
                                                    `form_basic.exam_paper_details[${index}].total_questions`,
                                                    parseInt(e.target.value, 10)
                                                  );
                                                }}
                                                value={
                                                  values[`${index}`]
                                                    .total_questions
                                                }
                                              />
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableCell}>
                                              <GridContainer
                                                justify="flex-end"
                                                alignItems="center">
                                                <GridItem>
                                                  <label
                                                    className={
                                                      classes.inputHeader
                                                    }>
                                                    Total Marks
                                                  </label>
                                                </GridItem>
                                              </GridContainer>
                                            </TableCell>
                                            <TableCell
                                              className={
                                                classes.tableCell +
                                                " " +
                                                classes.center
                                              }>
                                              <FastField
                                                name={`form_basic.exam_paper_details[${index}].total`}
                                                disabled
                                                inputColor="#1089F5"
                                                component={BareTextFieldInput}
                                                type="number"
                                                placeholder="Total Marks"
                                                onBlur={e => {
                                                  setFieldValue(
                                                    `form_basic.exam_paper_details[${index}].total`,
                                                    parseInt(e.target.value, 10)
                                                  );
                                                }}
                                                value={values[`${index}`].total}
                                              />
                                            </TableCell>

                                            <TableCell
                                              className={classes.tableCell}
                                            />
                                          </TableRow>
                                        </TableBody>

                                        <TableBody>
                                          <TableRow
                                            className={classes.tableRow}>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell
                                              className={classes.center}>
                                              <GridContainer justify="flex-end">
                                                <GridItem>
                                                  <Button
                                                    color="tumblr"
                                                    type="button"
                                                    onClick={() =>
                                                      push({
                                                        id: "",
                                                        full_name: "",
                                                        questions: "",
                                                        questions_per_marks: "",
                                                        total: ""
                                                      })
                                                    }>
                                                    Add Subject
                                                  </Button>
                                                </GridItem>
                                              </GridContainer>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </React.Fragment>
                                    )}
                                  </FieldArray>
                                </React.Fragment>
                              </Table>
                            </GridItem>
                          </GridContainer>
                          <GridContainer
                            justify="flex-end"
                            alignItems="flex-end">
                            <GridItem xs={12}>
                              <br />
                              <label className={classes.inputHeader}>
                                Paper {index + 1} Instructions
                              </label>
                              <FastField
                                multiline
                                name={`form_basic.exam_paper_details[${index}].instruction`}
                                component={BareTextFieldInput}
                                fullWidth
                                styleHeight
                                rows="3"
                                rowsMax="5"
                                placeholder="Paper Instructions"
                                onBlur={e => {
                                  setFieldValue(
                                    `form_basic.exam_paper_details[${index}].instruction`,
                                    e.target.value
                                  );
                                }}
                                defaultValue={values[`${index}`].instruction}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  ))}
                  <GridContainer justify="flex-end">
                    <GridItem>
                      <Button
                        color="tumblr"
                        type="button"
                        onClick={() =>
                          push({
                            negative_marking: {
                              is_active: false,
                              instruction: ""
                            },
                            instruction: "",
                            duration: "",
                            question_paper_types: [],
                            total: "",
                            total_questions: "",
                            pattern: [
                              {
                                id: "",
                                full_name: "",
                                questions: "",
                                questions_per_marks: "",
                                total: ""
                              }
                            ]
                          })
                        }>
                        Add Paper
                      </Button>
                    </GridItem>
                  </GridContainer>
                </React.Fragment>
              )}
            </FieldArray>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(ExamPaperDetails);
