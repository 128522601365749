import React, { Component } from "react";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import SelectorOnKeyWithoutFormik from "views/Components/College/SelectorOnKeyWithoutFormik";
import UniversitySelectorWithoutFormik from "views/College/MultiCollegeEdit/UniversitySelectorWithoutFormik.jsx";
import SetAddress from "views/Components/College/SetAddress";
import api from "data/utils/api";
import { shortNameForm } from "utils/values.jsx";
import AsyncCheckUniqueHooks from "views/Components/College/AsyncCheckUniqueHooks.jsx";
import CardText from "components/Card/CardText.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import { connect } from "react-redux";
import { showNotification } from "data/main/actions";
import LoadingOverlay from "react-loading-overlay";

class CollegeEdit extends Component {
  state = {
    collegeData: this.props.collegeData,
    shortNameUnique: false,
    fullNameUnique: false,
    isLoading: false,
    errors: []
  };

  handleSampleData = sample_data => {
    return sample_data.map((item, index) => (
      <div key={index}>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>{item.title}</p>
        <p>{item.value ? item.value : "Not Available"}</p>
      </div>
    ));
  };

  checkErrorBeforeSendingData = () => {
    const checkField = {
      short_name: "Short Name",
      full_name: "Full Name",
      institution_type_id: "Institution Types",
      ownership_type_id: "Ownership Types",
      address: "Address",
      shortNameUnique: "Short Name Unique",
      fullNameUnique: "Full Name Unique"
    };
    const addressCheck = {
      line_1: "Address Line 1",
      city: "City",
      state: "State",
      country: "Country"
    };
    let errors = [];
    const updatedData = this.state.collegeData;
    Object.keys(checkField).forEach(item => {
      if (item === "shortNameUnique" || item === "fullNameUnique") {
        if (!this.state[item]) {
          errors.push(checkField[item]);
        }
      } else if (item !== "address" && !updatedData[item]) {
        errors.push(checkField[item]);
      } else if (item === "address") {
        Object.keys(addressCheck).forEach(addr => {
          if (updatedData.address && !updatedData.address[addr]) {
            errors.push(addressCheck[addr]);
          }
        });
      }
    });

    if (!errors.length) {
      this.handleSubmit();
    } else {
      this.setState({ errors });
    }
  };

  handleSubmit = async () => {
    const { collegeData } = this.state;
    const { state, city, limit, offset } = this.props;
    // const updatedData = this.state.collegeBasic.filter(
    //   item => item.id === sendData.id
    // )[0];
    const id = collegeData.id;
    const data = _.omit(collegeData, ["id", "sample_data", "state", "city"]);
    this.setState({ isLoading: true });
    try {
      const res = await api.post(api.endpoints["colleges-temp"].root, {
        data,
        id,
        state,
        city,
        offset,
        limit
      });
      this.handleServerResponseData(res);
      this.props.showNotification("College Data Added Successfully");
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleServerResponseData = async res => {
    this.props.itemRemoveIndex(this.props.collegeIndex, res);
    this.setState({ isLoading: false });
  };

  handleDataUpdation = (key, value) => {
    this.setState(prevState => ({
      collegeData: {
        ...prevState.collegeData,
        [key]: value
      }
    }));
  };

  handleAsyncUnique = (key, value, isUnique) => {
    let uniquenessKey = "";
    if (key === "short_name") {
      uniquenessKey = "shortNameUnique";
    } else if (key === "full_name") {
      uniquenessKey = "fullNameUnique";
    }
    this.setState(prevState => ({
      collegeData: {
        ...prevState.collegeData,
        [key]: value
      },
      [uniquenessKey]: isUnique
    }));
  };

  componentWillReceiveProps({ collegeData }) {
    if (collegeData.id !== this.state.collegeData.id) {
      this.setState({ ...this.state, collegeData, errors: [] });
    }
  }
  render() {
    const { classes } = this.props;
    const { errors, collegeData, isLoading } = this.state;
    const {
      name,
      short_name,
      full_name,
      sample_data,
      establishment_year,
      accreditations,
      regulations,
      institution_type_id,
      university_type_id,
      ownership_type_id,
      website_url,
      facilities,
      address,
      collegeIndex,
      affiliated_to
    } = collegeData;
    return (
      <div>
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Submitting College Data"
        >
          <Card>
            <CardHeader color="rose" icon>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{name}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={9}>
                    <GridContainer>
                      <GridItem xs={6} sm={4}>
                        <br />
                        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                          Short Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <AsyncCheckUniqueHooks
                          inputValue={short_name}
                          apiEndpoint={api.endpoints.college.filters}
                          filterKey="short_name"
                          maxlength={shortNameForm}
                          id={this.state.collegeData.id}
                          placeholder="Short Name"
                          setInputValue={(value, isUnique) =>
                            this.handleAsyncUnique(
                              "short_name",
                              value,
                              isUnique
                            )
                          }
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6}>
                        <br />
                        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                          Full Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <AsyncCheckUniqueHooks
                          inputValue={full_name}
                          apiEndpoint={api.endpoints.college.filters}
                          filterKey="full_name"
                          placeholder="Full Name"
                          id={this.state.collegeData.id}
                          setInputValue={(value, isUnique) =>
                            this.handleAsyncUnique("full_name", value, isUnique)
                          }
                        />
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        <br />
                        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                          Establishment Year
                        </p>
                        <BareTextFieldInputOnChange
                          fullWidth
                          placeholder="Enter Establishment Year"
                          name="establishment_year"
                          type="number"
                          value={establishment_year}
                          onBlur={event =>
                            this.handleDataUpdation(
                              "establishment_year",
                              parseInt(event.target.value)
                            )
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <br />
                        <SelectorOnKeyWithoutFormik
                          valuesData={accreditations}
                          keyFilter="accrediting_bodies"
                          isMulti={true}
                          nameKey="accreditations"
                          setSelectorValue={value =>
                            this.handleDataUpdation("accreditations", value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <br />
                        <SelectorOnKeyWithoutFormik
                          valuesData={regulations}
                          keyFilter="regulatory_bodies"
                          isMulti={true}
                          nameKey="regulations"
                          setSelectorValue={value =>
                            this.handleDataUpdation("regulations", value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <SelectorOnKeyWithoutFormik
                          valuesData={institution_type_id}
                          keyFilter="institution_types"
                          isMulti={false}
                          nameKey="institution_type_id"
                          isRequired={true}
                          setSelectorValue={value =>
                            this.handleDataUpdation(
                              "institution_type_id",
                              value
                            )
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <UniversitySelectorWithoutFormik
                          name="university_type_id"
                          collegeIndex={collegeIndex}
                          handleEditData={({ key, value }) =>
                            this.handleDataUpdation(key, value)
                          }
                          university_type_id={university_type_id}
                          institution_type_id={institution_type_id}
                          affiliated_to={affiliated_to}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <SelectorOnKeyWithoutFormik
                          valuesData={ownership_type_id}
                          keyFilter="ownership_types"
                          isMulti={false}
                          nameKey="ownership_type_id"
                          isRequired={true}
                          setSelectorValue={value =>
                            this.handleDataUpdation("ownership_type_id", value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6}>
                        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                          Website URL
                        </p>
                        <BareTextFieldInputOnChange
                          fullWidth
                          placeholder="Enter Website URL"
                          name="website_url"
                          value={website_url}
                          onBlur={event =>
                            this.handleDataUpdation(
                              "website_url",
                              event.target.value
                            )
                          }
                        />
                        <label>
                          <a
                            href={website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {website_url}
                          </a>
                        </label>
                      </GridItem>
                      <GridItem xs={12}>
                        <br />
                        <SelectorOnKeyWithoutFormik
                          valuesData={facilities}
                          keyFilter="facilities"
                          isMulti={true}
                          nameKey="facilities"
                          setSelectorValue={value =>
                            this.handleDataUpdation("facilities", value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <SetAddress
                          handleAddress={value =>
                            this.handleDataUpdation("address", value)
                          }
                          // showAddress={show_address}
                          address={address}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={6}>
                        {errors && errors.length > 0 ? (
                          <Card>
                            <CardBody style={{ backgroundColor: "#ededed" }}>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "center"
                                }}
                              >
                                Errors
                              </p>
                              <ul style={{ color: "red" }}>
                                {errors.map(item => (
                                  <li>{`${item} field is mandatory`}</li>
                                ))}
                              </ul>
                            </CardBody>
                          </Card>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={3}>
                    <Card>
                      <CardBody style={{ backgroundColor: "#ededed" }}>
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>
                          Sample Data
                        </p>
                        <br />
                        <div style={{ paddingLeft: 0 }}>
                          {sample_data && sample_data.length > 0 ? (
                            this.handleSampleData(sample_data)
                          ) : (
                            <p>Not Available</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <br />
                    <br />
                    <GridContainer justify="flex-end">
                      <GridItem>
                        <Button
                          title="Add New Form"
                          color="success"
                          onClick={this.checkErrorBeforeSendingData}
                        >
                          SAVE
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </CardBody>
          </Card>
        </LoadingOverlay>
        {process.env.NODE_ENV !== "production" ? (
          <pre>{JSON.stringify(this.state.collegeData, null, 2)}</pre>
        ) : null}
      </div>
    );
  }
}

export default withStyles(tableFormStyles)(
  connect(null, { showNotification })(CollegeEdit)
);
