import api from "../utils/api";

// =================================================================================================
// FEATURE TYPES ===================================================================================
// =================================================================================================
const FEATURES_LOADING = "FEATURES_LOADING";
const FEATURES_SUCCESS = "FEATURES_SUCCESS";
const FEATURES_ERROR = "FEATURES_ERROR";

// =================================================================================================
// FEATURE REDUCERS ================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case FEATURES_LOADING:
      return { ...state, loading: true };
    case FEATURES_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case FEATURES_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state, loading: false };
  }
}

// =================================================================================================
// FEATURE ACTIONS =================================================================================
// =================================================================================================
export const getAllFeatures = () => async dispatch => {
  dispatch({ type: FEATURES_LOADING });
  try {
    const res = await api.get(api.endpoints.elements.features);
    dispatch({ type: FEATURES_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: FEATURES_ERROR, error: error.data.message });
  }
};
