import { combineReducers } from "redux";
import appStateReducers from "./appStateReducers";
import dataReducers from "./dataReducers";
import uiReducers from "./uiReducers";
import appStateReducersBasic from "./appStateReducersBasic";
import appStateReducersDetail from "./appStateReducersDetail";
import dataReducersBasic from "./dataReducersBasic";
import dataReducersDetail from "./dataReducersDetail";

export default combineReducers({
  appState: appStateReducers,
  appStateBasic: appStateReducersBasic,
  appStateDetail: appStateReducersDetail,
  data: dataReducers,
  dataBasic: dataReducersBasic,
  dataDetail: dataReducersDetail,
  ui: uiReducers
});
