import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "data/store";

import { indexRoutes, authRoutes } from "routes/index.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import PrivateRoute from "./views/PrivateRoute";
import { SnackbarProvider } from "notistack";
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <SnackbarProvider
      maxSnack={3}
      hideIconVariant
      preventDuplicate
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}>
      <Provider store={store}>
        <Switch>
          {authRoutes.map((prop, key) => {
            return (
              <PrivateRoute
                path={prop.path}
                component={prop.component}
                key={key}
                isAuthScreen={true}
              />
            );
          })}
          {indexRoutes.map((prop, key) => {
            return (
              <PrivateRoute
                path={prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
      </Provider>
    </SnackbarProvider>
  </Router>,
  document.getElementById("root")
);
