export const USER_APPSTATE_AUTH_LOADING = "USER_APPSTATE_AUTH_LOADING";
export const USER_APPSTATE_AUTH_SUCCESS = "USER_APPSTATE_AUTH_SUCCESS";
export const USER_APPSTATE_AUTH_ERROR = "USER_APPSTATE_AUTH_ERROR";

export const USER_APPSTATE_AUTH_LOGOUT_SUCCESS =
  "USER_APPSTATE_AUTH_LOGOUT_SUCCESS";
export const USER_APPSTATE_AUTH_LOGOUT_ERROR =
  "USER_APPSTATE_AUTH_LOGOUT_ERROR";

export const USER_DATA_AUTH = "USER_DATA_AUTH";
export const USER_DATA_AUTH_RESET = "USER_DATA_AUTH_RESET";
