import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";

import * as actions from "data/elements/actions";
import { LinearProgress } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class FeatureDataModal extends React.Component {
  state = {
    modal: true,
    full_name: "",
    id: null,
    error: ""
  };

  componentDidMount() {
    const { isEditing } = this.props.elements.ui;
    const { rowIndex, featureCell } = this.props;
    if (isEditing) {
      const { full_name, id } = featureCell[rowIndex];
      this.setState({ full_name, id });
    }
  }
  handleTextInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.hideFeatureDataModal();
  };

  handleProgressBar = () => {
    const rowLoading = this.props.elements.appState.rowLoading;
    if (rowLoading) {
      return <LinearProgress />;
    }
    return null;
  };

  handleCreateElement = () => {
    const { features, featureElement, feature } = this.props;
    const { appState } = this.props.elements;
    const {
      elementIndex,
      rowIndex,
      featureIndex,
      featureElementIndex
    } = appState;

    const featurePath = features.path;
    const featureId = feature.id;
    const path = featureElement.path;

    const { full_name, id } = this.state;
    if (full_name.length) {
      //directly call the api from here.
      if (this.props.elements.ui.isEditing) {
        this.props.updateFeatureElement(
          path,
          featurePath,
          featureId,
          elementIndex,
          featureIndex,
          featureElementIndex,
          rowIndex,
          { id, full_name }
        );
      } else {
        this.props.addFeatureElement(
          path,
          featurePath,
          featureId,
          elementIndex,
          featureIndex,
          featureElementIndex,
          { full_name }
        );
      }
    } else {
      //handle the error case.
      this.setState({ error: "Full Name is Mandatory" });
    }
  };

  handleError = () => {
    if (this.props.error) {
      return <p>{this.props.error}</p>;
    }
    return null;
  };
  render() {
    const { classes, elements, featureElement } = this.props;
    const { name } = featureElement;
    const { full_name, error } = this.state;
    const { showFeatureModal } = elements.ui;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showFeatureModal}
        transition={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => this.handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={() => this.handleClose()}>
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}>
          {error ? <pre>{error}</pre> : null}
          <div>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Full Name</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  placeholder="Full Name"
                  variant="outlined"
                  name="full_name"
                  onChange={this.handleTextInput}
                  value={full_name}
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <br />
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateElement} color="danger" simple>
            {this.props.elements.ui.isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {this.handleProgressBar()}
        {this.handleError()}
      </Dialog>
    );
  }
}
function mapStateToProps({ elements }) {
  const elementIndex = elements.appState.elementIndex;
  const featureIndex = elements.appState.featureIndex;
  const featureElementIndex = elements.appState.featureElementIndex;
  const rowIndex = elements.appState.rowIndex;

  const features = elements.data[elementIndex];
  const feature = features.data[featureIndex];
  const featureElement = feature.data[featureElementIndex];
  const featureCell = featureElement.data;

  return {
    elements,
    features,
    feature,
    featureCell,
    featureElement,
    rowIndex
  };
}
export default withStyles(modalStyle)(
  connect(
    mapStateToProps,
    actions
  )(FeatureDataModal)
);
