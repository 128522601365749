import React, { Component } from "react";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import _ from "lodash";
class AddImageDetails extends Component {
  handleClickValue = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  constructor(props) {
    super(props);
    const { values } = props;
    this.state = {
      url: _.get(values, "url", ""),
      title: _.get(values, "title", ""),
      alt_text: _.get(values, "alt_text", ""),
      caption: _.get(values, "caption", ""),
      description: _.get(values, "description", "")
    };
  }

  handleSubmit = () => {
    const { url, title, alt_text, caption, description } = this.state;
    this.props.onSubmit({ url, title, alt_text, caption, description });
  };
  render() {
    const { classes, shortName, folderPath } = this.props;
    const { url, title, alt_text, caption, description } = this.state;
    return (
      <React.Fragment>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <ImageUpload
              addButtonProps={{
                color: "tumblr",
                round: true
              }}
              changeButtonProps={{
                color: "tumblr",
                round: true
              }}
              removeButtonProps={{
                color: "danger",
                round: true
              }}
              addImageDetailsProps={{
                color: "tumblr",
                round: true
              }}
              shortName={shortName}
              folderPath={folderPath}
              imageUrl={url}
              imageUploadUrl={url => this.setState({ url })}
              imageFileTitle={title => this.setState({ title })}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="flex-start">
              <GridItem>
                <label className={classes.inputHeader}>Logo URL</label>
              </GridItem>
              <BareTextFieldInputOnChange
                name="url"
                fullWidth
                disabled
                placeholder="URL"
                onBlur={e => {
                  this.handleClickValue(e);
                }}
                value={url}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer justify="flex-start">
              <GridItem>
                <label className={classes.inputHeader}>Title</label>
              </GridItem>
              <BareTextFieldInputOnChange
                name="title"
                fullWidth
                placeholder="Title"
                onBlur={e => {
                  this.handleClickValue(e);
                }}
                value={title}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer justify="flex-start">
              <GridItem>
                <label className={classes.inputHeader}>Caption</label>
              </GridItem>
              <BareTextFieldInputOnChange
                name="caption"
                fullWidth
                placeholder="Caption"
                onBlur={e => {
                  this.handleClickValue(e);
                }}
                value={caption}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="flex-start">
              <GridItem>
                <label className={classes.inputHeader}>Alt Text</label>
              </GridItem>
              <BareTextFieldInputOnChange
                name="alt_text"
                fullWidth
                placeholder="Alt Text"
                onBlur={e => {
                  this.handleClickValue(e);
                }}
                value={alt_text}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="flex-start">
              <GridItem>
                <label className={classes.inputHeader}>Description</label>
              </GridItem>
              <BareTextFieldInputOnChange
                name="description"
                multiline
                styleHeight
                rows="3"
                rowsMax="5"
                fullWidth
                placeholder="Description"
                onBlur={e => {
                  this.handleClickValue(e);
                }}
                value={description}
              />
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              simple
              onClick={this.props.onClose}
              style={{ color: "grey" }}
            >
              Cancel
            </Button>

            <Button onClick={() => this.handleSubmit()} color="rose">
              Apply
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(AddImageDetails);
