import api from "data/utils/api";
import {
  FORMS_APPSTATE_ALL_LOADING,
  FORM_BASIC_APPSTATE_LOADING,
  FORM_DETAIL_APPSTATE_LOADING,
  FORMS_APPSTATE_ALL_SUCCESS,
  FORM_BASIC_APPSTATE_SUCCESS,
  FORM_DETAIL_APPSTATE_SUCCESS,
  FORMS_APPSTATE_ALL_ERROR,
  FORM_BASIC_APPSTATE_ERROR,
  FORM_DETAIL_APPSTATE_ERROR,
  FORMS_DATA_ALL_GET,
  FORM_BASIC_DATA_GET,
  FORM_DETAIL_DATA_GET,
  FORMS_DATA_CONTENT_COUNT,
  FORMS_DATA_TOTAL_COUNT,
  FORMS_DATA_PAGE_START,
  FORM_DETAIL_DATA_RESET,
  FORM_BASIC_DATA_RESET,
  FORM_BASIC_APPSTATE_RESET
  //FORMS_DATA_PAGE_LIMIT
} from "data/forms/types";

import showNotification from "data/utils/notificationObject";

// =================================================================================================
// GET ALL ELEMENTS ================================================================================
// =================================================================================================
export const getAllForms = (
  offset,
  limit,
  currentPage,
  filterValue
) => async dispatch => {
  dispatch({ type: FORMS_APPSTATE_ALL_LOADING });
  try {
    const res = await api.get(api.endpoints.forms, {
      offset,
      limit,
      name: filterValue
    });

    dispatch({
      type: FORMS_DATA_ALL_GET,
      data: res.data,
      currentPage,
      search: filterValue ? true : false
    });
    dispatch({ type: FORMS_DATA_TOTAL_COUNT, totalCount: res.totalCount });
    dispatch({ type: FORMS_DATA_CONTENT_COUNT, contentCount: res.count });
    //dispatch({ type: FORMS_DATA_PAGE_LIMIT, pageLimit: res.limit });
    dispatch({ type: FORMS_DATA_PAGE_START, pageStart: res.start });
    dispatch({ type: FORMS_APPSTATE_ALL_SUCCESS });
  } catch (error) {
    dispatch({
      type: FORMS_APPSTATE_ALL_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET FORM-BASIC ELEMENT ==========================================================================
// =================================================================================================
export const getFormBasicById = id => async dispatch => {
  dispatch({ type: FORM_BASIC_APPSTATE_LOADING });
  try {
    const res = await api.get(`${api.endpoints.forms}/${id}/basic`);
    dispatch({ type: FORM_BASIC_DATA_GET, data: res.data });
    dispatch({ type: FORM_BASIC_APPSTATE_SUCCESS });
  } catch (error) {
    dispatch({ type: FORM_BASIC_DATA_RESET });
    dispatch({
      type: FORM_BASIC_APPSTATE_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET FORM-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const getFormDetailById = id => async dispatch => {
  dispatch({ type: FORM_DETAIL_APPSTATE_LOADING });
  try {
    const res = await api.get(`${api.endpoints.forms}/${id}/detail`);
    dispatch({
      type: FORM_DETAIL_DATA_GET,
      data: res.data
    });
    dispatch({ type: FORM_DETAIL_APPSTATE_SUCCESS });
  } catch (error) {
    dispatch({ type: FORM_DETAIL_DATA_RESET });
    dispatch({
      type: FORM_DETAIL_APPSTATE_ERROR,
      error
    });
  }
};

// =================================================================================================
// GET FORM-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const createFormDetailById = (id, data) => async dispatch => {
  dispatch({ type: FORM_DETAIL_APPSTATE_LOADING });
  try {
    const res = await api.post(`${api.endpoints.forms}/${id}/detail`, { data });
    dispatch({ type: FORM_DETAIL_DATA_RESET });
    dispatch({ type: FORM_DETAIL_APPSTATE_SUCCESS, navigateBack: true });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({
      type: FORM_DETAIL_APPSTATE_ERROR,
      error
    });
    dispatch(showNotification(error, false));
    dispatch({ type: FORM_DETAIL_DATA_GET, data: { data } });
  }
};

// =================================================================================================
// GET FORM-DETAIL ELEMENT =========================================================================
// =================================================================================================
export const upsertFormBasicById = (id, data) => async dispatch => {
  dispatch({ type: FORM_BASIC_APPSTATE_LOADING });
  try {
    var res;
    if (id) {
      res = await api.put(`${api.endpoints.forms}/${id}/basic`, { data });
    } else {
      res = await api.post(`${api.endpoints.forms}`, { data });
    }
    dispatch({ type: FORM_BASIC_APPSTATE_SUCCESS, navigateBack: true });
    dispatch({ type: FORM_BASIC_DATA_RESET });
    dispatch({ type: FORM_BASIC_APPSTATE_RESET });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({ type: FORM_BASIC_DATA_GET, data: data });
    dispatch({
      type: FORM_BASIC_APPSTATE_ERROR,
      error
    });
    dispatch(showNotification(error, false));
  }
};
