import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import { FastField, Field } from "formik";
import BranchSelectors from "views/College/Basic/Stream/BranchSelectors";
import BranchDisplay from "views/College/Basic/Stream/BranchDisplay";
import CourseFeesDisplay from "views/College/Basic/Stream/CourseFeesDisplay";
import AsyncMultiSelector from "views/Components/College/AsyncMultiSelector.jsx";
import api from "data/utils/api";
import SelectorBasedOnKey from "views/Components/College/SelectorBasedOnKey.jsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "views/Components/ExpansionPanelCustomized.jsx";

class CourseDisplay extends Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  /*
  tabsData = () => {
    const {
      streamData,
      setFieldValue,
      streamIndex,
      courseData,
      courseIndex
    } = this.props;
    return courseData.branches.map((item, index) => {
      return {
        tabName: item.full_name,
        tabContent: (
          <BranchDisplay
            branchData={
              courseData.branches[index] ? courseData.branches[index] : []
            }
            courseIndex={courseIndex}
            streamIndex={streamIndex}
            streamData={streamData}
            courseData={courseData}
            branchIndex={index}
            setFieldValue={setFieldValue}
          />
        )
      };
    });
  };
  */

  createLayout = () => {
    const {
      streamData,
      setFieldValue,
      streamIndex,
      courseData,
      courseIndex
    } = this.props;
    const { expanded } = this.state;
    return courseData.branches.map((item, index) => {
      return (
        <ExpansionPanel
          square
          expanded={expanded === index.toString()}
          onChange={this.handleChange(index.toString())}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {item.full_name}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BranchDisplay
              branchData={item || []}
              courseIndex={courseIndex}
              streamIndex={streamIndex}
              streamData={streamData}
              courseData={courseData}
              branchIndex={index}
              setFieldValue={setFieldValue}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  render() {
    const {
      streamData,
      setFieldValue,
      streamIndex,
      courseData,
      courseIndex
    } = this.props;
    return (
      <React.Fragment>
        <GridContainer justify="flex-start">
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontWeight: "bold", margin: "0px" }}>
              Is this Course a part of CAF ?
            </p>
            <FastField
              name={`streams[${streamIndex}].courses[${courseIndex}].is_caf_applicable`}
              component={FormikToggleButton}
              onBlur={checkedValue => {
                setFieldValue(
                  `streams[${streamIndex}].courses[${courseIndex}].is_caf_applicable`,
                  !checkedValue
                );
              }}
              value={
                courseData.is_caf_applicable === undefined
                  ? true
                  : courseData.is_caf_applicable
              }
            />
          </GridItem>
          <GridItem xs={12}>
            <br />
            <p style={{ fontWeight: "bold" }}>College Year Wise Fees</p>
            <GridContainer justify="flex-start">
              <FastField
                component={CourseFeesDisplay}
                setFieldValue={setFieldValue}
                streamIndex={streamIndex}
                courseIndex={courseIndex}
                courseData={courseData}
                feesArray={courseData.fees}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <br />
            <FastField
              component={SelectorBasedOnKey}
              valuesData={courseData.study_modes}
              keyFilter="study_modes"
              isMulti={true}
              nameKey={`streams[${streamIndex}].courses[${courseIndex}].study_modes`}
              setFieldValue={setFieldValue}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <br />
            <p style={{ fontWeight: "bold" }}>{`Exams`}</p>
            <Field
              component={AsyncMultiSelector}
              selectedValues={courseData.exams}
              filterKey="exam"
              name="exams"
              isMulti={true}
              apiEndpoint={api.endpoints.forms}
              onChange={value => {
                setFieldValue(
                  `streams[${streamIndex}].courses[${courseIndex}].exams`,
                  value
                );
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FastField
              component={BranchSelectors}
              streamData={streamData}
              setFieldValue={setFieldValue}
              streamIndex={streamIndex}
              courseIndex={courseIndex}
              courseData={courseData}
            />
          </GridItem>

          {courseData.branches ? (
            courseData.branches.length > 0 ? (
              <GridItem xs={12}>{this.createLayout()}</GridItem>
            ) : null
          ) : null}
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(formStyle)(CourseDisplay);
