import React, { Component } from "react";
import sprites from "assets/img/sprites.svg";

const SvgWrapper = props => {
  const {
    defaultColor = "#4d4d4d",
    selectedColor = "#e91e63",
    width = "100px",
    height = "100px",
    iconId,
    isSelected
  } = props;
  return (
    <svg
      style={{
        fill: isSelected ? selectedColor : defaultColor,
        width: width,
        height: height
        // stroke: isSelected ? selectedColor : defaultColor
      }}>
      <use xlinkHref={`${sprites}#${iconId}${isSelected ? '-selected':''}`} />
    </svg>
  );
};

export default SvgWrapper;
