import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import { Field } from "formik";
import BranchMultiSelector from "views/College/Basic/Stream/BranchMultiSelector";
import _ from "lodash";

class BranchSelectors extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.map(item => {
      const { id, short_name, full_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`,
        short_name,
        full_name
      };
    });
    return newValue;
  };

  getFilteredElementsData = elements => {
    const {
      setFieldValue,
      streamData,
      streamIndex,
      courseData,
      courseIndex
    } = this.props;
    const StreamArray = elements.filter(item => item.key === "streams")[0];
    const CourseArray = StreamArray.data.filter(
      streamItem => streamItem.id === streamData.id
    )[0].data[0].data;
    const BranchArray = CourseArray.filter(
      courseItem => courseItem.id === courseData.id
    )[0].data;
    if (!_.isEmpty(BranchArray.data)) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <p style={{ fontWeight: "bold" }}>Branches</p>
            <Field
              name={`streams[${streamIndex}].courses[${courseIndex}].branches`}
              component={BranchMultiSelector}
              data={this.arrayModified(BranchArray.data)}
              onChange={value => {
                setFieldValue(
                  `streams[${streamIndex}].courses[${courseIndex}].branches`,
                  value
                );
              }}
              selectedValues={courseData.branches}
            />
            <br />
          </GridItem>
        </GridContainer>
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(BranchSelectors);
