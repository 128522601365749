import React, { Component } from "react";
import DropDownSelectorIdAndName from "views/Components/DropDownSelectorIdAndName.jsx";
import DropDownSelectorOnChange from "views/Components/DropDownSelectorOnChange.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import _ from "lodash";
import { FastField } from "formik";
class HandlingSubjectAndQuestionValues extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.data.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`
      };
    });
    return newValue;
  };

  onChangeIdAndNameSelectorHandler = (data, key, isMulti) => {
    const { setFieldValue, values } = this.props;
    if (_.isEmpty(data)) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <FastField
            component={DropDownSelectorIdAndName}
            name={`${key}`}
            data={this.arrayModified(data)}
            isMulti={isMulti}
            onChange={(id, value) => {
              setFieldValue(`${key}.id`, id);
              setFieldValue(`${key}.full_name`, value);
            }}
            selectedValues={values}
          />
        </GridItem>
      </GridContainer>
    );
  };

  onBlurSelectorHandler = (data, key, isMulti) => {
    const { setFieldValue, values } = this.props;
    if (_.isEmpty(data)) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <p style={{ fontWeight: "bold" }}>{data.name}</p>
          <FastField
            component={DropDownSelectorOnChange}
            name={`${key}`}
            data={this.arrayModified(data)}
            isMulti={isMulti}
            onChange={value => {
              setFieldValue(`${key}`, value);
            }}
            selectedValues={values}
          />
        </GridItem>
      </GridContainer>
    );
  };

  HandlingSelector = () => {
    const { valuesItem, nameKey } = this.props;
    switch (valuesItem.key) {
      case "subjects":
        return this.onChangeIdAndNameSelectorHandler(
          valuesItem,
          nameKey,
          false
        );
      case "question_paper_types":
        return this.onBlurSelectorHandler(valuesItem, nameKey, true);
      default:
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.HandlingSelector()}
        <br />
      </React.Fragment>
    );
  }
}

export default HandlingSubjectAndQuestionValues;
