import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CollegePagination from "views/College/CollegeListing/CollegePagination.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
//routing
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getAllColleges } from "data/colleges/actions";
import { getAllElements } from "data/elements/actions/dataActions";
import { showNotification } from "data/main/actions";
import _ from "lodash";
import Can from "../../../utils/Can";

const collegeRoutes = [
  {
    path: "/college-listing/basic",
    icon: Edit,
    name: "Basic",
    color: "info"
  },
  {
    path: "/college-listing/detail",
    icon: Edit,
    name: "Detail",
    color: "primary"
  }
];

class CollegeListing extends React.Component {
  componentDidMount() {
    const { elements } = this.props;
    if (!elements.data.length) {
      this.props.getAllElements();
    }
    this.fetchDataServer();
  }

  syncTabsDataWithServer = college_id => {
    const { elements } = this.props;
    if (!elements || !elements.data || !elements.data.length) {
      return [];
    }

    let collegeTabs = [];
    const features = elements.data.filter(item => item.key === "features");
    if (features.length) {
      const items = features[0].data.filter(item => item.id === 2);
      if (items.length) {
        const allTabs = items[0].data.filter(item => item.key === "tabs");
        if (allTabs.length) {
          collegeTabs = allTabs[0].data;
        }
      }
    }
    let orderedTabs = collegeTabs.sort(function(a, b) {
      return a.id - b.id;
    });

    const linkedTabs = orderedTabs.map(item => (
      <NavLink
        exact
        style={{
          display: "flex",
          flexGrow: 1,
          alignItems: "stretch",
          color: "inherit",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          paddingBottom: "5px"
        }}
        to={`/college-listing/detail/${college_id}/${item.id}`}
        onClick={this.handleNavLinkClick}
      >
        {item.full_name}
      </NavLink>
    ));
    return linkedTabs;
  };

  handleCollegeData = () => {
    const { appState, data } = this.props.colleges;
    let isSearched = data.isSearched;
    const { currentPage, pageLimit } = appState;
    const { classes } = this.props;
    const tableData = data.data[`${currentPage}`] || [];
    return tableData.map((item, key) => {
      const { alt_text, url, short_name, full_name, is_active, id } = item;
      return {
        row_count: isSearched
          ? key + 1
          : (currentPage - 1) * pageLimit + key + 1,
        url,
        alt_text,
        name: `${full_name}(${short_name})`,
        published: is_active ? "Yes" : "No",
        actions: (
          <div
            className="actions-right"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {collegeRoutes.map((item, key) => {
              if (item.redirect) {
                return null;
              }
              return (
                <React.Fragment key={key}>
                  {item.path === "/college-listing/basic" ? (
                    <NavLink
                      to={`${item.path}/${id}`}
                      style={{ marginRight: "5px" }}
                    >
                      <Tooltip
                        title={item.name}
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          // justIcon
                          // size="sm"
                          color={item.color}
                          className="edit"
                        >
                          {item.name}
                        </Button>
                      </Tooltip>
                    </NavLink>
                  ) : (
                    <CustomDropdown
                      buttonText={item.name}
                      buttonProps={{
                        // round: true,
                        color: item.color
                      }}
                      style={{ margin: "0px", padding: "0px" }}
                      dropdownList={this.syncTabsDataWithServer(id)}
                    />
                  )}
                  {"  "}
                </React.Fragment>
              );
            })}
          </div>
        )
      };
    });
  };

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined && typeof row[id] === "string"
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  searchFormData = (state, instance, data) => {
    const searchData = state.filter(item => item.id === "name");
    if (searchData.length > 0) {
      this.fetchDataServer(searchData[0].value);
    } else this.fetchDataServer();
  };

  fetchDataServer = value => {
    const { colleges, getAllColleges } = this.props;
    const { currentPage, pageStart, pageLimit } = colleges.appState;
    if (value) {
      getAllColleges(pageStart, pageLimit, currentPage, value);
    } else
      getAllColleges((currentPage - 1) * pageLimit, pageLimit, currentPage);
  };

  render() {
    const { classes, colleges } = this.props;
    const {
      currentPage,
      pageLimit,
      totalCount,
      error,
      loading
    } = colleges.appState;
    const formData = this.handleCollegeData();
    if (error) {
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
      return (
        <div>
          <strong>Something Went Wrong</strong>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Can I="create" a="College">
          <GridContainer justify="flex-end">
            <GridItem>
              <NavLink
                to={{
                  pathname: "/college-listing/basic",
                  state: { isNew: true }
                }}
              >
                <Button title="Add New Form" color="success" size="lg">
                  ADD COLLEGE
                </Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Can>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <GridItem xs={12}>
                <CardHeader color="primary" text>
                  <CardText color="primary">
                    <h4 className={classes.cardTitle}>
                      College Listing ({`${totalCount}`})
                    </h4>
                  </CardText>
                </CardHeader>
              </GridItem>

              <GridItem>
                <CardBody>
                  <ReactTable
                    data={formData}
                    pages={
                      Math.ceil(totalCount / pageLimit)
                        ? Math.ceil(totalCount / pageLimit)
                        : 1
                    }
                    manual
                    currentPage={currentPage}
                    PaginationComponent={CollegePagination}
                    onFilteredChange={this.searchFormData}
                    loading={loading}
                    filterable
                    defaultPageSize={pageLimit}
                    showPaginationTop={true}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    columns={[
                      {
                        Header: "#",
                        accessor: "row_count",
                        minWidth: 20,
                        sortable: false,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Logo",
                        Cell: row => (
                          <div>
                            <img
                              width={60}
                              height={60}
                              src={row.original.url}
                              alt="logo"
                            />
                          </div>
                        ),
                        sortable: false,
                        filterable: false,
                        minWidth: 50
                      },
                      {
                        Header: "Name",
                        accessor: "name",
                        minWidth: 120
                      },
                      {
                        Header: "Published",
                        accessor: "published",
                        minWidth: 50,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        minWidth: 100,
                        sortable: false,
                        filterable: false
                      }
                    ]}
                  />
                </CardBody>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ colleges, elements }) {
  return { colleges, elements };
}

export default withStyles(formStyle)(
  connect(mapStateToProps, {
    getAllColleges,
    showNotification,
    getAllElements
  })(CollegeListing)
);
