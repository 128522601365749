import React, { Component } from "react";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

class DeleteAlertBox extends Component {
  render() {
    const { title } = this.props;
    return (
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title ? title : "Are you sure?"}
        onConfirm={this.props.handleSuccess}
        onCancel={this.props.handleClose}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel>
        {this.props.children}
      </SweetAlert>
    );
  }
}

DeleteAlertBox.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withStyles(sweetAlertStyle)(DeleteAlertBox);
