// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";

import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import { FastField } from "formik";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const tableHead = ["Category", "Application Fee"];

class InputFormTables extends Component {
  render() {
    const { classes, values, setFieldValue, index } = this.props;
    const sectionValue = values.form_basic.application_fee[index];
    const full_name = sectionValue.full_name;
    const sectionKey = `form_basic.application_fee[${index}]`;

    return (
      <div>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{full_name}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHead className={classes["primary"]}>
                    <TableRow className={classes.tableRow}>
                      {tableHead.map((prop, key) => {
                        {
                          const tableCellClasses =
                            classes.tableHeadCell + " " + classes.tableCell;

                          return (
                            <TableCell key={key} className={tableCellClasses}>
                              {prop}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sectionValue.data.map((data, index) => {
                      const fieldKey = `${sectionKey}.data[${index}].application_fee`;
                      return (
                        <TableRow key={index} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            {`${data.full_name}${" "}(${data.short_name})`}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <FastField
                              name={fieldKey}
                              price
                              placeholder="Enter Amount"
                              component={BareTextFieldInput}
                              type="number"
                              step={0.01}
                              min={0}
                              onBlur={e => {
                                const domain = parseFloat(e.target.value);
                                setFieldValue(fieldKey, domain);
                              }}
                              defaultValue={
                                isNaN(data.application_fee)
                                  ? ""
                                  : data.application_fee
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(tableFormStyles)(InputFormTables);
