import React from "react";

// @material-ui/core components
import Facility from "views/College/Basic/Facilities/Facility";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import api from "data/utils/api";
import { showNotification } from "data/main/actions";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

class Facilities extends React.Component {
  state = {
    selectedFacilities: []
  };

  getFilteredElementsData = () => {
    const { elements } = this.props;
    return elements.data.filter(item => item.key === "facilities")[0];
  };

  handleChange = id => {
    const isSelected = this.state.selectedFacilities.indexOf(id) === -1;
    if (isSelected) {
      this.setState(prevState => ({
        selectedFacilities: [...prevState.selectedFacilities, id]
      }));
    } else {
      this.setState(prevState => ({
        selectedFacilities: prevState.selectedFacilities.filter(
          item => item !== id
        )
      }));
    }
  };

  handleFacilities = () => {
    const availableFacilities = this.getFilteredElementsData();
    return (
      availableFacilities &&
      availableFacilities.data.map((item, index) => {
        const isSelected =
          this.state.selectedFacilities.indexOf(item.id) !== -1;
        return (
          <Facility
            facData={item}
            key={index}
            handleChange={this.handleChange}
            isSelected={isSelected}
          />
        );
      })
    );
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const res = await api.get(`${api.endpoints.colleges}/${id}/facilities`);
      // const responseData = res.data.map(item => item.facility_id);
      this.setState({
        selectedFacilities: res.data
      });
      // console.log(res.data);
    } catch (error) {
      // console.log(error);
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong,Facilities"),
        false
      );
    }
  }

  saveFacilityData = async () => {
    const data = this.state.selectedFacilities;
    this.setState({ isLoading: true });
    const { id } = this.props;
    try {
      await api.post(`${api.endpoints.colleges}/${id}/facilities`, {
        data
      });
      this.props.showNotification("facilities Added Successfully");
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong,Facilities"),
        false
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.isLoading || false}
          style={{ overflow: "hidden" }}
        >
          <CircularProgress style={{ padding: "10px" }} />
        </Dialog>
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              title="Add Facilities"
              color="success"
              size="lg"
              onClick={this.saveFacilityData}
            >
              Save Facilities
            </Button>
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer justify="flex-start">
          {this.handleFacilities()}
        </GridContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps, { showNotification })(Facilities);
