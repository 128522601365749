import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class FormikToggleButton extends Component {
  render() {
    const { classes, onBlur, value, label } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={value}
            title={value ? "Yes" : "No"}
            onChange={() => onBlur(value)}
            // value={value}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              icon: classes.switchIcon,
              iconChecked: classes.switchIconChecked,
              bar: classes.switchBar
              // label: classes.label
            }}
          />
        }
        label={label ? label : ""}
        labelPlacement="start"
      />
    );
  }
}

export default withStyles(extendedFormsStyle)(FormikToggleButton);
