import { USER_DATA_AUTH, USER_DATA_AUTH_RESET } from "data/userProfile/types";

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case USER_DATA_AUTH:
      return action.data;
    case USER_DATA_AUTH_RESET:
      return {};
    default:
      return state;
  }
}
