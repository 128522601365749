import {
  USER_APPSTATE_AUTH_LOADING,
  USER_APPSTATE_AUTH_SUCCESS,
  USER_APPSTATE_AUTH_ERROR,
  USER_APPSTATE_AUTH_LOGOUT_SUCCESS
} from "data/userProfile/types";

const initialState = {
  loading: true,
  error: null,
  isAuthenticated: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_APPSTATE_AUTH_LOADING:
      return { ...state, loading: true };
    case USER_APPSTATE_AUTH_SUCCESS:
      return { ...state, error: null, loading: false, isAuthenticated: true };
    case USER_APPSTATE_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        isAuthenticated: false
      };
    case USER_APPSTATE_AUTH_LOGOUT_SUCCESS:
      return { ...state, error: null, isAuthenticated: false };
    default:
      return state;
  }
}
