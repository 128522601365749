import React, { useState, useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Card from "../../components/Card/Card.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem";
import { useSnackbar } from "notistack";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "data/utils/api";

import moment from "moment";
import Button from "components/CustomButtons/Button.jsx";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableRow, Divider } from "@material-ui/core";
const modalStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  loader: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}));

const CommentModal = ({
  openModal,
  handleModalClose,
  comment: user_comment
}) => {
  const classesModal = modalStyles();

  const [reply, setReply] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(user_comment || {});
  const [changed, setChanged] = useState(false);
  const { replies, pending_replies_count, ...main_comment } = comment;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // //scroll_to_bottom
  // const messagesEnd = React.useRef(null);

  // const scrollToBottom = () => {
  //   if (messagesEnd && messagesEnd.current)
  //     messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  // };

  useEffect(() => {
    setComment(user_comment || {});
  }, [user_comment]);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const tableRowComponent = (obj, type, isLast) => {
    return (
      <TableRow>
        <GridContainer
          style={{ marginTop: "16px", marginBottom: "16px" }}
          justify={type === "comment" ? "flex-start" : "flex-end"}
          alignItems="center"
        >
          <GridItem xs={6}>
            <GridContainer
              justify={type === "comment" ? "flex-start" : "flex-end"}
            >
              <GridItem
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: type === "comment" ? "flex-start" : "flex-end"
                }}
              >
                <div
                  style={{
                    color: type === "comment" ? "black" : "white",
                    padding: "15px 15px 1px 15px",
                    borderRadius: "5px",
                    minWidth: "45%",
                    backgroundColor: type === "comment" ? "#ecf0f1" : "#7faa82"
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      marginBottom: "1rem"
                    }}
                  >
                    {obj.name}
                  </p>
                  <p
                    style={{
                      margin: "0px"
                    }}
                  >
                    {obj.description}
                  </p>
                  <p style={{ fontSize: "11px", textAlign: "end" }}>
                    {moment(obj.created_at).fromNow()}
                  </p>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer
                  justify={type === "comment" ? "flex-start" : "flex-end"}
                >
                  {obj.status === "pending" ? (
                    <React.Fragment>
                      <Button
                        style={{
                          color: "#ecf0f1",
                          backgroundColor: "#e74c3c",
                          padding: "8px 16px",
                          marginRight: "6px",
                          fontSize: "11px",
                          textTransform: "none"
                        }}
                        onClick={e => {
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        Reject
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                      >
                        <Typography className={classes.typography}>
                          Are you sure?
                        </Typography>
                        <Button
                          onClick={() => {
                            handleApproveReject(obj.id, "rejected");
                            setAnchorEl(null);
                          }}
                        >
                          Yes
                        </Button>
                        <Button onClick={() => setAnchorEl(null)}>No</Button>
                      </Popover>

                      <Button
                        style={{
                          color: "#ecf0f1",
                          backgroundColor: "#2b8c30",
                          padding: "8px 16px",
                          fontSize: "11px",
                          textTransform: "none"
                        }}
                        onClick={() => handleApproveReject(obj.id, "approved")}
                      >
                        Approve
                      </Button>
                    </React.Fragment>
                  ) : null}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </TableRow>
    );
  };

  //popover related
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // ==================================================================================================
  // API CALLS  =======================================================================================
  // ==================================================================================================
  const handleApproveReject = async (id, type) => {
    try {
      setLoading(true);
      let response;
      if (type === "approved")
        response = await api.patch(`${api.endpoints.comments}/${id}`);
      else response = await api.delete(`${api.endpoints.comments}/${id}`);
      setLoading(false);
      setComment(response.data);
      setChanged(true);
      if (Object.keys(response.data).length === 0) handleModalClose({}, true);
      enqueueSnackbar(response.message, {
        variant: "success"
      });
    } catch (error) {
      //error_handling
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleSend = async () => {
    try {
      //check if the reply is not empty and also if the data after the pending response has been handled.
      const {
        source_id,
        source_type,
        feature_tab_id,
        id,
        status
      } = main_comment;
      if (pending_replies_count || status === "pending")
        throw new Error("Please Approve or Reject the pending comments first.");
      if (!reply) throw new Error("Please enter a reply to be sent.");
      setLoading(true);
      const response = await api.post(
        `${api.endpoints.comments}/${id}/replies`,
        {
          description: reply,
          type: source_type,
          source_id,
          feature_tab_id
        }
      );
      setLoading(false);
      setReply("");
      setComment(response.data);
      setChanged(true);
      enqueueSnackbar(response.message, {
        variant: "success"
      });
    } catch (error) {
      //error_handling
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleClose = () => {
    handleModalClose(comment, changed);
  };

  if (!comment.source_id) {
    return null;
  }

  return (
    <Card style={{ height: "90vh" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesModal.modal}
        open={openModal}
        onClose={handleClose}
        closeaftertransit000={"true"}
        BackdropComponent={Backdrop}
        disableBackdropClick
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <div style={{ width: "70%", height: "70vh" }}>
            <Card>
              <GridContainer
                justify="space-between"
                alignItems="center"
                style={{
                  width: "100%",
                  margin: "0px",
                  height: "100%"
                }}
              >
                <GridItem>
                  <p
                    style={{
                      margin: "0px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "400"
                    }}
                  >
                    {`${main_comment.source_full_name} [${main_comment.source_short_name}] - ${main_comment.page}`}
                  </p>
                </GridItem>

                <Button
                  color="transparent"
                  simple
                  onClick={handleClose}
                  className={classes.buttonLink}
                  style={{ padding: "0px !important" }}
                >
                  Close
                </Button>
              </GridContainer>
              <Divider light style={{ height: "2px" }} />
              <div style={{ overflow: "auto", maxHeight: "50vh" }}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    {tableRowComponent(main_comment, "comment")}

                    {replies
                      ? replies.map(item => tableRowComponent(item))
                      : null}
                  </TableBody>
                </Table>
              </div>
              <Divider light style={{ height: "2px" }} />
              <GridContainer
                justify="flex-end"
                style={{ marginBottom: "8px", marginTop: "8px" }}
                disabled
              >
                <GridItem style={{ flexGrow: "1" }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="reply"
                    label="Reply"
                    multiline
                    value={reply}
                    onChange={e => {
                      setReply(e.target.value);
                    }}
                    id="transition-modal-title"
                  />
                </GridItem>
                <GridItem>
                  <Button
                    disabled={reply.length === 0}
                    style={{
                      color: "#ecf0f1",
                      borderColor: "#2980b9",
                      backgroundColor: "#3498db",
                      border: "1px solid",
                      padding: "5px 40px",
                      fontSize: "20px",
                      textTransform: "none",
                      border: "none",
                      marginTop: "8px"
                    }}
                    onClick={handleSend}
                  >
                    Send
                  </Button>
                </GridItem>
              </GridContainer>
            </Card>
            <Modal
              className={classesModal.modal}
              open={loading}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={loading}>
                <CircularProgress />
              </Fade>
            </Modal>
          </div>
        </Fade>
      </Modal>
    </Card>
  );
};

export default CommentModal;
