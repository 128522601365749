import {
  MAIN_UI_SHOW_NOTIFICATION,
  MAIN_UI_HIDE_NOTIFICATION
} from "data/main/types";

const initialState = {
  showNotification: false,
  message: "",
  messageType: "info"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MAIN_UI_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        message: action.message || "Something Went Wrong",
        messageType: action.messageType
      };
    case MAIN_UI_HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
        message: "",
        messageType: "info"
      };
    default:
      return state;
  }
}
