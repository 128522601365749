import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import { Field } from "formik";
import CourseMultiSelector from "views/College/Basic/Stream/CourseMultiSelector";
import _ from "lodash";

class CourseSelectors extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.map(item => {
      const { id, short_name, full_name, duration } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`,
        short_name,
        full_name,
        duration
      };
    });
    return newValue;
  };

  getFilteredElementsData = elements => {
    const { setFieldValue, streamData, streamIndex } = this.props;
    const StreamArray = elements.filter(item => item.key === "streams")[0];
    const CourseArray = StreamArray.data.filter(
      streamItem => streamItem.id === streamData.id
    )[0].data[0].data;
    if (!_.isEmpty(CourseArray)) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <p style={{ fontWeight: "bold" }}>Courses</p>
            <Field
              component={CourseMultiSelector}
              name={`streams[${streamIndex}].courses`}
              data={this.arrayModified(CourseArray)}
              onChange={value => {
                setFieldValue(`streams[${streamIndex}].courses`, value);
              }}
              selectedValues={streamData.courses}
            />
            <br />
          </GridItem>
        </GridContainer>
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(CourseSelectors);
