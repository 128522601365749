import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { connect } from "react-redux";

import * as actions from "data/elements/actions";
import { LinearProgress } from "@material-ui/core";
import BasicMultiSelectors from "views/Components/BasicMultiSelectors";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class BranchDataModal extends React.Component {
  state = {
    modal: true,
    id: null,
    error: "",
    selected_branch_ids: [],
    branchesFiltered: [],
    selectedBranchId: null
  };

  handleBranchSelection = id => {
    this.setState({ selectedBranchId: id });
  };

  getBranches = branches => {
    if (branches) {
      return branches.data.map(item => ({
        id: item.id,
        value: item.full_name,
        label: `${item.full_name}(${item.short_name})`
      }));
    } else {
      return [];
    }
  };

  componentDidMount() {
    const { branchElement, branches } = this.props;
    const branches_full = this.getBranches(branches);
    if (branchElement.data && branchElement.data.length) {
      const selected_branch_ids = branchElement.data.map(item => item.id) || [];
      const branchesFiltered = branches_full.filter(
        item => !selected_branch_ids.includes(item.id)
      );
      this.setState({
        selected_branch_ids,
        branches: branches_full,
        branchesFiltered
      });
    } else {
      this.setState({
        branches: branches_full,
        branchesFiltered: branches_full
      });
    }
  }
  handleTextInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.hideBranchDataModal();
  };

  handleProgressBar = () => {
    const rowLoading = this.props.elements.appState.rowLoading;
    if (rowLoading) {
      return <LinearProgress />;
    }
    return null;
  };

  handleCreateElement = () => {
    const { streams, streamElement, stream, branchElement } = this.props;
    const { appState } = this.props.elements;
    const {
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex
    } = appState;

    const streamPath = streams.path;
    const streamId = stream.id;
    const coursePath = streamElement.path;
    const courseId = streamElement.data[courseIndex].id;
    const branchPath = branchElement.path;

    const data = {
      selected_branch_id: this.state.selectedBranchId
    };
    this.props.addBranchElement(
      streamPath,
      streamId,
      coursePath,
      courseId,
      branchPath,
      elementIndex,
      streamIndex,
      streamElementIndex,
      courseIndex,
      data
    );

    // const { short_name, full_name, id } = this.state;
    // if (full_name.length) {
    //   //directly call the api from here.
    //   const data = {
    //     short_name,
    //     full_name
    //   };
    //   if (this.props.elements.ui.isEditing) {
    //     data.id = id;
    //     this.props.updateBranchElement(
    //       streamPath,
    //       streamId,
    //       coursePath,
    //       courseId,
    //       branchPath,
    //       elementIndex,
    //       streamIndex,
    //       streamElementIndex,
    //       courseIndex,
    //       rowIndex,
    //       data
    //     );
    //   } else {
    //     this.props.addBranchElement(
    //       streamPath,
    //       streamId,
    //       coursePath,
    //       courseId,
    //       branchPath,
    //       elementIndex,
    //       streamIndex,
    //       streamElementIndex,
    //       courseIndex,
    //       data
    //     );
    //   }
    // } else {
    //   //handle the error case.
    //   this.setState({ error: "Full Name is Mandatory" });
    // }
  };

  handleError = () => {
    if (this.props.error) {
      return <p>{this.props.error}</p>;
    }
    return null;
  };

  render() {
    const { classes, elements, branchElement } = this.props;
    const { name } = branchElement;
    const { error, branchesFiltered } = this.state;
    const { showBranchModal } = elements.ui;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showBranchModal}
        transition={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => this.handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={() => this.handleClose()}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}</h4>
        </DialogTitle>
        <DialogContent
          style={{ width: "60vw" }}
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {error ? <pre>{error}</pre> : null}
          <div>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Branches</h6>
              </GridItem>
              <GridItem xs={12}>
                <BasicMultiSelectors
                  isMulti={false}
                  data={branchesFiltered}
                  onChange={this.handleBranchSelection}
                  selectedValues={this.state.selectedBranchId}
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <br />
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateElement} color="danger" simple>
            {this.props.elements.ui.isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {this.handleProgressBar()}
        {this.handleError()}
      </Dialog>
    );
  }
}
function mapStateToProps({ elements }) {
  const elementIndex = elements.appState.elementIndex;
  const streamIndex = elements.appState.streamIndex;
  const streamElementIndex = elements.appState.streamElementIndex;
  const courseIndex = elements.appState.courseIndex;
  const rowIndex = elements.appState.rowIndex;

  const streams = elements.data[elementIndex];
  const stream = streams.data[streamIndex];
  const streamElement = stream.data[streamElementIndex];
  const streamCell = streamElement.data;

  const course = streamCell[courseIndex];
  const branchElement = course.data;

  const branches = elements.data.filter(item => item.key === "branches")[0];

  return {
    elements,
    streams,
    stream,
    streamElement,
    rowIndex,
    course,
    branchElement,
    branches
  };
}
export default withStyles(modalStyle)(
  connect(mapStateToProps, actions)(BranchDataModal)
);
