import React, { useState, useEffect, Fragment } from "react";
import FAQItem from "./FAQItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FAQModal from "./FAQModal";
import _cloneDeep from "lodash/cloneDeep";

const FAQComponent = ({ faqs: frequentlyAskedQuestions = [], url }) => {
  const [faqs, setFaqs] = useState(frequentlyAskedQuestions);
  const [faq, setFaq] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleItemUpdation = index => {
    setFaq(faqs[index]);
    setShowModal(true);
  };

  const handleItemCreation = () => {
    setFaq({});
    setShowModal(true);
  };

  const handleFAQItemInsertUpdate = item => {
    if (!item || !item.id) {
      return;
    }
    //handle the updation here.
    let newFAQs = [];
    const index = faqs.findIndex(faq => faq.id === item.id);
    newFAQs = _cloneDeep(faqs);
    if (index > -1) {
      newFAQs[index] = item;
    } else {
      newFAQs = newFAQs.concat(item);
    }
    setFaqs(newFAQs);
  };

  const handleModalClose = item => {
    handleFAQItemInsertUpdate(item);
    setShowModal(false);
  };

  const buildFAQLayout = () => {
    if (!faqs.length) {
      //return the empty layout with add faq button.
      return (
        <Card style={{ height: "40vh" }}>
          <CardBody
            style={{
              backgroundColor: "#f5f7fa",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <h3>No FAQs Added Yet!!</h3>
            <br />
            <Button color="info" onClick={handleItemCreation}>
              Add First FAQ
            </Button>
          </CardBody>
        </Card>
      );
    }
    return (
      <div>
        <GridContainer align="flex-end" justify="flex-end">
          <GridItem>
            <Button color="primary" onClick={handleItemCreation}>
              Add FAQ
            </Button>
          </GridItem>
        </GridContainer>
        <br />
        {faqs.map((faq, index) => (
          <FAQItem
            key={faq.id}
            id={faq.id}
            que={faq.que}
            ans={faq.ans}
            queNo={index + 1}
            handleItemUpdation={() => handleItemUpdation(index)}
          />
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <FAQModal
        frequentlyAskedQuestion={faq}
        url={url}
        show={showModal}
        handleModalClose={handleModalClose}
      />
      {buildFAQLayout()}
      <br />
    </Fragment>
  );
};

export default FAQComponent;
