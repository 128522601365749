import React, { useState, useEffect } from "react";
import _ from "lodash";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import api from "data/utils/api";
import { debounce } from "throttle-debounce";

const AsyncCheckUniqueHooks = props => {
  const {
    apiEndpoint,
    filterKey,
    setInputValue,
    inputValue,
    id,
    maxlength,
    placeholder
  } = props;

  const [isShortNameUnique, setIsShortNameUnique] = useState(null);
  const [shortNameProgress, setShortNameProgress] = useState(null);
  const [tempInputValue, setTempInputValue] = useState(inputValue);

  useEffect(
    () => {
      if (props.inputValue !== tempInputValue) {
        handleShortNameUniqueness(props.inputValue);
      }
    },
    [props.id]
  );

  useEffect(() => {
    handleShortNameUniqueness(props.inputValue);
  }, []);

  const handleShortNameUniqueness = name => {
    setTempInputValue(name);
    if (!(name && name.length === 0)) {
      setShortNameProgress(false);
      setIsShortNameUnique(null);
    }
    debounce(500, autocompleteSearch(name));
  };

  const autocompleteSearch = async name => {
    if (name) {
      setShortNameProgress(true);
      setIsShortNameUnique(null);
      try {
        const res = await api.get(apiEndpoint, {
          [filterKey]: name,
          id
        });
        if (res.data.is_unique) {
          setInputValue(name, res.data.is_unique);
        } else setInputValue(name, false);

        setShortNameProgress(false);
        setIsShortNameUnique(res.data.is_unique);
      } catch (error) {
        setInputValue(name, false);
        setIsShortNameUnique(null);
        setShortNameProgress(false);
      }
    } else {
      setInputValue(name, false);
      setIsShortNameUnique(null);
      setShortNameProgress(false);
    }
  };

  const ClearInputValue = () => {
    setInputValue("", false);
    setShortNameProgress(null);
    setIsShortNameUnique(null);
    setTempInputValue("");
  };
  return (
    <React.Fragment>
      <BareTextFieldInputOnChange
        fullWidth
        // disabled={id ? true : false}
        maxlength={maxlength}
        placeholder={placeholder}
        onBlur={e => {
          handleShortNameUniqueness(e.target.value);
        }}
        value={tempInputValue || ""}
        progress={shortNameProgress}
        unique={props.inputValue === "" ? null : isShortNameUnique}
        error={isShortNameUnique || isShortNameUnique === null ? false : true}
        onClearInput={ClearInputValue}
      />
    </React.Fragment>
  );
};

export default AsyncCheckUniqueHooks;
