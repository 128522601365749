import React, { Component } from "react";
import StreamMultiSelector from "views/College/Basic/Stream/StreamMultiSelector";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import { Field } from "formik";

class StreamSelectors extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.map(item => {
      const { id, short_name, full_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`,
        short_name,
        full_name
      };
    });
    return newValue;
  };

  getFilteredElementsData = elements => {
    const { values, selectedStream, setFieldValue } = this.props;
    const streamArray = elements.filter(item => item.key === "streams")[0].data;
    if (streamArray.length > 0) {
      return (
        <GridContainer>
          <GridItem xs={6}>
            <p style={{ fontWeight: "bold" }}>Streams</p>
            <Field
              name="streams"
              component={StreamMultiSelector}
              data={this.arrayModified(streamArray)}
              onChange={value => {
                setFieldValue("streams", value);
              }}
              selectedValues={values.streams}
            />
            <br />
          </GridItem>
        </GridContainer>
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(StreamSelectors);
