// import { AbilityBuilder } from "@casl/ability";

// function subjectName(item) {
//   if (!item || typeof item === "string") {
//     return item;
//   }
//   return item.__type;
// }

// export default AbilityBuilder.define({ subjectName }, can => {
//   // can(["publish", "suggest"], ["PageDetail"], { role: "editor" });
//   // can(["publish", "suggest"], ["PageDetail"], { role: "admin" });
//   can(["publish", "suggest"], ["PageDetail"], { role: "super-admin" });
// });

/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from "@casl/ability";
import store from "data/store";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().user.data;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
});

function defineRulesFor(auth) {
  const { can, rules } = AbilityBuilder.extract();
  if (auth.role === "super_admin") {
    can("publish", "PageDetail");
    can("suggest", "PageDetail");
    can("change", "Elements");
    can("manage", "StudentsSection");
    can("create", "Form");
    can("create", "College");
  }
  if (auth.role === "admin") {
    can("publish", "PageDetail");
    can("suggest", "PageDetail");
    can("change", "Elements");
    can("create", "Form");
    can("create", "College");
  }
  if (auth.role === "editor") {
    can("publish", "PageDetail");
    can("suggest", "PageDetail");
  }
  return rules;
}

export default ability;
