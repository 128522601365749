import React, { Component } from "react";
import ContactDisplay from "views/College/Basic/ContactDetail/ContactDisplay";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import api from "data/utils/api";
import AttachmentDeleteAlert from "../Attachment/AttachmentDeleteAlert";
import { connect } from "react-redux";
import { showNotification } from "data/main/actions";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

class ContactInfoList extends Component {
  state = {
    contacts: [],
    showDeleteAlert: false,
    toBeDeletedContactId: null,
    isLoading: false
  };

  initialBareCell = () => ({
    full_name: "",
    department: "",
    emails: [],
    phones: []
  });

  handleInitialDataLoad = async () => {
    const { id } = this.props;
    try {
      const res = await api.get(`${api.endpoints.colleges}/${id}/contacts`);
      return this.setState({
        //contacts: res.data.length ? res.data : [this.initialBareCell()]
        contacts: res.data.length ? res.data : [this.initialBareCell()]
      });
    } catch (error) {
      //show the error if something went wrong here.
      //TODO: Layout should not be working.it should have a message showing something went wrong.
      // return this.setState({
      //   contacts: [this.initialBareCell()]
      // });
      // console.log(error);
    }
  };

  handleNewContact = () => {
    const itemWithoutId = this.state.contacts.filter(
      item => item.id === undefined
    );
    if (itemWithoutId.length) {
      //showNotification that he cannot add more than one element at a time without save.
      return this.props.showNotification(
        "Save the first contact before add a new one",
        false
      );
    }
    const data = this.initialBareCell();
    const newData = this.state.contacts.map(item => item);
    newData.unshift(data);
    this.setState({ contacts: newData });
  };

  // pushContactData = async data => {
  //   const newData = this.state.contacts.map(item => item);
  //   newData.push(data);
  //   this.setState({ contacts: newData, isLoading: false });
  // };

  // popContactData = async id => {
  //   const newData = this.state.contacts.filter(item => item.id !== id);
  //   this.setState({
  //     contacts: newData,
  //     isLoading: false,
  //     showDeleteAlert: false,
  //     toBeDeletedContactId: null
  //   });
  // };

  handleDeleteNonSyncedCell = async rowIndex => {
    const newData = this.state.contacts.filter(
      (item, index) => index !== rowIndex
    );
    this.setState({
      contacts: newData
    });
  };

  // updateContactData = async data => {
  //   //update the data into the contacts state
  //   const newData = this.state.contacts.map(item => {
  //     if (item.id === data.id) {
  //       return data;
  //     }
  //     return item;
  //   });
  //   this.setState({
  //     contacts: newData,
  //     isLoading: false
  //   });
  // };

  handleSubmit = async data => {
    if (data.id) {
      return this.handleUpdate(data);
    }
    return this.handleInsert(data);
  };

  handleInsert = async data => {
    this.setState({ isLoading: true });
    const { id } = this.props;
    try {
      const res = await api.post(`${api.endpoints.colleges}/${id}/contacts`, {
        data
      });
      this.handleResponse(res.data);
      this.props.showNotification("Contact Added Successfully");
    } catch (error) {
      this.setState({ isLoading: false, initialData: data });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleUpdate = async data => {
    this.setState({ isLoading: true });
    const { id } = this.props;
    try {
      const res = await api.put(
        `${api.endpoints.colleges}/${id}/contacts/${data.id}`,
        {
          data
        }
      );
      this.handleResponse(res.data);
      this.props.showNotification("Contact Updated Successfully");
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleDeleteOk = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props;
    try {
      const res = await api.delete(
        `${api.endpoints.colleges}/${id}/contacts/${
          this.state.toBeDeletedContactId
        }`
      );
      this.handleResponse(res.data);
      this.props.showNotification("Contact Deleted Successfully");
    } catch (error) {
      this.setState({
        isLoading: false,
        showDeleteAlert: false,
        toBeDeletedContactId: null
      });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleResponse = data => {
    this.setState({
      contacts: data,
      isLoading: false,
      showDeleteAlert: false,
      toBeDeletedContactId: null
    });
  };

  handleDelete = async (id, index) => {
    if (!id) {
      return this.handleDeleteNonSyncedCell(index);
    }
    this.setState({ showDeleteAlert: true, toBeDeletedContactId: id });
  };

  handlingContactData = () => {
    return this.state.contacts.map((item, index) => (
      <ContactDisplay
        {...item}
        key={index}
        index={index}
        onDelete={this.handleDelete}
        onSubmit={this.handleSubmit}
      />
    ));
  };

  componentDidMount() {
    this.handleInitialDataLoad();
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.isLoading}>
          <CircularProgress style={{ padding: "10px" }} />
        </Dialog>
        {this.state.showDeleteAlert ? (
          <AttachmentDeleteAlert
            handleSuccess={this.handleDeleteOk}
            handleClose={() => this.setState({ showDeleteAlert: false })}
          />
        ) : null}
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              title="Add New Contacts"
              color="success"
              size="lg"
              onClick={this.handleNewContact}>
              ADD Contact
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer>{this.handlingContactData()}</GridContainer>
      </div>
    );
  }
}

export default connect(
  null,
  { showNotification }
)(ContactInfoList);
