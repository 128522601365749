import {
  ELEMENTS_UI_SHOW_DATA_MODAL,
  ELEMENTS_UI_HIDE_DATA_MODAL,
  ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL,
  ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL,
  ELEMENTS_UI_SHOW_ALERT_BOX,
  ELEMENTS_UI_HIDE_ALERT_BOX,
  ELEMENTS_UI_SHOW_NOTIFICATION,
  ELEMENTS_UI_HIDE_NOTIFICATION,
  ELEMENTS_UI_SHOW_STREAM_DATA_MODAL,
  ELEMENTS_UI_HIDE_STREAM_DATA_MODAL,
  ELEMENTS_UI_SHOW_TAG_DATA_MODAL,
  ELEMENTS_UI_HIDE_TAG_DATA_MODAL,
  ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL,
  ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL
} from "data/elements/types";

const initialState = {
  showModal: false,
  showDeleteAlert: false,
  isEditing: false,
  showNotification: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ELEMENTS_UI_SHOW_DATA_MODAL:
      return { ...state, showModal: true, isEditing: action.isEditing };
    case ELEMENTS_UI_HIDE_DATA_MODAL:
      return { ...state, showModal: false, isEditing: false };
    case ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL:
      return { ...state, showFeatureModal: true, isEditing: action.isEditing };
    case ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL:
      return { ...state, showFeatureModal: false, isEditing: false };
    case ELEMENTS_UI_SHOW_STREAM_DATA_MODAL:
      return { ...state, showStreamModal: true, isEditing: action.isEditing };
    case ELEMENTS_UI_HIDE_STREAM_DATA_MODAL:
      return { ...state, showStreamModal: false, isEditing: action.isEditing };
    case ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL:
      return { ...state, showBranchModal: true, isEditing: action.isEditing };
    case ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL:
      return { ...state, showBranchModal: false, isEditing: action.isEditing };
    case ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL:
      return {
        ...state,
        showIndependentBranchModal: true,
        isEditing: action.isEditing
      };
    case ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL:
      return {
        ...state,
        showIndependentBranchModal: false,
        isEditing: action.isEditing
      };
    case ELEMENTS_UI_SHOW_TAG_DATA_MODAL:
      return { ...state, showTagModal: true, isEditing: action.isEditing };
    case ELEMENTS_UI_HIDE_TAG_DATA_MODAL:
      return { ...state, showTagModal: false, isEditing: false };
    case ELEMENTS_UI_SHOW_ALERT_BOX:
      return { ...state, showDeleteAlert: true };
    case ELEMENTS_UI_HIDE_ALERT_BOX:
      return { ...state, showDeleteAlert: false };
    case ELEMENTS_UI_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        message: action.message,
        messageType: action.messageType
      };
    case ELEMENTS_UI_HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
        message: "",
        messageType: "info"
      };
    default:
      return state;
  }
}
