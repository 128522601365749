import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import _cloneDeep from "lodash/cloneDeep";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Avatar
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
// core components

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import api from "data/utils/api";
import MultiCollegeFilters from "../../../layouts/NewsletterFilters";
import MultiCollegeModal from "./MultiCollegeModal.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

const useStyles = makeStyles(styles);

const avatarColors = [
  "#aa7f7f",
  "#7faa82",
  "#aa7fa9",
  "#aa7f7f",
  "#7faaa6",
  "#977faa",
  "#aa7f7f"
];

const MultiCollegeSection = () => {
  // ==================================================================================================
  // STATE AND VARIABLE DECLARATION ===================================================================
  // ==================================================================================================
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [rowSelectedFilter, setRowSelectedFilter] = useState({});
  const [selectedFilterQuery, setSelectedFilterQuery] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalIndex, setModalIndex] = useState(-1);

  const { enqueueSnackbar } = useSnackbar();

  // ==================================================================================================
  // USE EFFECT =======================================================================================
  // ==================================================================================================
  useEffect(() => {
    //make the api call here.
    if (!loading) {
      handleMultiCollegeListApiCall();
    }
    if (!filters.length) {
      handleFilterApiCall();
    }
  }, [page, rowsPerPage, selectedFilterQuery]);

  // ==================================================================================================
  // API CALLS  =======================================================================================
  // ==================================================================================================

  const handleMultiCollegeListApiCall = async () => {
    //make api call here.
    try {
      setLoading(true);
      const res = await api.get(api.endpoints.college_temp.root, {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...selectedFilterQuery
      });

      const { data, total_count } = res;
      setLoading(false);
      setTotalCount(total_count);
      setRows(data || []);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleFilterApiCall = async () => {
    if (filterLoading) return;
    try {
      setFilterLoading(true);
      const res = await api.get(api.endpoints.college_temp.filters);
      const { data } = res;
      setFilterLoading(false);
      setFilters(data || {});
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setFilterLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  // ==================================================================================================
  // CALLBACK FUNCTIONS ===============================================================================
  // ==================================================================================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = filters => {
    const filterQuery = {};
    filters.forEach(item => {
      if (filterQuery[item.key]) filterQuery[item.key].push(item.value);
      else filterQuery[item.key] = [item.value];
    });
    setPage(0);
    setSelectedFilterQuery(filterQuery);
  };

  // ==================================================================================================
  // LAYOUT RENDERING =================================================================================
  // ==================================================================================================
  const getMultiCollegeData = () =>
    rows.map((college, index) => {
      const {
        full_name,
        short_name = " ",
        state,
        city,
        institution_type
      } = college;

      return (
        <TableRow hover key={`comment-${index}`}>
          <TableCell component="th" scope="row">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{
                  marginRight: "8px",
                  backgroundColor: avatarColors[index % avatarColors.length]
                }}
              >
                {full_name ? full_name.charAt(0).toUpperCase() : null}
              </Avatar>
              <div>
                <p
                  style={{
                    margin: "0px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "12px"
                  }}
                >
                  {full_name ? full_name : null}{" "}
                </p>
                <p
                  style={{
                    margin: "0px",
                    fontSize: "11px",
                    color: "gray",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                >
                  {short_name}
                </p>
              </div>
            </div>
          </TableCell>
          <TableCell align="left">
            <p style={{ fontSize: "13px", margin: "0px" }}>
              {state ? (
                <Button
                  style={{
                    color: "#7faaa6",
                    backgroundColor: "white",
                    padding: "2px 12px",
                    fontSize: "12px",
                    textTransform: "none",
                    boxShadow: "none"
                  }}
                  onClick={() =>
                    setRowSelectedFilter({ key: "state", value: state })
                  }
                >
                  {state}
                </Button>
              ) : (
                <p
                  style={{
                    fontSize: "12px",
                    margin: "0px"
                  }}
                >
                  ---
                </p>
              )}
            </p>
          </TableCell>
          <TableCell align="left">
            {city ? (
              <Button
                style={{
                  color: "#977faa",
                  backgroundColor: "white",
                  padding: "2px 12px",
                  fontSize: "12px",
                  boxShadow: "none",
                  textTransform: "none"
                }}
                onClick={() =>
                  setRowSelectedFilter({ key: "city", value: city })
                }
              >
                {city}
              </Button>
            ) : (
              <p
                style={{
                  fontSize: "12px",
                  margin: "0px"
                }}
              >
                ---
              </p>
            )}
          </TableCell>
          <TableCell align="left" size="small">
            {institution_type ? (
              <Button
                style={{
                  color: "#7faa82",
                  backgroundColor: "white",
                  padding: "2px 12px",
                  fontSize: "12px",
                  boxShadow: "none",
                  textTransform: "none"
                }}
                onClick={() =>
                  setRowSelectedFilter({
                    key: "institution_type",
                    value: institution_type
                  })
                }
              >
                {institution_type}
              </Button>
            ) : (
              <p
                style={{
                  fontSize: "12px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  margin: "0px"
                }}
              >
                ---
              </p>
            )}
          </TableCell>
          <TableCell align="center">
            <Button
              style={{
                color: "#aa7f7f",
                borderColor: "#aa7f7f",
                backgroundColor: "transparent",
                border: "1px solid",
                padding: "4px 30px",
                fontSize: "12px",
                textTransform: "none"
              }}
              onClick={() => {
                setModalIndex(index);
              }}
            >
              Edit
            </Button>
          </TableCell>
        </TableRow>
      );
    });

  const getTableLayout = () => (
    <Card style={{ overflow: "scroll" }}>
      <Divider style={{ backgroundColor: "#7faa82" }} />
      <GridContainer
        justify="space-between"
        alignItems="center"
        style={{ width: "100%", margin: "0px", padding: "0.3rem 0px" }}
      >
        <div style={{ paddingLeft: "1rem" }}>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "500"
            }}
          >
            Multi College Edit{" --> "}
            <span
              style={{
                color: "#1E88E5",
                borderColor: "#1E88E5",
                border: "1px solid",
                padding: "2px 12px",
                fontSize: "12px"
              }}
            >
              {totalCount}
            </span>
          </h4>
        </div>
      </GridContainer>
      <Divider light />
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#fafafa" }}>
          <TableRow>
            <TableCell align="left" style={{ width: "30%" }}>
              College Name
            </TableCell>
            <TableCell align="left" style={{ width: "15%" }}>
              State
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              City
            </TableCell>
            <TableCell align="left" style={{ width: "15%" }}>
              Institute type
            </TableCell>
            <TableCell align="center" style={{ width: "20%" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getMultiCollegeData()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Card>
  );

  const handleModalClose = () => {
    setModalIndex(-1);
  };
  return (
    <React.Fragment>
      <MultiCollegeFilters
        filters={filters}
        handleFilterChange={handleFilterChange}
        rowSelectedFilter={rowSelectedFilter}
        showSearchBar
      />
      {getTableLayout()}
      <MultiCollegeModal
        openModal={modalIndex > -1}
        handleModalClose={handleModalClose}
      />
    </React.Fragment>
  );
};
export default MultiCollegeSection;
