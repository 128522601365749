import React, { Component } from "react";
import api from "data/utils/api";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";

class AsyncCreatableSelector extends Component {
  handleInputChange = value => {
    const { onSelectValue } = this.props;
    if (value) {
      let updatedValue = {
        full_name: value.value
      };
      onSelectValue(updatedValue);
    } else onSelectValue(value);
  };

  arrayModified = valuesItem => {
    const newValue = valuesItem.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      if (id)
        return {
          id,
          value: full_name,
          label: `${full_name}${updated_short_name}`,
          short_name,
          full_name
        };
      else
        return {
          value: full_name,
          label: full_name,
          full_name
        };
    });
    return newValue;
  };

  fetchData = async inputValue => {
    const { filterKey, apiEndpoint } = this.props;
    let newData = [];
    try {
      const res = await api.get(apiEndpoint, {
        [filterKey]: inputValue || ""
      });
      if (res) {
        newData = this.arrayModified(res.data);
      }
      return newData;
    } catch (error) {
      // console.log(error);
    }
  };

  handleValue = () => {
    const { values } = this.props;
    if (!_.isEmpty(values)) {
      return {
        value: values.full_name,
        label: values.full_name
      };
    } else return null;
  };

  render() {
    const { values } = this.props;
    return (
      <AsyncCreatableSelect
        cacheOptions
        isClearable
        defaultOptions
        loadOptions={this.fetchData}
        value={this.handleValue()}
        onChange={this.handleInputChange}
      />
    );
  }
}

export default AsyncCreatableSelector;
