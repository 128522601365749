import {
  COLLEGE_DETAIL_DATA_GET,
  COLLEGE_DETAIL_DATA_RESET
} from "data/colleges/types";

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = [], action) {
  switch (action.type) {
    case COLLEGE_DETAIL_DATA_GET:
      return action.data.data;
    case COLLEGE_DETAIL_DATA_RESET:
      return [];
    default:
      return state;
  }
}
