import React, { Component } from "react";
import AttachmentList from "views/College/Basic/Attachment/AttachmentList";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

class TabsAttachment extends Component {
  tabsData = () => {
    const { initialData, selectedTags, collegeData } = this.props;
    return selectedTags.map((item, index) => {
      return {
        tabButton: item.value,
        tabContent: (
          <AttachmentList
            attachmentData={
              initialData[index] ? initialData[index].attachments : []
            }
            selectedTag={item}
            collegeData={collegeData}
            key={item.id}
          />
        )
      };
    });
  };

  render() {
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12}>
          <NavPills
            color="rose"
            // title="Selected Tags:"
            // headerColor="rose"
            tabs={this.tabsData()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(formStyle)(TabsAttachment);
