import React, { Component } from "react";
import { connect } from "react-redux";
import HandlingCollegeSelector from "views/Components/College/HandlingCollegeSelector.jsx";
import _ from "lodash";

class SelectorBasedOnKey extends Component {
  getFilteredElementsData = elements => {
    const { keyFilter } = this.props;
    const questionArray = elements.filter(item => item.key === keyFilter)[0];
    if (questionArray) {
      return this.handleSelectorValue(questionArray);
    }
  };

  handleSelectorValue = selectorArray => {
    const {
      setFieldValue,
      valuesData,
      nameKey,
      isMulti,
      label,
      isRequired
    } = this.props;
    if (_.isEmpty(selectorArray.data)) {
      return null;
    } else {
      return (
        <HandlingCollegeSelector
          elementValues={selectorArray}
          setFieldValue={setFieldValue}
          valuesData={valuesData}
          nameKey={nameKey}
          isMulti={isMulti}
          label={label}
          isRequired={isRequired}
        />
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(SelectorBasedOnKey);
