import {
  FORMS_UI_SHOW_ALERT_BOX,
  FORMS_UI_HIDE_ALERT_BOX
} from "data/forms/types";

// =================================================================================================
// SHOW DELETE BOX =================================================================================
// =================================================================================================
export const showDeleteBox = (elementIndex, rowIndex) => async dispatch => {
  // dispatch({
  //   type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
  //   elementIndex
  // });
  // dispatch({
  //   type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
  //   rowIndex
  // });
  dispatch({ type: FORMS_UI_SHOW_ALERT_BOX });
};

// =================================================================================================
// HIDE DELETE BOX =================================================================================
// =================================================================================================
export const hideDeleteBox = () => async dispatch => {
  dispatch({ type: FORMS_UI_HIDE_ALERT_BOX });
};
