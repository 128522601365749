import {
  FORM_DETAIL_APPSTATE_LOADING,
  FORM_DETAIL_APPSTATE_SUCCESS,
  FORM_DETAIL_APPSTATE_ERROR
} from "data/forms/types";

const initialState = { loading: true, error: null, navigateBack: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FORM_DETAIL_APPSTATE_LOADING:
      return { ...state, loading: true, navigateBack: false };
    case FORM_DETAIL_APPSTATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        navigateBack: action.navigateBack || false
      };
    case FORM_DETAIL_APPSTATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        navigateBack: false
      };
    default:
      return state;
  }
}
