import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import * as actions from "data/auth/actions";
import * as routesPath from "routes/routesPath";
import { connect } from "react-redux";

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      emailState: ""
    };
    this.signUpClick = this.signUpClick.bind(this);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success"
          });
        } else {
          this.setState({
            [stateName + "State"]: "error"
          });
        }
        break;
      default:
        break;
    }

    switch (type) {
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  signUpClick(e) {
    e.preventDefault();
    if (this.state.emailState === "") {
      this.setState({ emailState: "error" });
    }
    if (this.state.emailState === "success") {
      this.props.signUpUser(this.state.email);
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 500 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      500
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleLoader() {
    if (this.props.appStateSignup.loading) {
      return <CustomLinearProgress color="rose" />;
    }
  }

  render() {
    const { classes, appStateSignup } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.signUpClick}>
              <Card
                login
                background={appStateSignup.loading ? true : false}
                className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose">
                  <h4 className={classes.cardTitle}>Sign Up</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.emailState === "success"}
                    error={this.state.emailState === "error"}
                    labelText="Email"
                    id="signupemail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: event => this.change(event, "email", "email"),
                      type: "email"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" type="submit">
                    Sign Up Now
                  </Button>
                </CardFooter>
                <Link to={routesPath.LOGIN_PAGE}>
                  <Button color="rose" simple block>
                    Already Signed Up ? Click here to Login In
                  </Button>
                </Link>
              </Card>
            </form>
            {this.handleLoader()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SignupPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps({ auth }) {
  return { appStateSignup: auth.appStateSignup };
}

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    actions
  )(SignupPage)
);
