import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
import _ from "lodash";

import MultipleColumnInputTable from "views/Forms/EazyForms/FormBasic/MultipleColumnInputTable.jsx";

class ReservationCreteria extends Component {
  reservationCreteria = () => {
    const { values, setFieldValue } = this.props;
    const defaultValue = values.form_basic.reservation_criteria;
    const tableHead = defaultValue[0].data.map(item => item.display_name);
    tableHead.unshift("Category");
    const updatedValue = [{ reservation_criteria: defaultValue }];
    return updatedValue.map((data, index) => {
      return (
        <GridItem key={index} xs={12}>
          <FastField
            name="form_basic.reservation_criteria"
            component={MultipleColumnInputTable}
            defaultValue={data.reservation_criteria}
            setFieldValue={setFieldValue}
            index={index}
            headerName="Reservation Creteria"
            tableHead={tableHead}
            sectionKey={`form_basic.reservation_criteria`}
          />
        </GridItem>
      );
    });
  };
  render() {
    const { values } = this.props;
    return (
      <React.Fragment>
        {!_.isEmpty(values.form_basic.reservation_criteria) ? (
          <GridContainer>{this.reservationCreteria()}</GridContainer>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReservationCreteria;
