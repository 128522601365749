import React, { Component } from "react";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import ImageUploadDetails from "./ImageUploadDetails";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ImageUploadDialog extends Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, selectedImage, displayURL } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.handleClose}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={this.handleClose}>
            <Close className={classes.modalClose} />
          </Button>
          {/* <h4 className={classes.modalTitle}>Image</h4> */}
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
          style={{
            width: "65vw",
            paddingTop: "0px"
          }}>
          <ImageUploadDetails
            onClose={this.handleClose}
            selectedImage={selectedImage}
            onSubmit={this.props.onSubmit}
            displayURL={displayURL}
          />
          {this.props.children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(tableFormStyles)(ImageUploadDialog);
