//all the types of the AUTH should be added here.
export const AUTH_APPSTATE_SIGNUP_LOADING = "AUTH_APPSTATE_SIGNUP_LOADING";
export const AUTH_APPSTATE_SIGNUP_SUCCESS = "AUTH_APPSTATE_SIGNUP_SUCCESS";
export const AUTH_APPSTATE_SIGNUP_ERROR = "AUTH_APPSTATE_SIGNUP_ERROR";

export const AUTH_APPSTATE_LOGIN_LOADING = "AUTH_APPSTATE_LOGIN_LOADING";
export const AUTH_APPSTATE_LOGIN_SUCCESS = "AUTH_APPSTATE_LOGIN_SUCCESS";
export const AUTH_APPSTATE_LOGIN_ERROR = "AUTH_APPSTATE_LOGIN_ERROR";

export const AUTH_APPSTATE_RESET_LOADING = "AUTH_APPSTATE_RESET_LOADING";
export const AUTH_APPSTATE_RESET_SUCCESS = "AUTH_APPSTATE_RESET_SUCCESS";
export const AUTH_APPSTATE_RESET_ERROR = "AUTH_APPSTATE_RESET_ERROR";

export const AUTH_APPSTATE_FORGOT_LOADING = "AUTH_APPSTATE_FORGOT_LOADING";
export const AUTH_APPSTATE_FORGOT_SUCCESS = "AUTH_APPSTATE_FORGOT_SUCCESS";
export const AUTH_APPSTATE_FORGOT_ERROR = "AUTH_APPSTATE_FORGOT_ERROR";
