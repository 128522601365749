import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class FormikRemoveButton extends Component {
  state = {
    alert: null,
    show: false
  };

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-150px", marginLeft: "-40px" }}
          title="Are you sure?"
          onConfirm={() => {
            this.props.onClickRemove();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel>
          <p>You really want to delete this Additional Editor !!! </p>
          <span style={{ color: "red" }}>
            <strong>Note !! </strong>
          </span>
          <span>
            In order to reflect this changes on server ,You need to Submit the
            Form !!
          </span>
        </SweetAlert>
      )
    });
  };

  // successDelete = () => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         success
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Deleted!"
  //         onConfirm={() => {
  //           this.hideAlert();
  //         }}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnCssClass={
  //           this.props.classes.button + " " + this.props.classes.success
  //         }>
  //         Your imaginary file has been deleted.
  //       </SweetAlert>
  //     )
  //   });
  // };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  render() {
    const { title, classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip
          title="Remove Editor"
          placement="top"
          classes={{
            tooltip: classes.tooltip
          }}>
          <Button
            justIcon
            color="youtube"
            title={title ? title : ""}
            // type="button"
            onClick={this.warningWithConfirmMessage}>
            {/* <i className={" fas fa-times-circle"} /> */}
            <i className="far fa-trash-alt" />
          </Button>
        </Tooltip>
        {this.state.alert}
      </React.Fragment>
    );
  }
}

export default withStyles(sweetAlertStyle)(FormikRemoveButton);
