import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import AsyncMultiSelector from "views/Components/College/AsyncMultiSelector.jsx";
import AsyncCreatableSelector from "views/Components/College/AsyncCreatableSelector.jsx";
import { FastField } from "formik";
import api from "data/utils/api";
import SelectorBasedOnKey from "views/Components/College/SelectorBasedOnKey.jsx";

class AllCollegeSelectors extends Component {
  render() {
    const { classes, setFieldValue, values } = this.props;
    return (
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Selectors</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <br />
              <FastField
                component={SelectorBasedOnKey}
                valuesData={values.accreditations}
                keyFilter="accrediting_bodies"
                isMulti={true}
                nameKey="accreditations"
                setFieldValue={setFieldValue}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <br />
              <FastField
                component={SelectorBasedOnKey}
                valuesData={values.regulations}
                keyFilter="regulatory_bodies"
                isMulti={true}
                nameKey="regulations"
                setFieldValue={setFieldValue}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <br />
              <FastField
                component={SelectorBasedOnKey}
                valuesData={values.students_gender}
                keyFilter="genders"
                isMulti={true}
                nameKey="students_gender"
                label="Students Gender"
                setFieldValue={setFieldValue}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <br />
              <FastField
                component={SelectorBasedOnKey}
                valuesData={values.hostels_for}
                keyFilter="genders"
                isMulti={true}
                nameKey="hostels_for"
                label="Hostels For"
                setFieldValue={setFieldValue}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <br />
              <p style={{ fontWeight: "bold" }}>Trust</p>
              <FastField
                component={AsyncCreatableSelector}
                values={values.trust}
                filterKey="trust"
                apiEndpoint={api.endpoints.college.filters}
                onSelectValue={value => {
                  setFieldValue("trust", value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <br />
              <p style={{ fontWeight: "bold" }}>Parent Body</p>
              <FastField
                component={AsyncMultiSelector}
                selectedValues={values.parent_body}
                filterKey="parent_body"
                name="parent_body"
                isMulti={false}
                apiEndpoint={api.endpoints.college.filters}
                onChange={value => {
                  setFieldValue("parent_body", value);
                }}
              />
            </GridItem>
          </GridContainer>
          <br />
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(tableFormStyles)(AllCollegeSelectors);
