import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";

import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import Rating from "@material-ui/lab/Rating";
import Button from "components/CustomButtons/Button.jsx";
import moment from "moment";

import { useSnackbar } from "notistack";

// import InfoOutline from "@material-ui/icons/InfoOutline";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import CollegePagination from "views/College/CollegeListing/CollegePagination.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import LoadingOverlay from "react-loading-overlay";

import api from "data/utils/api";

const status = {
  pending: {
    title: "Pending",
    key: "pending",
    count_key: "pending_count",
    button_text: "Review This"
  },
  approved: {
    title: "Approved",
    key: "approved",
    count_key: "approved_count",
    button_text: "View"
  },
  rejected: {
    title: "Rejected",
    key: "rejected",
    count_key: "rejected_count",
    button_text: "View"
  }
};

const userInfo = {
  name: {
    key: "name",
    value: "Name",
    type: "text"
  },
  college: {
    key: "college",
    value: "College",
    type: "text"
  },
  education_level: {
    key: "education_level",
    value: "Education Level",
    type: "text"
  },
  course: {
    key: "course",
    value: "Course",
    type: "text"
  },
  branch: {
    key: "branch",
    value: "Branch",
    type: "text"
  },
  social_media_profile_link: {
    key: "social_media_profile_link",
    value: "Social Media Profile Link",
    type: "url"
  },
  current_status: {
    key: "current_status",
    value: "Current Status",
    type: "text",
    converted_values: {
      ongoing: "Ongoing",
      passed_out: "Passed Out"
    }
  },
  current_year: {
    key: "current_year",
    value: "Current Year",
    type: "text"
  },
  batch: {
    key: "batch",
    value: "Batch",
    type: "text"
  }
};

const ratingObject = {
  admission_process_rating: "Admission Process Rating",
  placement_rating: "Placement Rating",
  alumni_senior_rating: "Alumni Senior Rating",
  curriculum_exam_rating: "Curriculum Rating",
  faculty_rating: "Faculty Rating",
  campus_life_rating: "Campus Life Rating"
};

const userReviews = [
  {
    que: "Review Title",
    ansKey: "title"
  },
  {
    que:
      "What was the procedure to get admission in this college? Describe the process and dates of your joining.",
    ansKey: "admission_process_experience"
  },
  {
    que:
      "How active is the placement cell of your college? Did you avail Internships in between the session? If yes, mention the details below",
    ansKey: "placement_experience"
  },
  {
    que:
      "Conclusion: How would you rate your college in terms of quality in Academics, Faculty, Infrastructure, Facilities, Extra-curricular events etc? Is it Bad/ Good / Moderate.",
    ansKey: "conclusion"
  }
];

// const useStyles = makeStyles(theme => ({
//   appBar: {
//     position: "relative"
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1
//   }
// }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CollegeReviewSection = props => {
  const { classes } = props;

  const [loading, setLoading] = useState(false);
  const [reviewUpdateLoading, setReviewUpdateLoading] = useState(false);
  const [reviewType, setReviewType] = useState(status.pending);
  const [currentPage, setCurrentPage] = useState(1);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showRejectedReasonModal, setShowRejectedReasonModal] = useState(false);
  const [reviewsCount, setReviewsCount] = useState({
    pending_count: 0,
    approved_count: 0,
    rejected_count: 0
  });
  const [reviews, setReviews] = useState([]);
  const [reviewItemPosition, setReviewItemPosition] = useState();
  const [reviewModified, setReviewModified] = useState({});
  const pageLimit = 20;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    //make the api call here.
    getReviewData();
  }, [reviewType, currentPage, showReviewModal]);

  const totalCount = reviewsCount[reviewType.count_key] || 0;

  const getReviewData = async () => {
    //make api call here.
    try {
      setLoading(true);
      const res = await api.get(`${api.endpoints.reviews}`, {
        status: reviewType.key,
        offset: (currentPage - 1) * pageLimit,
        limit: pageLimit
      });
      const { reviews = [], ...count } = res.data;
      setLoading(false);
      setReviewsCount(count);
      setReviews(reviews || []);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleValidation = () => {
    if (status === "rejected" && !reviewModified.rejected_reason) {
      throw new Error("Message is required for Rejected Review");
    }
    return Object.keys(reviewModified).forEach(item => {
      if (typeof reviewModified[item] === "string" && !reviewModified[item]) {
        throw new Error(`${item} field cannot be empty`);
      }
    });
  };

  const updateReviewStatus = async status => {
    try {
      handleValidation();
      setReviewUpdateLoading(true);
      const res = await api.patch(
        `${api.endpoints.reviews}/${reviews[reviewItemPosition].review.id}`,
        {
          status,
          ...reviewModified
        }
      );
      const { message } = res;
      enqueueSnackbar(message, {
        variant: "success"
      });
      setReviewModified({});
      setReviewUpdateLoading(false);
      setShowReviewModal(false);
    } catch (error) {
      const { message } = error || {};
      setReviewUpdateLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const getReactTableData = () => {
    return reviews.map((review, index) => ({
      row_count: (currentPage - 1) * pageLimit + index + 1,
      college: review.user_data.college,
      name: review.user_data.name,
      created_at: moment(review.review.created_at).fromNow(),
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            title="Review it now"
            color="success"
            onClick={() => {
              setReviewItemPosition(index);
              setShowReviewModal(true);
            }}
            color="info"
          >
            {reviewType.button_text}
          </Button>
        </div>
      )
    }));
  };

  const getCountLayout = () => (
    <GridContainer>
      <GridItem xs={12} sm={4} md={4} lg={4}>
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <Icon>content_copy</Icon>
            </CardIcon>
            <p
              className={classes.cardCategory}
              style={{ fontSize: "18px", color: "#666" }}
            >
              Total Pending Reviews
            </p>
            <h3 className={classes.cardTitle}>{reviewsCount.pending_count}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Button
                simple
                size="sm"
                color="primary"
                onClick={e => setReviewType(status.pending)}
              >
                View All Pending Reviews
              </Button>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={4} md={4} lg={4}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <Store />
            </CardIcon>
            <p
              className={classes.cardCategory}
              style={{ fontSize: "18px", color: "#666" }}
            >
              Total Approved Reviews
            </p>
            <h3 className={classes.cardTitle}>{reviewsCount.approved_count}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Button
                simple
                size="sm"
                color="primary"
                onClick={e => setReviewType(status.approved)}
              >
                View All Approved Reviews
              </Button>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={4} md={4} lg={4}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <Icon>info_outline</Icon>
            </CardIcon>
            <p
              className={classes.cardCategory}
              style={{ fontSize: "18px", color: "#666" }}
            >
              Total Rejected Reviews
            </p>
            <h3 className={classes.cardTitle}>{reviewsCount.rejected_count}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Button
                simple
                size="sm"
                color="primary"
                onClick={e => setReviewType(status.rejected)}
              >
                View All Rejected Reviews
              </Button>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const getTableLayout = () => (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <GridItem xs={12}>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} style={{ color: "white" }}>
                  {reviewType.title} Reviews [{`${totalCount}`}]
                </h4>
              </CardText>
            </CardHeader>
          </GridItem>

          <GridItem>
            <CardBody>
              <ReactTable
                data={getReactTableData()}
                pages={
                  Math.ceil(totalCount / pageLimit)
                    ? Math.ceil(totalCount / pageLimit)
                    : 1
                }
                manual
                currentPage={currentPage}
                loading={loading}
                defaultPageSize={pageLimit}
                className="-striped -highlight"
                columns={[
                  {
                    Header: "#",
                    accessor: "row_count",
                    sortable: false,
                    filterable: false,
                    minWidth: 20
                  },
                  {
                    Header: "College Name",
                    accessor: "college",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "User Name",
                    accessor: "name",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "Created",
                    accessor: "created_at",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
              />
            </CardBody>
          </GridItem>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const getReviewModalUserData = () => {
    const reviewItem =
      reviews[reviewItemPosition] && reviews[reviewItemPosition].user_data
        ? reviews[reviewItemPosition].user_data
        : {};
    return Object.keys(reviewItem).map(key => (
      <GridItem xs={12} sm={12} md={6} key={`review-user-${key}`}>
        <p
          style={{ fontWeight: "bold", marginBottom: "8px", fontSize: "16px" }}
        >
          {userInfo[key].value}
        </p>
        {userInfo[key].type === "url" ? (
          <a
            style={{ marginBottom: "16px" }}
            target="_blank"
            href={reviewItem[key]}
          >
            {reviewItem[key]}
          </a>
        ) : (
          <p style={{ marginBottom: "16px", fontSize: "18px" }}>
            {userInfo[key].converted_values
              ? userInfo[key].converted_values[reviewItem[key]]
              : reviewItem[key]}
          </p>
        )}
      </GridItem>
    ));
  };

  const getReviewModalRatingData = () => {
    const reviewItem =
      reviews[reviewItemPosition] && reviews[reviewItemPosition].review
        ? reviews[reviewItemPosition].review
        : {};
    return Object.keys(ratingObject).map(key => (
      <GridItem xs={12} sm={12} md={6} key={`review-rating-${key}`}>
        <p
          style={{ fontWeight: "bold", marginBottom: "8px", fontSize: "16px" }}
        >
          {ratingObject[key]}
        </p>
        <Rating
          style={{ marginBottom: "16px" }}
          name="read-only"
          value={reviewItem[key]}
          readOnly
        />
      </GridItem>
    ));
  };

  const onReviewChange = e => {
    setReviewModified({
      ...reviewModified,
      [e.target.name]: e.target.value.trim()
    });
  };

  const getReviewModalReviewData = () => {
    const reviewItem =
      reviews[reviewItemPosition] && reviews[reviewItemPosition].review
        ? reviews[reviewItemPosition].review
        : {};
    return userReviews.map((userReview, index) => (
      <GridItem xs={12} key={`review-data-${index}`}>
        <p
          style={{ fontWeight: "bold", marginBottom: "12px", fontSize: "16px" }}
        >
          Que {index + 1}. {userReview.que}
        </p>
        <TextField
          required
          fullWidth
          rowsMax={4}
          variant="outlined"
          type="text"
          name={userReview.ansKey}
          onChange={onReviewChange}
          defaultValue={reviewItem[userReview.ansKey]}
          style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}
        />
      </GridItem>
    ));
  };

  const getReviewModalLayout = (name, functionCall) => (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <GridItem xs={12}>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} style={{ color: "white" }}>
                  {name}
                </h4>
              </CardText>
            </CardHeader>
          </GridItem>

          <GridItem>
            <CardBody>
              <GridContainer>{functionCall}</GridContainer>
            </CardBody>
          </GridItem>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const submitReviewModalLayout = () => (
    <GridContainer justify="flex-end">
      <GridItem>
        <Button color="danger" onClick={() => setShowRejectedReasonModal(true)}>
          Reject
        </Button>
      </GridItem>
      <GridItem>
        <Button color="success" onClick={() => updateReviewStatus("approved")}>
          Approve
        </Button>
      </GridItem>
    </GridContainer>
  );

  const rejectedReviewModalLayout = () => (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      fullWidth
      transition={Transition}
      open={showRejectedReasonModal}
      onClose={() => setShowRejectedReasonModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Reject Reason</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          id="name"
          label="Reason"
          type="text"
          name="rejected_reason"
          onChange={onReviewChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          simple
          onClick={() => setShowRejectedReasonModal(false)}
          color="rose"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (reviewModified.rejected_reason) {
              setShowRejectedReasonModal(false);
              updateReviewStatus("rejected");
            }
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getReviewModal = () => (
    <Dialog
      fullScreen
      open={showReviewModal}
      onClose={() => setShowReviewModal(false)}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: "relative", background: "#00acc1" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setShowReviewModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            style={{ flex: 1, marginLeft: "16px", color: "white" }}
          >
            Review Modal
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ background: "#eee", padding: "8px" }}>
        <LoadingOverlay
          active={reviewUpdateLoading}
          spinner
          text="Submitting College Data"
        >
          {getReviewModalLayout("User Information", getReviewModalUserData())}
          {getReviewModalLayout("Rating", getReviewModalRatingData())}
          {getReviewModalLayout("Review", getReviewModalReviewData())}
          {reviewType === status.pending ? (
            <React.Fragment>
              {submitReviewModalLayout()}
              {rejectedReviewModalLayout()}
            </React.Fragment>
          ) : null}
        </LoadingOverlay>
      </div>
    </Dialog>
  );

  return (
    <React.Fragment>
      {getReviewModal()}
      {getCountLayout()}
      {getTableLayout()}
    </React.Fragment>
  );
};

export default withStyles(dashboardStyle)(CollegeReviewSection);
