export const UNIVERSITIES_APPSTATE_ALL_LOADING =
  "UNIVERSITIES_APPSTATE_ALL_LOADING";
export const UNIVERSITIES_DATA_ALL_GET = "UNIVERSITIES_DATA_ALL_GET";
export const UNIVERSITIES_DATA_TOTAL_COUNT = "UNIVERSITIES_DATA_TOTAL_COUNT";
export const UNIVERSITIES_DATA_CONTENT_COUNT =
  "UNIVERSITIES_DATA_CONTENT_COUNT";
export const UNIVERSITIES_DATA_PAGE_START = "UNIVERSITIES_DATA_PAGE_START";
export const UNIVERSITIES_APPSTATE_ALL_SUCCESS =
  "UNIVERSITIES_APPSTATE_ALL_SUCCESS";
export const UNIVERSITIES_APPSTATE_ALL_ERROR =
  "UNIVERSITIES_APPSTATE_ALL_ERROR";
export const UNIVERSITIES_DATA_CURRENT_PAGE = "UNIVERSITIES_DATA_CURRENT_PAGE";
