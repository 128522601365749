// @material-ui/core components
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import HandlingSelectorsValues from "views/Forms/EazyForms/FormBasic/HandlingSelectorsValues.jsx";
import _ from "lodash";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import MultipleColumnInputTable from "views/Forms/EazyForms/FormBasic/MultipleColumnInputTable.jsx";
class QualificationEligibilty extends Component {
  getFormSelectors = elements => {
    var formArray = [];
    elements.forEach(element => {
      switch (element.key) {
        case "education_levels": {
          const data = _.cloneDeep(element);
          formArray.push(data);
          break;
        }
        default:
          break;
      }
    });
    return formArray;
  };

  handleFormValuesRedux = () => {
    const { setFieldValue, values } = this.props;
    const myForm = this.getFormSelectors(this.props.elements.data);
    return myForm.map((item, index) => {
      // if (!item) {
      //   return;
      // }
      function getCell(itemType) {
        switch (itemType) {
          case "short_full":
            return (
              <GridItem xs={12} md={6} key={index}>
                <HandlingSelectorsValues
                  valuesItem={item}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </GridItem>
            );
          default:
            return null;
        }
      }

      return getCell(item.type);
    });
  };
  dataformation = () => {
    const { values, elements } = this.props;
    const newElements = elements.data;
    const educationLevels = values.form_basic.education_levels;

    const educationLevelsData = newElements
      .filter(item => item.key === "education_levels")[0]
      .data.filter(item => educationLevels.includes(item.id));

    const updatedEducationLevelDetails = educationLevelsData.map(item => {
      const { id, full_name, short_name } = item;
      const newValue = values.form_basic.education_level_details.filter(
        newItem => newItem.id === item.id
      );
      if (newValue.length > 0) {
        const data = newElements
          .filter(item => item.key === "reservation_categories")[0]
          .data.map(resvItem => {
            const { id, full_name, short_name } = resvItem;
            const availableReservCat = newValue[0].data.filter(
              item => item.id === resvItem.id
            );
            if (availableReservCat.length > 0) {
              return availableReservCat[0];
            } else
              return {
                id,
                full_name,
                short_name,
                data: [
                  {
                    id: 1,
                    name: "year_of_passing",
                    display_name: "Year Of Passing",
                    type: "number",
                    input_value: ""
                  },
                  {
                    id: 2,
                    name: "marks_percentage",
                    display_name: "Marks Percentage",
                    type: "number",
                    input_value: ""
                  },
                  {
                    id: 3,
                    name: "marks_grade",
                    display_name: "Marks Grade",
                    input_value: ""
                  }
                ]
              };
          });
        return { id, short_name, full_name, data };
      } else {
        const resvCheck = newElements
          .filter(item => item.key === "reservation_categories")[0]
          .data.map(item => {
            const { id, short_name, full_name } = item;
            return {
              id,
              short_name,
              full_name,
              data: [
                {
                  id: 1,
                  name: "year_of_passing",
                  display_name: "Year Of Passing",
                  type: "number",
                  input_value: ""
                },
                {
                  id: 2,
                  name: "marks_percentage",
                  display_name: "Marks Percentage",
                  type: "number",
                  input_value: ""
                },
                {
                  id: 3,
                  name: "marks_grade",
                  display_name: "Marks Grade",
                  input_value: ""
                }
              ]
            };
          });
        if (resvCheck.length > 0) {
          return {
            id,
            full_name,
            short_name,
            data: resvCheck
          };
        } else
          return {
            id,
            full_name,
            short_name,
            data: []
          };
      }
    });
    values.form_basic.education_level_details = updatedEducationLevelDetails;
  };

  qualificationsValuesFormik = () => {
    this.dataformation();
    const { values, setFieldValue } = this.props;
    const defaultValue = values.form_basic.education_level_details;
    if (!_.isEmpty(values.form_basic.education_level_details)) {
      if (!_.isEmpty(values.form_basic.education_level_details[0].data)) {
        const tableHead = defaultValue[0].data[0].data.map(
          item => item.display_name
        );
        tableHead.unshift("Category");
        return defaultValue.map((data, index) => {
          return (
            <GridItem key={index} xs={12}>
              <FastField
                component={MultipleColumnInputTable}
                defaultValue={data.data}
                setFieldValue={setFieldValue}
                index={index}
                headerName={data.full_name}
                tableHead={tableHead}
                sectionKey={`form_basic.education_level_details[${index}].data`}
                name="form_basic.education_level_details"
              />
            </GridItem>
          );
        });
      }
    }
  };

  render() {
    const { values, classes } = this.props;
    return (
      <React.Fragment>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4
                className={classes.cardTitle}>{`Qualification Eligibility`}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>{this.handleFormValuesRedux()}</GridContainer>
          </CardBody>
        </Card>
        {!_.isEmpty(values.form_basic.education_levels) ? (
          <GridContainer>{this.qualificationsValuesFormik()}</GridContainer>
        ) : null}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default withStyles(tableFormStyles)(
  connect(mapStateToProps)(QualificationEligibilty)
);
