import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Divider,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Grow,
  Popper,
  Avatar
} from "@material-ui/core";

// core components
import Button from "components/CustomButtons/Button.jsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import api from "data/utils/api";
import NewsletterFilter from "./NewsletterFilters";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

const useStyles = makeStyles(styles);

const avatarColors = [
  "#aa7f7f",
  "#7faa82",
  "#aa7fa9",
  "#aa7f7f",
  "#7faaa6",
  "#977faa",
  "#aa7f7f"
];

const NewsletterSection = props => {
  // ==================================================================================================
  // STATE AND VARIABLE DECLARATION ===================================================================
  // ==================================================================================================
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [rowSelectedFilter, setRowSelectedFilter] = useState({});
  const [selectedFilterQuery, setSelectedFilterQuery] = useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);

  // ==================================================================================================
  // USE EFFECT =======================================================================================
  // ==================================================================================================
  useEffect(() => {
    //make the api call here.
    if (!loading) {
      handleNewsletterApiCall();
    }
    if (!filters.length) {
      handleFilterApiCall();
    }
  }, [page, rowsPerPage, selectedFilterQuery]);

  // ==================================================================================================
  // API CALLS  =======================================================================================
  // ==================================================================================================

  const handleNewsletterApiCall = async () => {
    //make api call here.
    try {
      setLoading(true);
      const res = await api.get(`${api.endpoints.newsletter_subscribers}`, {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...selectedFilterQuery
      });
      const { data, total_count } = res;

      setLoading(false);
      setTotalCount(total_count);
      setRows(data || []);
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleFilterApiCall = async () => {
    //make api call here.
    try {
      setFilterLoading(true);
      const res = await api.get(
        `${api.endpoints.newsletter_subscribers}/filters`
      );
      const { data } = res;
      setFilterLoading(false);
      setFilters(data || {});
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setFilterLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const mailNewsletterFile = async () => {
    try {
      const res = await api.get(
        `${api.endpoints.newsletter_subscribers}/mail`,
        { ...selectedFilterQuery }
      );
      const { message } = res;
      enqueueSnackbar(message, {
        variant: "success"
      });
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setFilterLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const downloadNewsletterFile = async () => {
    try {
      handleCloseMenu();
      const response = await api.get(
        `${api.endpoints.newsletter_subscribers}/download`,
        {
          ...selectedFilterQuery
        }
      );

      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "newsletter-subscribers.csv");
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      enqueueSnackbar("Download Successfully", { variant: "success" });
    } catch (error) {
      const { data: { message = "Something Unusual Happened" } = {} } =
        error || {};
      setFilterLoading(false);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  // ==================================================================================================
  // CALLBACK FUNCTIONS ===============================================================================
  // ==================================================================================================

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = filters => {
    const filterQuery = {};
    filters.forEach(item => {
      if (filterQuery[item.key]) filterQuery[item.key].push(item.value);
      else filterQuery[item.key] = [item.value];
    });
    setPage(0);
    setSelectedFilterQuery(filterQuery);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  // ==================================================================================================
  // LAYOUT RENDERING =================================================================================
  // ==================================================================================================
  const getNewsletterData = () =>
    rows.map((subscriber, index) => (
      <TableRow hover key={`subscriber-${index}`}>
        <TableCell component="th" scope="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{
                marginRight: "8px",
                backgroundColor: avatarColors[index % avatarColors.length]
              }}
            >
              {subscriber.name[0]}
            </Avatar>
            <div>
              <p
                style={{
                  margin: "0px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                {subscriber.name}
              </p>
              <p
                style={{
                  margin: "0px",
                  fontSize: "11px",
                  color: "gray",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                {subscriber.email}
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell align="left">
          <p style={{ fontSize: "13px", margin: "0px" }}>{subscriber.state}</p>
        </TableCell>
        <TableCell align="left">
          <div style={{ display: "flex" }}>
            {subscriber.source === "Wordpress" ? (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#7faa82",
                  padding: "2px 12px",
                  fontSize: "11px",
                  textTransform: "none"
                }}
                onClick={() =>
                  setRowSelectedFilter({ key: "source", value: "Wordpress" })
                }
              >
                Wordpress
              </Button>
            ) : (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#aa877f",
                  padding: "2px 12px",
                  fontSize: "11px",
                  textTransform: "none"
                }}
                onClick={() =>
                  setRowSelectedFilter({ key: "source", value: "EZAP" })
                }
              >
                EZAP
              </Button>
            )}
          </div>
        </TableCell>
        <TableCell align="left" size="small">
          <p
            style={{
              fontSize: "12px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              margin: "0px"
            }}
          >
            {subscriber.source_page}
          </p>
        </TableCell>
        <TableCell align="left">
          {subscriber.wordpress_category ? (
            <Button
              style={{
                color: "#aa7f7f",
                borderColor: "#aa7f7f",
                backgroundColor: "transparent",
                border: "1px solid",
                padding: "2px 12px",
                fontSize: "11px",
                textTransform: "none"
              }}
              onClick={() =>
                setRowSelectedFilter({
                  key: "wordpress_category",
                  value: subscriber.wordpress_category
                })
              }
            >
              {subscriber.wordpress_category}
            </Button>
          ) : (
            "---"
          )}
        </TableCell>
        <TableCell align="left">
          <p style={{ fontSize: "12px", margin: "0px" }}>
            {subscriber.created_at}
          </p>
        </TableCell>
      </TableRow>
    ));

  const getOptionsMenu = () => (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="bottom"
      className={classNames({
        [classes.popperClose]: !open,
        [classes.popperResponsive]: true,
        [classes.popperNav]: true
      })}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          id="profile-menu-list"
          style={{ transformOrigin: "0 0 0" }}
        >
          <Paper className={classes.dropdown}>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <MenuList role="menu">
                <MenuItem onClick={mailNewsletterFile} className={dropdownItem}>
                  Send File to Mail
                </MenuItem>
                <MenuItem
                  onClick={downloadNewsletterFile}
                  className={dropdownItem}
                >
                  Download CSV
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const getTableLayout = () => (
    <Card style={{ overflow: "scroll" }}>
      <Divider style={{ backgroundColor: "#7faa82" }} />
      <GridContainer
        justify="space-between"
        alignItems="center"
        style={{ width: "100%", margin: "0px", padding: "0.3rem 0px" }}
      >
        <div style={{ paddingLeft: "1rem" }}>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "500"
            }}
          >
            Newsletter Subscribers{" --> "}
            <span
              style={{
                color: "#1E88E5",
                borderColor: "#1E88E5",
                border: "1px solid",
                padding: "2px 12px",
                fontSize: "12px"
              }}
            >
              {totalCount}
            </span>
          </h4>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <Button
            color="transparent"
            simple
            justIcon
            onClick={handleShowMenu}
            className={classes.buttonLink}
          >
            <MoreVertIcon />
          </Button>
          {getOptionsMenu()}
        </div>
      </GridContainer>
      <Divider light />
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#fafafa" }}>
          <TableRow>
            <TableCell align="left" style={{ width: "25%" }}>
              Name
            </TableCell>
            <TableCell align="left" style={{ width: "15%" }}>
              State
            </TableCell>
            <TableCell align="left" style={{ width: "12%" }}>
              Source
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              Source Page
            </TableCell>
            <TableCell align="left" style={{ width: "15%" }}>
              Wordpress Category
            </TableCell>
            <TableCell align="left" style={{ width: "13%" }}>
              Subscribed On
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getNewsletterData()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Card>
  );

  return (
    <React.Fragment>
      <NewsletterFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        rowSelectedFilter={rowSelectedFilter}
      />
      {getTableLayout()}
    </React.Fragment>
  );
};

export default withStyles(dashboardStyle)(NewsletterSection);
