import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import _ from "lodash";

const customStyles = {
  valueContainer: styles => ({
    ...styles,
    maxHeight: "150px",
    overflow: "auto"
  })
  // control: (base, state) => ({
  //   ...base
  // })
};

const DropDownSelectorHooks = props => {
  const { data, isMulti, onChange, selectedValues } = props;

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedValues)) {
      if (selectedValues.length === data.length && isMulti) {
        setSelectAll(true);
      } else setSelectAll(false);
    } else setSelectAll(false);
  }, [props.selectedValues]);

  const handleChange = value => {
    if (isMulti) {
      let data_id = value.map(val => val.id);
      onChange(data_id);
      if (data_id.length < data.length) {
        setSelectAll(false);
      } else setSelectAll(true);
    } else {
      if (value) onChange(value.id);
      else onChange(value);
    }
  };

  const handleValue = () => {
    var selectorValue = null;
    if (selectedValues) {
      selectorValue = data.filter(item =>
        isMulti ? selectedValues.includes(item.id) : selectedValues === item.id
      );
    }
    return selectorValue;
  };

  const selectAllValue = selectedState => {
    if (selectedState) {
      let data_id = data.map(val => val.id);
      setSelectAll(selectedState);
      onChange(data_id);
    } else {
      setSelectAll(selectedState);
      onChange([]);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Select
          isClearable
          isMulti={isMulti}
          options={data}
          value={handleValue()}
          components={makeAnimated()}
          onChange={handleChange}
          styles={customStyles}
          backspaceRemovesValue={false}
        />
      </div>
      {isMulti ? (
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={selectAll}
              onClick={() => selectAllValue(!selectAll)}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              color="primary"
              // margin="dense"
              margin="0"
              style={{ padding: "0px", marginRight: "3px" }}
            />
          }
          labelPlacement="end"
          label="Select All"
          style={{ marginTop: "10px", marginLeft: "0px" }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default DropDownSelectorHooks;
