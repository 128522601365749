import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
import _ from "lodash";

import MultipleColumnInputTable from "views/Forms/EazyForms/FormBasic/MultipleColumnInputTable.jsx";

class EligibilityAge extends Component {
  ageEligibilityFormik = () => {
    const { values, setFieldValue } = this.props;
    const defaultValue = values.form_basic.eligibility_age;
    const tableHead = defaultValue[0].data.map(item => item.display_name);
    tableHead.unshift("Category");
    const updatedValue = [{ eligibility_age: defaultValue }];
    return updatedValue.map((data, index) => {
      return (
        <GridItem key={index} xs={12}>
          <FastField
            name="form_basic.eligibility_age"
            component={MultipleColumnInputTable}
            defaultValue={data.eligibility_age}
            setFieldValue={setFieldValue}
            index={index}
            headerName="Eligibility Age"
            tableHead={tableHead}
            placeholder="Enter Age"
            sectionKey={`form_basic.eligibility_age`}
          />
        </GridItem>
      );
    });
  };
  render() {
    const { values } = this.props;
    return (
      <React.Fragment>
        {!_.isEmpty(values.form_basic.eligibility_age) ? (
          <GridContainer>{this.ageEligibilityFormik()}</GridContainer>
        ) : null}
      </React.Fragment>
    );
  }
}

export default EligibilityAge;
