import React from "react";
import { FastField } from "formik";
import BareTextFieldInput from "views/Components/BareTextFieldInput";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const CourseFeesDisplay = ({
  feesArray,
  setFieldValue,
  streamIndex,
  courseIndex,
  courseData
}) => {
  let FeesData = null;
  if (feesArray) {
    if (feesArray.length === 1 && feesArray[0] === null) {
      return FeesData;
    }
    let Fees = [];
    FeesData = feesArray.map((item, index) => {
      return (
        <GridItem xs={6} md={3} key={index}>
          <br />
          <p style={{ fontWeight: "bold", marginBottom: "0px" }}>{`Year ${
            item.year
          }`}</p>
          <FastField
            key={index}
            type="number"
            min={0}
            component={BareTextFieldInput}
            name={`streams[${streamIndex}].courses[${courseIndex}].fees[${index}].amount`}
            onBlur={value => {
              setFieldValue(
                `streams[${streamIndex}].courses[${courseIndex}].fees[${index}].amount`,
                parseInt(value.target.value)
              );
            }}
            defaultValue={item.amount || ""}
          />
        </GridItem>
      );
    });
  }
  return FeesData;
};

export default CourseFeesDisplay;
