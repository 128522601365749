// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import * as actions from "data/elements/actions";

const tableHead = ["#", "Full Name", "Is Part Of Gallery", "Actions"];

class CollegeTag extends Component {
  /**
   * @prop(index) Index of the row
   * This would create the actions layout based on the row type.
   */
  handleActionsLayout = index => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableCell className={classes.tableCell}>
          <div>
            <Button
              onClick={() => this.editRow(index)}
              className={classes.actionButton}
              color="info"
              size="lg"
              title="Edit">
              <Edit className={classes.icon} />
            </Button>
            <Button
              onClick={() => this.deleteRow(index)}
              className={classes.actionButton}
              color="danger"
              size="lg"
              title="Delete">
              <Delete className={classes.icon} />
            </Button>
          </div>
        </TableCell>
      </React.Fragment>
    );
  };

  editRow = index => {
    this.props.showTagDataModal(this.props.elementIndex, index, true);
  };
  deleteRow = index => {
    this.props.showDeleteBox(this.props.elementIndex, index);
  };

  /**
   * Create a row with empty values
   */
  addRowButtonAction = () => {
    this.props.showTagDataModal(this.props.elementIndex, null, false);
  };

  render() {
    const { classes, elements } = this.props;
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12}>
          <Table>
            <TableHead className={classes["primary"]}>
              <TableRow className={classes.tableRow}>
                {tableHead.map((prop, key) => {
                  {
                    const tableCellClasses =
                      classes.tableHeadCell + " " + classes.tableCell;
                    return (
                      <TableCell
                        key={key}
                        className={tableCellClasses}
                        style={{ fontWeight: "bold" }}>
                        {prop}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {elements.map((item, index) => {
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {index + 1}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <h4>{item.full_name}</h4>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <h4>{item.is_part_of_gallery ? "Yes" : "No"}</h4>
                    </TableCell>
                    {this.handleActionsLayout(index)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <GridContainer justify="flex-end">
            <GridItem>
              <Button
                color="success"
                title="Add"
                type="button"
                onClick={this.addRowButtonAction}>
                Add Row
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

CollegeTag.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  elementIndex: PropTypes.number
};

function mapStateToProps({ elements }, ownProps) {
  const elementIndex = ownProps.elementIndex;
  return {
    elements: elements.data[elementIndex].data,
    name: elements.data[elementIndex].name,
    path: elements.data[elementIndex].path,
    showModal: elements.ui.showModal
  };
}

export default withStyles(tableFormStyles)(
  connect(
    mapStateToProps,
    actions
  )(CollegeTag)
);
