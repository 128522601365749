import api from "data/utils/api";
import {
  UNIVERSITIES_APPSTATE_ALL_LOADING,
  UNIVERSITIES_DATA_ALL_GET,
  UNIVERSITIES_DATA_TOTAL_COUNT,
  UNIVERSITIES_DATA_CONTENT_COUNT,
  UNIVERSITIES_DATA_PAGE_START,
  UNIVERSITIES_APPSTATE_ALL_SUCCESS,
  UNIVERSITIES_APPSTATE_ALL_ERROR
} from "data/universities/types";

// =================================================================================================
// GET ALL ELEMENTS ================================================================================
// =================================================================================================
export const getAllUniversities = (
  offset,
  limit,
  currentPage,
  filterValue
) => async dispatch => {
  dispatch({ type: UNIVERSITIES_APPSTATE_ALL_LOADING });
  try {
    const res = await api.get(api.endpoints.universities, {
      offset,
      limit,
      name: filterValue
    });

    dispatch({
      type: UNIVERSITIES_DATA_ALL_GET,
      data: res.data,
      currentPage,
      search: filterValue ? true : false
    });
    dispatch({
      type: UNIVERSITIES_DATA_TOTAL_COUNT,
      totalCount: res.totalCount
    });
    dispatch({
      type: UNIVERSITIES_DATA_CONTENT_COUNT,
      contentCount: res.count
    });
    //dispatch({ type: UNIVERSITIES_DATA_PAGE_LIMIT, pageLimit: res.limit });
    dispatch({ type: UNIVERSITIES_DATA_PAGE_START, pageStart: res.start });
    dispatch({ type: UNIVERSITIES_APPSTATE_ALL_SUCCESS });
  } catch (error) {
    dispatch({
      type: UNIVERSITIES_APPSTATE_ALL_ERROR,
      error
    });
  }
};
