import React, { Component } from "react";
import api from "data/utils/api";
import AsyncSelect from "react-select/async";
import _ from "lodash";

class AsyncMultiSelector extends Component {
  handleInputChange = (value, type) => {
    const { onChange, selectedValues, isMulti } = this.props;

    let newSelectedValue = selectedValues || [];
    //********************** */For adding a new Element to array******************************//
    if (isMulti) {
      if (type.action === "select-option") {
        const { id, short_name, full_name } = type.option;
        const data = {
          id,
          short_name,
          full_name
        };
        newSelectedValue.push(data);
        onChange(newSelectedValue);
      }

      //********************** */for removing 1 element from an array*******************************//
      else if (type.action === "remove-value") {
        const updatedArray = newSelectedValue.filter(
          item => item.id !== type.removedValue.id
        );
        onChange(updatedArray);
      }

      //*************************** */for removing all elements from an array*************************
      else if (type.action === "clear") {
        onChange([]);
      }
    } else {
      if (value) {
        const { id, short_name, full_name } = value;
        const newData = { id, short_name, full_name };
        onChange(newData);
      } else onChange(value);
    }
  };

  arrayModified = valuesItem => {
    const newValue = valuesItem.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      if (id)
        return {
          id,
          value: full_name,
          label: `${full_name}${updated_short_name}`,
          short_name,
          full_name
        };
      else
        return {
          value: full_name,
          label: full_name
        };
    });
    return newValue;
  };

  fetchData = async inputValue => {
    const { filterKey, apiEndpoint } = this.props;
    let newData = [];
    try {
      const res = await api.get(apiEndpoint, {
        [filterKey]: inputValue || ""
      });
      if (res) {
        newData = this.arrayModified(res.data);
      }
      return newData;
    } catch (error) {
      // console.log(error);
    }
  };

  handleValue = () => {
    const { selectedValues, isMulti } = this.props;
    let selectedData;
    if (isMulti) {
      selectedData = selectedValues.map(item => {
        const { id, full_name, short_name } = item;
        const updated_short_name = short_name ? `(${short_name})` : "";
        return {
          id,
          value: full_name,
          label: `${full_name}${updated_short_name}`
        };
      });
      return selectedData;
    } else {
      if (!_.isEmpty(selectedValues)) {
        const { id, full_name, short_name } = selectedValues;
        const updated_short_name = short_name ? `(${short_name})` : "";
        selectedData = {
          id,
          value: full_name,
          label: `${full_name}${updated_short_name}`
        };
        return selectedData;
      } else return null;
    }
  };

  render() {
    const { isMulti } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        isMulti={isMulti}
        isClearable
        defaultOptions
        loadOptions={this.fetchData}
        value={this.handleValue()}
        onChange={this.handleInputChange}
      />
    );
  }
}

export default AsyncMultiSelector;
