import {
  ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
  ELEMENTS_APPSTATE_CURRENT_ROW_INDEX
} from "data/elements/types";

export const setCurrentElementIndex = elementIndex => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX, elementIndex });
};

export const setCurrentRowIndex = rowIndex => async dispatch => {
  dispatch({ type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX, rowIndex });
};
