import React from "react";
// react component plugin for creating a beautiful datetime dropdown picker

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import api from "data/utils/api";
import defaultImage from "assets/img/image_placeholder.jpg";
import _ from "lodash";
import { FastField, Field } from "formik";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import { shortNameForm } from "utils/values.jsx";

import { debounce } from "throttle-debounce";
import ImageDetailDialog from "views/Components/ImageDetailDialog";
import * as actions from "data/main/actions";
import { connect } from "react-redux";
import FormikCheckbox from "views/Components/FormikCheckbox.jsx";
import CollegeImages from "views/College/Basic/CollegeBasic/CollegeImages.jsx";

class BasicEntranceForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShortNameUnique: null,
      shortNameProgress: null,
      openImageModal: false
    };
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
  }
  handleShortNameUniqueness = (name, id) => {
    if (!(name.length === 0)) {
      this.setState({
        shortNameProgress: false,
        isShortNameUnique: null
      });
    }
    this.autocompleteSearchDebounced(name, id);
  };

  ClearInputValue = () => {
    const { setFieldValue } = this.props;
    setFieldValue("form_basic.short_name", "");
    this.setState({
      shortNameProgress: null,
      isShortNameUnique: null
    });
  };

  autocompleteSearch = async (name, id) => {
    if (name) {
      this.setState({ shortNameProgress: true, isShortNameUnique: null });
      try {
        const res = await api.get(api.endpoints.form.short_name, {
          short_name: name,
          id
        });
        // this.props.checkShortNameUnique(true);
        this.setState({
          isShortNameUnique: res.data.is_unique,
          shortNameProgress: false
        });
        this.props.checkShortNameUnique(res.data.is_unique);
      } catch (error) {
        this.setState({ isShortNameUnique: null, shortNameProgress: false });
        this.props.checkShortNameUnique(false);
      }
    } else {
      this.setState({ isShortNameUnique: null, shortNameProgress: false });
      this.props.checkShortNameUnique(false);
    }
  };

  handleImageButtonAction = (isLogo = true) => {
    if (!this.state.isShortNameUnique && !this.props.id) {
      //show the notification that the user cannot get into image section without the short name.
      this.props.showNotification(
        "Upload image will only work after filling Unique Short Name",
        false
      );
    } else {
      this.setState({ openImageModal: true, isLogo });
    }
  };

  handleImageValue = values => {
    const value = _.get(values, "form_basic.logo.url", defaultImage);
    if (_.isNull(value)) {
      return defaultImage;
    } else {
      return value;
    }
  };

  handleFeaturedImageValue = values => {
    const value = _.get(values, "form_basic.featured_image.url", defaultImage);
    if (_.isNull(value)) {
      return defaultImage;
    } else {
      return value;
    }
  };

  render() {
    const {
      classes,
      setFieldValue,
      values,
      touched,
      setFieldTouched,
      validationError,
      id
    } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Card>
                  <CardBody>
                    {values.form_basic.is_active ? (
                      <label style={{ fontWeight: "bold", color: "#4CAF50" }}>
                        Published
                      </label>
                    ) : (
                      <label style={{ fontWeight: "bold", color: "#F44336" }}>
                        Published
                      </label>
                    )}
                    <FastField
                      name={`form_basic.is_active`}
                      component={FormikToggleButton}
                      onBlur={checkedValue => {
                        setFieldValue(`form_basic.is_active`, !checkedValue);
                      }}
                      value={values.form_basic.is_active}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Basic Info - Form</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <p style={{ fontWeight: "bold", margin: "0px" }}>
                          Short Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <Field
                          name={`form_basic.short_name`}
                          fullWidth
                          maxlength={shortNameForm}
                          component={BareTextFieldInputOnChange}
                          placeholder="Short Name"
                          onBlur={e => {
                            this.handleShortNameUniqueness(e.target.value, id);
                            setFieldValue(
                              `form_basic.short_name`,
                              e.target.value
                            );
                            setFieldTouched(`short_name`, true);
                          }}
                          value={values.form_basic.short_name}
                          progress={this.state.shortNameProgress}
                          unique={this.state.isShortNameUnique}
                          error={
                            this.state.isShortNameUnique ||
                            this.state.isShortNameUnique === null
                              ? false
                              : true
                          }
                          onClearInput={this.ClearInputValue}
                        />
                        {!values.form_basic.short_name.length > 0 &&
                        (touched.short_name || validationError) ? (
                          <div style={{ color: "red" }}>
                            *Short Name is required
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem>
                        <br />
                        <br />
                      </GridItem>
                      <GridItem xs={12}>
                        <br />
                        <p style={{ fontWeight: "bold", margin: "0px" }}>
                          Full Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <FastField
                          name={`form_basic.full_name`}
                          fullWidth={true}
                          component={BareTextFieldInput}
                          placeholder="Full Name"
                          onBlur={e => {
                            setFieldValue(
                              `form_basic.full_name`,
                              e.target.value
                            );
                            setFieldTouched(`full_name`, true);
                          }}
                          defaultValue={values.form_basic.full_name}
                        />
                        {!values.form_basic.full_name.length > 0 &&
                        (touched.full_name || validationError) ? (
                          <div style={{ color: "red" }}>
                            *Full Name is required
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <p style={{ fontWeight: "bold", margin: "0px" }}>
                          Website URL
                        </p>
                        <FastField
                          name="form_basic.website_url"
                          fullWidth
                          // styleHeight
                          type="text"
                          component={BareTextFieldInput}
                          placeholder="Website URL"
                          onBlur={e => {
                            setFieldValue(
                              "form_basic.website_url",
                              e.target.value
                            );
                          }}
                          defaultValue={values.form_basic.website_url}
                        />
                        <label>
                          <a
                            href={values.form_basic.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {values.form_basic.website_url}
                          </a>
                        </label>
                        <br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <p style={{ fontWeight: "bold", margin: "0px" }}>
                          Number of Attempts
                        </p>
                        <FastField
                          name={`form_basic.number_of_attempts`}
                          fullWidth
                          // styleHeight
                          type="number"
                          min={0}
                          component={BareTextFieldInput}
                          placeholder="Number of Attempts"
                          onBlur={e => {
                            setFieldValue(
                              `form_basic.number_of_attempts`,
                              parseInt(e.target.value, 10)
                            );
                          }}
                          defaultValue={
                            isNaN(values.form_basic.number_of_attempts)
                              ? ""
                              : parseInt(
                                  values.form_basic.number_of_attempts,
                                  10
                                ) || ""
                          }
                        />
                        <br />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {/* {id ? (
                    <React.Fragment>
                      <GridItem xs={12} sm={12} md={2}>
                        <GridContainer justify="center">
                          <GridItem>
                            <legend>Form Logo</legend>
                            <div className={"thumbnail"}>
                              <img
                                src={this.handleImageValue(values)}
                                alt="..."
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px"
                                }}
                              />
                            </div>
                            <br />
                            <Button
                              color="tumblr"
                              type="button"
                              fullWidth
                              onClick={() => this.handleImageButtonAction()}>
                              Manage Logo
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <GridContainer justify="center">
                          <GridItem>
                            <legend>Featured Image</legend>
                            <div className={"thumbnail"}>
                              <img
                                src={this.handleFeaturedImageValue(values)}
                                alt="..."
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px"
                                }}
                              />
                            </div>
                            <br />
                            <Button
                              color="tumblr"
                              type="button"
                              fullWidth
                              onClick={() =>
                                this.handleImageButtonAction(false)
                              }>
                              Manage Featured Image
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </React.Fragment>
                  ) : null} */}
                  {id ? (
                    <CollegeImages
                      logo={values.form_basic.logo}
                      featured_image={values.form_basic.featured_image}
                      setFieldValue={setFieldValue}
                      id={id}
                      folderPath="forms"
                    />
                  ) : null}
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem>
                        <br />
                        <div className={classes.inputHeader}>Apply Now</div>
                        <FastField
                          label="Is Apply Now available for this Form ?"
                          name={`form_basic.is_apply_now`}
                          component={FormikCheckbox}
                          onBlur={checkedValue => {
                            setFieldValue(
                              `form_basic.is_apply_now`,
                              !checkedValue
                            );
                            setFieldValue(`form_basic.apply_now_url`, "");
                            setFieldValue(
                              `form_basic.apply_now_description`,
                              ""
                            );
                          }}
                          value={values.form_basic.is_apply_now}
                        />
                      </GridItem>
                      {values.form_basic.is_apply_now ? (
                        <GridItem xs={6}>
                          <br />
                          <div className={classes.inputHeader}>
                            Apply Now URL
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <FastField
                            name={`form_basic.apply_now_url`}
                            component={BareTextFieldInput}
                            fullWidth
                            placeholder="Apply Now URL"
                            onBlur={e => {
                              setFieldValue(
                                `form_basic.apply_now_url`,
                                e.target.value
                              );
                              setFieldTouched(`apply_now_url`, true);
                            }}
                            defaultValue={values.form_basic.apply_now_url}
                          />
                          {!values.form_basic.apply_now_url.length > 0 &&
                          (touched.apply_now_url || validationError) ? (
                            <div style={{ color: "red" }}>
                              *Apply Now URL is required
                            </div>
                          ) : null}
                        </GridItem>
                      ) : null}
                    </GridContainer>
                  </GridItem>
                  {values.form_basic.is_apply_now ? (
                    <GridItem xs={12}>
                      <br />
                      <label className={classes.inputHeader}>
                        Apply Now Description{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <FastField
                        multiline
                        name={`form_basic.apply_now_description`}
                        component={BareTextFieldInput}
                        fullWidth
                        styleHeight
                        rows="3"
                        rowsMax="5"
                        placeholder="Apply Now Description"
                        onBlur={e => {
                          setFieldValue(
                            `form_basic.apply_now_description`,
                            e.target.value
                          );
                          setFieldTouched(`apply_now_description`, true);
                        }}
                        defaultValue={values.form_basic.apply_now_description}
                      />
                      {!values.form_basic.apply_now_description.length > 0 &&
                      (touched.apply_now_description || validationError) ? (
                        <div style={{ color: "red" }}>
                          *Apply Now Description is required
                        </div>
                      ) : null}
                    </GridItem>
                  ) : null}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.openImageModal ? (
          <ImageDetailDialog
            values={
              this.state.isLogo
                ? values.form_basic.logo
                : values.form_basic.featured_image
            }
            onClose={() => this.setState({ openImageModal: false })}
            onSubmit={value => {
              this.setState({ openImageModal: false });
              if (this.state.isLogo) {
                setFieldValue(`form_basic.logo`, value);
              } else {
                setFieldValue(`form_basic.featured_image`, value);
              }
            }}
            shortName={values.form_basic.short_name}
            folderPath="exams"
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(tableFormStyles)(
  connect(null, actions)(BasicEntranceForms)
);
