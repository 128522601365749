import _ from "lodash";

export default (elements, data, id, newForm, checkNewForm) => {
  if (!_.isEmpty(data) && id) {
    return refractorServerData(elements, data);
  } else if (newForm && checkNewForm) {
    return refractorServerData(elements, data);
  } else {
    return createInitialData(elements);
  }
};

const createInitialData = data => {
  const formBasic = {
    is_active: false,
    short_name: "",
    full_name: "",
    number_of_attempts: "",
    is_apply_now: false,
    apply_now_url: "",
    apply_now_description: "",
    application_fee_default: "",
    education_level_details: [],
    exam_paper_details: [
      {
        negative_marking: {
          is_active: false,
          instruction: ""
        },
        instruction: "",
        duration: "",
        question_paper_types: [],
        total: "",
        total_questions: "",
        pattern: [
          {
            id: "",
            full_name: "",
            questions: "",
            questions_per_marks: "",
            total: ""
          }
        ]
      }
    ],
    rank_prediction: []
  };

  //dates
  const dates = data
    .filter(item => item.key === "features")[0]
    .data.filter(item => item.id === 1)[0]
    .data.filter(item => item.key === "dates")[0].data;

  const clonedDates = dates ? _.cloneDeep(dates) : [];
  const finalDates = clonedDates.map(item => ({
    id: item.id,
    full_name: item.full_name,
    display_name: item.full_name,
    data: {}
  }));

  formBasic.dates = finalDates;
  //fees
  const fees = data
    .filter(item => item.key === "genders")[0]
    .data.map(item => {
      const reservation_categories = data
        .filter(item => item.key === "reservation_categories")[0]
        .data.map(item => ({
          id: item.id,
          full_name: item.full_name,
          short_name: item.short_name,
          application_fee: ""
        }));
      return {
        id: item.id,
        full_name: item.full_name,
        short_name: item.short_name,
        data: reservation_categories
      };
    });

  formBasic.application_fee = fees;

  //eligibility age
  const eligibilityAge = data
    .filter(item => item.key === "reservation_categories")[0]
    .data.map(item => ({
      full_name: item.full_name,
      short_name: item.full_name,
      id: item.id,
      data: [
        {
          id: 1,
          name: "upper_age_limit",
          display_name: "Upper Age Limit",
          type: "number",
          input_value: ""
        },
        {
          id: 2,
          name: "lower_age_limit",
          display_name: "Lower Age Limit",
          type: "number",
          input_value: ""
        },
        {
          id: 3,
          name: "instructions",
          display_name: "Instructions",
          input_value: ""
        }
      ]
    }));
  formBasic.eligibility_age = eligibilityAge;

  // Reservation Creteria

  const reservationCreteria = data
    .filter(item => item.key === "reservation_categories")[0]
    .data.map(item => ({
      full_name: item.full_name,
      short_name: item.full_name,
      id: item.id,
      data: [
        {
          id: 1,
          name: "percentage_creteria",
          display_name: "Reserved Percentage",
          type: "number",
          input_value: ""
        },
        {
          id: 2,
          name: "instructions",
          display_name: "Instructions",
          input_value: ""
        }
      ]
    }));
  formBasic.reservation_criteria = reservationCreteria;
  return { form_basic: formBasic };
};

const refractorServerData = (elements, data) => {
  const newObject = {};
  for (var key in data) {
    switch (key) {
      case "application_fee":
        newObject[key] = applicationFeeData(elements, data[key]);
        break;
      case "eligibility_age":
        newObject[key] = eligibilityAgeData(elements, data[key]);
        break;
      case "reservation_criteria":
        newObject[key] = reservationCreteriaData(elements, data[key]);
        break;
      case "dates":
        newObject[key] = datesData(elements, data[key]);
        break;
      default:
        newObject[key] = data[key];
    }
  }
  return { form_basic: newObject };
};

const applicationFeeData = (elements, values) => {
  const feeBasedOnGender = elements
    .filter(gender_item => gender_item.key === "genders")[0]
    .data.map(genderItem => {
      const { id, full_name, short_name } = genderItem;
      const availableGender = values.filter(item => item.id === id);
      if (availableGender.length > 0) {
        const data = elements
          .filter(item => item.key === "reservation_categories")[0]
          .data.map(resvItem => {
            const { id, full_name, short_name } = resvItem;
            const availableReservCat = availableGender[0].data.filter(
              item => item.id === resvItem.id
            );
            if (availableReservCat.length > 0) {
              return availableReservCat[0];
            } else
              return {
                id: id,
                full_name: full_name,
                short_name: short_name,
                application_fee: ""
              };
          });
        return { id, full_name, short_name, data };
      } else {
        const resvCheck = elements
          .filter(item => item.key === "reservation_categories")[0]
          .data.map(item => {
            const { id, short_name, full_name } = item;
            return {
              id,
              short_name,
              full_name,
              application_fee: ""
            };
          });
        if (resvCheck.length > 0) {
          return {
            id,
            full_name,
            short_name,
            data: resvCheck
          };
        } else
          return {
            id,
            full_name,
            short_name,
            data: []
          };
      }
    });
  return feeBasedOnGender;
};

const eligibilityAgeData = (elements, values) => {
  const data = elements
    .filter(item => item.key === "reservation_categories")[0]
    .data.map(resvItem => {
      const { id, full_name, short_name } = resvItem;
      const availableReservCat = values.filter(item => item.id === resvItem.id);
      if (availableReservCat.length > 0) {
        return availableReservCat[0];
      } else
        return {
          id: id,
          full_name: full_name,
          short_name: short_name,
          data: [
            {
              id: 1,
              name: "upper_age_limit",
              display_name: "Upper Age Limit",
              type: "number",
              input_value: ""
            },
            {
              id: 2,
              name: "lower_age_limit",
              display_name: "Lower Age Limit",
              type: "number",
              input_value: ""
            },
            {
              id: 3,
              name: "instructions",
              display_name: "Instructions",
              input_value: ""
            }
          ]
        };
    });
  return data;
};

const reservationCreteriaData = (elements, values) => {
  const data = elements
    .filter(item => item.key === "reservation_categories")[0]
    .data.map(resvItem => {
      const { id, full_name, short_name } = resvItem;
      const availableReservCat = values.filter(item => item.id === resvItem.id);
      if (availableReservCat.length > 0) {
        return availableReservCat[0];
      } else
        return {
          id: id,
          full_name: full_name,
          short_name: short_name,
          data: [
            {
              id: 1,
              name: "percentage_creteria",
              display_name: "Reserved Percentage",
              type: "number",
              input_value: ""
            },
            {
              id: 2,
              name: "instructions",
              display_name: "Instructions",
              input_value: ""
            }
          ]
        };
    });
  return data;
};

const datesData = (elements, values) => {
  const data = elements
    .filter(item => item.key === "features")[0]
    .data.filter(item => item.full_name === "Form")[0]
    .data.filter(item => item.key === "dates")[0].data;
  if (data) {
    return data.map(date => {
      const { id, full_name } = date;
      const availableDate = values.filter(item => item.id === id);
      if (availableDate.length > 0) {
        return availableDate[0];
      } else
        return {
          id,
          full_name,
          display_name: full_name,
          data: {}
        };
    });
  }
  return [];
};
