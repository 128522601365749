import React from "react";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { shortNameLength } from "utils/values.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";

import * as actions from "data/elements/actions";
import { LinearProgress } from "@material-ui/core";
import BasicMultiSelectors from "views/Components/BasicMultiSelectors";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class StreamDataModal extends React.Component {
  state = {
    modal: true,
    full_name: "",
    short_name: "",
    id: null,
    error: "",
    levels: [],
    education_level_id: null,
    duration: null
  };

  getEducationLevels = education_levels => {
    if (education_levels) {
      return education_levels.data.map(item => ({
        id: item.id,
        value: item.full_name,
        label: `${item.full_name}(${item.short_name})`
      }));
    } else {
      return [];
    }
  };

  componentDidMount() {
    const { isEditing } = this.props.elements.ui;
    const { rowIndex, streamCell, education_levels } = this.props;

    const levels = this.getEducationLevels(education_levels);

    if (isEditing) {
      const {
        full_name,
        short_name,
        id,
        education_level_id,
        duration
      } = streamCell[rowIndex];

      this.setState({
        full_name,
        id,
        short_name,
        levels,
        education_level_id,
        duration
      });
    } else {
      this.setState({ levels });
    }
  }
  handleTextInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.hideStreamDataModal();
  };

  handleProgressBar = () => {
    const rowLoading = this.props.elements.appState.rowLoading;
    if (rowLoading) {
      return <LinearProgress />;
    }
    return null;
  };

  handleCreateElement = () => {
    const { streams, streamElement, stream } = this.props;
    const { appState } = this.props.elements;
    const {
      elementIndex,
      rowIndex,
      streamIndex,
      streamElementIndex
    } = appState;

    const streamPath = streams.path;
    const streamId = stream.id;
    const path = streamElement.path;

    const {
      short_name,
      full_name,
      id,
      education_level_id,
      duration
    } = this.state;
    if (full_name.length && duration > 0) {
      //directly call the api from here.
      const data = {
        short_name,
        full_name,
        education_level_id,
        duration
      };
      if (this.props.elements.ui.isEditing) {
        data.id = id;

        this.props.updateStreamElement(
          path,
          streamPath,
          streamId,
          elementIndex,
          streamIndex,
          streamElementIndex,
          rowIndex,
          data
        );
      } else {
        this.props.addStreamElement(
          path,
          streamPath,
          streamId,
          elementIndex,
          streamIndex,
          streamElementIndex,
          data
        );
      }
    } else {
      //handle the error case.
      this.setState({ error: "Full Name and Course Duration is Mandatory" });
    }
  };

  handleError = () => {
    if (this.props.error) {
      return <p>{this.props.error}</p>;
    }
    return null;
  };

  render() {
    const { classes, elements, streamElement } = this.props;
    const { name } = streamElement;
    const {
      full_name,
      short_name,
      error,
      levels,
      education_level_id,
      duration
    } = this.state;
    const { showStreamModal } = elements.ui;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showStreamModal}
        transition={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => this.handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="info"
            onClick={() => this.handleClose()}>
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}>
          {error ? <pre>{error}</pre> : null}
          <div>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Short Name</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  placeholder="Short Name"
                  variant="outlined"
                  name="short_name"
                  onChange={this.handleTextInput}
                  inputProps={{ maxLength: shortNameLength }}
                  value={short_name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Full Name</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  fullWidth
                  required
                  placeholder="Full Name"
                  variant="outlined"
                  name="full_name"
                  onChange={this.handleTextInput}
                  value={full_name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Course Duration(in years)</h6>
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  fullWidth
                  required
                  placeholder="Course Duration"
                  variant="outlined"
                  name="duration"
                  onChange={this.handleTextInput}
                  value={duration}
                  type="number"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h6>Education Level</h6>
              </GridItem>
              <GridItem xs={12}>
                <BasicMultiSelectors
                  isMulti={false}
                  data={levels}
                  selectedValues={education_level_id}
                  onChange={id => this.setState({ education_level_id: id })}
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <br />
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleCreateElement} color="danger" simple>
            {this.props.elements.ui.isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {this.handleProgressBar()}
        {this.handleError()}
      </Dialog>
    );
  }
}
function mapStateToProps({ elements }) {
  const elementIndex = elements.appState.elementIndex;
  const streamIndex = elements.appState.streamIndex;
  const streamElementIndex = elements.appState.streamElementIndex;
  const rowIndex = elements.appState.rowIndex;

  const streams = elements.data[elementIndex];
  const stream = streams.data[streamIndex];
  const streamElement = stream.data[streamElementIndex];
  const streamCell = streamElement.data;

  const education_levels = elements.data.filter(
    item => item.key === "education_levels"
  )[0];
  return {
    elements,
    streams,
    stream,
    streamCell,
    streamElement,
    rowIndex,
    education_levels
  };
}
export default withStyles(modalStyle)(
  connect(
    mapStateToProps,
    actions
  )(StreamDataModal)
);
