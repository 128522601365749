import { MAIN_UI_HIDE_NOTIFICATION } from "data/main/types";
import notificationObject from "data/utils/notificationObject";

// =================================================================================================
// HIDE NOTIFICATION ===============================================================================
// =================================================================================================
export const hideNotification = () => async dispatch => {
  dispatch({ type: MAIN_UI_HIDE_NOTIFICATION });
};

// =================================================================================================
// SHOW NOTIFICATION ===============================================================================
// =================================================================================================
export const showNotification = (
  message,
  isSuccess = true
) => async dispatch => {
  let data;
  if (isSuccess) {
    data = { message };
  } else {
    data = { data: { message } };
  }
  dispatch(notificationObject(data, isSuccess));
};
