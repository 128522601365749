import _ from "lodash";
import { MAIN_UI_SHOW_NOTIFICATION } from "data/main/types";

export default (data, isSuccess = true) => {
  let message = "Success";
  let messageType = "success";
  if (isSuccess) {
    if (typeof data === "string") {
      message = data;
    } else if (typeof data === "object") {
      message = data.message;
    }
    messageType = "success";
  } else {
    message = _.has(data, "data.message")
      ? data.data.message
      : "Something Went Wrong";
    messageType = "danger";
  }
  return { type: MAIN_UI_SHOW_NOTIFICATION, message, messageType };
};
