import {
  UNIVERSITIES_DATA_CURRENT_PAGE,
  UNIVERSITIES_DATA_PAGE_START
} from "data/universities/types";

export const setCurrentUniversityPage = (page, pageLimit) => async dispatch => {
  dispatch({ type: UNIVERSITIES_DATA_CURRENT_PAGE, currentPage: page });
  dispatch({
    type: UNIVERSITIES_DATA_PAGE_START,
    pageStart: pageLimit * (page - 1)
  });
};
