import {
  ELEMENTS_UI_SHOW_DATA_MODAL,
  ELEMENTS_UI_HIDE_DATA_MODAL,
  ELEMENTS_UI_SHOW_ALERT_BOX,
  ELEMENTS_UI_HIDE_ALERT_BOX,
  ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
  ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
  ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX,
  ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX,
  ELEMENTS_UI_HIDE_NOTIFICATION,
  ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL,
  ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL,
  ELEMENTS_UI_SHOW_STREAM_DATA_MODAL,
  ELEMENTS_UI_HIDE_STREAM_DATA_MODAL,
  ELEMENTS_UI_SHOW_TAG_DATA_MODAL,
  ELEMENTS_UI_HIDE_TAG_DATA_MODAL,
  ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX,
  ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL,
  ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL,
  ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL
} from "data/elements/types";

// =================================================================================================
// SHOW DATA MODAL =================================================================================
// =================================================================================================
export const showDataModal = (
  elementIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
    elementIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({ type: ELEMENTS_UI_SHOW_DATA_MODAL, isEditing: isEditing });
};

// =================================================================================================
// SHOW FEATURE DATA MODAL =========================================================================
// =================================================================================================
export const showFeatureDataModal = (
  elementIndex,
  featureIndex,
  featureElementIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX,
    elementIndex,
    featureIndex,
    featureElementIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({ type: ELEMENTS_UI_SHOW_FEATURE_DATA_MODAL, isEditing: isEditing });
};

// =================================================================================================
// HIDE FEATURE DATA MODAL =========================================================================
// =================================================================================================
export const hideFeatureDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_FEATURE_DATA_MODAL });
};

// =================================================================================================
// HIDE DATA MODAL =================================================================================
// =================================================================================================
export const hideDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_DATA_MODAL });
};

// =================================================================================================
// SHOW STREAM DATA MODAL =========================================================================
// =================================================================================================
export const showStreamDataModal = (
  elementIndex,
  streamIndex,
  streamElementIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX,
    elementIndex,
    streamIndex,
    streamElementIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({ type: ELEMENTS_UI_SHOW_STREAM_DATA_MODAL, isEditing: isEditing });
};

// =================================================================================================
// HIDE STREAM DATA MODAL =========================================================================
// =================================================================================================
export const hideStreamDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_STREAM_DATA_MODAL });
};

// =================================================================================================
// SHOW STREAM DATA MODAL =========================================================================
// =================================================================================================
export const showBranchDataModal = (
  elementIndex,
  streamIndex,
  streamElementIndex,
  courseIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX,
    elementIndex,
    streamIndex,
    streamElementIndex,
    courseIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({ type: ELEMENTS_UI_SHOW_BRANCH_DATA_MODAL, isEditing: isEditing });
};

// =================================================================================================
// HIDE STREAM DATA MODAL =========================================================================
// =================================================================================================
export const hideBranchDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_BRANCH_DATA_MODAL });
};

// =================================================================================================
// SHOW STREAM DATA MODAL =========================================================================
// =================================================================================================
export const showIndependentBranchDataModal = (
  elementIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
    elementIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({
    type: ELEMENTS_UI_SHOW_INDEPENDENT_BRANCH_DATA_MODAL,
    isEditing: isEditing
  });
};

// =================================================================================================
// HIDE STREAM DATA MODAL =========================================================================
// =================================================================================================
export const hideIndependentBranchDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_INDEPENDENT_BRANCH_DATA_MODAL });
};

// =================================================================================================
// SHOW TAG DATA MODAL =============================================================================
// =================================================================================================
export const showTagDataModal = (
  elementIndex,
  rowIndex,
  isEditing
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
    elementIndex
  });
  if (isEditing) {
    dispatch({
      type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
      rowIndex
    });
  }
  dispatch({ type: ELEMENTS_UI_SHOW_TAG_DATA_MODAL, isEditing: isEditing });
};

// =================================================================================================
// HIDE TAG DATA MODAL =============================================================================
// =================================================================================================
export const hideTagDataModal = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_TAG_DATA_MODAL });
};

// =================================================================================================
// SHOW DELETE BOX =================================================================================
// =================================================================================================
export const showDeleteBox = (elementIndex, rowIndex) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
    elementIndex
  });
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
    rowIndex
  });
  dispatch({ type: ELEMENTS_UI_SHOW_ALERT_BOX });
};

// =================================================================================================
// SHOW DELETE BOX =================================================================================
// =================================================================================================
export const showFeatureDeleteBox = (
  elementIndex,
  featureIndex,
  featureElementIndex,
  rowIndex
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX,
    elementIndex,
    featureIndex,
    featureElementIndex
  });
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
    rowIndex
  });
  dispatch({ type: ELEMENTS_UI_SHOW_ALERT_BOX });
};

// =================================================================================================
// SHOW DELETE BOX =================================================================================
// =================================================================================================
export const showStreamDeleteBox = (
  elementIndex,
  streamIndex,
  streamElementIndex,
  rowIndex
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX,
    elementIndex,
    streamIndex,
    streamElementIndex
  });
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
    rowIndex
  });
  dispatch({ type: ELEMENTS_UI_SHOW_ALERT_BOX });
};

// =================================================================================================
// SHOW DELETE BOX =================================================================================
// =================================================================================================
export const showBranchDeleteBox = (
  elementIndex,
  streamIndex,
  streamElementIndex,
  courseIndex,
  rowIndex
) => async dispatch => {
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX,
    elementIndex,
    streamIndex,
    streamElementIndex,
    courseIndex
  });
  dispatch({
    type: ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
    rowIndex
  });
  dispatch({ type: ELEMENTS_UI_SHOW_ALERT_BOX });
};
// =================================================================================================
// HIDE DELETE BOX =================================================================================
// =================================================================================================
export const hideDeleteBox = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_ALERT_BOX });
};

// =================================================================================================
// HIDE NOTIFICATION ===============================================================================
// =================================================================================================
export const hideNotification = () => async dispatch => {
  dispatch({ type: ELEMENTS_UI_HIDE_NOTIFICATION });
};
