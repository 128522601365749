import React, { Component } from "react";
import axios from "axios";
import api from "data/utils/api";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import { showNotification } from "data/main/actions";
import { connect } from "react-redux";
import ImageUploadDialog from "../../../Components/ImageUpload/ImageUploadDialog";
import _ from "lodash";
class CollegeImageHandler extends Component {
  state = {
    progress: false
  };

  handleSubmit = async uploadData => {
    //check if the operation required is the update operation or the insert operation based on the attachment id.
    _.get(this.props.selectedImage, "id")
      ? this.handleImageUpdate(uploadData)
      : this.handleImageInsert(uploadData);
  };

  handleImageUpdate = async uploadData => {
    this.setState({ progress: true });

    const {
      id,
      url,
      title,
      alt_text,
      caption,
      description,
      file,
      display_url
    } = uploadData;
    // save the data of the image uploaded to the database in here.
    const itemId = this.props.itemId;
    let display_image_url = display_url;
    try {
      if (file) {
        //this means the image has been changed. hence we need to do the same at the server level.

        const res = await api.post(api.endpoints.upload, {
          file_path: url,
          file_type: file.type,
          folder_path: `/${this.props.folderPath}/${itemId}`,
          replace_url: this.props.displayURL
        });
        await axios.put(res.data.url[0], file, {
          headers: {
            "Content-Type": file.type
          }
        });
        display_image_url = res.data.display_url;
      }

      const image = {
        id,
        url: `/${this.props.folderPath}/${itemId}/${url}`,
        title,
        alt_text,
        caption,
        description,
        display_url: display_image_url
      };
      const endpoint = `${
        api.endpoints[this.props.folderPath]
      }/${itemId}/image`;
      await api.put(endpoint, {
        image
      });
      this.setState({ progress: false });
      this.props.onSubmit(image);
      this.props.showNotification("Attachment Updated Successfully");
    } catch (error) {
      this.setState({
        progress: false
      });
      this.props.showNotification(error.data.message || "", false);
    }
  };

  handleImageInsert = async uploadData => {
    this.setState({ progress: true });
    const { title, alt_text, caption, description, file, url } = uploadData;
    //Converting all the upper case to lower case and the space to dash.
    const itemId = this.props.itemId;
    try {
      const res = await api.post(api.endpoints.upload, {
        file_path: url,
        file_type: file.type,
        folder_path: `/${this.props.folderPath}/${itemId}`
      });
      await axios.put(res.data.url[0], file, {
        headers: {
          "Content-Type": file.type
        }
      });
      // save the data of the image uploaded to the database in here.
      const image = {
        url: `/${this.props.folderPath}/${itemId}/${url}`,
        title,
        alt_text,
        caption,
        description,
        display_url: res.data.display_url
      };
      const endpoint = `${
        api.endpoints[this.props.folderPath]
      }/${itemId}/image`;
      const response = await api.post(endpoint, {
        image,
        is_logo: this.props.isLogo
      });
      this.setState({ progress: false });
      image.id = response.data.id;
      this.props.onSubmit(image);
      this.props.showNotification("Image Added Successfully");
    } catch (error) {
      this.setState({ progress: false });
      this.props.showNotification(error.message, false);
    }
  };

  render() {
    const { selectedImage, displayURL } = this.props;
    return (
      <div>
        <ImageUploadDialog
          displayURL={displayURL}
          onClose={this.props.onClose}
          selectedImage={selectedImage}
          onSubmit={this.handleSubmit}>
          {this.state.progress ? (
            <CustomLinearProgress color="success" />
          ) : null}
        </ImageUploadDialog>
      </div>
    );
  }
}

export default connect(
  null,
  { showNotification }
)(CollegeImageHandler);
