//all the types of the FORMS should be added here.
export const COLLEGES_APPSTATE_ALL_LOADING = "COLLEGES_APPSTATE_ALL_LOADING";
export const COLLEGES_APPSTATE_ALL_SUCCESS = "COLLEGES_APPSTATE_ALL_SUCCESS";
export const COLLEGES_APPSTATE_ALL_ERROR = "COLLEGES_APPSTATE_ALL_ERROR";

export const COLLEGE_BASIC_APPSTATE_LOADING = "COLLEGE_BASIC_APPSTATE_LOADING";
export const COLLEGE_BASIC_APPSTATE_SUCCESS = "COLLEGE_BASIC_APPSTATE_SUCCESS";
export const COLLEGE_BASIC_APPSTATE_ERROR = "COLLEGE_BASIC_APPSTATE_ERROR";
export const COLLEGE_BASIC_APPSTATE_RESET = "COLLEGE_BASIC_APPSTATE_RESET";

export const COLLEGE_DETAIL_APPSTATE_LOADING =
  "COLLEGE_DETAIL_APPSTATE_LOADING";
export const COLLEGE_DETAIL_APPSTATE_SUCCESS =
  "COLLEGE_DETAIL_APPSTATE_SUCCESS";
export const COLLEGE_DETAIL_APPSTATE_ERROR = "COLLEGE_DETAIL_APPSTATE_ERROR";

export const COLLEGES_DATA_ALL_GET = "COLLEGES_DATA_ALL_GET";
export const COLLEGE_BASIC_DATA_GET = "COLLEGE_BASIC_DATA_GET";
export const COLLEGE_BASIC_DATA_RESET = "COLLEGE_BASIC_DATA_RESET";

export const COLLEGE_DETAIL_DATA_GET = "COLLEGE_DETAIL_DATA_GET";
export const COLLEGE_DETAIL_DATA_RESET = "COLLEGE_DETAIL_DATA_RESET";

export const COLLEGES_DATA_ONE_DELETE = "COLLEGES_DATA_ONE_DELETE";

export const COLLEGES_UI_SHOW_ALERT_BOX = "COLLEGES_UI_SHOW_ALERT_BOX";
export const COLLEGES_UI_HIDE_ALERT_BOX = "COLLEGES_UI_HIDE_ALERT_BOX";

export const COLLEGES_DATA_TOTAL_COUNT = "COLLEGES_DATA_TOTAL_COUNT";
export const COLLEGES_DATA_CONTENT_COUNT = "COLLEGES_DATA_CONTENT_COUNT";
export const COLLEGES_DATA_PAGE_LIMIT = "COLLEGES_DATA_PAGE_LIMIT";

//-----------------------------------------

export const COLLEGES_DATA_PAGE_START = "COLLEGES_DATA_PAGE_START";
export const COLLEGES_DATA_CURRENT_PAGE = "COLLEGES_DATA_CURRENT_PAGE";
