import React, { Component } from "react";
import TagsSelector from "views/College/Basic/Attachment/TagsSelector";
import TabsAttachment from "views/College/Basic/Attachment/TabsAttachment";
import api from "data/utils/api";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import { getAllElements } from "data/elements/actions/dataActions";
import { showNotification } from "data/main/actions";
import _ from "lodash";

class Attachment extends Component {
  state = {
    tabIndex: 0,
    selectedTags: [],
    initialData: []
  };

  componentDidMount() {
    const { id } = this.props;
    const { elements } = this.props;
    if (!elements.data.length) {
      this.props.getAllElements();
      this.fetchAttachmentData();
    } else {
      this.fetchAttachmentData();
    }
  }

  setAllAttachmentData = responseData => {
    const { college, data } = responseData;
    const selectedTags = data.map(item => {
      return {
        id: item.id,
        value: item.full_name,
        label: item.full_name
      };
    });
    this.setState({ selectedTags, initialData: data, collegeData: college });
  };

  fetchAttachmentData = async inputValue => {
    const { id } = this.props;
    try {
      const res = await api.get(`${api.endpoints.colleges}/${id}/attachments`);
      return this.setAllAttachmentData(res.data);
    } catch (error) {
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  setTagsSelectorValue = value => {
    this.setState({ selectedTags: value });
  };

  render() {
    const { id } = this.props;
    const { selectedTags, initialData, collegeData } = this.state;
    return (
      <div>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={6}>
            <TagsSelector
              selectedTags={selectedTags}
              setTagsSelectorValue={this.setTagsSelectorValue}
            />
          </GridItem>
        </GridContainer>
        <br />
        {selectedTags.length ? (
          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12}>
              <TabsAttachment
                selectedTags={selectedTags}
                initialData={initialData}
                collegeData={collegeData}
              />
            </GridItem>
          </GridContainer>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps({ elements }) {
  return {
    elements
  };
}

export default connect(
  mapStateToProps,
  { getAllElements, showNotification }
)(Attachment);
