import React, { Component } from "react";
import LocationSearchInput from "views/Components/College/LocationSearchInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

class SetAddress extends Component {
  searchedResultHandler = (value, selectedValue) => {
    if (value) {
      let newAddress = {};
      newAddress.selected_address = selectedValue;
      const newSelectedAddress = selectedValue.split(",");
      newAddress.line_1 = newSelectedAddress[0];
      const address_data = value.address_components.map(item => {
        switch (item.types[0]) {
          case "route":
          case "political":
          case "sublocality":
            newAddress.line_2 = item.long_name;
            break;
          case "administrative_area_level_2":
            newAddress.district = item.short_name;

            break;
          case "locality":
            newAddress.city = item.long_name;

            break;
          case "administrative_area_level_1":
            newAddress.state = item.long_name;

            break;
          case "country":
            newAddress.country = item.long_name;

            break;
          case "postal_code":
            newAddress.pincode = item.long_name;
            break;
          default:
            break;
        }
      });
      this.setState({ ...newAddress });
    }
  };
  clearAddressResultHandler = () => {
    const { handleAddress } = this.props;
    let address = {
      line_1: "",
      line_2: "",
      city: "",
      pincode: null,
      district: "",
      state: "",
      country: "",
      lat: "",
      lng: "",
      selected_address: ""
    };
    this.setState({ ...address }, () => handleAddress(this.state));
  };

  setLatAndLngHandler = value => {
    const { values, handleAddress } = this.props;
    if (value) {
      this.setState({ lat: value.lat, lng: value.lng }, () =>
        handleAddress(this.state)
      );
    }
  };

  componentDidMount() {
    const { address } = this.props;
    this.setState({ ...address });
  }

  render() {
    const {
      address: {
        line_1,
        line_2,
        city,
        pincode,
        district,
        state,
        country,
        selected_address
      }
    } = this.props;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <LocationSearchInput
              searchedResults={(value, selectedValue) =>
                this.searchedResultHandler(value, selectedValue)
              }
              clearAddressResult={this.clearAddressResultHandler}
              setLatAndLng={value => this.setLatAndLngHandler(value)}
              selectedAddress={selected_address}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              {line_1 ? (
                <GridItem xs={12}>
                  <label style={{ fontWeight: "bold", color: "#404040" }}>
                    Selected Address
                  </label>
                  <p style={{ marginTop: "10px" }}>{`${
                    line_1 ? `${line_1},` : ""
                  } ${line_2 ? `${line_2},` : ""}`}</p>
                  <p>{`${city ? `${city},` : ""} ${
                    pincode ? `${pincode},` : ""
                  } ${district ? `${district},` : ""} ${
                    state ? `${state},` : ""
                  } ${country ? `${country},` : ""}`}</p>
                </GridItem>
              ) : null}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(SetAddress);
