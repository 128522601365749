import api from "data/utils/api";

import {
  AUTH_APPSTATE_SIGNUP_LOADING,
  AUTH_APPSTATE_SIGNUP_SUCCESS,
  AUTH_APPSTATE_SIGNUP_ERROR,
  AUTH_APPSTATE_LOGIN_LOADING,
  AUTH_APPSTATE_LOGIN_SUCCESS,
  AUTH_APPSTATE_LOGIN_ERROR,
  AUTH_APPSTATE_RESET_LOADING,
  AUTH_APPSTATE_RESET_SUCCESS,
  AUTH_APPSTATE_RESET_ERROR,
  AUTH_APPSTATE_FORGOT_LOADING,
  AUTH_APPSTATE_FORGOT_SUCCESS,
  AUTH_APPSTATE_FORGOT_ERROR
} from "data/auth/types";

import { USER_APPSTATE_AUTH_SUCCESS } from "data/userProfile/types";

import showNotification from "data/utils/notificationObject";

// =================================================================================================
// LOGIN USER ======================================================================================
// =================================================================================================
export const loginUser = (email, password, history) => async dispatch => {
  dispatch({ type: AUTH_APPSTATE_LOGIN_LOADING });
  try {
    const res = await api.post(api.endpoints.auth.login, {
      email,
      password
    });
    dispatch({ type: AUTH_APPSTATE_LOGIN_SUCCESS });
    dispatch({ type: USER_APPSTATE_AUTH_SUCCESS });
    dispatch(showNotification(res));
    history.replace("/dashboard");
  } catch (error) {
    dispatch({ type: AUTH_APPSTATE_LOGIN_ERROR, error: error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// SIGNUP USER =====================================================================================
// =================================================================================================
export const signUpUser = email => async dispatch => {
  dispatch({ type: AUTH_APPSTATE_SIGNUP_LOADING });
  try {
    const res = await api.post(api.endpoints.auth.signup, { email });
    dispatch({ type: AUTH_APPSTATE_SIGNUP_SUCCESS });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({ type: AUTH_APPSTATE_SIGNUP_ERROR, error: error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// FORGOT PASSWORD =================================================================================
// =================================================================================================
export const forgotPassword = email => async dispatch => {
  dispatch({ type: AUTH_APPSTATE_FORGOT_LOADING });
  try {
    const res = await api.post(api.endpoints.auth.forgot_password, { email });
    dispatch({ type: AUTH_APPSTATE_FORGOT_SUCCESS });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({ type: AUTH_APPSTATE_FORGOT_ERROR, error: error });
    dispatch(showNotification(error, false));
  }
};

// =================================================================================================
// RESET PASSWORD ==================================================================================
// =================================================================================================
export const resetPassword = (
  email,
  password,
  token,
  history
) => async dispatch => {
  dispatch({ type: AUTH_APPSTATE_RESET_LOADING });
  try {
    const res = await api.post(api.endpoints.auth.reset, {
      email,
      password,
      token
    });
    dispatch({ type: USER_APPSTATE_AUTH_SUCCESS });
    history.replace("/dashboard");
    dispatch({ type: AUTH_APPSTATE_RESET_SUCCESS, data: res });
    dispatch(showNotification(res));
  } catch (error) {
    dispatch({ type: AUTH_APPSTATE_RESET_ERROR, error: error });
    dispatch(showNotification(error, false));
  }
};
