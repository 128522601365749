import { Formik, Form, FastField } from "formik";
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import DatesFormTables from "views/Forms/EazyForms/FormBasic/DatesFormTables.jsx";
import BasicFormFieldRedux from "views/Forms/EazyForms/FormBasic/BasicFormFieldRedux.jsx";

import QualificationEligibilty from "views/Forms/EazyForms/FormBasic/QualificationEligibilty.jsx";
import ReservationCreteria from "views/Forms/EazyForms/FormBasic/ReservationCreteria.jsx";
// import StreamCourseSelectorHandler from "views/Forms/EazyForms/FormBasic/StreamCourseSelectorHandler.jsx";
import CourseTreeSelector from "views/Forms/EazyForms/FormBasic/CourseTreeSelector.jsx";

import EligibilityAge from "views/Forms/EazyForms/FormBasic/EligibilityAge.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import BasicEntranceForms from "views/Forms/EazyForms/FormBasic/BasicEntranceForms.jsx";
import CheckFormikDirty from "views/Components/CheckFormikDirty.jsx";
import AddRankPrediction from "views/Forms/EazyForms/FormBasic/AddRankPrediction.jsx";

import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import CategoryInputFormTables from "views/Forms/EazyForms/FormBasic/CategoryInputFormTables.jsx";
import ExamPaperDetails from "views/Forms/EazyForms/FormBasic/ExamPaperDetails.jsx";
import Button from "components/CustomButtons/Button.jsx";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import GridItem from "components/Grid/GridItem.jsx";
import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import initialValues from "./basicFields";
import { connect } from "react-redux";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";
import { getAllElements } from "data/elements/actions/dataActions";
import {
  getFormBasicById,
  upsertFormBasicById
} from "data/forms/actions/dataActions";
import Tooltip from "@material-ui/core/Tooltip";

class FormBasic extends Component {
  state = {
    showDeleteAlert: false,
    validationError: false,
    checkUnique: false
  };

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.props.getAllElements();
      this.props.getFormBasicById(id);
    } else this.props.getAllElements();
  }

  submitBasicForm = data => {
    const { id } = this.props;
    if (!id) {
      if (
        data.full_name.length > 0 &&
        data.short_name.length > 0 &&
        this.state.checkUnique
      ) {
        if (data.is_apply_now) {
          if (
            data.apply_now_url.length > 0 &&
            data.apply_now_description.length > 0
          ) {
            this.props.upsertFormBasicById(id, data);
          }
        } else if (!data.is_apply_now) {
          this.props.upsertFormBasicById(id, data);
        }
      }
    } else {
      if (data.full_name.length > 0) {
        if (data.is_apply_now) {
          if (
            data.apply_now_url.length > 0 &&
            data.apply_now_description.length > 0
          ) {
            this.props.upsertFormBasicById(id, data);
          }
        } else if (!data.is_apply_now) {
          this.props.upsertFormBasicById(id, data);
        }
      }
    }
  };

  handleAlertSuccess = () => {
    this.setState({ showDeleteAlert: false });
    this.props.history.push("/form-listing");
  };

  showDeleteAlertHandler = isDirty => {
    if (this.state.showDeleteAlert && isDirty) {
      return (
        <DeleteAlertBox
          handleSuccess={this.handleAlertSuccess}
          handleClose={() => this.setState({ showDeleteAlert: false })}
          title="Leave Editor ?"
        >
          Changes that you made may not be saved.
        </DeleteAlertBox>
      );
    } else if (this.state.showDeleteAlert) {
      this.props.history.push("/form-listing");
      return null;
    }
  };

  render() {
    const { classes, elements, forms, id, location } = this.props;
    if (
      forms.appStateBasic.loading ||
      elements.appState.loading ||
      elements.data.length === 0
    ) {
      return <Spinner />;
    }
    if (forms.appStateBasic.navigateBack) {
      this.props.history.push("/form-listing");
    }
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(
            elements.data,
            forms.dataBasic,
            id,
            location.state,
            forms.appStateBasic.checkNewFormData
          )}
          isValidating={false}
          onSubmit={values => {
            this.submitBasicForm(values.form_basic);
            return forms.appStateBasic.navigateBack;
          }}
        >
          {({
            values,
            setFieldValue,
            touched,
            setFieldTouched,
            handleSubmit,
            dirty
          }) => (
            <Form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12}>
                  <Button
                    size="sm"
                    title="Go Back"
                    onClick={() => this.setState({ showDeleteAlert: true })}
                    className={classes.customBackButton}
                  >
                    <KeyboardArrowLeft />
                    BACK
                  </Button>
                </GridItem>
              </GridContainer>
              {this.state.validationError &&
              !values.form_basic.full_name.length > 0 ? (
                <div style={{ color: "red" }}>*Full Name is Missing</div>
              ) : null}
              {this.state.validationError &&
              !values.form_basic.short_name.length > 0 ? (
                <div style={{ color: "red" }}>*Short Name is Missing</div>
              ) : null}
              {this.state.validationError &&
              values.form_basic.is_apply_now &&
              !values.form_basic.apply_now_url.length > 0 ? (
                <div style={{ color: "red" }}>*Apply Now URL is Missing</div>
              ) : null}
              {this.state.validationError &&
              values.form_basic.is_apply_now &&
              !values.form_basic.apply_now_description.length > 0 ? (
                <div style={{ color: "red" }}>
                  *Apply Now Description is Missing
                </div>
              ) : null}
              <FastField
                component={BasicEntranceForms}
                setFieldValue={setFieldValue}
                values={values}
                touched={touched}
                setFieldTouched={setFieldTouched}
                validationError={this.state.validationError}
                id={id}
                checkShortNameUnique={checkUnique => {
                  this.setState({ checkUnique });
                }}
              />
              <FastField
                component={BasicFormFieldRedux}
                setFieldValue={setFieldValue}
                values={values}
              />
              <br />
              <FastField
                component={CourseTreeSelector}
                setFieldValue={setFieldValue}
                values={values}
              />
              <br />
              <FastField
                component={CategoryInputFormTables}
                name="form_basic.application_fee"
                values={values}
                setFieldValue={setFieldValue}
              />
              <br />
              <FastField
                name="form_basic.eligibility_age"
                component={EligibilityAge}
                values={values}
                setFieldValue={setFieldValue}
              />
              <br />
              <FastField
                name="form_basic.reservation_creteria"
                component={ReservationCreteria}
                values={values}
                setFieldValue={setFieldValue}
              />
              <br />
              <QualificationEligibilty
                name="form_basic.educationEligibility"
                values={values}
                setFieldValue={setFieldValue}
              />
              <br />
              <FastField
                component={ExamPaperDetails}
                values={values.form_basic.exam_paper_details}
                setFieldValue={setFieldValue}
              />
              <br />
              <FastField
                component={AddRankPrediction}
                valuesData={values.form_basic.rank_prediction}
                setFieldValue={setFieldValue}
              />
              <br />
              <FastField
                component={DatesFormTables}
                values={values}
                setFieldValue={setFieldValue}
              />
              {!this.state.showDeleteAlert ? (
                <CheckFormikDirty isDirty={dirty} />
              ) : null}
              {this.showDeleteAlertHandler(dirty)}
              <GridContainer justify="flex-end">
                <GridItem>
                  <Tooltip
                    title="Save"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="success"
                      variant="fab"
                      size="lg"
                      type="submit"
                      justIcon
                      round
                      className={classes.fab}
                      onClick={() => {
                        this.setState({ validationError: true });
                      }}
                    >
                      {/* <i className="material-icons">send</i> */}
                      <i className="fas fa-save" />
                    </Button>
                  </Tooltip>
                </GridItem>
              </GridContainer>
              {process.env.NODE_ENV !== "production" ? (
                <pre>{JSON.stringify(values.form_basic, null, 2)}</pre>
              ) : null}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements, forms }) {
  return { elements, forms };
}

export default withStyles(formStyle)(
  connect(mapStateToProps, {
    getAllElements,
    getFormBasicById,
    upsertFormBasicById
  })(FormBasic)
);
