import React, { Component } from "react";
import { connect } from "react-redux";
import HandlingSubjectAndQuestionValues from "views/Forms/EazyForms/FormBasic/HandlingSubjectAndQuestionValues.jsx";
import _ from "lodash";

class KeyBasedTypeSelector extends Component {
  getQuestionPaperSelectors = elements => {
    const { keyFilter } = this.props;
    const questionArray = elements.filter(item => item.key === keyFilter)[0];
    return this.handleSelectorValue(questionArray);
  };

  handleSelectorValue = selectorArray => {
    const { setFieldValue, values, nameKey } = this.props;
    if (_.isEmpty(selectorArray.data)) {
      return null;
    } else {
      return (
        <HandlingSubjectAndQuestionValues
          valuesItem={selectorArray}
          setFieldValue={setFieldValue}
          values={values}
          nameKey={nameKey}
        />
      );
    }
  };

  render() {
    const { elements } = this.props;
    return (
      <React.Fragment>
        <div>{this.getQuestionPaperSelectors(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(KeyBasedTypeSelector);
