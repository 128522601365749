import {
  AUTH_APPSTATE_FORGOT_LOADING,
  AUTH_APPSTATE_FORGOT_SUCCESS,
  AUTH_APPSTATE_FORGOT_ERROR
} from "data/auth/types";

const initialState = {
  loading: false,
  error: null
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_APPSTATE_FORGOT_LOADING:
      return { ...state, loading: true };
    case AUTH_APPSTATE_FORGOT_SUCCESS:
      return { ...state, error: null, loading: false };
    case AUTH_APPSTATE_FORGOT_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
