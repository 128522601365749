import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import { FastField } from "formik";
import BareTextFieldInput from "views/Components/BareTextFieldInput";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";

class BranchDisplay extends Component {
  render() {
    const {
      setFieldValue,
      streamIndex,
      courseIndex,
      branchIndex,
      branchData
    } = this.props;
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontWeight: "bold", margin: "0px" }}>
            Is this Branch a part of CAF ?
          </p>
          <FastField
            name={`streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].is_caf_applicable`}
            component={FormikToggleButton}
            onBlur={checkedValue => {
              setFieldValue(
                `streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].is_caf_applicable`,
                !checkedValue
              );
            }}
            value={
              branchData.is_caf_applicable === undefined
                ? true
                : branchData.is_caf_applicable
            }
          />
        </GridItem>
        <GridItem xs={6} md={3}>
          <br />
          <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Seats</p>
          <FastField
            type="number"
            min={0}
            component={BareTextFieldInput}
            name={`streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].seats`}
            onBlur={value => {
              setFieldValue(
                `streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].seats`,
                parseInt(value.target.value)
              );
            }}
            defaultValue={branchData.seats || ""}
          />
        </GridItem>
        <GridItem xs={6}>
          <br />
          <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Speciality</p>
          <FastField
            component={BareTextFieldInput}
            name={`streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].speciality`}
            onBlur={value => {
              setFieldValue(
                `streams[${streamIndex}].courses[${courseIndex}].branches[${branchIndex}].speciality`,
                value.target.value
              );
            }}
            defaultValue={branchData.speciality || ""}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(formStyle)(BranchDisplay);
