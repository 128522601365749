import React, { Component } from "react";
import DropDownSelectorOnChange from "views/Components/DropDownSelectorOnChange.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
class HandlingCollegeSelector extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.data.map(item => {
      const { id, full_name, short_name } = item;
      const updated_short_name = short_name ? `(${short_name})` : "";
      return {
        id,
        value: full_name,
        label: `${full_name}${updated_short_name}`
      };
    });
    return newValue;
  };
  selectorHandler = () => {
    const {
      setFieldValue,
      valuesData,
      elementValues,
      isMulti,
      nameKey,
      label,
      isRequired
    } = this.props;
    if (!elementValues) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <p style={{ fontWeight: "bold" }}>
            {label ? label : elementValues.name}
            {isRequired ? <span style={{ color: "red" }}>*</span> : null}
          </p>
          <FastField
            component={DropDownSelectorOnChange}
            name={`${nameKey}`}
            data={this.arrayModified(elementValues)}
            isMulti={isMulti}
            onChange={value => {
              setFieldValue(`${nameKey}`, value);
            }}
            selectedValues={valuesData}
          />
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.selectorHandler()}
        <br />
      </React.Fragment>
    );
  }
}

export default HandlingCollegeSelector;
