import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import _ from "lodash";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class AttachmentItem extends React.Component {
  render() {
    const {
      classes,
      item: { id, image }
    } = this.props;

    let itemURL = _.get(image, "url", "");
    if (itemURL) {
      itemURL = image.display_url;
    }
    return (
      <div>
        <Card product className={classes.cardHover}>
          <CardHeader image>
            <img
              src={itemURL}
              alt={image.alt_text}
              style={{ height: "265px" }}
            />
          </CardHeader>
          <CardBody>
            <br />
            <h4>{image.title}</h4>
          </CardBody>
          <CardFooter>
            <div
              style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
              <Button
                color="danger"
                simple
                onClick={() => this.props.onDelete(id, image)}>
                Delete
              </Button>
              <Button
                color="info"
                default
                onClick={() => {
                  this.props.onEdit(id, image);
                }}>
                Edit
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

AttachmentItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(AttachmentItem);
