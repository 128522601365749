import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    marginTop: "20px",
    height: "40px"
  }
});

const LocationSearchInput = props => {
  const {
    searchedResults,
    clearAddressResult,
    setLatAndLng,
    selectedAddress
  } = props;

  const [address, setAddress] = useState(selectedAddress);

  useEffect(() => {
    setAddress(selectedAddress);
  }, [selectedAddress]);

  const handleChange = address => {
    setAddress(address);
    // if (address.length === 0) {
    //   clearAddressResult();
    // }
  };

  const handleSelect = newAddress => {
    if (address !== newAddress) {
      setAddress(newAddress);
      geocodeByAddress(newAddress)
        .then(results => {
          // // this.setState({ address: results[0].formatted_address });
          clearAddressResult();
          searchedResults(results[0], newAddress);
          return getLatLng(results[0]);
        })
        .then(latLng => setLatAndLng(latLng))
        .catch(error => console.error("Error", error));
    }
  };
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <GridContainer>
          <GridItem xs={12}>
            <p
              style={{
                fontWeight: "bold",
                color: "#404040",
                marginBottom: "12px"
              }}
            >
              Search By Address
              <span style={{ color: "red" }}>*</span>
            </p>
            <TextField
              fullWidth
              variant="outlined"
              style={{ height: "40px" }}
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input"
              })}
              // onKeyDown={e => (e.keyCode === 13 ? alert("hi") : null)}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "rgba(225,235,254,1)",
                      fontSize: "14px",
                      padding: "10px",
                      cursor: "pointer",
                      zIndex: 2
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      fontSize: "14px",
                      padding: "10px",
                      zIndex: 2,
                      overflow: "auto",
                      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)"
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </GridItem>
        </GridContainer>
      )}
    </PlacesAutocomplete>
  );
};

export default withStyles(styles)(LocationSearchInput);
