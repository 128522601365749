import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserAuth } from "data/userProfile/actions/dataActions";
import { hideNotification } from "data/main/actions/uiActions";
import { Route, Redirect } from "react-router-dom";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import MainNotification from "views/MainNotification.jsx";
import _ from "lodash";

class PrivateRoute extends Component {
  componentDidMount() {
    //make api only if the authenticated value is null. Else come to the conclusion.
    if (
      this.props.user.appState.isAuthenticated === null ||
      !_.isEmpty(this.props.user.data)
    ) {
      this.props.getUserAuth();
    }
  }

  // =================================================================================================
  // NOTIFICATION HANDLING ===========================================================================
  // =================================================================================================
  handleNotification = () => {
    if (this.props.main.ui.showNotification) {
      return (
        <MainNotification
          message={this.props.main.ui.message}
          color={this.props.main.ui.messageType}
          onClose={this.props.hideNotification}
        />
      );
    }
    return null;
  };

  render() {
    const { loading, isAuthenticated } = this.props.user.appState;
    const { component: Component, isAuthScreen, ...rest } = this.props;
    if (loading) {
      return <CustomLinearProgress color="primary" />;
    }
    return (
      <div>
        {this.handleNotification()}
        <Route
          {...rest}
          render={props => {
            if (isAuthScreen) {
              return isAuthenticated ? (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: this.props.location }
                  }}
                />
              ) : (
                <Component {...props} />
              );
            } else {
              return isAuthenticated ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth/login",
                    state: { from: this.props.location }
                  }}
                />
              );
            }
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, main }) {
  return { user, main };
}

export default connect(mapStateToProps, { getUserAuth, hideNotification })(
  PrivateRoute
);
