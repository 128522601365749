import React, { Component } from "react";
import HandlingSelectorsValues from "views/Forms/EazyForms/FormBasic/HandlingSelectorsValues.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import _ from "lodash";
import { connect } from "react-redux";

class BasicFormFieldRedux extends Component {
  getFormSelectors = elements => {
    var formArray = [];
    elements.forEach(element => {
      switch (element.key) {
        case "exam_modes": {
          const data = _.cloneDeep(element);
          formArray.push(data);
          break;
        }
        case "school_boards": {
          const data = _.cloneDeep(element);
          data.key = "boards_allowed";
          formArray.push(data);
          break;
        }
        case "exam_conducting_bodies": {
          const data = _.cloneDeep(element);
          data.key = "exam_conducting_body_id";
          formArray.push(data);
          break;
        }
        case "languages": {
          const data = _.cloneDeep(element);
          data.key = "question_paper_mediums";
          formArray.push(data);
          break;
        }
        default:
          break;
      }
    });

    return formArray;
  };

  handleFormValuesRedux = () => {
    const { setFieldValue, values } = this.props;
    const myForm = this.getFormSelectors(this.props.elements.data);
    return myForm.map((item, index) => {
      // if (!item) {
      //   return;
      // }
      function getCell(itemType) {
        switch (itemType) {
          case "short_full":
            return (
              <GridItem xs={12} md={6} key={index}>
                <HandlingSelectorsValues
                  valuesItem={item}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </GridItem>
            );
          default:
            return null;
        }
      }
      return getCell(item.type);
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>{`Selectors`}</h4>
            </CardText>
          </CardHeader>

          <CardBody>
            <GridContainer>{this.handleFormValuesRedux()}</GridContainer>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}
export default withStyles(tableFormStyles)(
  connect(mapStateToProps)(BasicFormFieldRedux)
);
