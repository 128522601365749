// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import * as actions from "data/elements/actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BranchCell from "./BranchCell";

const tableHead = [
  "#",
  "Short Name",
  "Full Name",
  "Education Level",
  "Duration (in Years)",
  "Actions"
];

class StreamCell extends Component {
  state = {
    page: 0,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: +event.target.value });
  };

  /**
   * @prop(index) Index of the row
   * This would create the actions layout based on the row type.
   */
  handleActionsLayout = index => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableCell className={classes.tableCell}>
          <div>
            <Button
              onClick={() => this.editRow(index)}
              className={classes.actionButton}
              color="info"
              size="lg"
              title="Edit">
              <Edit className={classes.icon} />
            </Button>
            <Button
              onClick={() => this.deleteRow(index)}
              className={classes.actionButton}
              color="danger"
              size="lg"
              title="Delete">
              <Delete className={classes.icon} />
            </Button>
          </div>
        </TableCell>
      </React.Fragment>
    );
  };

  editRow = index => {
    const { elementIndex, streamElementIndex, streamIndex } = this.props;
    this.props.showStreamDataModal(
      elementIndex,
      streamIndex,
      streamElementIndex,
      index,
      true
    );
  };
  deleteRow = index => {
    const { elementIndex, streamElementIndex, streamIndex } = this.props;
    this.props.showStreamDeleteBox(
      elementIndex,
      streamIndex,
      streamElementIndex,
      index
    );
  };

  /**
   * Create a row with empty values
   */
  addRowButtonAction = () => {
    const { elementIndex, streamElementIndex, streamIndex } = this.props;
    this.props.showStreamDataModal(
      elementIndex,
      streamIndex,
      streamElementIndex,
      null,
      false
    );
  };

  getEducationLevel = education_level_id => {
    const { education_levels } = this.props;
    const education_level = _.get(education_levels, "data");
    if (education_level) {
      const data = education_level.filter(
        level => level.id === education_level_id
      )[0];
      return data ? data.full_name : "-";
    } else {
      return "Not Found";
    }
  };

  render() {
    const { classes, streamCell, name } = this.props;
    const { rowsPerPage, page } = this.state;
    return (
      <div>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{name}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHead className={classes["primary"]}>
                    <TableRow className={classes.tableRow}>
                      {tableHead.map((prop, key) => {
                        {
                          const tableCellClasses =
                            classes.tableHeadCell + " " + classes.tableCell;
                          return (
                            <TableCell
                              key={key}
                              className={tableCellClasses}
                              style={{ fontWeight: "bold" }}>
                              {prop}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {streamCell ? (
                      streamCell
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => {
                          return (
                            <React.Fragment
                              key={`stream_cell_${index}_${page}`}>
                              <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                  {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <h4>{item.short_name}</h4>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <h4>{item.full_name}</h4>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <h4>
                                    {this.getEducationLevel(
                                      item.education_level_id
                                    )}
                                  </h4>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <h4>{item.duration ? item.duration : "-"}</h4>
                                </TableCell>
                                {this.handleActionsLayout(
                                  page * rowsPerPage + index
                                )}
                              </TableRow>
                              <TableRow key={index * 100 * page}>
                                <TableCell colSpan={12}>
                                  <ExpansionPanel
                                    CollapseProps={{ unmountOnExit: true }}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}>
                                      All Branches
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <BranchCell
                                        elementIndex={this.props.elementIndex}
                                        streamIndex={this.props.streamIndex}
                                        streamElementIndex={
                                          this.props.streamElementIndex
                                        }
                                        courseIndex={page * rowsPerPage + index}
                                      />
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })
                    ) : (
                      <React.Fragment />
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={streamCell ? streamCell.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      title="Add"
                      type="button"
                      onClick={this.addRowButtonAction}>
                      Add Row
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

StreamCell.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  elementIndex: PropTypes.number
};

function mapStateToProps({ elements }, ownProps) {
  const elementIndex = ownProps.elementIndex;
  const streamIndex = ownProps.streamIndex;
  const streamElementIndex = ownProps.streamElementIndex;

  const streams = elements.data[elementIndex];
  const stream = streams.data[streamIndex];
  const streamElement = stream.data[streamElementIndex];
  const streamCell = streamElement.data;
  const name = streamElement.name;
  const path = streamElement.path;

  const education_levels = elements.data.filter(
    item => item.key === "education_levels"
  )[0];

  return {
    streams,
    stream,
    streamCell,
    streamElement,
    name,
    path,
    education_levels
  };
}

export default withStyles(tableFormStyles)(
  connect(
    mapStateToProps,
    actions
  )(StreamCell)
);
