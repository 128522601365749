import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange";
import InputBoxOnChange from "views/Components/InputBoxOnChange";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import FormikCompleteEditor from "views/Forms/EazyForms/FormDetail/FormikCompleteEditor.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormikRemoveButton from "views/Forms/EazyForms/FormDetail/FormikRemoveButton.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { showNotification } from "data/main/actions";
import api from "data/utils/api";
import _ from "lodash";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";

const CollegeDetailTab = props => {
  const {
    collegeTabData: tabData,
    tabDataStatus,
    tabDataChangedHandler,
    classes,
    id,
    fetchCollegeDetails,
    displaySlug
  } = props;

  // const { id } = props.match.params;

  const [is_active, setIsActive] = useState(tabData.is_active);
  const [full_name, setFullName] = useState(tabData.full_name);
  const [title, setTitle] = useState(tabData.title);
  const [description, setDescription] = useState(tabData.description);
  const [slug, setSlug] = useState(tabData.slug);
  const [meta_title, setMetaTitle] = useState(tabData.meta_title);
  const [meta_slug, setMetaSlug] = useState(tabData.meta_slug);
  const [meta_description, setMetaDescription] = useState(
    tabData.meta_description
  );
  const [additional_information, setAdditionalInformation] = useState(
    tabData.additional_information || []
  );
  const [slug_changed, setSlugChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setInitialData(tabData);
  // }, []);

  // const setInitialData = tabData => {
  //   setIsActive(tabData.is_active);
  //   setFullName(tabData.full_name);
  //   setTitle(tabData.title);
  //   setDescription(tabData.description);
  //   setSlug(tabData.slug);
  //   setMetaTitle(tabData.meta_title);
  //   setMetaSlugKey(tabData.meta_slug);
  //   setMetaDescription(tabData.meta_description);
  //   setAdditionalInformation(tabData.additional_information);
  // };

  const printCurrentState = () => {
    const printObject = {
      is_active,
      full_name,
      title,
      description,
      slug,
      meta_title,
      meta_slug,
      meta_description,
      additional_information,
      slug_changed,
      displaySlug,
      isLoading
    };

    return <pre>{JSON.stringify(printObject, null, 2)}</pre>;
  };

  const checkTabStatusCallback = key => {
    if (tabDataStatus) {
      tabDataChangedHandler(false);
    }
  };

  const showInformationEditors = () => {
    const editors = additional_information.map((item, index) => (
      <Card>
        <GridContainer justify="space-between">
          <GridItem>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>
                  {`Additional Editor ${index + 1}`}{" "}
                </h4>
              </CardText>
            </CardHeader>
          </GridItem>
          <GridItem>
            <GridContainer justify="flex-end">
              <GridItem>
                <FormikToggleButton
                  name={item.is_active}
                  onBlur={checkedValue => {
                    informationDataHandler(index, "is_active", !checkedValue);
                    checkTabStatusCallback("Additional is_active");
                  }}
                  value={item.is_active}
                />
                <FormikRemoveButton
                  onClickRemove={() => handleInformationRemove(index)}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <CardBody>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={12} md={6}>
              <InputBoxOnChange
                name={item.title}
                labelText="Additional Editor Title"
                onBlur={e => {
                  informationDataHandler(index, "title", e.target.value);
                  checkTabStatusCallback("Additional Title");
                }}
                valueInput={item.title}
                shrink={item.title ? true : false}
              />
              <br />
            </GridItem>
            <GridItem xs={12}>
              <FormikCompleteEditor
                onBlur={value => {
                  informationDataHandler(index, "description", value);
                  checkTabStatusCallback("Additional Editor");
                }}
                name={item.description}
                valueInput={item.description}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    ));
    return editors;
  };

  const informationDataHandler = (index, key, value) => {
    let updatedData = JSON.parse(JSON.stringify(additional_information));

    updatedData[index][key] = value;
    setAdditionalInformation(updatedData);
  };

  const handleInformationRemove = index => {
    let updatedData = JSON.parse(JSON.stringify(additional_information));
    updatedData.splice(index, 1);
    setAdditionalInformation(updatedData);
  };

  const handleDataInformation = () => {
    let clonedArray = JSON.parse(JSON.stringify(additional_information));
    clonedArray.push({
      type: "editor",
      is_active: false,
      title: "",
      description: ""
    });
    setAdditionalInformation(clonedArray);
  };

  const submitCollegeDetailTab = async () => {
    setIsLoading(true);
    const detailData = {
      slug_changed,
      is_active,
      tab_id: tabData.tab_id,
      tab_name: tabData.tab_name,
      full_name,
      slug,
      title,
      description,
      meta_title,
      meta_slug,
      meta_description,
      additional_information
    };

    try {
      await api.post(
        `${api.endpoints.colleges}/${id}/detail/${tabData.tab_id}`,
        { data: detailData }
      );
      setIsLoading(false);
      props.showNotification("Tab Data is Updated Successfully");
      // history.push("/college-listing");
      tabDataChangedHandler(true);
      fetchCollegeDetails();
    } catch (error) {
      setIsLoading(false);
      props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
      // setInitialData(detailData);
    }
  };

  const removeHeaderSpacesFromSlug = value => {
    if (value.trim().length) {
      const data = value
        .trim()
        .replace(/\s+/g, "-")
        .replace("--", "-")
        .toLowerCase();
      if (data[0] === "-") {
        return data.subString(1, data.length);
      } else return data;
    } else return value.trim().toLowerCase();
  };

  const removeSpacesFromSlug = value => {
    if (value.trim().length) {
      return value
        .replace(/\s+/g, "-")
        .replace("--", "-")
        .toLowerCase();
    } else return value.trim().toLowerCase();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <GridContainer justify="flex-end">
        <GridItem>
          <Card>
            <CardBody>
              {is_active ? (
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#4CAF50"
                  }}
                >
                  Published
                </label>
              ) : (
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#F44336"
                  }}
                >
                  Published
                </label>
              )}
              <FormikToggleButton
                name="is_active"
                onBlur={checkedValue => {
                  setIsActive(!checkedValue);
                  checkTabStatusCallback("is_active");
                }}
                value={is_active}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>{tabData.tab_name}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer justify="space-between">
              <GridItem xs={8} sm={6} md={6}>
                <InputBoxOnChange
                  name="full_name"
                  labelText="Tab Display Name"
                  onBlur={e => {
                    setFullName(e.target.value);
                    checkTabStatusCallback("full_name");
                  }}
                  valueInput={full_name}
                  shrink={full_name ? true : false}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Main Section</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={12} md={5}>
              <InputBoxOnChange
                name="title"
                labelText="Title"
                onBlur={e => {
                  setTitle(e.target.value);
                  setMetaTitle(e.target.value);
                  checkTabStatusCallback("title");
                  if (!slug_changed) {
                    setSlug(removeHeaderSpacesFromSlug(e.target.value));
                    setMetaSlug(removeHeaderSpacesFromSlug(e.target.value));
                  }
                }}
                shrink={title ? true : false}
                valueInput={title}
              />
            </GridItem>
            <GridItem xs={12}>
              <label>{`${displaySlug}/${slug}`}</label>
            </GridItem>
            <GridItem xs={12} md={4}>
              <BareTextFieldInputOnChange
                name="Slug"
                fullWidth
                placeholder="Enter Slug Key"
                onBlur={e => {
                  setSlug(removeSpacesFromSlug(e.target.value));
                  checkTabStatusCallback("slug key");
                  setSlugChanged(true);
                }}
                value={slug}
              />
            </GridItem>
            <GridItem xs={12}>
              <br />
              <FormikCompleteEditor
                onBlur={value => {
                  setDescription(value);
                  if (value !== description) {
                    checkTabStatusCallback("Complete Editor");
                  }
                }}
                name="description"
                valueInput={description}
              />
              {/* <FormikCompleteEditor
                onBlur={value => {
                  informationDataHandler(index, "description", value);
                  checkTabStatusCallback("Additional Editor");
                }}
                name={item.description}
                valueInput={item.description}
              /> */}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {/* ===========================================================================================
          =====================================  Meta Header sections ================================
          ============================================================================================*/}
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Meta Section</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={12} md={6}>
              <InputBoxOnChange
                name="meta_title"
                labelText="Meta Title Name"
                onBlur={e => {
                  setMetaTitle(e.target.value);
                  checkTabStatusCallback("meta_title");
                }}
                valueInput={meta_title}
                shrink={meta_title ? true : false}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-start">
            <GridItem xs={12}>
              <label>{`${displaySlug}/${meta_slug}`}</label>
            </GridItem>
            <GridItem xs={12} md={4}>
              <BareTextFieldInputOnChange
                name="Meta Slug"
                fullWidth
                placeholder=" Enter Meta Slug Key"
                onBlur={e => {
                  setMetaSlug(removeSpacesFromSlug(e.target.value));
                  checkTabStatusCallback("meta_slug");
                  setSlugChanged(true);
                }}
                value={meta_slug}
              />
            </GridItem>
            {/* <GridItem>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button
                    title="Pull Data from Main Section"
                    color="tumblr"
                    size="sm"
                    className={classes.marginRight}
                    onClick={() => {}}>
                    <i className="fas fa-download" /> AutoFill
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem> */}
            <GridItem xs={12}>
              <br />
              <FormikCompleteEditor
                onBlur={value => {
                  setMetaDescription(value);
                  if (value !== meta_description) {
                    checkTabStatusCallback("Complete Editor");
                  }
                }}
                name="meta_description"
                // CharsLimit={150}
                valueInput={meta_description}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <br />
      {/* ===========================================================================================
          =====================================  additional_Information sections ================================
          ===============================================================================================*/}
      {additional_information.length > 0 ? showInformationEditors() : null}
      <GridContainer justify="flex-end">
        <GridItem>
          <Button
            title="Add Editor"
            color="tumblr"
            onClick={() => handleDataInformation()}
          >
            Add Editor
          </Button>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer justify="flex-end">
        <GridItem>
          <Tooltip
            title="Save"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              color="success"
              variant="fab"
              size="lg"
              onClick={() => submitCollegeDetailTab()}
              className={classes.fab}
            >
              <i className="fas fa-save" />
            </Button>
          </Tooltip>
        </GridItem>
      </GridContainer>
      {process.env.NODE_ENV !== "production" ? printCurrentState() : null}
    </React.Fragment>
  );
};

export default withStyles(formStyle)(
  connect(null, { showNotification })(CollegeDetailTab)
);
