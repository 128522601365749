import CustomInput from "components/CustomInput/CustomInput.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

import React, { Component } from "react";

class InputBoxOnChange extends Component {
  render() {
    const { labelText, onBlur, valueInput, shrink } = this.props;

    return (
      <div>
        <CustomInput
          id="help-text"
          labelText={labelText}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => onBlur(event),
            value: valueInput
          }}
          // InputLabelProps={{ shrink: true }}
          shrink={shrink}
        />
      </div>
    );
  }
}

export default withStyles(regularFormsStyle)(InputBoxOnChange);
