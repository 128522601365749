import React, { Component } from "react";
import { getAllElements } from "data/elements/actions/dataActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import _get from "lodash/get";
import { connect } from "react-redux";
import api from "data/utils/api";
import { showNotification } from "data/main/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import TagsInput from "react-tagsinput";
import TextField from "@material-ui/core/TextField";
import Tasks from "components/Tasks/Tasks.jsx";
import Badge from "components/Badge/Badge.jsx";
import LoadingOverlay from "react-loading-overlay";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import FormikCompleteEditor from "views/Forms/EazyForms/FormDetail/FormikCompleteEditor.jsx";
import BareTextFieldInputOnChange from "../../Components/BareTextFieldInputOnChange";
import { Divider, Button } from "@material-ui/core";
import Can from "../../../utils/Can";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";
import moment from "moment";
import FAQComponent from "./FAQs/FAQComponent";

class GenericDetailSection extends Component {
  checkpoints = [
    {
      id: 1,
      value:
        "Unique Header - Headline must be unique with/without Dates including Focus Keyword",
      completed: false
    },
    {
      id: 2,
      value:
        "First Paragraph must contain brief and crispy information about article (containing short/full name, organizing body, level and purpose of exam with dates)",
      completed: false
    },
    {
      id: 3,
      value:
        "Include important dates in table (Date/Time format 01-02 January 2020)",
      completed: false
    },
    {
      id: 4,
      value:
        "Include Necessary PDF in google doc (syllabus & information brochure)",
      completed: false
    },
    {
      id: 5,
      value: "Include knowledge graph in tabular form",
      completed: false
    },
    {
      id: 6,
      value: "Include Bullets & Points in the article",
      completed: false
    },
    {
      id: 7,
      value: "Include alt tag in all images",
      completed: false
    },
    {
      id: 8,
      value: "Include Info-graphic",
      completed: false
    },
    {
      id: 9,
      value: "Include Charts",
      completed: false
    },
    {
      id: 10,
      value: "Include Video",
      completed: false
    },
    {
      id: 11,
      value: "Include keyword variations and verify keyword density",
      completed: false
    }
  ];

  state = {
    isLoading: true,
    isBackupContent: false,
    backupCount: 0,
    currentBackupPage: undefined,
    isSubmitting: false,
    isPublished: false,
    websiteURL: "",
    tabDisplayName: "",
    title: "",
    description: "",
    metaDescription: "",
    checkpoints: [],
    data: {},
    focusKeyword: "",
    keywordVariations: [],
    instructions: "",
    errors: {
      title: true,
      description: true,
      metaDescription: true,
      tabDisplayName: true
    }
  };

  handleSuggestionsTag = value => {
    this.setState({
      keywordVariations: value
    });
  };

  handleField = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDescription = value => {
    this.setState({ description: value });
  };

  getErrors = () => {
    const { title, description, metaDescription, tabDisplayName } = this.state;
    const errors = {};
    errors.title = title.length === 0;
    errors.description = description.length === 0;
    errors.metaDescription = metaDescription.length === 0;
    errors.tabDisplayName = tabDisplayName.length === 0;
    return errors;
  };

  getURL = () => {
    const { id, tabId } = this.props.match.params;
    const url = this.props.match.url;

    let endpoint = undefined;

    if (url.includes("college-listing")) {
      endpoint = api.endpoints.colleges;
    } else if (url.includes("form-listing")) {
      endpoint = api.endpoints.forms;
    }
    if (endpoint) {
      return `${endpoint}/${id}/detail/${tabId}`;
    }
    return undefined;
  };

  fetchDetails = async () => {
    // this.setState({ isLoading: true });
    try {
      const apiURL = this.getURL();
      const res = await api.get(apiURL);
      this.dataMapping(res.data);
    } catch (error) {
      this.setState({
        isLoading: false,
        error: "Something went wrong. Try refreshing the page"
      });
      this.props.showNotification(
        _get(
          error,
          "data.message",
          "Something Went Wrong. Try refreshing the page"
        ),
        false
      );
    }
  };

  dataMapping = (
    data,
    currentBackupPage = undefined,
    isBackupContent = false
  ) => {
    const {
      is_active,
      tab_display_name,
      title,
      url,
      slug,
      description,
      analysis,
      suggestions,
      validations,
      meta_description,
      full_name,
      short_name,
      panel_user,
      backup_count,
      faqs = []
    } = data;
    const displayName = `${tab_display_name} - ${full_name} [${short_name}]`;
    const baseURL =
      process.env.NODE_ENV === "production"
        ? "https://www.entrancezone.com"
        : "https://beta.entrancezone.com";
    const websiteURL = `${baseURL}${url}${
      slug.includes("about-exam") || slug.includes("about-college")
        ? ""
        : `/${slug}`
    }`;

    let focusKeyword = "";
    let keywordVariations = [];
    let instructions = "";
    if (suggestions) {
      focusKeyword = suggestions.focusKeyword || "";
      keywordVariations = suggestions.keywordVariations || [];
      instructions = suggestions.instructions || "";
    }
    let checkpoints;
    if (analysis && analysis.checkpoints && analysis.checkpoints.length) {
      checkpoints = analysis.checkpoints;
    } else {
      checkpoints = this.checkpoints;
    }
    this.setState({
      data,
      isPublished: is_active || false,
      tabDisplayName: tab_display_name || "",
      title: title || "",
      description: description || "",
      metaDescription: meta_description || "",
      checkpoints: checkpoints,
      isLoading: false,
      isSubmitting: false,
      displayName,
      websiteURL,
      focusKeyword,
      keywordVariations,
      instructions,
      panel_user,
      backupCount: backup_count || this.state.backupCount,
      currentBackupPage: currentBackupPage || backup_count,
      isBackupContent,
      faqs: faqs || []
    });
  };

  handlePublished = checkedValue => {
    this.setState({ isPublished: checkedValue });
  };

  handleEditorContent = content => {
    //Check if the content contains the keyword variations.
    const includedKeywordVariations = this.state.keywordVariations.filter(
      keyword => content.includes(keyword)
    );

    const excludedKeywordVariations = this.state.keywordVariations.filter(
      keyword => !includedKeywordVariations.includes(keyword)
    );

    //find the keyword density.
  };

  // do not delete this. This one is load the previous backup data.
  loadBackups = async pageNo => {
    const { backupCount } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await api.get(
        this.getURL() + `/backup?position=${pageNo}&total_count=${backupCount}`
      );
      this.dataMapping(response.data, pageNo, true);
      this.props.showNotification(response.message);
    } catch (error) {
      this.setState({
        isLoading: false,
        error: "Something went wrong. Try refreshing the page"
      });
      this.props.showNotification(
        _get(
          error,
          "data.message",
          "Something Went Wrong. Try refreshing the page"
        ),
        false
      );
    }
  };

  handleSubmit = async () => {
    //console.log("make api call here.")
    //Published should only be allowed after all the required fields are filled. This can be checked when the user is trying to save the content.

    try {
      const errors = this.getErrors();
      if (
        errors.title ||
        errors.description ||
        errors.metaDescription ||
        errors.tabDisplayName
      ) {
        throw { data: { message: "Mandatory Fields needs to be filled" } };
      }
      this.setState({ isSubmitting: true });
      const {
        data,
        isPublished,
        tabDisplayName,
        title,
        description,
        metaDescription,
        checkpoints,
        focusKeyword,
        keywordVariations,
        instructions
      } = this.state;

      const suggestions = {
        focusKeyword: focusKeyword.trim(),
        keywordVariations,
        instructions: instructions.trim()
      };
      const analysis = { checkpoints };
      const detailData = {
        is_active: isPublished,
        tab_display_name: tabDisplayName.trim(),
        slug: data.slug,
        title: title.trim(),
        description,
        meta_title: title.trim(),
        meta_description: metaDescription.trim(),
        analysis,
        suggestions
      };

      const response = await api.post(this.getURL(), {
        data: detailData
      });
      this.dataMapping(response.data);
      this.props.showNotification(response.message);
    } catch (error) {
      this.setState({ isSubmitting: false });
      this.props.showNotification(
        _get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  handleCheckedChange = newChecked => {
    if (!newChecked) return;
    const newCheckpoints = this.state.checkpoints.map((item, index) => {
      if (newChecked.includes(index)) {
        item.completed = true;
      } else if (item.completed) {
        item.completed = false;
      }
      return item;
    });
    this.setState({ checkpoints: newCheckpoints });
  };

  componentDidMount() {
    const { elements } = this.props;
    if (!elements.data.length) {
      this.props.getAllElements();
    }
    this.fetchDetails();
  }

  getNavPage = () => {
    const { backupCount, currentBackupPage } = this.state;
    const pages = [];
    if (currentBackupPage > 1) {
      pages.push({ text: "PREV" });
    }

    for (let i = 1; i <= backupCount; i++) {
      const newObject = {};
      newObject.text = i;
      if (i === currentBackupPage) {
        newObject.active = true;
      }
      pages.push(newObject);
    }

    if (currentBackupPage < backupCount) {
      pages.push({ text: "NEXT" });
    }

    return pages;
  };

  handleNavPageClick = page => {
    let { currentBackupPage, backupCount } = this.state;
    if (page === "PREV") {
      currentBackupPage = Math.max(currentBackupPage - 1, 1);
    } else if (page === "NEXT") {
      currentBackupPage = Math.min(currentBackupPage + 1, backupCount);
    } else {
      currentBackupPage = Math.max(Math.min(page, backupCount), 1);
    }
    this.loadBackups(currentBackupPage);
  };

  /*
  getBackUpLayout = () => (
    <React.Fragment>
      {this.state.backupCount && this.state.backupCount > 1 ? (
        <GridContainer justify="flex-end" alignItems="center">
          <GridItem>
            <Card>
              <CardHeader color="rose">
                <h4
                  className={this.props.classes.cardTitle}
                  style={{ textAlign: "center" }}>
                  {this.state.backupCount}{" "}
                  {`${this.state.backupCount > 1 ? "Backups" : "Backup"}`}{" "}
                  Available
                </h4>
              </CardHeader>
              <CardBody>
                <Paginations
                  pages={this.getNavPage()}
                  color="info"
                  onClick={this.handleNavPageClick}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : null}
    </React.Fragment>
  );
  */

  getTopRow = (isPublished, websiteURL, role) => (
    <GridContainer justify="space-between" alignItems="center">
      <GridItem>
        <Card>
          {this.state.data.is_active ? (
            <CardBody>
              {" "}
              <label
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginRight: "5px"
                }}>
                Website URL:
              </label>
              <a target="_blank" href={websiteURL} rel="no referrer">
                {websiteURL}
              </a>{" "}
            </CardBody>
          ) : null}
        </Card>
      </GridItem>
      <GridItem>
        <Card>
          <CardBody>
            {isPublished ? (
              <label
                style={{
                  fontWeight: "bold",
                  color: "#4CAF50"
                }}>
                Published
              </label>
            ) : (
              <label
                style={{
                  fontWeight: "bold",
                  color: "#F44336"
                }}>
                Not Published
              </label>
            )}
            <Can I="publish" a="PageDetail">
              <FormikToggleButton
                name="is_active"
                onBlur={checkedValue => {
                  this.handlePublished(!checkedValue);
                }}
                value={isPublished}
              />
            </Can>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  getAllInstructions = () => {
    const { focusKeyword, instructions, keywordVariations } = this.state;
    return (
      <div>
        {focusKeyword ? (
          <div style={{ marginBottom: "10px" }}>
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "4px",
                fontSize: "12px"
              }}>
              Focus Keywords
            </p>
            <p style={{ marginLeft: "4px", fontSize: "14px" }}>
              {focusKeyword}
            </p>
          </div>
        ) : null}

        {keywordVariations.length ? (
          <div style={{ marginBottom: "10px" }}>
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "4px",
                fontSize: "12px"
              }}>
              Keyword Variations
            </p>
            <p style={{ marginLeft: "4px" }}>
              {keywordVariations.map(item => (
                <span style={{ margin: "3px" }} key={item}>
                  <Badge color="info">{item}</Badge>
                </span>
              ))}
            </p>
          </div>
        ) : null}

        {instructions ? (
          <div style={{ marginBottom: "10px" }}>
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "4px",
                fontSize: "12px"
              }}>
              Instructions
            </p>
            <p style={{ marginLeft: "4px" }}>{instructions}</p>
          </div>
        ) : null}
      </div>
    );
  };

  getCheckpoints = () => {
    if (!this.state.checkpoints.length) {
      return null;
    }
    const checkedIndexes = this.state.checkpoints
      .filter(item => item.completed)
      .map(item => item.id - 1);
    const tasksIndexes = this.state.checkpoints.map(item => item.id - 1);
    const tasks = this.state.checkpoints.map(item => item.value);
    return (
      <Tasks
        checkedIndexes={checkedIndexes}
        tasksIndexes={tasksIndexes}
        tasks={tasks}
        onCheckedChange={this.handleCheckedChange}
      />
    );
  };

  getEditorSection = errors => {
    const { description, title } = this.state;
    const totalCheckpoints = this.state.checkpoints.length;
    const totalCheckpointsSelected = this.state.checkpoints.filter(
      item => item.completed
    ).length;
    return (
      <GridContainer>
        <GridItem xs={12} sm={8}>
          <GridContainer>
            <GridItem xs={12}>
              <br />
              <p style={{ fontWeight: "bold", margin: "0px" }}>
                Header <span style={{ color: "red" }}>*</span> (
                {this.state.title.length}
                /60)
              </p>
              <BareTextFieldInputOnChange
                placeholder="Enter Header"
                fullWidth
                name="title"
                onBlur={this.handleField}
                value={title}
                error={errors.title}
              />
            </GridItem>
            <GridItem xs={12}>
              <br />
              <p style={{ fontWeight: "bold", marginBottom: "12px" }}>
                Editor <span style={{ color: "red" }}>*</span>
              </p>
              <FormikCompleteEditor
                onBlur={value => {
                  this.handleDescription(value);
                }}
                name="description"
                valueInput={description}
              />
            </GridItem>
          </GridContainer>
          {this.state.panel_user && this.state.panel_user.created_by ? (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody style={{ backgroundColor: "#fffaea" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px"
                      }}>
                      Content Creator Info:
                    </p>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <p style={{ fontWeight: 400 }}>
                          Created by:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {this.state.panel_user.created_by}
                          </span>
                        </p>
                        <p style={{ fontWeight: 400 }}>
                          Created on:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {moment(this.state.panel_user.created_at).format(
                              "Do MMMM YYYY"
                            )}
                          </span>
                        </p>
                      </GridItem>
                      {this.state.panel_user.updated_by ? (
                        <GridItem xs={12} sm={6}>
                          <p style={{ fontWeight: 400 }}>
                            Last Updated by:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {this.state.panel_user.updated_by}
                            </span>
                          </p>
                          <p style={{ fontWeight: 400 }}>
                            Last Updated on:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {moment(
                                this.state.panel_user.updated_at
                              ).fromNow()}
                            </span>
                          </p>
                        </GridItem>
                      ) : null}
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          ) : null}
        </GridItem>
        <GridItem xs={12} sm={4}>
          <br />
          <Card>
            <CardBody style={{ backgroundColor: "#f5f7fa" }}>
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "18px"
                }}>
                Suggestions
              </p>
              <br />
              <div style={{ paddingLeft: 0 }}>{this.getAllInstructions()}</div>
              <br />
              <Divider />
              <br />
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "18px"
                }}>
                Checkpoints -{" "}
                {`${totalCheckpointsSelected}/${totalCheckpoints}`} completed
              </p>
              <br />
              <div style={{ paddingLeft: 0 }}>{this.getCheckpoints()}</div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  getMetaSection = (title, errors) => (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={this.props.classes.cardTitle}>Meta Section</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <label
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    marginRight: "5px"
                  }}>
                  Meta Title:
                </label>
                <span>{title}</span>
                <br />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12}>
                <p style={{ fontWeight: "bold", margin: "0px" }}>
                  Meta Description <span style={{ color: "red" }}>*</span> (
                  {this.state.metaDescription.length}
                  /160)
                </p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Meta Description in here"
                  multiline={true}
                  rows={2}
                  rowsMax={4}
                  onChange={this.handleField}
                  name="metaDescription"
                  value={this.state.metaDescription}
                  style={{ marginTop: "12px" }}
                  error={errors.metaDescription}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  getFAQSection = (faqs, url) => (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={this.props.classes.cardTitle}>FAQ Section</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <FAQComponent faqs={faqs} url={url} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  getEditorSuggestions = () => (
    <Card>
      <CardHeader color="rose" text>
        <CardText color="primary">
          <h4 className={this.props.classes.cardTitle}>
            Suggestions (Only for Editors)
          </h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <p style={{ fontWeight: "bold", margin: "0px" }}>Focus Keyword</p>
            <BareTextFieldInputOnChange
              placeholder="Enter Name to be displayed on the tab"
              fullWidth
              name="focusKeyword"
              onBlur={this.handleField}
              value={this.state.focusKeyword}
            />
          </GridItem>
          <GridItem xs={6}>
            <p style={{ fontWeight: "bold", margin: "0px" }}>
              Keyword Variations
            </p>
            <TagsInput
              name="keywordVariations"
              value={this.state.keywordVariations}
              onChange={this.handleSuggestionsTag}
              tagProps={{ className: "react-tagsinput-tag info" }}
            />
          </GridItem>
          <GridItem xs={12}>
            <br />
            <p style={{ fontWeight: "bold", margin: "0px" }}>Instructions</p>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter Instructions in here"
              multiline={true}
              rows={2}
              rowsMax={4}
              value={this.state.instructions}
              name="instructions"
              onChange={this.handleField}
              style={{ marginTop: "12px" }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  getJSONData = () => (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody style={{ backgroundColor: "#f5f7fa" }}>
            <pre>{JSON.stringify(this.state, null, 4)}</pre>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  getFAQsURL = () => {
    return this.getURL() + "/faqs";
  };

  render() {
    const { classes, user } = this.props;
    const role = user.data ? user.data.role : "author";
    const faqsURL = this.getFAQsURL();
    const errors = this.getErrors();
    const {
      displayName,
      isLoading,
      error,
      title,
      isPublished,
      websiteURL,
      isSubmitting,
      faqs
    } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    if (error) {
      return <p>{error}</p>;
    }
    return (
      <LoadingOverlay
        active={isSubmitting}
        spinner
        text="Submitting Detail Data">
        <div>
          <GridContainer>
            <GridItem>
              <Button
                size="small"
                title="Go Back"
                style={{ color: "white" }}
                onClick={() => this.props.history.goBack()}
                className={classes.customBackButton}>
                <KeyboardArrowLeft />
                BACK
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-start">
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>{displayName}</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {this.getTopRow(isPublished, websiteURL, role)}
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <p style={{ fontWeight: "bold", margin: "0px" }}>
                        Tab Display Name <span style={{ color: "red" }}>*</span>
                      </p>
                      <BareTextFieldInputOnChange
                        placeholder="Enter Name to be displayed on the tab"
                        fullWidth
                        name="tabDisplayName"
                        onBlur={this.handleField}
                        value={this.state.tabDisplayName}
                        error={errors.tabDisplayName}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.getEditorSection(errors)}
                  {this.getMetaSection(title, errors)}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Can I="suggest" a="PageDetail">
            {this.getEditorSuggestions()}
          </Can>
          {this.getFAQSection(faqs, faqsURL)}
          {process.env.NODE_ENV !== "production" ? this.getJSONData() : null}
          <Fab
            variant="extended"
            color="secondary"
            aria-label="Add"
            className={classes.margin}
            onClick={this.handleSubmit}
            // disabled={this.state.isError}
            style={{
              margin: "0px",
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed"
            }}>
            <SaveIcon style={{ marginRight: "5px" }} /> Save
          </Fab>
        </div>
      </LoadingOverlay>
    );
  }
}

function mapStateToProps({ elements, user }) {
  return { elements, user };
}
export default withStyles(formStyle)(
  connect(
    mapStateToProps,
    { getAllElements, showNotification }
  )(GenericDetailSection)
);
