import SignupPage from "views/Auth/SignupPage.jsx";
import ResetPasswordPage from "views/Auth/ResetPasswordPage.jsx";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import LoginScreen from "views/Auth/LoginScreen";
import ForgotPasswordPage from "views/Auth/ForgotPasswordPage";

import * as routesPath from "routes/routesPath";

const authRoutes = [
  {
    path: routesPath.LOGIN_PAGE,
    name: "Login Screen",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginScreen
  },
  {
    path: routesPath.SIGNUP_PAGE,
    name: "Sign Page",
    short: "Signup",
    mini: "SU",
    icon: Fingerprint,
    component: SignupPage
  },
  {
    path: routesPath.RESET_PASSWORD_PAGE,
    name: "Reset Password Page",
    short: "Reset",
    mini: "RP",
    icon: Fingerprint,
    component: ResetPasswordPage
  },
  {
    path: routesPath.FORGOT_PASSWORD_PAGE,
    name: "Forgot Password Page",
    short: "Forgot",
    mini: "FP",
    icon: Fingerprint,
    component: ForgotPasswordPage
  }
];

export default authRoutes;
