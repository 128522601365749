import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers, compose } from "redux";

import auth from "data/auth/reducers";
import features from "data/features/features";
import elements from "data/elements/reducers";
import forms from "data/forms/reducers";
import user from "data/userProfile/reducers";
import main from "data/main/reducers";
import colleges from "data/colleges/reducers";
import universities from "data/universities/reducers";

const rootReducer = combineReducers({
  auth,
  features,
  elements,
  forms,
  user,
  main,
  colleges,
  universities
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
