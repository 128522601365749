import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import axios from "axios";

// core components
import Button from "components/CustomButtons/Button.jsx";
import api from "data/utils/api";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.imageUrl
        ? this.props.imageUrl
        : this.props.avatar
        ? defaultAvatar
        : defaultImage,
      progress: false
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleImageChange = async e => {
    e.preventDefault();
    let file = e.target.files[0];
    //Converting all the upper case to lower case and the space to dash.
    const shortName = this.props.shortName.replace(/\s+/g, "-").toLowerCase();
    this.setState({ progress: true });
    try {
      const res = await api.post(api.endpoints.upload, {
        file_path: file.name,
        file_type: file.type,
        folder_path: `${this.props.folderPath}/${shortName}`,
        replace_url: this.props.imageUrl
      });
      await axios.put(res.data.url, file, {
        headers: {
          "Content-Type": file.type
        }
      });
      this.setState({
        file,
        progress: false,
        imagePreviewUrl: res.data.display_url
      });
      this.props.imageUploadUrl(res.data.display_url);
      const fileTitle =
        file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
      this.props.imageFileTitle(fileTitle);
    } catch (error) {
      this.setState({ file: null, progress: false });
    }
  };

  handleClick() {
    this.refs.fileInput.click();
  }

  render() {
    var { avatar, addButtonProps, changeButtonProps } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="..." />
        </div>
        {this.state.progress ? <CustomLinearProgress color="success" /> : null}
        <div>
          <Button {...addButtonProps} onClick={() => this.handleClick()}>
            {avatar
              ? "Add Photo"
              : this.props.imageUrl === ""
              ? "Select image"
              : "Change Image"}
          </Button>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
