import React, { Component } from "react";
import SelectorWithoutFormik from "views/Components/College/SelectorWithoutFormik.jsx";
import _ from "lodash";
import GridItem from "components/Grid/GridItem.jsx";
import { getAllElements } from "data/elements/actions/dataActions";
import MultiCollegeSection from "./MultiCollegeSection.jsx";

import { connect } from "react-redux";

class StateSelectorFilter extends Component {
  getFilteredState = () => {
    const { stateData } = this.props;
    const stateArray = stateData.map(item => ({
      full_name: item.state,
      id: item.state
    }));
    const updatedStates = {
      name: "State",
      data: stateArray
    };
    return this.handleSelectorValue(updatedStates);
  };

  componentDidMount() {
    this.props.getAllElements();
  }

  handleSelectorValue = selectorArray => {
    const {
      setSelectorValue,
      selectedState,
      selectedCity,
      nameKey,
      isMulti
    } = this.props;
    if (_.isEmpty(selectorArray.data)) {
      return null;
    } else {
      return (
        <SelectorWithoutFormik
          elementValues={selectorArray}
          setSelectorValue={value =>
            setSelectorValue({ key: selectorArray.name.toLowerCase(), value })
          }
          valuesData={
            selectorArray.name.toLowerCase() === "state"
              ? selectedState
              : selectedCity
          }
          nameKey={nameKey}
          isMulti={isMulti}
        />
      );
    }
  };

  getFilteredCities = () => {
    const { stateData, selectedState } = this.props;
    const citiesFilteredArray = stateData.filter(
      item => item.state === selectedState
    );
    const citiesArray = citiesFilteredArray[0].cities.map(item => ({
      full_name: item,
      id: item
    }));
    const updatedCities = {
      name: "City",
      data: citiesArray
    };
    return this.handleSelectorValue(updatedCities);
  };

  render() {
    const { selectedState } = this.props;
    return (
      <React.Fragment>
        <GridItem xs={6} sm={4}>
          {this.getFilteredState()}
        </GridItem>
        <GridItem xs={6} sm={4}>
          {selectedState ? this.getFilteredCities() : null}
        </GridItem>
        <MultiCollegeSection />
      </React.Fragment>
    );
  }
}

export default connect(null, { getAllElements })(StateSelectorFilter);
