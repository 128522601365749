import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.jsx";

import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const AttachmentDeleteAlert = props => {
  const { handleClose, handleSuccess, classes } = props;
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall
      }}
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description">
      <DialogTitle
        id="small-modal-slide-title"
        disableTypography
        className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}>
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody + " " + classes.modalSmallBody}>
        <h5>Are you sure you want to delete it?</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}>
        <Button
          onClick={handleClose}
          color="transparent"
          className={classes.modalSmallFooterFirstButton}>
          Cancel
        </Button>
        <Button
          onClick={handleSuccess}
          color="success"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(notificationsStyle)(AttachmentDeleteAlert);
