import React, { Component } from "react";
import { FieldArray, FastField } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Delete from "@material-ui/icons/Delete";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import Button from "components/CustomButtons/Button.jsx";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import Tooltip from "@material-ui/core/Tooltip";

const tableHead = [
  "#",
  "Mark Start",
  "Mark End",
  "Rank Start",
  "Rank End",
  "Action"
];

class AddRankPrediction extends Component {
  render() {
    const { valuesData, setFieldValue, classes } = this.props;
    const tableCellClasses = classes.tableHeadCell + " " + classes.tableCell;
    const tableCellClassesCenter =
      classes.tableHeadCell + " " + classes.tableCell + " " + classes.center;
    const values = valuesData ? valuesData : [];
    return (
      <React.Fragment>
        <Card
          style={{
            backgroundColor: "#f9f9f9"
          }}>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Rank Prediction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Table>
                  <React.Fragment>
                    <TableHead className={classes["primary"]}>
                      <TableRow className={classes.tableRow}>
                        {tableHead.map((prop, key) => {
                          return (
                            <TableCell
                              key={key}
                              className={
                                key === 0
                                  ? tableCellClasses
                                  : tableCellClassesCenter
                              }>
                              {prop}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <FieldArray name={`form_basic.rank_prediction`}>
                      {({ push, remove }) => (
                        <React.Fragment>
                          {values.map((item, index) => (
                            <TableBody
                              key={`form_basic.rank_prediction[${index}]`}>
                              <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  className={
                                    classes.tableCell + " " + classes.center
                                  }>
                                  <FastField
                                    name={`form_basic.rank_prediction[${index}].mark_start`}
                                    component={BareTextFieldInput}
                                    type="number"
                                    step={0.01}
                                    placeholder="Mark Start Range"
                                    onBlur={e => {
                                      setFieldValue(
                                        `form_basic.rank_prediction[${index}].mark_start`,
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                    defaultValue={values[`${index}`].mark_start}
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    classes.tableCell + " " + classes.center
                                  }>
                                  <FastField
                                    name={`form_basic.rank_prediction[${index}].mark_end`}
                                    component={BareTextFieldInput}
                                    type="number"
                                    step={0.01}
                                    placeholder="Mark End Range"
                                    onBlur={e => {
                                      setFieldValue(
                                        `form_basic.rank_prediction[${index}].mark_end`,
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                    defaultValue={values[`${index}`].mark_end}
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    classes.tableCell + " " + classes.center
                                  }>
                                  <FastField
                                    name={`form_basic.rank_prediction[${index}].rank_start`}
                                    component={BareTextFieldInput}
                                    type="number"
                                    min={0}
                                    step={1}
                                    placeholder="Rank Start Range"
                                    onBlur={e => {
                                      setFieldValue(
                                        `form_basic.rank_prediction[${index}].rank_start`,
                                        parseInt(e.target.value)
                                      );
                                    }}
                                    defaultValue={values[`${index}`].rank_start}
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    classes.tableCell + " " + classes.center
                                  }>
                                  <FastField
                                    name={`form_basic.rank_prediction[${index}].rank_end`}
                                    component={BareTextFieldInput}
                                    type="number"
                                    min={0}
                                    step={1}
                                    placeholder="Rank End Range"
                                    onBlur={e => {
                                      setFieldValue(
                                        `form_basic.rank_prediction[${index}].rank_end`,
                                        parseInt(e.target.value)
                                      );
                                    }}
                                    defaultValue={values[`${index}`].rank_end}
                                  />
                                </TableCell>
                                <TableCell
                                  className={
                                    classes.tableCell + " " + classes.center
                                  }>
                                  <Tooltip
                                    title={`Delete `}
                                    placement="top"
                                    classes={{
                                      tooltip: classes.tooltip
                                    }}>
                                    <Button
                                      onClick={() => {
                                        remove(index);
                                      }}
                                      className={classes.actionButton}
                                      color="danger"
                                      size="lg">
                                      <Delete className={classes.icon} />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          <TableBody>
                            <TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell className={classes.center}>
                                <GridContainer justify="flex-end">
                                  <GridItem>
                                    <Button
                                      color="tumblr"
                                      type="button"
                                      onClick={() =>
                                        push({
                                          mark_start: "",
                                          mark_end: "",
                                          rank_start: "",
                                          rank_end: ""
                                        })
                                      }>
                                      Add Row
                                    </Button>
                                  </GridItem>
                                </GridContainer>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </React.Fragment>
                      )}
                    </FieldArray>
                  </React.Fragment>
                </Table>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(AddRankPrediction);
