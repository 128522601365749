import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import { connect } from "react-redux";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import StreamCell from "./Streams/StreamCell";
import StreamDataModal from "./Streams/StreamDataModal";
import BranchDataModal from "./Streams/BranchDataModal";

class StreamList extends Component {
  handleTabLayout = () => {
    return this.props.streams.data.map((item, index) => {
      return {
        tabButton: item.full_name,
        tabContent: this.handleStreamLayout(item, index)
      };
    });
  };

  handleStreamLayout = (item, streamIndex) => {
    return (
      <GridContainer style={{ marginLeft: "0px", width: "100%" }}>
        {this.handleStreamTableLayout(item, streamIndex)}
      </GridContainer>
    );
  };

  handleStreamTableLayout = (item, streamIndex) => {
    return item.data.map((item, index) => {
      return (
        <GridItem xs={12} key={index}>
          <StreamCell
            key={`stream_${index}`}
            elementIndex={this.props.elementIndex}
            streamIndex={streamIndex}
            streamElementIndex={index}
          />
        </GridItem>
      );
    });
  };
  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleModalLayout = () => {
    return this.props.elements.ui.showStreamModal ? <StreamDataModal /> : null;
  };

  // =================================================================================================
  // MODAL HANDLING ==================================================================================
  // =================================================================================================
  handleBranchModalLayout = () => {
    return this.props.elements.ui.showBranchModal ? <BranchDataModal /> : null;
  };

  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        {this.handleModalLayout()}
        {this.handleBranchModalLayout()}
        <NavPills color="warning" tabs={this.handleTabLayout()} />
      </div>
    );
  }
}

function mapStateToProps({ elements }, prevProps) {
  const elementIndex = prevProps.elementIndex;
  const streams = elements.data[elementIndex];
  return { streams, elements };
}

export default withStyles(tableFormStyles)(
  connect(mapStateToProps)(StreamList)
);
