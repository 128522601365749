import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit
  }
});

class BareTextFieldInput extends Component {
  render() {
    const {
      classes,
      onBlur,
      label,
      defaultValue,
      price,
      placeholder,
      disabled,
      name,
      fullWidth,
      styleHeight,
      value,
      type,
      multiline,
      rows,
      rowsMax,
      step,
      min,
      max,
      inputColor,
      variant,
      labelText
    } = this.props;
    return (
      <TextField
        name={name || ""}
        style={
          !styleHeight
            ? { height: "40px", margin: "12px 0px" }
            : { margin: "12px 0px" }
        }
        id="outlined-bare"
        className={classes.textField}
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        variant={variant || "outlined"}
        type={type || "text"}
        disabled={disabled || false}
        autoComplete="off"
        rows={rows}
        rowsMax={rowsMax}
        value={value}
        fullWidth={fullWidth}
        multiline={multiline || false}
        onBlur={event => onBlur(event)}
        InputProps={{
          startAdornment: price ? (
            <InputAdornment position="start">
              <span>₹</span>
            </InputAdornment>
          ) : null
        }}
        inputProps={{
          step,
          min,
          max,
          style: inputColor ? { color: inputColor } : null
        }}
      />
    );
  }
}

export default withStyles(styles)(BareTextFieldInput);
