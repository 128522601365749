import React, { Component } from "react";
import ChipInput from "material-ui-chip-input";

class MaterialUIChips extends Component {
  state = {
    error: false,
    errorText: ""
  };
  handleDeleteChip = deletedChip => {
    const { value, handleTags } = this.props;
    const updatedValue = value.filter(item => item !== deletedChip);
    handleTags(updatedValue);
  };

  handleAdd = chip => {
    const { value, handleTags, placeholder, regValidate, limit } = this.props;
    let re = new RegExp(regValidate);
    let updatedValue;
    if (value.length < limit) {
      if (re.test(chip)) {
        updatedValue = [...value, chip];
        handleTags(updatedValue);
        this.setState({
          error: false,
          errorText: ""
        });
      } else {
        this.setState({
          error: true,
          errorText: `${placeholder} is not correct`
        });
      }
    } else {
      this.setState({
        error: true,
        errorText: `Maximum 10 ${placeholder} are allowed`
      });
    }
  };

  handleBlur = () => {
    this.setState({
      error: false,
      errorText: ""
    });
  };

  render() {
    const { value, placeholder } = this.props;
    return (
      <ChipInput
        value={value}
        // alwaysShowPlaceholder
        placeholder={`Add ${placeholder}`}
        onBlur={this.handleBlur}
        onAdd={chip => this.handleAdd(chip)}
        onDelete={chip => this.handleDeleteChip(chip)}
        error={this.state.error}
        helperText={
          this.state.error
            ? this.state.errorText
            : `Please Press Enter in order to add ${placeholder}`
        }
        variant="outlined"
        fullWidthInput
        style={{ display: "block" }}
      />
    );
  }
}
export default MaterialUIChips;
