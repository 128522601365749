import api from "data/utils/api";

import {
  USER_APPSTATE_AUTH_LOADING,
  USER_APPSTATE_AUTH_SUCCESS,
  USER_APPSTATE_AUTH_ERROR,
  USER_DATA_AUTH,
  USER_DATA_AUTH_RESET,
  USER_APPSTATE_AUTH_LOGOUT_SUCCESS,
  USER_APPSTATE_AUTH_LOGOUT_ERROR
} from "data/userProfile/types";

const errorMessage = error => {
  var message = "Something Went Wrong";
  if (error && error.message) {
    message = error.message;
  }
  return message;
};
// =================================================================================================
// GET USER AUTH DATA ==============================================================================
// =================================================================================================
export const getUserAuth = () => async dispatch => {
  dispatch({ type: USER_APPSTATE_AUTH_LOADING });
  try {
    const res = await api.get(api.endpoints.user.auth);
    dispatch({ type: USER_DATA_AUTH, data: res.data });
    dispatch({ type: USER_APPSTATE_AUTH_SUCCESS });
  } catch (error) {
    const message = errorMessage(error);
    dispatch({
      type: USER_APPSTATE_AUTH_ERROR,
      error: message
    });
  }
};

// =================================================================================================
// GET USER AUTH DATA ==============================================================================
// =================================================================================================
export const logoutUser = history => async dispatch => {
  try {
    await api.get(api.endpoints.user.logout);
    dispatch({ type: USER_APPSTATE_AUTH_LOGOUT_SUCCESS });
    dispatch({ type: USER_DATA_AUTH_RESET });
    history.replace("/auth/login");
  } catch (error) {
    const message = errorMessage(error);
    dispatch({
      type: USER_APPSTATE_AUTH_LOGOUT_ERROR,
      error: message
    });
  }
};
