import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import CardText from "components/Card/CardText.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { debounce } from "throttle-debounce";
import _ from "lodash";
import Edit from "@material-ui/icons/Edit";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import FormPaginations from "views/Forms/EazyForms/FormListing/FormPaginations.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";

//routing
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getAllForms } from "data/forms/actions";
import { showNotification } from "data/main/actions";
import { getAllElements } from "data/elements/actions/dataActions";
import Can from "../../../../utils/Can";

const formsRoute = [
  {
    path: "/form-listing/basic",
    icon: Edit,
    name: "Basic",
    color: "info"
  },
  {
    path: "/form-listing/detail",
    icon: Edit,
    name: "Detail",
    color: "primary"
  }
];

const formStatus = [
  {
    status: "Expired",
    color: "#F44336"
  },
  {
    status: "Upcoming",
    color: "#2196F3"
  },
  {
    status: "Ongoing",
    color: "#4CAF50"
  }
];

class FormListing extends React.Component {
  componentDidMount() {
    const { elements } = this.props;
    if (!elements.data.length) {
      this.props.getAllElements();
    }
    this.fetchDataServer();
  }

  syncTabsDataWithServer = form_id => {
    const { elements } = this.props;
    if (!elements || !elements.data || !elements.data.length) {
      return [];
    }

    let formTabs = [];
    const features = elements.data.filter(item => item.key === "features");
    if (features.length) {
      const items = features[0].data.filter(item => item.id === 1);
      if (items.length) {
        const allTabs = items[0].data.filter(item => item.key === "tabs");
        if (allTabs.length) {
          formTabs = allTabs[0].data;
        }
      }
    }

    let orderedTabs = formTabs.sort(function(a, b) {
      return a.id - b.id;
    });

    const linkedTabs = orderedTabs.map(item => (
      <NavLink
        exact
        style={{
          display: "flex",
          flexGrow: 1,
          alignItems: "stretch",
          color: "inherit",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          paddingBottom: "5px"
        }}
        to={`/form-listing/detail/${form_id}/${item.id}`}
        onClick={this.handleNavLinkClick}
      >
        {item.full_name}
      </NavLink>
    ));
    return linkedTabs;
  };

  formDateTypeHandler = (formDate, type) => {
    var value = "";
    if (formDate) {
      const selector_date = formDate.selector_date;
      const selector_type = formDate.selector_type || "single";
      switch (selector_type) {
        case "single":
          value = this.singleDateStatusHandler(selector_date, type);
          break;
        case "multi":
          value = this.multiDateStatusHandler(selector_date, type);
          break;
        case "range":
          value = this.rangeDateStatusHandler(selector_date, type);
          break;
        default:
          break;
      }
      return value;
    } else return null;
  };

  singleDateStatusHandler = (selector_date, type) => {
    var status = "";
    if (selector_date) {
      const FormDateString = moment(selector_date).format("DD/MM/YYYY");
      const FormDate = moment(FormDateString, "DD/MM/YYYY");
      const todayDateString = moment().format("DD/MM/YYYY");
      const todayDate = moment(todayDateString, "DD/MM/YYYY");
      const diff = FormDate.diff(todayDate, "days");
      switch (type) {
        case "form_status":
          if (diff < 0) {
            status = "Expired";
          } else if (diff > 0) {
            status = "Upcoming";
          } else {
            status = "Ongoing";
          }
          return status;
        case "days":
          return diff;
        default:
          break;
      }
    }
  };

  rangeDateStatusHandler = (selector_date, type) => {
    var status = "";
    var diff = "";
    if (selector_date) {
      const startDateString = moment(selector_date.start).format("DD/MM/YYYY");
      const startDate = moment(startDateString, "DD/MM/YYYY");
      const endDateString = moment(selector_date.end).format("DD/MM/YYYY");
      const endDate = moment(endDateString, "DD/MM/YYYY");
      const todayDateString = moment().format("DD/MM/YYYY");
      const todayDate = moment(todayDateString, "DD/MM/YYYY");
      if (todayDate > endDate) {
        diff = endDate.diff(todayDate, "days");
        status = "Expired";
      } else if (todayDate < startDate) {
        diff = startDate.diff(todayDate, "days");
        status = "Upcoming";
      } else {
        diff = 0;
        status = "Ongoing";
      }
      switch (type) {
        case "form_status":
          return status;
        case "days":
          return diff;
        default:
          break;
      }
    }
  };

  multiDateStatusHandler = (selector_date, type) => {
    var status = "";
    var diff = "";
    if (selector_date) {
      const moments = selector_date.map(d => moment(d));
      const maxDate = moment.max(moments);
      const minDate = moment.min(moments);
      const startDateString = minDate.format("DD/MM/YYYY");
      const startDate = moment(startDateString, "DD/MM/YYYY");
      const endDateString = maxDate.format("DD/MM/YYYY");
      const endDate = moment(endDateString, "DD/MM/YYYY");
      const todayDateString = moment().format("DD/MM/YYYY");
      const todayDate = moment(todayDateString, "DD/MM/YYYY");
      if (todayDate > endDate) {
        diff = endDate.diff(todayDate, "days");
        status = "Expired";
      } else if (todayDate < startDate) {
        diff = startDate.diff(todayDate, "days");
        status = "Upcoming";
      } else {
        diff = 0;
        status = "Ongoing";
      }
      switch (type) {
        case "form_status":
          return status;
        case "days":
          return diff;
        default:
          break;
      }
    }
  };

  formDateHandler = dates => {
    var formData = "";
    if (dates) {
      const formDate = dates.filter(item => item.full_name === "Form Date");
      if (formDate.length > 0) {
        formData = formDate[0].data;
      }
      return formData;
    } else return null;
  };

  formDateRelatedValueHandler = dates => {
    if (dates) {
      let days = "";
      const formDate = this.formDateHandler(dates);
      const formStatus = this.formDateTypeHandler(formDate, "form_status");
      const daysValue = this.formDateTypeHandler(formDate, "days");
      if (daysValue !== undefined) days = Math.abs(daysValue);

      return {
        form_date: formDate,
        form_status: formStatus,
        days
      };
    } else
      return {
        form_date: "",
        form_status: "",
        days: ""
      };
  };

  formStatusHighlightHandler = row => {
    const { form_status } = row.original;
    const formStatusHighlight = formStatus.filter(
      item => item.status === form_status
    );
    if (formStatusHighlight.length > 0) {
      return (
        <React.Fragment>
          <GridContainer justify="center">
            <GridItem>
              <span
                className="far fa-circle"
                aria-hidden="true"
                style={{
                  backgroundColor: formStatusHighlight[0].color,
                  borderRadius: "50%",
                  color: formStatusHighlight[0].color
                }}
              />{" "}
              {formStatusHighlight[0].status}
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    }
  };

  handleFormData = () => {
    const { appState, data } = this.props.forms;
    const { currentPage, pageLimit } = appState;
    let isSearched = data.isSearched;
    const { classes } = this.props;
    const tableData = data.data[`${currentPage}`] || [];
    return tableData.map((item, key) => {
      const {
        alt_text,
        url,
        short_name,
        full_name,
        dates,
        is_active,
        id
      } = item;
      const formRelatedObj = this.formDateRelatedValueHandler(dates);
      return {
        row_count: isSearched
          ? key + 1
          : (currentPage - 1) * pageLimit + key + 1,
        url,
        alt_text,
        name: `${full_name}(${short_name})`,
        form_date: formRelatedObj.form_date,
        form_status: formRelatedObj.form_status,
        days: formRelatedObj.days,
        published: is_active ? "Yes" : "No",
        actions: (
          <div
            className="actions-right"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {formsRoute.map((item, key) => {
              if (item.redirect) {
                return null;
              }
              return (
                <React.Fragment key={key}>
                  {item.path === "/form-listing/basic" ? (
                    <NavLink
                      to={`${item.path}/${id}`}
                      style={{ marginRight: "5px" }}
                    >
                      <Button color={item.color} className="edit">
                        {item.name}
                      </Button>
                    </NavLink>
                  ) : (
                    <CustomDropdown
                      buttonText={item.name}
                      buttonProps={{
                        // round: true,
                        color: item.color
                      }}
                      style={{ margin: "0px", padding: "0px" }}
                      dropdownList={this.syncTabsDataWithServer(id)}
                    />
                  )}
                  {"  "}
                </React.Fragment>
              );
            })}
          </div>
        )
      };
    });
  };

  showDateValues = row => {
    const { form_date } = row.original;
    const { classes } = this.props;
    if (form_date) {
      const selector_date = form_date.selector_date;
      const selector_type = form_date.selector_type || "single";
      switch (selector_type) {
        case "single": {
          return (
            <React.Fragment>
              <GridContainer>
                <GridItem xs={4}>
                  {selector_date ? (
                    <Chip
                      label={moment(selector_date).format("DD/MM/YYYY")}
                      className={classes.chip}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </React.Fragment>
          );
        }
        case "multi": {
          return (
            <React.Fragment>
              <GridContainer>
                {selector_date
                  ? selector_date.map((date, index) => {
                      return (
                        <GridItem xs={12} key={index} md={6}>
                          <Chip
                            label={moment(date).format("DD/MM/YYYY")}
                            className={classes.chip}
                            key={index}
                          />
                        </GridItem>
                      );
                    })
                  : null}
              </GridContainer>
            </React.Fragment>
          );
        }
        case "range": {
          return (
            <React.Fragment>
              <GridContainer>
                {selector_date.start && selector_date.end ? (
                  <GridItem xs={6}>
                    <Chip
                      label={moment(selector_date.start).format("DD/MM/YYYY")}
                      className={classes.chip}
                    />
                    <span>{`--->`}</span>
                    <Chip
                      label={moment(selector_date.end).format("DD/MM/YYYY")}
                      className={classes.chip}
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
            </React.Fragment>
          );
        }
        default:
          break;
      }
    } else return null;
  };

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined && typeof row[id] === "string"
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  searchFormData = (state, instance) => {
    const searchData = state.filter(item => item.id === "name");
    if (searchData.length > 0) {
      this.fetchDataServer(searchData[0].value);
    } else this.fetchDataServer();
  };

  fetchDataServer = value => {
    const { forms, getAllForms } = this.props;
    const { currentPage, pageStart, pageLimit } = forms.appState;
    if (value) {
      getAllForms(pageStart, pageLimit, currentPage, value);
    } else getAllForms((currentPage - 1) * pageLimit, pageLimit, currentPage);
  };

  render() {
    const { classes, forms } = this.props;
    const {
      currentPage,
      pageLimit,
      totalCount,
      error,
      loading
    } = forms.appState;
    const formData = this.handleFormData();
    if (error) {
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
      return (
        <div>
          <strong>Something Went Wrong</strong>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Can I="create" a="Form">
          <GridContainer justify="flex-end">
            <GridItem>
              <NavLink
                to={{
                  pathname: "/form-listing/basic",
                  state: { isNew: true }
                }}
              >
                <Button title="Add New Form" color="success" size="lg">
                  ADD FORM
                </Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Can>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <GridItem xs={12}>
                <CardHeader color="primary" text>
                  <CardText color="primary">
                    <h4 className={classes.cardTitle}>
                      Form Listing ({`${totalCount}`})
                    </h4>
                  </CardText>
                </CardHeader>
              </GridItem>

              <GridItem>
                <CardBody>
                  <ReactTable
                    data={formData}
                    pages={
                      Math.ceil(totalCount / pageLimit)
                        ? Math.ceil(totalCount / pageLimit)
                        : 1
                    }
                    manual
                    currentPage={currentPage}
                    PaginationComponent={FormPaginations}
                    onFilteredChange={this.searchFormData}
                    loading={loading}
                    filterable
                    defaultPageSize={pageLimit}
                    showPaginationTop={true}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    columns={[
                      {
                        Header: "#",
                        accessor: "row_count",
                        minWidth: 20,
                        sortable: false,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Logo",
                        Cell: row => (
                          <div>
                            <img
                              width={60}
                              height={60}
                              src={row.original.url}
                              alt={row.original.alt_text}
                            />
                          </div>
                        ),
                        sortable: false,
                        filterable: false,
                        minWidth: 50
                      },
                      {
                        Header: "Name",
                        accessor: "name",
                        minWidth: 120
                      },
                      {
                        Header: "Form Date",
                        Cell: row => this.showDateValues(row),
                        filterable: false
                      },
                      {
                        Header: "Form Status",
                        accessor: "form_status",
                        Cell: row => this.formStatusHighlightHandler(row),
                        minWidth: 60,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Days",
                        accessor: "days",
                        minWidth: 50,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Published",
                        accessor: "published",
                        minWidth: 50,
                        filterable: false,
                        className: classes.reactTableCenter
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        minWidth: 100,
                        sortable: false,
                        filterable: false
                      }
                    ]}
                  />
                </CardBody>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ forms, elements }) {
  return { forms, elements };
}

export default withStyles(formStyle)(
  connect(mapStateToProps, { getAllForms, showNotification, getAllElements })(
    FormListing
  )
);
