import React, { useState, useEffect, Component } from "react";
import { getAllElements } from "data/elements/actions/dataActions";
import { fixedPart } from "utils/values.jsx";
import CollegeDetailTab from "views/College/Detail/CollegeDetailTab";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ControlledTabs from "components/CustomTabs/ControlledTabs.jsx";
import _ from "lodash";
import { connect } from "react-redux";
import api from "data/utils/api";
import { showNotification } from "data/main/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Button from "components/CustomButtons/Button.jsx";
import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import Spinner from "views/Forms/EazyForms/Spinner/Spinner";

class CollegeDetail extends Component {
  state = {
    tabIndex: 0,
    showDeleteAlert: false,
    isTabCanChange: true,
    serverDetails: [],
    isLoading: false,
    collegeName: ""
  };

  fetchCollegeDetails = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    try {
      const res = await api.get(`${api.endpoints.colleges}/${id}/detail`);
      this.setState({
        serverDetails: res.data.data,
        collegeName: `${res.data.full_name}[${res.data.short_name}]`,
        displaySlug: res.data.display_slug,
        isLoading: false
      });
      // this.syncTabsDataWithServer();
    } catch (error) {
      // console.log(error);
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  componentDidMount() {
    const { elements } = this.props;
    if (!elements.data.length) {
      this.props.getAllElements();
    }
    this.fetchCollegeDetails();
  }

  syncTabsDataWithServer = () => {
    const { elements } = this.props;
    const newTemplate = elements.data
      .filter(item => item.key === "features")[0]
      .data.filter(item => item.id === 2)[0]
      .data.filter(item => item.key === "tabs")[0].data;
    if (!_.isEmpty(this.state.serverDetails)) {
      const tabs = newTemplate.map(template => {
        const updatedTabs = this.state.serverDetails.filter(
          item => item.tab_id === template.id
        );
        if (updatedTabs.length > 0) {
          return updatedTabs[0];
        } else {
          return {
            is_active: false,
            tab_id: template.id,
            tab_name: template.full_name,
            full_name: template.full_name,
            slug: "",
            title: "",
            description: "",
            meta_title: "",
            meta_slug: "",
            meta_description: "",
            additional_information: []
          };
        }
      });
      return tabs;
    } else {
      const tabs = newTemplate.map(template => {
        return {
          is_active: false,
          tab_id: template.id,
          tab_name: template.full_name,
          full_name: template.full_name,
          slug: "",
          title: "",
          description: "",
          meta_title: "",
          meta_slug: "",
          meta_description: "",
          additional_information: []
        };
      });
      return tabs;
    }
  };

  tabDataChangedHandler = value => {
    this.setState({ isTabCanChange: value });
  };

  handleCollegeTabs = () => {
    const { id } = this.props.match.params;
    const tabData = this.syncTabsDataWithServer();
    return tabData.map((item, index) => {
      return {
        tabName: item.full_name,
        tabContent: (
          <CollegeDetailTab
            collegeTabData={tabData[index] ? tabData[index] : []}
            key={index}
            tabDataChangedHandler={value => this.tabDataChangedHandler(value)}
            tabDataStatus={this.state.isTabCanChange}
            id={id}
            fetchCollegeDetails={this.fetchCollegeDetails}
            displaySlug={this.state.displaySlug}
          />
        )
      };
    });
  };

  handleAlertSuccess = () => {
    const { history } = this.props;
    this.setState({ showDeleteAlert: false });
    history.push("/college-listing");
  };

  showDeleteAlertHandler = () => {
    if (this.state.showDeleteAlert) {
      return (
        <DeleteAlertBox
          handleSuccess={() => this.handleAlertSuccess()}
          handleClose={() => this.setState({ showDeleteAlert: false })}
          title="Leave Editor ?">
          Changes that you made may not be saved.
        </DeleteAlertBox>
      );
    }
  };

  handleTabChange = (event, value) => {
    if (this.state.isTabCanChange) {
      // console.log(value);
      this.setState({ tabIndex: value });
    } else
      this.props.showNotification(
        "Please save tab data and then change the tab",
        false
      );
  };

  render() {
    const { classes, elements, history } = this.props;
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        <GridContainer align="center">
          <GridItem>
            <Button
              size="md"
              title="Go Back"
              onClick={() => this.setState({ showDeleteAlert: true })}
              className={classes.customBackButton}>
              <KeyboardArrowLeft />
              BACK
            </Button>
          </GridItem>
          <GridItem>
            <h4>{this.state.collegeName}</h4>
          </GridItem>
          {this.showDeleteAlertHandler()}
        </GridContainer>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            {elements.data.length > 0 ? (
              <ControlledTabs
                title="Detail:"
                headerColor="rose"
                tabs={this.handleCollegeTabs()}
                handleChange={this.handleTabChange}
                value={this.state.tabIndex}
              />
            ) : null}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}
export default withStyles(formStyle)(
  connect(
    mapStateToProps,
    { getAllElements, showNotification }
  )(CollegeDetail)
);
