import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { SingleDatePicker, DateRangePicker } from "react-dates";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: "5px"
  }
});

class BasicDateSelector extends Component {
  componentDidMount() {
    this.props.onRef(this);
    this.handleValuesfromFormik();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  state = {
    date: moment(),
    startDate: moment(),
    endDate: moment(),
    multiDates: [],
    focused: true,
    multiCalendarFocused: true,
    focusedInput: "startDate",
    selector_type: "",
    noDateSelected: false
  };

  handleDateChange = date => {
    const { tabActiveIndex } = this.props;
    if (tabActiveIndex === 0) {
      this.setState({
        date: date,
        multiDates: [],
        startDate: null,
        endDate: null,
        selector_type: "single"
      });
    } else {
      const isPresent = this.state.multiDates.find(d => date.isSame(d));
      this.setState({
        multiDates: this.state.multiDates
          .filter(d => (isPresent ? !date.isSame(d) : true))
          .concat(isPresent ? [] : [date])
          .sort((d1, d2) => (d1.isBefore(d2) ? -1 : 1)),
        startDate: null,
        endDate: null,
        date: null,
        selector_type: "multi",
        noDateSelected: false
      });
    }
  };

  handleRangeDateChange = (startDate, endDate) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      multiDates: [],
      date: null,
      selector_type: "range",
      noDateSelected: false
    });
  };
  removeMultiDate = date => {
    this.setState({
      multiDates: this.state.multiDates.filter(d => !date.isSame(d))
    });
  };

  handleValuesfromFormik = () => {
    const { valuesData } = this.props;
    const selector_type = valuesData.selector_type || "single";

    switch (selector_type) {
      case "single": {
        const date = moment(valuesData.selector_date);
        this.setState({ date: date, selector_type });
        break;
      }
      case "multi": {
        const date = valuesData.selector_date.map(date => {
          return moment(date);
        });
        this.setState({ multiDates: date, selector_type });
        break;
      }
      case "range": {
        const startDate = moment(valuesData.selector_date.start);
        const endDate = moment(valuesData.selector_date.end);
        this.setState({ startDate, endDate, selector_type });
        break;
      }
      default:
        break;
    }
  };

  handleFormikInsertion = (selector_type, data, startDate, endDate) => {
    const { setFieldValue, mainIndex, onClose } = this.props;
    setFieldValue(`form_basic.dates[${mainIndex}].data`, null);

    setFieldValue(
      `form_basic.dates[${mainIndex}].data.selector_type`,
      selector_type
    );
    if (startDate && endDate) {
      setFieldValue(
        `form_basic.dates[${mainIndex}].data.selector_date.start`,
        startDate
      );
      setFieldValue(
        `form_basic.dates[${mainIndex}].data.selector_date.end`,
        endDate
      );
    } else {
      setFieldValue(`form_basic.dates[${mainIndex}].data.selector_date`, data);
    }
    onClose();
  };

  submitOkButton = () => {
    const selector_type = this.state.selector_type;
    switch (this.state.selector_type) {
      case "single": {
        const date = this.state.date.format("YYYY-MM-DD");
        this.handleFormikInsertion(selector_type, date);
        break;
      }
      case "multi": {
        const date = this.state.multiDates.map(date => {
          return date.format("YYYY-MM-DD");
        });
        this.handleFormikInsertion(selector_type, date);
        break;
      }
      case "range": {
        if (this.state.startDate && this.state.endDate) {
          const startDate = this.state.startDate.format("YYYY-MM-DD");
          const endDate = this.state.endDate.format("YYYY-MM-DD");
          this.handleFormikInsertion(
            selector_type,
            undefined,
            startDate,
            endDate
          );
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    let tabDisplayDate;
    const { tabActiveIndex, classes } = this.props;

    if (tabActiveIndex === 0) {
      tabDisplayDate = (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer alignItems="center" style={{ minHeight: "50px" }}>
                <GridItem>{`Selected Date:`}</GridItem>

                {this.state.date ? (
                  <Chip
                    label={this.state.date.format("DD/MM/YYYY")}
                    className={classes.chip}
                  />
                ) : null}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <SingleDatePicker
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                date={this.state.date}
                onDateChange={this.handleDateChange}
                focused={this.state.focused}
                onFocusChange={({ focused }) =>
                  this.setState({ focused: true })
                }
                numberOfMonths={2}
                keepOpenOnDateSelect={true}
                id="date"
              />
              {[0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                ind => {
                  return (
                    <div key={ind}>
                      <br />
                    </div>
                  );
                }
              )}
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    } else if (tabActiveIndex === 1) {
      tabDisplayDate = (
        <React.Fragment>
          <GridContainer spacing={0}>
            <GridItem xs={12}>
              <GridContainer alignItems="center" style={{ minHeight: "50px" }}>
                <GridItem>{`Selected Dates:`}</GridItem>
                {this.state.multiDates.map(d => (
                  <Chip
                    key={d}
                    label={d.format("DD/MM/YYYY")}
                    onDelete={e => this.removeMultiDate(d)}
                    className={classes.chip}
                  />
                ))}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <SingleDatePicker
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                onDateChange={this.handleDateChange}
                focused={this.state.multiCalendarFocused}
                onFocusChange={e =>
                  this.setState({
                    multiCalendarFocused: true
                  })
                }
                keepOpenOnDateSelect
                isDayHighlighted={d1 =>
                  this.state.multiDates.some(d2 => d1.isSame(d2, "day"))
                }
              />
              {[0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                ind => {
                  return (
                    <div key={ind}>
                      <br />
                    </div>
                  );
                }
              )}
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    } else if (tabActiveIndex === 2) {
      tabDisplayDate = (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer alignItems="center" style={{ minHeight: "50px" }}>
                <GridItem>{`Start Date:`}</GridItem>
                {this.state.startDate ? (
                  <Chip
                    label={this.state.startDate.format("DD/MM/YYYY")}
                    className={classes.chip}
                  />
                ) : null}
                <GridItem>{`End Date:`}</GridItem>
                {this.state.endDate ? (
                  <Chip
                    label={this.state.endDate.format("DD/MM/YYYY")}
                    className={classes.chip}
                  />
                ) : null}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <DateRangePicker
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                keepOpenOnDateSelect
                startDateId="startDate"
                endDateId="endDate"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) => {
                  this.handleRangeDateChange(startDate, endDate);
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => {
                  const updatedFocusedInput =
                    focusedInput === null ? "startDate" : focusedInput;
                  this.setState({ focusedInput: updatedFocusedInput });
                }}
                numberOfMonths={2}
              />
              {[0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                ind => {
                  return (
                    <div key={ind}>
                      <br />
                    </div>
                  );
                }
              )}
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    }

    return <React.Fragment>{tabDisplayDate}</React.Fragment>;
  }
}

export default withStyles(styles)(BasicDateSelector);
