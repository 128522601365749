import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import api from "data/utils/api";
import { connect } from "react-redux";
import { showNotification } from "data/main/actions";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import CollegeEdit from "views/College/MultiCollegeEdit/CollegeEdit";
import StateSelectorFilter from "views/College/MultiCollegeEdit/StateSelectorFilter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const address = {
  line_1: "",
  line_2: "",
  city: "",
  pincode: null,
  district: "",
  state: "",
  country: "",
  lat: "",
  lng: "",
  selected_address: ""
};

class MultiCollegeEdit extends Component {
  state = {
    collegeBasic: [],
    isLoading: false,
    stateData: [],
    state: null,
    city: null,
    offset: 0,
    limit: 1,
    getLimit: 5,
    totalCount: null,
    error: []
  };

  fetchStateAndCitiesFromServer = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props;
    try {
      const res = await api.get(
        api.endpoints["colleges-temp"]["states-and-cities"]
      );
      // this.handleResponse(res.data, "stateData");
      this.setState({
        stateData: res.data,
        isLoading: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  fetchCollegeDetails = async city => {
    const { state, getLimit } = this.state;
    this.setState({ isLoading: true });
    try {
      const res = await api.get(api.endpoints["colleges-temp"].root, {
        state,
        city,
        limit: getLimit,
        offset: 0
      });
      this.mapDataFromServer(res);
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.showNotification(
        _.get(error, "data.message", "Something Went Wrong"),
        false
      );
    }
  };

  mapDataFromServer = async res => {
    const updatedData = await res.data.map(item => ({
      ...item,
      address,
      affiliated_to: null
    }));
    this.setState({
      collegeBasic: updatedData,
      offset: res.start + res.count,
      totalCount: res.totalCount
      // isLoading: false
    });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
    // this.handleResponse(updatedData, "collegeBasic");
  };

  handleResponse = (data, key) => {
    this.setState({
      [key]: data,
      isLoading: false
    });
  };

  handleItemRemove = (collegeIndex, res) => {
    const collegeBasicFiltered = this.state.collegeBasic.filter(
      (_, i) => i !== collegeIndex
    );
    if (res.data.length) {
      const newDataParsed = res.data.map(item => ({
        ...item,
        address,
        affiliated_to: null
      }));
      collegeBasicFiltered.splice(collegeIndex, 0, ...newDataParsed);
      // const finalData = collegeBasicFiltered.concat(newDataParsed);
    }

    this.setState({
      collegeBasic: collegeBasicFiltered,
      offset: res.start + res.count,
      totalCount: res.totalCount
    });
    // this.setState(prevState => ({
    //   collegeBasic: prevState.collegeBasic.filter((_, i) => i !== collegeIndex)
    // }));
  };

  handlingCollegeDisplay = () => {
    return this.state.collegeBasic.map((item, index) => (
      <CollegeEdit
        // {...item}
        key={index}
        collegeData={item}
        collegeIndex={index}
        itemRemoveIndex={this.handleItemRemove}
        state={this.state.state}
        city={this.state.city}
        offset={this.state.offset}
        limit={this.state.limit}
      />
    ));
  };

  componentDidMount() {
    this.fetchStateAndCitiesFromServer();
  }

  setFilterValue = ({ key, value }) => {
    this.setState({ [key]: value });
    if (key === "city" && value) {
      this.fetchCollegeDetails(value);
    }
  };

  render() {
    const { stateData, state, city } = this.state;
    return (
      <React.Fragment>
        <GridContainer style={{ marginLeft: "6px" }}>
          {this.state.totalCount && this.state.state && this.state.city ? (
            <h3>{`${this.state.totalCount} Universities/Colleges in ${this.state.city} [${this.state.state}]`}</h3>
          ) : null}
        </GridContainer>
        <GridContainer>
          <StateSelectorFilter
            stateData={stateData}
            selectedState={state}
            selectedCity={city}
            setSelectorValue={data => this.setFilterValue(data)}
          />
        </GridContainer>
        {/* <Dialog open={this.state.isLoading}>
          <CircularProgress style={{ padding: "10px" }} />
        </Dialog> */}
        {this.state.isLoading ? (
          <SkeletonTheme color="#ddd" highlightColor="#aaa">
            <Skeleton count={5} height="50vh" />
          </SkeletonTheme>
        ) : (
          <GridContainer>{this.handlingCollegeDisplay()}</GridContainer>
        )}

        {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
      </React.Fragment>
    );
  }
}

export default connect(null, { showNotification })(MultiCollegeEdit);
