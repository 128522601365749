import React, { Component } from "react";
import TagsMultiSelector from "views/College/Basic/Attachment/TagsMultiSelector";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";

class TagsSelector extends Component {
  arrayModified = valuesItem => {
    const newValue = valuesItem.data.map(item => {
      const { id, full_name } = item;
      return {
        id,
        value: full_name,
        label: `${full_name}`
      };
    });
    return newValue;
  };

  getFilteredElementsData = elements => {
    const { setTagsSelectorValue, selectedTags } = this.props;
    const tagsArray = elements.filter(item => item.key === "tags")[0];
    if (tagsArray.data.length > 0) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <p style={{ fontWeight: "bold" }}>{tagsArray.name}</p>
            <TagsMultiSelector
              data={this.arrayModified(tagsArray)}
              isMulti={true}
              onChange={value => {
                setTagsSelectorValue(value);
              }}
              selectedValues={selectedTags}
            />
          </GridItem>
        </GridContainer>
      );
    }
  };

  render() {
    const { elements } = this.props;
    if (elements.data.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div>{this.getFilteredElementsData(elements.data)}</div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(TagsSelector);
