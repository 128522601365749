import React, { Component } from "react";
import NavPills from "components/NavPills/NavPills.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CollegeBasic from "views/College/Basic/CollegeBasic/CollegeBasic.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";

import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import Attachment from "views/College/Basic/Attachment/Attachment.jsx";
import Stream from "views/College/Basic/Stream/Stream.jsx";
import ContactInfoList from "views/College/Basic/ContactDetail/ContactInfoList";
import Facilities from "views/College/Basic/Facilities/Facilities";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import RankingSection from "views/College/Basic/Ranking/RankingSection";

const navPillData = [
  {
    displayButton: "Basic",
    component: CollegeBasic
  },
  {
    displayButton: "Stream",
    component: Stream
  },
  {
    displayButton: "Contact",
    component: ContactInfoList
  },
  {
    displayButton: "Facilities",
    component: Facilities
  },
  {
    displayButton: "Attachment",
    component: Attachment
  },
  {
    displayButton: "Ranking",
    component: RankingSection
  }
];

class CollegeBasicTabs extends Component {
  state = {
    showDeleteAlert: false
  };

  handleAlertSuccess = () => {
    this.setState({ showDeleteAlert: false });
    this.props.history.push("/college-listing");
  };

  showDeleteAlertHandler = () => {
    if (this.state.showDeleteAlert) {
      return (
        <DeleteAlertBox
          handleSuccess={this.handleAlertSuccess}
          handleClose={() => this.setState({ showDeleteAlert: false })}
          title="Leave Editor ?"
        />
      );
    }
  };
  collegeTabsData = () => {
    const { id } = this.props.match.params;
    const { history } = this.props;
    const data = navPillData.map(item => {
      return {
        tabButton: item.displayButton,
        tabContent: (
          <Card
            style={{
              backgroundColor: "#f7f7f7"
            }}
          >
            <CardBody>
              <item.component history={history} id={id} />
            </CardBody>
          </Card>
        )
      };
    });
    return data;
  };

  render() {
    const { classes } = this.props;
    // console.log(history);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              size="sm"
              title="Go Back"
              onClick={() => {
                this.setState({ showDeleteAlert: true });
              }}
              className={classes.customBackButton}
            >
              <KeyboardArrowLeft />
              BACK
            </Button>
          </GridItem>
        </GridContainer>
        {this.showDeleteAlertHandler()}
        <GridContainer>
          <GridItem xs={12}>
            {/* <Card
              style={{
                backgroundColor: "#f7f7f7"
              }}>
              <CardBody> */}
            <NavPills color="rose" tabs={this.collegeTabsData()} />
            {/* </CardBody>
            </Card> */}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(formStyle)(CollegeBasicTabs);
