const shortNameLength = "15";
const shortNameForm = "50";

const headerTagTypes = [
  {
    id: "header-1",
    label: "h1",
    value: "h1"
  },
  {
    id: "header-2",
    label: "h2",
    value: "h2"
  },
  {
    id: "header-3",
    label: "h3",
    value: "h3"
  },
  {
    id: "header-4",
    label: "h4",
    value: "h4"
  },
  {
    id: "header-5",
    label: "h5",
    value: "h5"
  },
  {
    id: "header-6",
    label: "h6",
    value: "h6"
  }
];

const fixedPart = "https://entrancezone.com/exams/";

module.exports = {
  shortNameLength,
  shortNameForm,
  fixedPart,
  headerTagTypes
};
