import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import * as actions from "data/universities/actions";

import paginationStyle from "assets/jss/material-dashboard-pro-react/components/paginationStyle.jsx";

class UniversityPagination extends Component {
  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (total, page) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage = page => {
    const { currentPage } = this.props;
    const { universities } = this.props;
    const { data, appState } = universities;
    const { pageLimit } = appState;
    this.props.setCurrentUniversityPage(page, pageLimit);
    if (page === currentPage) {
      return;
    }
    if (!data.data.hasOwnProperty(`${page}`)) {
      this.props.getAllUniversities((page - 1) * pageLimit, pageLimit, page);
    }
  };
  render() {
    const { classes } = this.props;
    const { pages, currentPage } = this.props;
    const visiblePages = this.getVisiblePages(pages, currentPage);
    return (
      <GridContainer justify="flex-end">
        <GridItem>
          <ul className={classes.pagination}>
            <Button
              className={classes.paginationLink}
              onClick={() => {
                if (currentPage === 1) return;
                this.changePage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              {"PREV"}
            </Button>
            {visiblePages.length
              ? visiblePages.map((page, key, array) => {
                  const paginationLink = cx({
                    [classes.paginationLink]: true,
                    [classes["info"]]: true,
                    [classes.disabled]: page.disabled
                  });
                  return (
                    <li className={classes.paginationItem} key={key}>
                      <Button
                        className={
                          currentPage === page
                            ? paginationLink
                            : classes.paginationLink
                        }
                        onClick={() => this.changePage(page)}
                      >
                        {array[key - 1] + 2 < page ? `...${page}` : page}
                      </Button>
                    </li>
                  );
                })
              : null}

            <Button
              className={classes.paginationLink}
              onClick={() => {
                if (currentPage === this.props.pages) return;
                this.changePage(currentPage + 1);
              }}
              disabled={currentPage === this.props.pages}
            >
              {"NEXT"}
            </Button>
          </ul>
        </GridItem>
      </GridContainer>
    );
  }
}

UniversityPagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func
};

function mapStateToProps({ universities }) {
  return { universities };
}

export default withStyles(paginationStyle)(
  connect(mapStateToProps, actions)(UniversityPagination)
);
