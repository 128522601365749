// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

import Add from "@material-ui/icons/Add";
import HandlingDatesSelector from "views/Forms/EazyForms/FormBasic/HandlingDatesSelector.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import { FastField } from "formik";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import _isEmpty from "lodash/isEmpty";

const tableHead = [
  " Date Type",
  "Display Name",
  "Is Tentative",
  "Dates",
  "Actions"
];

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class DatesFormTables extends Component {
  state = {
    selectedIndex: null,
    open: false
  };

  handleClickOpen = index => {
    this.setState({ open: true, selectedIndex: index });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  dateSelectorModal = () => {
    const { classes, values, setFieldValue } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => this.handleClose()}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description">
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
            style={{
              width: "670px",
              paddingTop: "0px"
            }}>
            <HandlingDatesSelector
              onClose={() => this.handleClose()}
              setFieldValue={setFieldValue}
              values={values}
              mainIndex={this.state.selectedIndex}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  handleRemoveDate = index => {
    const { setFieldValue } = this.props;
    setFieldValue(`form_basic.dates[${index}].data`, {});
    setFieldValue(`form_basic.dates[${index}].is_tentative`, false);
  };

  addButton = index => {
    const { classes } = this.props;
    return (
      <Tooltip
        title="Add Dates"
        placement="top"
        classes={{ tooltip: classes.tooltip }}>
        <Button
          onClick={() => this.handleClickOpen(index)}
          className={classes.actionButton}
          color="info"
          size="lg">
          <Add className={classes.icon} />
        </Button>
      </Tooltip>
    );
  };

  editButton = index => {
    const { classes } = this.props;
    return (
      <Tooltip
        title="Edit Dates"
        placement="top"
        classes={{ tooltip: classes.tooltip }}>
        <Button
          onClick={() => this.handleClickOpen(index)}
          className={classes.actionButton}
          color="success"
          size="lg"
          title="Save Changes">
          <Edit className={classes.icon} />
        </Button>
      </Tooltip>
    );
  };

  removeButton = index => {
    const { classes } = this.props;
    return (
      <Tooltip
        title="Remove Dates"
        placement="top"
        classes={{ tooltip: classes.tooltip }}>
        <Button
          onClick={() => this.handleRemoveDate(index)}
          className={classes.actionButton}
          color="danger"
          size="lg">
          <Close className={classes.icon} />
        </Button>
      </Tooltip>
    );
  };

  showValuesfromFormik = index => {
    const valuesData = this.props.values.form_basic.dates[`${index}`].data;
    const { classes } = this.props;
    const selector_type = valuesData.selector_type || "single";

    switch (selector_type) {
      case "single": {
        return (
          <React.Fragment>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date ? (
                <Chip
                  label={moment(valuesData.selector_date).format("DD/MM/YYYY")}
                  className={classes.chip}
                />
              ) : null}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date ? (
                <div>
                  <span>{this.editButton(index)}</span>
                  <span>{this.removeButton(index)}</span>
                </div>
              ) : (
                this.addButton(index)
              )}
            </TableCell>
          </React.Fragment>
        );
      }
      case "multi": {
        return (
          <React.Fragment>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date
                ? valuesData.selector_date.map((date, index) => {
                    return (
                      <Chip
                        label={moment(date).format("DD/MM/YYYY")}
                        className={classes.chip}
                        key={index}
                      />
                    );
                  })
                : null}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date ? (
                <div>
                  <span>{this.editButton(index)}</span>
                  <span>{this.removeButton(index)}</span>
                </div>
              ) : (
                this.addButton(index)
              )}
            </TableCell>
          </React.Fragment>
        );
      }
      case "range": {
        return (
          <React.Fragment>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date.start &&
              valuesData.selector_date.end ? (
                <div>
                  <Chip
                    label={moment(valuesData.selector_date.start).format(
                      "DD/MM/YYYY"
                    )}
                    className={classes.chip}
                  />
                  <span>{`------>`}</span>
                  <Chip
                    label={moment(valuesData.selector_date.end).format(
                      "DD/MM/YYYY"
                    )}
                    className={classes.chip}
                  />
                </div>
              ) : null}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {valuesData.selector_date ? (
                <div>
                  <span>{this.editButton(index)}</span>
                  <span>{this.removeButton(index)}</span>
                </div>
              ) : (
                this.addButton(index)
              )}
            </TableCell>
          </React.Fragment>
        );
      }
      default:
        break;
    }
  };

  render() {
    const { classes, values, setFieldValue } = this.props;
    return (
      <React.Fragment>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{`Dates Section`}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHead className={classes["primary"]}>
                    <TableRow className={classes.tableRow}>
                      {tableHead.map((prop, key) => {
                        {
                          const tableCellClasses =
                            classes.tableHeadCell + " " + classes.tableCell;

                          return (
                            <TableCell key={key} className={tableCellClasses}>
                              {prop}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.form_basic.dates.map((date, index) => {
                      return (
                        <TableRow key={index} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            {values.form_basic.dates[`${index}`].full_name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <FastField
                              name={`form_basic.dates[${index}].display_name`}
                              component={BareTextFieldInput}
                              onBlur={e => {
                                const domain = e.target.value;
                                setFieldValue(
                                  `form_basic.dates[${index}].display_name`,
                                  domain
                                );
                              }}
                              defaultValue={
                                values.form_basic.dates[`${index}`].display_name
                              }
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {_isEmpty(values.form_basic.dates[index].data) ? (
                              <p> ---- </p>
                            ) : (
                              <FastField
                                name={`form_basic.dates[${index}].is_tentative`}
                                component={FormikToggleButton}
                                onBlur={checkedValue => {
                                  setFieldValue(
                                    `form_basic.dates[${index}].is_tentative`,
                                    !checkedValue
                                  );
                                }}
                                value={
                                  values.form_basic.dates[`${index}`]
                                    .is_tentative || false
                                }
                              />
                            )}
                          </TableCell>
                          {this.showValuesfromFormik(index)}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.open ? this.dateSelectorModal() : null}
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(DatesFormTables);
