import { COLLEGES_DATA_ALL_GET } from "data/colleges/types";

const combinePages = (state, action) => {
  return {
    ...JSON.parse(JSON.stringify(state.initialData)),
    [action.currentPage]: action.data
  };
};

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(
  state = { initialData: {}, data: {}, isSearched: false },
  action
) {
  switch (action.type) {
    case COLLEGES_DATA_ALL_GET:
      if (action.search) {
        return {
          data: { [action.currentPage]: action.data },
          initialData: state.initialData,
          isSearched: action.search
        };
      }
      const initialData = combinePages(state, action);
      return { data: initialData, initialData, isSearched: false };
    default:
      return state;
  }
}
