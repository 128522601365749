import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import api from "data/utils/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import _cloneDeep from "lodash/cloneDeep";

const RankingSection = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [rankingBodyOptions, setRankingBodyOptions] = useState([]);
  const [rankingCategoryOptions, setRankingCategoryOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [data, setData] = useState([]);
  const columns = [
    {
      title: "Ranking Body",
      field: "ranking_body_id",
      type: "numeric",
      initialEditValue: rankingBodyOptions[0]
        ? rankingBodyOptions[0].label
        : "",

      cellStyle: { width: "20%" },
      editComponent: props => (
        <Select
          defaultValue={
            rankingBodyOptions.find(item => item.value === props.value) ||
            rankingBodyOptions[0]
          }
          options={rankingBodyOptions}
          onChange={e => props.onChange(e.value)}
          components={makeAnimated()}
        />
      )
    },
    {
      title: "Ranking Category",
      field: "ranking_category_id",
      type: "numeric",
      initialEditValue: rankingCategoryOptions[0]
        ? rankingCategoryOptions[0].label
        : "",

      cellStyle: { width: "20%" },
      editComponent: props => (
        <Select
          defaultValue={
            rankingCategoryOptions.find(item => item.value === props.value) ||
            rankingCategoryOptions[0]
          }
          options={rankingCategoryOptions}
          components={makeAnimated()}
          onChange={e => props.onChange(e.value)}
        />
      )
    },
    {
      title: "Year",
      field: "year",
      type: "numeric",
      initialEditValue: yearOptions[0] ? yearOptions[0].label : "",
      cellStyle: { width: "15%" },
      editComponent: props => (
        <Select
          defaultValue={
            yearOptions.find(item => item.value === props.value) ||
            yearOptions[0]
          }
          options={yearOptions}
          components={makeAnimated()}
          onChange={e => props.onChange(e.value)}
        />
      )
    },
    {
      title: "Rank",
      field: "rank",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Score",
      field: "score",
      type: "numeric",
      cellStyle: { width: "15%" }
    },
    {
      title: "Max Score",
      field: "max_score",
      type: "numeric",
      cellStyle: { width: "15%" }
    }
  ];

  const { enqueueSnackbar } = useSnackbar();

  const parseData = (newData, ranking_bodies, ranking_categories) => {
    const dataParsed = newData.map(rank => {
      return {
        id: rank.id,
        score: parseFloat(rank.score),
        max_score: parseFloat(rank.max_score),
        rank: parseInt(rank.rank),
        year: parseInt(rank.year),
        ranking_body_id: ranking_bodies.find(
          item => item.id === rank.ranking_body_id
        ).label,
        ranking_category_id: ranking_categories.find(
          item => item.id === rank.ranking_category_id
        ).label
      };
    });
    return dataParsed;
  };

  useEffect(() => {
    async function getCollegeRankingData() {
      //make api call here.
      try {
        setLoading(true);
        const [rankings, selectors] = await Promise.all([
          api.get(`${api.endpoints.colleges}/${id}/rankings`),
          api.get(`${api.endpoints.colleges}/${id}/rankings/selectors`)
        ]);
        const {
          ranking_bodies = [],
          ranking_categories = [],
          year = []
        } = selectors.data;
        const rankingData = parseData(
          rankings.data,
          ranking_bodies,
          ranking_categories
        );
        setLoading(false);
        setRankingBodyOptions(ranking_bodies);
        setRankingCategoryOptions(ranking_categories);
        setYearOptions(year);
        setData(rankingData);
      } catch (error) {
        const { data: { message = "Something Unusual Happened" } = {} } =
          error || {};
        setLoading(false);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
    getCollegeRankingData();
  }, [id]);

  const handleDataAddition = async newData => {
    try {
      const {
        max_score,
        rank,
        ranking_body_id,
        ranking_category_id,
        score,
        year
      } = newData;

      const modifiedData = {
        max_score: parseFloat(max_score),
        rank: parseInt(rank),
        year: parseInt(year),
        score: parseFloat(score),
        ranking_body_id: rankingBodyOptions.find(
          item => item.label === ranking_body_id
        ).id,
        ranking_category_id: rankingCategoryOptions.find(
          item => item.label === ranking_category_id
        ).id
      };
      if (
        !modifiedData.score ||
        !modifiedData.max_score ||
        !modifiedData.rank ||
        !modifiedData.ranking_body_id ||
        !modifiedData.ranking_category_id ||
        !modifiedData.year
      )
        throw new Error("All fields are mandatory");

      if (modifiedData.score > modifiedData.max_score)
        throw new Error("Score cannot be greater than Max Score");

      const res = await api.post(
        `${api.endpoints.colleges}/${id}/rankings`,
        modifiedData
      );
      const clonedData = _cloneDeep(data);

      const newDataWithId = { ...newData, id: res.id };
      clonedData.push(newDataWithId);

      setData(clonedData);
    } catch (error) {
      const {
        data: { message = error.message || "Something Unusual Happened" } = {}
      } = error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleDataDeletion = async newData => {
    try {
      const rank_id = newData.id;
      await api.delete(`${api.endpoints.colleges}/${id}/rankings/${rank_id}`);
      let clonedData = _cloneDeep(data);
      const index = newData.tableData.id;
      clonedData.splice(index, 1);
      setData(clonedData);
    } catch (error) {
      const {
        data: { message = error.message || "Something Unusual Happened" } = {}
      } = error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  const handleDataUpdation = async (newData, oldData) => {
    try {
      const {
        id: rank_id,
        max_score,
        rank,
        ranking_body_id,
        ranking_category_id,
        score,
        year
      } = newData;

      const modifiedData = {
        max_score: parseFloat(max_score),
        rank: parseInt(rank),
        year: parseInt(year),
        score: parseFloat(score),
        ranking_body_id: rankingBodyOptions.find(
          item => item.label === ranking_body_id
        ).id,
        ranking_category_id: rankingCategoryOptions.find(
          item => item.label === ranking_category_id
        ).id
      };

      if (
        !modifiedData.score ||
        !modifiedData.max_score ||
        !modifiedData.rank ||
        !modifiedData.ranking_body_id ||
        !modifiedData.ranking_category_id ||
        !modifiedData.year
      )
        throw new Error("All fields are mandatory");

      if (modifiedData.score > modifiedData.max_score)
        throw new Error("Score cannot be greater than Max Score");

      await api.put(
        `${api.endpoints.colleges}/${id}/rankings/${rank_id}`,
        modifiedData
      );

      let clonedData = _cloneDeep(data);
      const index = oldData.tableData.id;
      clonedData[index] = { ...newData };
      setData(clonedData);
    } catch (error) {
      const {
        data: { message = error.message || "Something Unusual Happened" } = {}
      } = error || {};

      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  };

  return (
    <MaterialTable
      title="College Ranking"
      columns={columns}
      data={data}
      isLoading={loading}
      options={{
        actionsColumnIndex: 6
      }}
      editable={{
        onRowAdd: newData => handleDataAddition(newData),
        onRowUpdate: (newData, oldData) => handleDataUpdation(newData, oldData),
        onRowDelete: newData => handleDataDeletion(newData)
      }}
    />
  );
};

export default withStyles(formStyle)(RankingSection);
