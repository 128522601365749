import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import { FastField, Field } from "formik";
import FormikToggleButton from "views/Components/FormikToggleButton.jsx";
import AsyncCheckUniqueHooks from "views/Components/College/AsyncCheckUniqueHooks.jsx";
import CollegeInputFields from "views/College/Basic/CollegeBasic/CollegeInputFields.jsx";
import CollegeImages from "views/College/Basic/CollegeBasic/CollegeImages.jsx";
import SelectorBasedOnKey from "views/Components/College/SelectorBasedOnKey.jsx";
import UniversityTypeSelector from "views/College/Basic/CollegeBasic/UniversityTypeSelector.jsx";
import FormikErrorMessage from "views/College/Basic/CollegeBasic/FormikErrorMessage.jsx";
import api from "data/utils/api";
import { shortNameForm } from "utils/values.jsx";

class CollegeIntro extends React.Component {
  render() {
    const { classes, setFieldValue, values, id } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer justify="flex-end">
            <GridItem>
              <Card>
                <CardBody>
                  {values.is_active ? (
                    <label style={{ fontWeight: "bold", color: "#4CAF50" }}>
                      Published
                    </label>
                  ) : (
                    <label style={{ fontWeight: "bold", color: "#F44336" }}>
                      Published
                    </label>
                  )}
                  <FastField
                    name="is_active"
                    component={FormikToggleButton}
                    onBlur={checkedValue => {
                      setFieldValue("is_active", !checkedValue);
                    }}
                    value={values.is_active}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>Basic Info - College</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <br />
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <p style={{ fontWeight: "bold", margin: "0px" }}>
                        Short Name <span style={{ color: "red" }}>*</span>
                      </p>
                      <FastField
                        component={AsyncCheckUniqueHooks}
                        inputValue={values.short_name}
                        id={id}
                        apiEndpoint={api.endpoints.college.filters}
                        filterKey="short_name"
                        maxlength={shortNameForm}
                        placeholder="Short Name"
                        setInputValue={value =>
                          setFieldValue("short_name", value)
                        }
                      />
                      <FormikErrorMessage nameValue="short_name" />
                    </GridItem>
                    <GridItem xs={12}>
                      <br />
                      <p style={{ fontWeight: "bold", margin: "0px" }}>
                        Full Name <span style={{ color: "red" }}>*</span>
                      </p>
                      <FastField
                        component={AsyncCheckUniqueHooks}
                        inputValue={values.full_name}
                        id={id}
                        apiEndpoint={api.endpoints.college.filters}
                        filterKey="full_name"
                        placeholder="Full Name"
                        setInputValue={value =>
                          setFieldValue("full_name", value)
                        }
                      />
                      <FormikErrorMessage nameValue="full_name" />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {id ? (
                  <CollegeImages
                    logo={values.logo}
                    featured_image={values.featured_image}
                    setFieldValue={setFieldValue}
                    folderPath="colleges"
                    id={id}
                  />
                ) : null}
              </GridContainer>
              <GridContainer>
                <CollegeInputFields
                  setFieldValue={setFieldValue}
                  websiteURL={values.website_url}
                  establishmentYear={values.establishment_year}
                  campusSize={values.campus_size}
                />
                <label style={{ marginLeft: "18px", marginTop: "12px" }}>
                  <a
                    href={values.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {values.website_url}
                  </a>
                </label>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <FastField
                    component={SelectorBasedOnKey}
                    valuesData={values.ownership_type_id}
                    keyFilter="ownership_types"
                    isMulti={false}
                    nameKey="ownership_type_id"
                    setFieldValue={setFieldValue}
                    isRequired={true}
                  />
                  <FormikErrorMessage nameValue="ownership_type_id" />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <FastField
                    component={SelectorBasedOnKey}
                    valuesData={values.institution_type_id}
                    keyFilter="institution_types"
                    isMulti={false}
                    nameKey="institution_type_id"
                    setFieldValue={setFieldValue}
                    isRequired={true}
                  />
                  <FormikErrorMessage nameValue="institution_type_id" />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <Field
                    name="university_type_id"
                    component={UniversityTypeSelector}
                    valuesData={values}
                    setFieldValue={setFieldValue}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(tableFormStyles)(CollegeIntro);
