import React, { Component } from "react";
import Select from "react-select";
// import { colourOptions, groupedOptions } from './docs/data';
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeAnimated from "react-select/animated";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const customStyles = {
  valueContainer: styles => ({
    ...styles,
    maxHeight: "150px",
    overflow: "auto"
  })
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

class TreeMultiSelector extends Component {
  state = {
    selectAll: false
  };
  handleChange = value => {
    const { data, isMulti, onChange } = this.props;
    if (isMulti) {
      let data_id = value.map(val => val.id);
      onChange(data_id);
      const selectorValue = data.map(item => item.options);
      const newDataArray = Array.prototype.concat(...selectorValue);
      if (data_id.length < newDataArray.length) {
        this.setState({ selectAll: false });
      } else this.setState({ selectAll: true });
    } else onChange(value.id);
  };

  componentDidMount() {
    const { selectedValues, data, isMulti } = this.props;
    if (!_.isEmpty(selectedValues)) {
      const selectorValue = data.map(item => item.options);
      const newDataArray = Array.prototype.concat(...selectorValue);
      if (selectedValues.length === newDataArray.length && isMulti) {
        this.setState({ selectAll: true });
      }
    }
  }

  handleValue = () => {
    const { selectedValues, data, isMulti } = this.props;
    var selectorValue = null;
    var updatedValues = null;

    if (selectedValues) {
      selectorValue = data.map(item => {
        const data = item.options;
        updatedValues = data.filter(newItem =>
          isMulti
            ? selectedValues.includes(newItem.id)
            : selectedValues === newItem.id
        );
        return updatedValues;
      });
      return Array.prototype.concat(...selectorValue);
    }
  };

  selectAllValue = selectAll => {
    const { data } = this.props;

    if (selectAll) {
      let data_id = data.map(item => {
        return item.options.map(val => val.id);
      });

      const updatedData = Array.prototype.concat(...data_id);
      this.setState({ selectAll });
      this.props.onChange(updatedData);
    } else {
      this.setState({ selectAll });
      this.props.onChange([]);
    }
  };
  render() {
    const { data, isMulti } = this.props;
    const formatGroupLabel = dataLen => (
      <div style={groupStyles}>
        <span>{dataLen.label}</span>
        <span style={groupBadgeStyles}>{dataLen.options.length}</span>
      </div>
    );
    return (
      <React.Fragment>
        <Select
          options={data}
          formatGroupLabel={formatGroupLabel}
          components={makeAnimated()}
          isMulti={true}
          value={this.handleValue()}
          onChange={this.handleChange}
          styles={customStyles}
          backspaceRemovesValue={false}
        />
        {isMulti ? (
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={this.state.selectAll}
                onClick={() => this.selectAllValue(!this.state.selectAll)}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                color="primary"
                margin="dense"
              />
            }
            labelPlacement="end"
            label="Select All"
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default TreeMultiSelector;
