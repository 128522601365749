import React, { Component } from "react";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

class MainNotification extends Component {
  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  startTimer() {
    setTimeout(
      function() {
        this.props.onClose();
      }.bind(this),
      5000
    );
  }

  render() {
    const { color, message } = this.props;
    return (
      <Snackbar
        place="tr"
        color={color || "info"}
        message={message}
        open
        closeNotification={this.props.onClose}
        close
      />
    );
  }
}

export default withStyles(notificationsStyle)(MainNotification);
