import React, { Fragment, useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const FAQItem = ({ id, queNo, que, ans, handleItemUpdation }) => {
  return (
    <Fragment>
      <ExpansionPanel square>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header">
          <div>
            <h4>
              <b>Que {queNo}. </b>
              {que}
            </h4>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Card
            style={{
              backgroundColor: "#f6f8fa",
              boxShadow: "none",
              margin: "0px"
            }}>
            <CardBody>
              <b>Ans. </b>
              {ans}
            </CardBody>
          </Card>
        </ExpansionPanelDetails>
        <ExpansionPanelActions>
          <Button color="primary" onClick={() => handleItemUpdation(id)}>
            Edit
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Fragment>
  );
};

export default FAQItem;
