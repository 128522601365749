import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectorBasedOnKey from "views/Components/College/SelectorBasedOnKey";
import { FastField } from "formik";
import AsyncMultiSelector from "views/Components/College/AsyncMultiSelector.jsx";
import api from "data/utils/api";

const UniversityTypeSelector = ({ valuesData, setFieldValue, elements }) => {
  const { university_type_id, institution_type_id, affiliated_to } = valuesData;
  const [institute_type, setInstituteType] = useState();

  useEffect(() => {
    if (!institution_type_id) {
      setFieldValue("university_type_id", null);
      setFieldValue("affiliated_to", null);
      setInstituteType(null);
      return;
    }
    if (elements.data.length && institution_type_id) {
      const instituteTypeString = elements.data
        .filter(item => item.key === "institution_types")[0]
        .data.filter(item => item.id === institution_type_id)[0]
        .full_name.trim()
        .toLowerCase();
      setInstituteType(instituteTypeString);
      if (instituteTypeString === "college") {
        setFieldValue("university_type_id", null);
      } else if (instituteTypeString === "university") {
        setFieldValue("affiliated_to", null);
      }
    }
  }, [institution_type_id]);

  const getFilteredElementsData = () => {
    let universityData = null;
    if (institute_type === "university") {
      universityData = (
        <FastField
          name="university_type_id"
          component={SelectorBasedOnKey}
          valuesData={university_type_id}
          keyFilter="university_types"
          isMulti={false}
          nameKey="university_type_id"
          setFieldValue={setFieldValue}
        />
      );
    } else if (institute_type === "college") {
      universityData = (
        <React.Fragment>
          <p style={{ fontWeight: "bold" }}>Affiliated To</p>
          <FastField
            component={AsyncMultiSelector}
            selectedValues={affiliated_to}
            filterKey="affiliated_to"
            isMulti={false}
            name="affiliated_to"
            apiEndpoint={api.endpoints.college.filters}
            onChange={value => {
              setFieldValue("affiliated_to", value);
            }}
          />
        </React.Fragment>
      );
    }
    return universityData;
  };

  return (
    <React.Fragment>
      {elements.data.length ? (
        <div>{getFilteredElementsData(elements.data)}</div>
      ) : null}
    </React.Fragment>
  );
};

function mapStateToProps({ elements }) {
  return { elements };
}

export default connect(mapStateToProps)(UniversityTypeSelector);
