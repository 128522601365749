//all the types of the FORMS should be added here.
export const FORMS_APPSTATE_ALL_LOADING = "FORMS_APPSTATE_ALL_LOADING";
export const FORMS_APPSTATE_ALL_SUCCESS = "FORMS_APPSTATE_ALL_SUCCESS";
export const FORMS_APPSTATE_ALL_ERROR = "FORMS_APPSTATE_ALL_ERROR";

export const FORM_BASIC_APPSTATE_LOADING = "FORM_BASIC_APPSTATE_LOADING";
export const FORM_BASIC_APPSTATE_SUCCESS = "FORM_BASIC_APPSTATE_SUCCESS";
export const FORM_BASIC_APPSTATE_ERROR = "FORM_BASIC_APPSTATE_ERROR";
export const FORM_BASIC_APPSTATE_RESET = "FORM_BASIC_APPSTATE_RESET";

export const FORM_DETAIL_APPSTATE_LOADING = "FORM_DETAIL_APPSTATE_LOADING";
export const FORM_DETAIL_APPSTATE_SUCCESS = "FORM_DETAIL_APPSTATE_SUCCESS";
export const FORM_DETAIL_APPSTATE_ERROR = "FORM_DETAIL_APPSTATE_ERROR";

export const FORMS_DATA_ALL_GET = "FORMS_DATA_ALL_GET";
export const FORM_BASIC_DATA_GET = "FORM_BASIC_DATA_GET";
export const FORM_BASIC_DATA_RESET = "FORM_BASIC_DATA_RESET";

export const FORM_DETAIL_DATA_GET = "FORM_DETAIL_DATA_GET";
export const FORM_DETAIL_DATA_RESET = "FORM_DETAIL_DATA_RESET";

export const FORMS_DATA_ONE_DELETE = "FORMS_DATA_ONE_DELETE";

export const FORMS_UI_SHOW_ALERT_BOX = "FORMS_UI_SHOW_ALERT_BOX";
export const FORMS_UI_HIDE_ALERT_BOX = "FORMS_UI_HIDE_ALERT_BOX";

export const FORMS_DATA_TOTAL_COUNT = "FORMS_DATA_TOTAL_COUNT";
export const FORMS_DATA_CONTENT_COUNT = "FORMS_DATA_CONTENT_COUNT";
export const FORMS_DATA_PAGE_LIMIT = "FORMS_DATA_PAGE_LIMIT";
export const FORMS_DATA_PAGE_START = "FORMS_DATA_PAGE_START";
export const FORMS_DATA_CURRENT_PAGE = "FORMS_DATA_CURRENT_PAGE";
