import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import _ from "lodash";

const customStyles = {
  valueContainer: styles => ({
    ...styles,
    maxHeight: "150px",
    overflow: "auto"
  })
  // control: (base, state) => ({
  //   ...base
  // })
};

class DropDownSelectorOnChange extends Component {
  state = {
    selectAll: false
  };
  handleChange = value => {
    const { data, isMulti, onChange } = this.props;
    if (isMulti) {
      let data_id = value.map(val => val.id);
      onChange(data_id);
      if (data_id.length < data.length) {
        this.setState({ selectAll: false });
      } else this.setState({ selectAll: true });
    } else {
      if (value) onChange(value.id);
      else onChange(value);
    }
  };

  componentDidMount() {
    const { selectedValues, data, isMulti } = this.props;
    if (!_.isEmpty(selectedValues)) {
      if (selectedValues.length === data.length && isMulti) {
        this.setState({ selectAll: true });
      }
    }
  }

  handleValue = () => {
    const { selectedValues, data, isMulti } = this.props;
    var selectorValue = null;

    if (selectedValues) {
      selectorValue = data.filter(item =>
        isMulti ? selectedValues.includes(item.id) : selectedValues === item.id
      );
    }
    return selectorValue;
  };

  selectAllValue = selectAll => {
    const { data } = this.props;
    if (selectAll) {
      let data_id = data.map(val => val.id);
      this.setState({ selectAll });
      this.props.onChange(data_id);
    } else {
      this.setState({ selectAll });
      this.props.onChange([]);
    }
  };

  render() {
    const { data, isMulti } = this.props;

    return (
      <React.Fragment>
        <div>
          <Select
            isClearable
            isMulti={isMulti}
            options={data}
            value={this.handleValue()}
            components={makeAnimated()}
            // className="basic-multi-select"
            // classNamePrefix="select"
            onChange={this.handleChange}
            styles={customStyles}
            backspaceRemovesValue={false}
          />
        </div>
        {isMulti ? (
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={this.state.selectAll}
                onClick={() => this.selectAllValue(!this.state.selectAll)}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                color="primary"
                // margin="dense"
                margin="0"
                style={{ padding: "0px", marginRight: "3px" }}
              />
            }
            labelPlacement="end"
            label="Select All"
            style={{ marginTop: "10px", marginLeft: "0px" }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default DropDownSelectorOnChange;
