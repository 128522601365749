import React, { Component } from "react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { Editor } from "@tinymce/tinymce-react";

class FormikCompleteEditor extends Component {
  /**
   * @property Jodit jodit instance of native Jodit
   */
  jodit;
  setRef = jodit => (this.jodit = jodit);

  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: "500",
    useAceEditor: true,
    toolbarSticky: true
  };

  updateContent = value => {
    this.props.onBlur(value.target.getContent());
    // console.log();
  };

  render() {
    const { onBlur, valueInput } = this.props;
    // console.log(this.props);

    return (
      <div style={{ marginLeft: "1px" }}>
        <Editor
          initialValue={valueInput}
          // style={{ width: "100%", height: "600px" }}
          init={{
            // plugins: [
            //   "table",
            //   "link image code",
            //   "fullscreen",
            //   "print",
            //   "preview",
            //   "textcolor",
            //   "colorpicker",
            //   "media",
            //   "wordcount",
            //   "template"
            // ],
            plugins: [
              "advlist autolink lists link image charmap print preview anchor textcolor",
              "searchreplace visualblocks code fullscreen toc",
              "insertdatetime media table paste code help wordcount"
            ],
            // table_default_styles: {
            //   width: "100%",
            //   padding: "0",
            //   margin: "0",
            //   text_align: "center"
            // },
            height: 600,
            // selector: "textarea",
            // theme: "modern",
            toolbar:
              " undo redo | formatselect | bold italic forecolor backcolor permanentpen formatpainter | specialsymbol |  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsizeselect fontselect | table |  link media image pageembed  removeformat | fullscreen | preview"
          }}
          onChange={this.updateContent}
        />
      </div>
    );
  }
}

export default FormikCompleteEditor;
