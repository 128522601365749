import React, { useEffect, useState, useRef } from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";

import {
  Avatar,
  Chip,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import _cloneDeep from "lodash/cloneDeep";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(styles);

const NewsletterFilter = ({
  filters: allFilters,
  handleFilterChange,
  rowSelectedFilter,
  showSearchBar
}) => {
  // ==================================================================================================
  // STATE AND VARIABLE DECLARATION ===================================================================
  // ==================================================================================================
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filters, setFilters] = useState(allFilters);
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const handleStateCityCase = () => {
    const selectedStateData = selectedFilters.find(
      filter => filter.key === "state"
    );

    const selectedCityData = selectedFilters.find(
      filter => filter.key === "city"
    );
    if (selectedStateData) {
      const getStateData = (
        filters.find(filter => filter.key === "state") || { data: [] }
      ).data.find(item => item.name === selectedStateData.value) || {
        cities: []
      };
      const allCities = getStateData.cities.map(city => ({
        name: city,
        value: city
      }));
      const cityObject = {
        name: "City",
        key: "city",
        type: "single",
        data: allCities
      };
      const clonedData = _cloneDeep(filters);

      const cityData = clonedData.find(filter => filter.key === "city");
      if (cityData) {
        cityData.data = allCities;
      } else {
        clonedData.push(cityObject);
      }
      setFilters(clonedData);
    } else if (selectedCityData) {
      //remove the city from the filters.
      const clonedData = _cloneDeep(filters);

      const filteredData = clonedData.filter(filter => filter.key !== "city");
      setFilters(filteredData);
    }
  };

  // ==================================================================================================
  // USE EFFECT =======================================================================================
  // ==================================================================================================
  useEffect(() => {
    //Check if the state key is present.
    handleStateCityCase();
    handleFilterChange(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    setFilters(allFilters);
  }, [allFilters]);

  useEffect(() => {
    if (rowSelectedFilter.key) {
      const { key, value } = rowSelectedFilter;
      const data = selectedFilters.filter(
        filter => filter.key === key && filter.value === value
      );
      if (!data.length) {
        const newFilters = selectedFilters.map(filter => filter);
        newFilters.push(rowSelectedFilter);
        setSelectedFilters(newFilters);
      }
    }
  }, [rowSelectedFilter]);

  // ==================================================================================================
  // CALLBACK FUNCTIONS ===============================================================================
  // ==================================================================================================
  const handleMultiple = event => {
    const key = event.target.name;
    const values = event.target.value;
    const structeredValues = values.map(value => {
      return {
        key,
        value
      };
    });
    const selectedFiltersWithoutKeyElements = selectedFilters.filter(
      filter => filter.key !== key
    );
    const newFilters = selectedFiltersWithoutKeyElements.concat(
      structeredValues
    );
    setSelectedFilters(newFilters);
  };

  const handleSingle = event => {
    const key = event.target.name;
    const value = event.target.value;

    const structeredValues = {
      key,
      value
    };
    const selectedFiltersWithoutKeyElements = selectedFilters.filter(
      filter => filter.key !== key
    );
    selectedFiltersWithoutKeyElements.push(structeredValues);
    setSelectedFilters(selectedFiltersWithoutKeyElements);
  };

  const getSelectedMultiValue = key => {
    const data = selectedFilters
      .filter(item => item.key === key)
      .map(item => item.value);
    return data;
  };

  const getSelectedSingleValue = key => {
    const data = selectedFilters.filter(item => item.key === key);
    if (data.length) {
      return data[0].value;
    }
    return "";
  };

  const handleDelete = chipToDelete => {
    const newFilters = selectedFilters.filter(
      filter =>
        !(
          filter.value === chipToDelete.value && filter.key === chipToDelete.key
        )
    );
    //handle the special case of state. if the state is deleted delete the city also.
    setSelectedFilters(newFilters);
    setSearch("");
  };

  const handleFilterClear = () => {
    setSelectedFilters([]);
    setSearch("");
  };

  // ==================================================================================================
  // LAYOUT RENDERING =================================================================================
  // ==================================================================================================
  const getFilterLayout = () => {
    return filters.map((filter, index) => {
      const { name, type, key, data } = filter;
      switch (type) {
        case "multi": {
          return (
            <GridItem xs={4} sm={4} key={`${key}-${index}-${type}`}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="multiple-select"
                  className={classes.selectLabel}
                >
                  {name}
                </InputLabel>
                <Select
                  multiple
                  value={getSelectedMultiValue(key)}
                  onChange={handleMultiple}
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={{ select: classes.select }}
                  inputProps={{ name: key }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {name}
                  </MenuItem>
                  {data.map((item, index) => (
                    <MenuItem
                      key={`menu-${index}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          );
        }
        case "single": {
          return (
            <GridItem xs={4} sm={4} key={`${key}-${index}-${type}`}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  {name}
                </InputLabel>
                <Select
                  value={getSelectedSingleValue(key)}
                  onChange={handleSingle}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{ select: classes.select }}
                  inputProps={{ name: key }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {name}
                  </MenuItem>
                  {data.map((item, index) => (
                    <MenuItem
                      key={`menu-single-${index}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          );
        }
      }
    });
  };

  const collegeSearch = item => {
    if (item) setSelectedFilters([{ key: "name", value: item }]);
    else setSelectedFilters([]);
  };
  const debouncedSearch = useRef(debounce(500, q => collegeSearch(q))).current;
  const onChangeSearch = e => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <Card>
      <Divider style={{ backgroundColor: "#aa877f", margin: "0px 2px" }} />
      <GridContainer
        justify="space-between"
        alignItems="center"
        style={{ width: "100%", margin: "0px", padding: "0.3rem 0px" }}
      >
        <div style={{ paddingLeft: "1rem" }}>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "500"
            }}
          >
            Filters
          </h4>
        </div>
        <div>
          <Button
            color="transparent"
            simple
            onClick={handleFilterClear}
            className={classes.buttonLink}
          >
            Clear All
          </Button>
        </div>
      </GridContainer>
      <Divider light />
      <GridContainer>
        <GridItem xs={12}>
          <p
            style={{
              margin: "1rem 1rem 0rem 0rem",
              fontSize: "12px",
              fontWeight: "400",
              color: "black"
            }}
          >
            Selected Filters {" --> "}
            <span
              style={{
                color: "#1E88E5",
                borderColor: "#1E88E5",
                border: "1px solid",
                padding: "2px 12px",
                fontSize: "12px"
              }}
            >
              {selectedFilters.length}
            </span>
          </p>
          <div style={{ margin: "0.8rem" }}>
            {selectedFilters.map((filter, index) => (
              <Chip
                style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
                key={`${filter.key}-${index}`}
                label={filter.value}
                onDelete={() => handleDelete(filter)}
                className={classes.chip}
                variant="outlined"
                avatar={<Avatar>{filter.value[0]}</Avatar>}
              />
            ))}
          </div>
        </GridItem>
      </GridContainer>
      <Divider light />
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        style={{ padding: "0 5px 10px 5px" }}
      >
        {showSearchBar ? (
          <React.Fragment>
            <GridItem xs={8} container direction="row" spacing={2}>
              {getFilterLayout()}
            </GridItem>
            <GridItem xs={4}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="College Search"
                variant="outlined"
                value={search}
                size="small"
                color="primary"
                onChange={onChangeSearch}
              />
            </GridItem>
          </React.Fragment>
        ) : (
          getFilterLayout()
        )}
      </GridContainer>
    </Card>
  );
};
export default NewsletterFilter;
