import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import * as actions from "data/auth/actions";
import * as routesPath from "routes/routesPath";

import { connect } from "react-redux";

import queryString from "query-string";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      password: "",
      passwordState: "",
      passwordConfirm: "",
      passwordConfirmState: "",
      email: "",
      token: ""
    };
    this.resetPasswordClick = this.resetPasswordClick.bind(this);
  }

  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  resetPasswordClick(e) {
    e.preventDefault();
    //handle the password reset button click here. Also remember to make the api call from here.
    if (this.state.passwordState === "") {
      this.setState({ passwordState: "error" });
    }
    if (this.state.passwordConfirmState === "") {
      this.setState({ passwordConfirmState: "error" });
    }

    if (
      this.state.passwordState === "success" &&
      this.state.passwordConfirmState === "success"
    ) {
      const { email, password, token } = this.state;
      this.props.resetPassword(email, password, token, this.props.history);
    }
  }

  handleLoader() {
    if (this.props.appStateReset.loading) {
      return <CustomLinearProgress color="rose" />;
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const token = this.props.match.params.id;
    const email = values.email;
    if (email) {
      this.setState({ email, token });
    }
    // we add a hidden class to the card and after 500 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      500
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const { classes, appStateReset } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.resetPasswordClick}>
              <Card
                login
                background={appStateReset.loading ? true : false}
                className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose">
                  <h4 className={classes.cardTitle}>Reset Password</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText={this.state.email}
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      disabled: true
                    }}
                  />
                  <CustomInput
                    success={this.state.passwordState === "success"}
                    error={this.state.passwordState === "error"}
                    labelText="Password"
                    id="resetpassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      onChange: event =>
                        this.change(event, "password", "password")
                    }}
                  />
                  <CustomInput
                    success={this.state.passwordConfirmState === "success"}
                    error={this.state.passwordConfirmState === "error"}
                    labelText="Confirm Password"
                    id="resetconfirmpassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      onChange: event =>
                        this.change(
                          event,
                          "passwordConfirm",
                          "equalTo",
                          "password"
                        )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" type="submit">
                    Reset Now
                  </Button>
                </CardFooter>
                <Link to={routesPath.LOGIN_PAGE}>
                  <Button color="rose" simple block>
                    Login
                  </Button>
                </Link>
              </Card>
            </form>
            {this.handleLoader()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps({ auth }) {
  return { appStateReset: auth.appStateReset };
}

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    actions
  )(ResetPasswordPage)
);
