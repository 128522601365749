import React, { Component } from "react";
import { ErrorMessage } from "formik";

class FormikErrorMessage extends Component {
  render() {
    const { nameValue } = this.props;
    return (
      <ErrorMessage
        name={nameValue}
        render={msg => <div style={{ color: "red" }}>{msg}</div>}
      />
    );
  }
}

export default FormikErrorMessage;
