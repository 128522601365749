import {
  COLLEGE_BASIC_DATA_GET,
  COLLEGE_BASIC_DATA_RESET
} from "data/colleges/types";

// =================================================================================================
// REDUCER =========================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case COLLEGE_BASIC_DATA_GET:
      return action.data;
    case COLLEGE_BASIC_DATA_RESET:
      return {};
    default:
      return state;
  }
}
