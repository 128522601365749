// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import InputFormTables from "./InputFormTables.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FastField } from "formik";
import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
class CategoryInputFormTables extends Component {
  showValuesfromFormik = () => {
    const { values, setFieldValue } = this.props;
    const valuesData = values.form_basic.application_fee;
    return valuesData.map((data, index) => {
      return (
        <GridItem key={index} xs={12} md={4}>
          <InputFormTables
            key={data.gender_id}
            values={values}
            setFieldValue={setFieldValue}
            index={index}
          />
        </GridItem>
      );
    });
  };

  render() {
    const { values, setFieldValue, classes } = this.props;
    const priceDefaultValue = values.form_basic.application_fee_default;
    const priceDefaultKey = "form_basic.application_fee_default";
    return (
      <Card
        style={{
          backgroundColor: "#f9f9f9"
        }}>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>Application Fees</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer alignItems="center">
            <GridItem>
              <h6>Default Application Fee</h6>
            </GridItem>
            <GridItem>
              <FastField
                name={priceDefaultKey}
                component={BareTextFieldInput}
                placeholder="Enter Amount"
                price
                type="number"
                step={0.01}
                min={0}
                onBlur={e => {
                  const domain = parseFloat(e.target.value);
                  setFieldValue(priceDefaultKey, domain);
                }}
                defaultValue={isNaN(priceDefaultValue) ? "" : priceDefaultValue}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>{this.showValuesfromFormik()}</GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(tableFormStyles)(CategoryInputFormTables);
