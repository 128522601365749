import {
  ELEMENTS_APPSTATE_ALL_LOADING,
  ELEMENTS_APPSTATE_ALL_SUCCESS,
  ELEMENTS_APPSTATE_ALL_ERROR,
  ELEMENTS_APPSTATE_ONE_LOADING,
  ELEMENTS_APPSTATE_ONE_SUCCESS,
  ELEMENTS_APPSTATE_ONE_ERROR,
  ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX,
  ELEMENTS_APPSTATE_CURRENT_ROW_INDEX,
  ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX,
  ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX,
  ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX
} from "data/elements/types";

export default function reducer(
  state = {
    loading: false,
    rowLoading: false,
    rowError: null,
    error: null,
    isFeature: false,
    isStream: false,
    isBranch: false
  },
  action
) {
  switch (action.type) {
    case ELEMENTS_APPSTATE_ALL_LOADING:
      return { ...state, loading: true };
    case ELEMENTS_APPSTATE_ALL_SUCCESS:
      return { ...state, error: null, loading: false };
    case ELEMENTS_APPSTATE_ALL_ERROR:
      return { ...state, loading: false, error: action.error };
    case ELEMENTS_APPSTATE_ONE_LOADING:
      return { ...state, rowLoading: true };
    case ELEMENTS_APPSTATE_ONE_SUCCESS:
      return {
        ...state,
        rowError: null,
        rowLoading: false,
        isFeature: false,
        isStream: false,
        isBranch: false
      };
    case ELEMENTS_APPSTATE_ONE_ERROR:
      return {
        ...state,
        rowLoading: false,
        rowError: action.error,
        isFeature: false,
        isBranch: false,
        isStream: false
      };
    case ELEMENTS_APPSTATE_CURRENT_ELEMENT_INDEX:
      return { ...state, elementIndex: action.elementIndex };
    case ELEMENTS_APPSTATE_CURRENT_ROW_INDEX:
      return { ...state, rowIndex: action.rowIndex };
    case ELEMENTS_APPSTATE_CURRENT_FEATURE_INDEX: {
      const { elementIndex, featureIndex, featureElementIndex } = action;
      return {
        ...state,
        elementIndex,
        featureIndex,
        featureElementIndex,
        isFeature: true
      };
    }
    case ELEMENTS_APPSTATE_CURRENT_STREAM_INDEX: {
      const { elementIndex, streamIndex, streamElementIndex } = action;
      return {
        ...state,
        elementIndex,
        streamIndex,
        streamElementIndex,
        isStream: true
      };
    }
    case ELEMENTS_APPSTATE_CURRENT_BRANCH_INDEX: {
      const {
        elementIndex,
        streamIndex,
        streamElementIndex,
        courseIndex
      } = action;
      return {
        ...state,
        elementIndex,
        streamIndex,
        streamElementIndex,
        courseIndex,
        isBranch: true
      };
    }
    default:
      return state;
  }
}
