import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
const styles = theme => ({
  textField: {
    margin: theme.spacing.unit
  },
  iconButton: {
    padding: 15
  },
  facebook2: {
    color: "#6798e5",
    // animationDuration: "550ms",
    position: "absolute",
    left: 0
  },
  success: {
    color: "green",
    fontSize: "20px"
  },
  failure: {
    color: "red",
    fontSize: "20px"
  },
  buttonStyle: {
    border: "none",
    cursor: "pointer",
    outline: "none",
    backgroundColor: "Transparent"
  }
});

class BareTextFieldInputOnChange extends Component {
  render() {
    const {
      classes,
      onBlur,
      label,
      defaultValue,
      price,
      placeholder,
      disabled,
      name,
      fullWidth,
      styleHeight,
      value,
      type,
      step,
      min,
      max,
      maxlength,
      progress,
      unique,
      error,
      onClearInput,
      variant,
      startText
    } = this.props;
    return (
      <TextField
        name={name || ""}
        style={
          !styleHeight
            ? { height: "40px", margin: "12px 0" }
            : { margin: "12px 0" }
        }
        id="outlined-bare"
        className={classes.textField}
        label={label}
        margin="dense"
        defaultValue={defaultValue}
        placeholder={placeholder}
        variant={variant || "outlined"}
        type={type || "text"}
        disabled={disabled || false}
        value={value}
        fullWidth={fullWidth}
        step={step}
        error={error || false}
        onChange={event => onBlur(event)}
        InputProps={{
          startAdornment: price ? (
            <InputAdornment position="start">
              <span>₹</span>
            </InputAdornment>
          ) : startText ? (
            <InputAdornment position="start">
              <span style={{ color: "blue" }}>{startText}</span>
            </InputAdornment>
          ) : null,
          endAdornment: progress ? (
            <InputAdornment position="end">
              <IconButton className={classes.iconButton} aria-label="Progress">
                <CircularProgress
                  className={classes.facebook2}
                  size={24}
                  thickness={6}
                />
              </IconButton>
            </InputAdornment>
          ) : unique === null || unique === undefined ? null : unique ? (
            <i className={`far fa-check-circle icon ${classes.success}`} />
          ) : (
            <button onClick={onClearInput} className={classes.buttonStyle}>
              <i className={`far fa-times-circle icon ${classes.failure}`} />
            </button>
          )
        }}
        inputProps={{
          step,
          min,
          max,
          maxLength: maxlength
        }}
      />
    );
  }
}

export default withStyles(styles)(BareTextFieldInputOnChange);
