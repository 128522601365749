import React, { Component } from "react";
import NavPills from "components/NavPills/NavPills.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import FormBasic from "views/Forms/EazyForms/FormBasic/FormBasic.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";

import DeleteAlertBox from "views/Forms/Elements/DeleteAlertBox.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormRankPredictionSection from "./FormRankPredictionSection";

const navPillData = [
  {
    displayButton: "Basic",
    component: FormBasic
  },
  {
    displayButton: "Rank Prediction",
    component: FormRankPredictionSection
  }
];

class FormBasicTabs extends Component {
  state = {
    showDeleteAlert: false
  };

  handleAlertSuccess = () => {
    this.setState({ showDeleteAlert: false });
    this.props.history.push("/form-listing");
  };

  showDeleteAlertHandler = () => {
    if (this.state.showDeleteAlert) {
      return (
        <DeleteAlertBox
          handleSuccess={this.handleAlertSuccess}
          handleClose={() => this.setState({ showDeleteAlert: false })}
          title="Leave Form Info ?"
        />
      );
    }
  };

  formTabsData = () => {
    const { id } = this.props.match.params;
    const { history, location } = this.props;
    const data = navPillData.map(item => {
      return {
        tabButton: item.displayButton,
        tabContent: (
          <Card
            style={{
              backgroundColor: "#f7f7f7"
            }}
          >
            <CardBody>
              <item.component history={history} id={id} location={location} />
            </CardBody>
          </Card>
        )
      };
    });
    return data;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              size="sm"
              title="Go Back"
              onClick={() => {
                this.setState({ showDeleteAlert: true });
              }}
              className={classes.customBackButton}
            >
              <KeyboardArrowLeft />
              BACK
            </Button>
          </GridItem>
        </GridContainer>
        {this.showDeleteAlertHandler()}
        <GridContainer>
          <GridItem xs={12}>
            <NavPills color="rose" tabs={this.formTabsData()} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(formStyle)(FormBasicTabs);
