import React, { Component } from "react";
import StreamDisplay from "views/College/Basic/Stream/StreamDisplay";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import formStyle from "assets/jss/material-dashboard-pro-react/components/forms/formStyle.jsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "views/Components/ExpansionPanelCustomized.jsx";
class StreamsAccordion extends Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  /*
  tabsData = () => {
    const { streams, setFieldValue } = this.props;
    return streams.map((item, index) => {
      return {
        tabName: item.full_name,
        tabContent: (
          <StreamDisplay
            streamData={streams[index] ? streams[index] : []}
            setFieldValue={setFieldValue}
            streamIndex={index}
          />
        )
      };
    });
  };*/

  createLayout = () => {
    const { streams, setFieldValue } = this.props;
    const { expanded } = this.state;
    return streams.map((item, index) => {
      return (
        <ExpansionPanel
          square
          expanded={expanded === index.toString()}
          onChange={this.handleChange(index.toString())}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {item.full_name}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <StreamDisplay
              streamData={item || []}
              setFieldValue={setFieldValue}
              streamIndex={index}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  render() {
    const { streams } = this.props;
    if (streams.length === 0) {
      return null;
    }
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12}>{this.createLayout()}</GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(formStyle)(StreamsAccordion);
