import React, { Component } from "react";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange.jsx";
import { FastField } from "formik";
import LocationSearchInput from "views/Components/College/LocationSearchInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";
import FormikErrorMessage from "views/College/Basic/CollegeBasic/FormikErrorMessage.jsx";

class AutoPopulateAddress extends Component {
  searchedResultHandler = (value, selectedValue) => {
    const { setFieldValue, values } = this.props;
    if (values.id) {
      setFieldValue("address.id", values.id);
    }
    if (value) {
      // setFieldValue("full_address", value);
      setFieldValue("address.selected_address", selectedValue);
      const newSelectedAddress = selectedValue.split(",");
      setFieldValue("address.line_1", newSelectedAddress[0]);
      const address_data = value.address_components.map(item => {
        switch (item.types[0]) {
          // case "political":
          //   setFieldValue("address.line_1", item.long_name);
          //   break;
          case "route":
          case "political":
          case "sublocality":
            setFieldValue("address.line_2", item.long_name);
            break;
          case "administrative_area_level_2":
            setFieldValue("address.district", item.short_name);
            break;
          case "locality":
            setFieldValue("address.city", item.long_name);
            break;
          case "administrative_area_level_1":
            setFieldValue("address.state", item.long_name);
            break;
          case "country":
            setFieldValue("address.country", item.long_name);
            break;
          case "postal_code":
            setFieldValue("address.pincode", item.long_name);
            break;
          default:
            break;
        }
      });
    }
  };
  clearAddressResultHandler = () => {
    const { setFieldValue, values } = this.props;
    let address = {
      line_1: "",
      line_2: "",
      city: "",
      pincode: null,
      district: "",
      state: "",
      country: "",
      lat: "",
      lng: "",
      selected_address: ""
    };
    setFieldValue("address", address);
    setFieldValue("address.id", values.id);
  };

  setLatAndLngHandler = value => {
    const { setFieldValue } = this.props;
    if (value) {
      setFieldValue("address.lat", value.lat);
      setFieldValue("address.lng", value.lng);
    }
  };

  render() {
    const { values, setFieldValue, classes } = this.props;
    return (
      <React.Fragment>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>College Address</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <LocationSearchInput
                  searchedResults={(value, selectedValue) =>
                    this.searchedResultHandler(value, selectedValue)
                  }
                  clearAddressResult={this.clearAddressResultHandler}
                  setLatAndLng={value => this.setLatAndLngHandler(value)}
                  selectedAddress={values.selected_address}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      Address Line 1<span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <p style={{ fontWeight: "bold" }}>
                      {" "}
                      Address Line 1<span style={{ color: "red" }}>*</span>
                    </p> */}
                    <FastField
                      name={`address.line_1`}
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="Address Line 1"
                      onBlur={e => {
                        setFieldValue(`address.line_1`, e.target.value);
                      }}
                      value={values.line_1}
                    />
                    <FormikErrorMessage nameValue="address.line_1" />
                  </GridItem>
                  <GridItem xs={12}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      Address Line 2
                    </label>
                    <FastField
                      name={`address.line_2`}
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="Address Line 2"
                      onBlur={e => {
                        setFieldValue(`address.line_2`, e.target.value);
                      }}
                      value={values.line_2}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      City
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <FastField
                      name="address.city"
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="City"
                      onBlur={e => {
                        setFieldValue(`address.city`, e.target.value);
                      }}
                      value={values.city}
                    />
                    <FormikErrorMessage nameValue="address.city" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      Pincode
                    </label>
                    <FastField
                      name="address.pincode"
                      fullWidth
                      type="number"
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="Pin Code"
                      onBlur={e => {
                        setFieldValue(
                          `address.pincode`,
                          parseInt(e.target.value)
                        );
                      }}
                      value={values.pincode ? values.pincode : ""}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      District
                    </label>
                    <FastField
                      name={`address.district`}
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="district"
                      onBlur={e => {
                        setFieldValue(`address.district`, e.target.value);
                      }}
                      value={values.district || ""}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      State
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <FastField
                      name={`address.state`}
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="State"
                      onBlur={e => {
                        setFieldValue(`address.state`, e.target.value);
                      }}
                      value={values.state}
                    />
                    <FormikErrorMessage nameValue="address.state" />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <label style={{ fontWeight: "bold", color: "#404040" }}>
                      Country
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <FastField
                      name={`address.country`}
                      fullWidth
                      disabled
                      component={BareTextFieldInputOnChange}
                      placeholder="Country"
                      onBlur={e => {
                        setFieldValue(`address.country`, e.target.value);
                      }}
                      value={values.country}
                    />
                    <FormikErrorMessage nameValue="address.country" />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(tableFormStyles)(AutoPopulateAddress);
