// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";

import BareTextFieldInput from "views/Components/BareTextFieldInput.jsx";
import { FastField } from "formik";

import tableFormStyles from "assets/jss/material-dashboard-pro-react/components/forms/tableFormStyles.jsx";

import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class MultipleColumnInputTable extends Component {
  multipleInputTableColumns = (item, index1) => {
    const { setFieldValue, classes, sectionKey } = this.props;
    const fieldKey = `${sectionKey}[${index1}].data`;
    let inputData = "";
    return item.data.map((data, index2) => {
      if (data.name === "instructions" || data.name === "marks_grade")
        inputData = data.input_value;
      else
        isNaN(data.input_value)
          ? (inputData = "")
          : (inputData = data.input_value);
      return (
        <TableCell className={classes.tableCell} key={index2}>
          <FastField
            name={`${fieldKey}[${index2}].input_value`}
            placeholder={`Enter ${data.display_name}`}
            type={data.type}
            step={data.name === "year_of_passing" ? 1 : 0.01}
            min={0}
            max={
              data.name === "percentage_creteria" ||
              data.name === "marks_percentage"
                ? 100
                : "any"
            }
            component={BareTextFieldInput}
            onBlur={e => {
              var domain = null;
              if (data.name === "instructions" || data.name === "marks_grade") {
                domain = e.target.value;
              } else {
                domain = parseFloat(e.target.value);
              }
              setFieldValue(`${fieldKey}[${index2}].input_value`, domain);
            }}
            defaultValue={inputData}
          />
        </TableCell>
      );
    });
  };

  render() {
    const { classes, tableHead, defaultValue, headerName } = this.props;
    return (
      <div>
        <GridContainer justify="flex-start">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{headerName}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHead className={classes["primary"]}>
                    <TableRow className={classes.tableRow}>
                      {tableHead.map((prop, key) => {
                        {
                          const tableCellClasses =
                            classes.tableHeadCell + " " + classes.tableCell;

                          return (
                            <TableCell key={key} className={tableCellClasses}>
                              {prop}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {defaultValue.map((item, index1) => {
                      return (
                        <TableRow key={index1} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            {`${item.full_name}${" "}(${item.short_name})`}
                          </TableCell>
                          {this.multipleInputTableColumns(item, index1)}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(tableFormStyles)(MultipleColumnInputTable);
