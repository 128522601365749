import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";

import BasicDateSelector from "views/Forms/EazyForms/FormBasic/BasicDateSelector.jsx";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import navPillsStyle from "assets/jss/material-dashboard-pro-react/components/navPillsStyle.jsx";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const dateData = [
  {
    selector_type: "single",
    selector_date: ""
  },
  {
    selector_type: "multi",
    selector_date: ["12/01/2018", "15/02/2019", "20/03/2019"]
  },
  {
    selector_type: "range",
    selector_date: {
      start: "12/01/2018",
      end: "12/15/2018"
    }
  }
];

class HandlingDatesSelector extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      active: null,
      openDateModal: false,
      okClicked: false
    };
  }

  componentDidMount() {
    const { values, mainIndex } = this.props;
    const data = values.form_basic.dates[`${mainIndex}`].data;
    const selector_type = data.selector_type || "single";
    var active = 0;
    switch (selector_type) {
      case "single":
        active = 0;
        break;
      case "multi":
        active = 1;
        break;
      case "range":
        active = 2;
        break;
      default:
        break;
    }
    this.setState({ active });
  }

  handleChange = (event, active) => {
    this.setState({ active });
  };
  // handleChangeIndex = index => {
  //   this.setState({ active: index });
  // };

  render() {
    const {
      classes,
      horizontal,
      alignCenter,
      setFieldValue,
      mainIndex,
      values
    } = this.props;
    const flexContainerClasses = classNames({
      [classes.flexContainer]: true,
      [classes.horizontalDisplay]: horizontal !== undefined
    });
    return (
      <React.Fragment>
        <Tabs
          classes={{
            root: classes.root,
            fixed: classes.fixed,
            flexContainer: flexContainerClasses,
            indicator: classes.displayNone
          }}
          value={this.state.active}
          onChange={this.handleChange}
          centered={alignCenter}>
          {dateData.map((tabBar, index) => {
            const pillsClasses = classNames({
              [classes.pills]: true,
              [classes.horizontalPills]: horizontal !== undefined,
              [classes.pillsWithIcons]: tabBar.tabIcon !== undefined
            });

            return (
              <Tab
                label={tabBar.selector_type}
                key={index}
                classes={{
                  root: pillsClasses,
                  labelContainer: classes.labelContainer,
                  label: classes.label,
                  selected: classes["rose"]
                }}
              />
            );
          })}
        </Tabs>
        <br />
        <BasicDateSelector
          tabActiveIndex={this.state.active}
          onRef={ref => (this.child = ref)}
          onClose={this.props.onClose}
          onDateSelectCheck={noDateSelected =>
            this.setState({ noDateSelected })
          }
          setFieldValue={setFieldValue}
          valuesData={values.form_basic.dates[`${mainIndex}`].data}
          mainIndex={mainIndex}
        />
        <br />
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              simple
              onClick={this.props.onClose}
              style={{ color: "grey" }}>
              Cancel
            </Button>

            <Button
              onClick={() => {
                this.child.submitOkButton();
              }}
              color="rose">
              Apply
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(navPillsStyle)(HandlingDatesSelector);
