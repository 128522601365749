import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import SvgWrapper from "views/Components/College/SvgWrapper";
import { Button } from "@material-ui/core";

class Facility extends Component {
  render() {
    const { facData, handleChange, isSelected } = this.props;
    return (
      <GridItem sm={2}>
        <Button
          onClick={() => handleChange(facData.id)}
          style={{
            display: "block",
            textAlign: "center",
            width: "100%",
            marginBottom: "50px"
          }}
        >
          <div>
            <SvgWrapper iconId={facData.icon_path} isSelected={isSelected} />
          </div>
          <div
            style={{
              textAlign: "center",
              color: isSelected ? "#216caf" : "#4d4d4d"
            }}
          >
            {facData.full_name}
          </div>
        </Button>
      </GridItem>
    );
  }
}

export default Facility;
