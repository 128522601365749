import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Card from "../../../components/Card/Card.jsx";
import MultiCollegeEdit from "./MultiCollegeEdit";

import { makeStyles } from "@material-ui/core/styles";
const modalStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  loader: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

const MultiCollegeModal = ({ openModal, handleModalClose }) => {
  const classesModal = modalStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classesModal.modal}
      open={openModal}
      onClose={handleModalClose}
      closeaftertransit000={"true"}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={openModal}>
        <MultiCollegeEdit />
      </Fade>
    </Modal>
  );
};

export default MultiCollegeModal;
