import {
  COLLEGES_DATA_CURRENT_PAGE,
  COLLEGES_DATA_PAGE_START
} from "data/colleges/types";

export const setCurrentPAge = (page, pageLimit) => async dispatch => {
  dispatch({ type: COLLEGES_DATA_CURRENT_PAGE, currentPage: page });
  dispatch({
    type: COLLEGES_DATA_PAGE_START,
    pageStart: pageLimit * (page - 1)
  });
};
