import {
  COLLEGE_BASIC_APPSTATE_LOADING,
  COLLEGE_BASIC_APPSTATE_SUCCESS,
  COLLEGE_BASIC_APPSTATE_ERROR,
  COLLEGE_BASIC_APPSTATE_RESET
} from "data/colleges/types";

const initialState = {
  loading: false,
  error: null,
  navigateBack: false,
  checkNewCollegeData: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COLLEGE_BASIC_APPSTATE_LOADING:
      return { ...state, loading: true, navigateBack: false };
    case COLLEGE_BASIC_APPSTATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        navigateBack: action.navigateBack || false,
        checkNewCollegeData: false
      };
    case COLLEGE_BASIC_APPSTATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        navigateBack: false,
        checkNewCollegeData: true
      };
    case COLLEGE_BASIC_APPSTATE_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        navigateBack: false,
        checkNewCollegeData: false
      };
    default:
      return state;
  }
}
