import React, { useState, useEffect } from "react";
import BareTextFieldInputOnChange from "views/Components/BareTextFieldInputOnChange";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MaterialUIChips from "views/College/Basic/MaterialUIChips.jsx";
import Contacts from "@material-ui/icons/Contacts";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

const ContactDisplay = props => {
  const [id, setId] = useState(props.id);
  const [full_name, setFullName] = useState(props.full_name);
  const [department, setDepartment] = useState(props.department);
  const [emails, setEmails] = useState(props.emails);
  const [phones, setPhones] = useState(props.phones);
  const [faxes, setFaxes] = useState(props.faxes || []);

  useEffect(
    () => {
      setId(props.id);
      setFullName(props.full_name);
      setDepartment(props.department);
      setEmails(props.emails);
      setPhones(props.phones);
    },
    [props.id]
  );

  const handleSubmit = () => {
    const data = {
      full_name,
      department,
      phones,
      emails
    };
    let finalData = {};
    if (id) {
      finalData = { ...data, id };
    } else {
      finalData = { ...data };
    }
    return props.onSubmit(finalData);
  };

  // render() {
  return (
    <GridItem xs={12} sm={6}>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Contacts />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <br />
              <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                Contact Person Name
              </p>
              <BareTextFieldInputOnChange
                fullWidth
                placeholder="Enter Contact Person Name"
                name="full_name"
                value={full_name}
                onBlur={event => setFullName(event.target.value)}
              />
            </GridItem>
            <GridItem xs={12}>
              <br />
              <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                Department Name
              </p>
              <BareTextFieldInputOnChange
                fullWidth
                placeholder="Enter Department Name"
                name="department"
                value={department}
                onBlur={event => setDepartment(event.target.value)}
              />
            </GridItem>
            <GridItem xs={12}>
              <br />
              <p style={{ fontWeight: "bold", marginBottom: "12px" }}>
                Mobile No
              </p>
              <MaterialUIChips
                name="phones"
                required
                limit={10}
                placeholder="Phone/Mobile Number."
                regValidate={/\+?\d[\d -]{8,12}\d/}
                handleTags={value => setPhones(value)}
                value={phones}
              />
              {/* <BareTextFieldInput
                type="number"
                name="mobile_no"
                required
                onBlur={event => this.handleInputData(event)}
              /> */}
            </GridItem>
            <GridItem xs={12}>
              <br />
              <br />
              <p style={{ fontWeight: "bold", marginBottom: "12px" }}>
                Email id
              </p>
              <MaterialUIChips
                name="emails"
                required
                limit={10}
                placeholder="Email IDs."
                regValidate={/\S+@\S+\.\S+/}
                handleTags={value => setEmails(value)}
                value={emails}
              />
            </GridItem>
            <GridItem xs={12}>
              <br />
              <br />
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button
                    color="danger"
                    simple
                    onClick={() => props.onDelete(id, props.index)}>
                    Delete
                  </Button>
                  <Button
                    title="Add New Form"
                    color="success"
                    onClick={handleSubmit}>
                    SAVE
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default ContactDisplay;
